import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Text, Button, Divider, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Card, CardBody, Flex, Stack, HStack, Image, Link } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { HiOutlineChevronRight } from 'react-icons/hi';

import { bannerDetailsAPI, bannerQuotaDetailsAPI } from 'api/marketing';
import { IBanner, IBannerQuota } from '../types';
import { IAdminRequest, IPagination, IParams, IUser } from 'common/types';
import { userDetailsAPI } from 'api/users';

import { buildQueryString, formatDate, isNullOrUndefined } from 'common/helpers';
import { bannerStatus, quotaPackageOptions } from '../constants';
import colors from 'theme/foundations/colors';
import { FaCheck, FaXmark } from 'react-icons/fa6';

import ConfirmAcceptBannerModal from './modals/confirmAcceptBanner';
import ConfirmDeleteBannerModal from './modals/confirmDeleteBanner';
import ConfirmRejectBannerModal from './modals/confirmRejectBanner';
import FullSizeImageModal from './modals/fullSizeImage';

import { BannerStatus } from '../enums/banners.enums';
import UserContactCard from 'components/userCard';
import { daysFromWeeksAndDays } from '../helpers';
import { reportsAPI } from 'api/shared';
import { AdminRequestStatus } from 'app/users/enums/users.enums';
import { AdminRequestType } from 'app/reports/enums/admin.requests.enums';

const BannerDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    const [data, setData] = useState<IBanner>();
    const [user, setUser] = useState<IUser>();
    const [quota, setQuota] = useState<IBannerQuota>();
    const [paginator, setPaginator] = useState<Partial<IPagination>>({
        orderDir: 'DESC',
        orderBy: 'created_at',
        perPage: Number.MAX_SAFE_INTEGER,
    });
    const [adminRequests, setAdminRequests] = useState<IAdminRequest[]>([]);

    const [selectedImg, setSelectedImg] = useState<string>('');
    const [openImgModal, setOpenImgModal] = useState<boolean>(false);

    const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);
    const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    const toggleAcceptModal = () => {
        setAcceptModalOpen(!acceptModalOpen);
    };

    const toggleRejectModal = () => {
        setRejectModalOpen(!rejectModalOpen);
    };

    const toggleDeleteModal = () => {
        setDeleteModalOpen(!deleteModalOpen);
    };

    const toggleImgModal = () => {
        setOpenImgModal(!openImgModal);
    };

    const openImageInFullSize = (uri: string) => {
        setSelectedImg(uri);
        toggleImgModal();
    };

    const getBannerDetails = async () => {
        try {
            const response = await bannerDetailsAPI(id?.toString() ?? '');
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getAdminRequests = async () => {
        try {
            const params: IParams = {
                ...paginator,
                page: 1,
                status: `${AdminRequestStatus.CREATED}`,
                type: `[${AdminRequestType.BANNER_REQUESTED}]`,
            };

            const queryString = buildQueryString(params);
            const response = await reportsAPI(queryString);
            setAdminRequests(response.data.results);
            setPaginator({ ...paginator, totalPages: response.data.paginator.totalPages });
        } catch (error) {
            console.log(error);
        }
    };

    const getUserDetails = async () => {
        if (data?.userUUID) {
            try {
                const response = await userDetailsAPI(data?.userUUID);
                setUser(response.data);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getBannerQuotaDetails = async (id: number) => {
        try {
            const response = await bannerQuotaDetailsAPI(id.toString());
            setQuota(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getBannerDetails();
        getAdminRequests();
    }, []);

    useEffect(() => {
        getUserDetails();
    }, [data?.userUUID]);

    useEffect(() => {
        if (data?.bannerQuotaID) getBannerQuotaDetails(data.bannerQuotaID);
    }, [data?.bannerQuotaID]);

    return data ? (
        <Box>
            <Breadcrumb spacing="8px" separator={<HiOutlineChevronRight color="gray.500" />} m="24px 0">
                <BreadcrumbItem>
                    <BreadcrumbLink href="/marketing">{t('MENU.MARKETING')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href="#">
                        {t('BANNER')} {id}
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <Flex alignItems="flex-start" direction={{ base: 'column', md: 'row' }} gap="24px">
                <Card maxWidth={'755px'} w="100%">
                    <CardBody>
                        <Stack>
                            <HStack>
                                <Text>{t('QUOTA.PACKAGE_LABEL') + ': ' + t(quotaPackageOptions.find((option) => option.id === quota?.packageID)?.label ?? 'GENERAL.NOT_FOUND')}</Text>
                            </HStack>
                            <HStack>
                                <Text>
                                    <Trans i18nKey={'QUOTA.URI'} />
                                </Text>
                                <Link fontSize={'16px'} href={data.redirectURI} target="_blank">
                                    {data.redirectURI}
                                </Link>
                            </HStack>
                        </Stack>

                        <Divider color="brand.menuBorder" m="16px 0" />
                        <Stack gap="24px">
                            <Text fontWeight={600}>
                                <Trans i18nKey="BANNER.LINKED_FILES" />
                            </Text>
                            <Stack gap="20px">
                                {data?.webBannerURI && (
                                    <Image onClick={() => openImageInFullSize(data.webBannerURI)} cursor={'pointer'} maxW={'710px'} maxHeight={'114px'} objectFit={'cover'} src={data?.webBannerURI} />
                                )}
                                {data?.mobileBannerURI && (
                                    <Image
                                        onClick={() => openImageInFullSize(data.mobileBannerURI)}
                                        cursor={'pointer'}
                                        maxW={'502px'}
                                        maxHeight={'114px'}
                                        objectFit={'cover'}
                                        src={data?.mobileBannerURI}
                                    />
                                )}
                            </Stack>
                            <HStack gap={0}>
                                <Text style={{ border: `1px solid ${colors.brand.menuBorder}`, padding: '8px', width: '100%' }}>{user?.username ?? t('USER.NOT_FOUND')}</Text>
                                <Text style={{ border: `1px solid ${colors.brand.menuBorder}`, padding: '8px', width: '100%' }}>{formatDate(data?.createdAt)}</Text>
                                <Text style={{ border: `1px solid ${colors.brand.menuBorder}`, padding: '8px', width: '100%' }}>
                                    {t(bannerStatus.find((status) => status.status === data?.status)?.label ?? 'GENERAL.NOT_FOUND')}
                                </Text>
                                <Text style={{ border: `1px solid ${colors.brand.menuBorder}`, padding: '8px', width: '100%' }}>{`${daysFromWeeksAndDays(
                                    data?.bannerQuota.durationWeeks ?? 1,
                                    data?.bannerQuota.durationDays ?? 1,
                                )} ${t('QUOTA.DAYS')}`}</Text>
                            </HStack>

                            {data?.status === BannerStatus.REQUESTED && !isNullOrUndefined(user) ? (
                                <HStack>
                                    <Button variant={'danger'} leftIcon={<FaXmark />} onClick={toggleRejectModal}>
                                        <Trans i18nKey={'GENERAL.DECLINE'} />
                                    </Button>
                                    <Button variant={'dark'} leftIcon={<FaCheck />} onClick={toggleAcceptModal}>
                                        <Trans i18nKey={'GENERAL.ACCEPT'} />
                                    </Button>
                                </HStack>
                            ) : (
                                <Button variant={'danger'} leftIcon={<FaXmark />} w="fit-content" onClick={toggleDeleteModal}>
                                    <Trans i18nKey={'BANNER.REMOVE'} />
                                </Button>
                            )}
                        </Stack>
                    </CardBody>
                </Card>
                <Stack w="100%" maxW={{ base: '100%', md: '360px' }}>
                    <UserContactCard userUUID={data.userUUID} translation="BANNER.USER" />
                </Stack>
            </Flex>
            <ConfirmAcceptBannerModal isOpen={acceptModalOpen} toggleOpen={toggleAcceptModal} username={user?.username} banner={data} adminRequests={adminRequests} />
            <ConfirmRejectBannerModal isOpen={rejectModalOpen} toggleOpen={toggleRejectModal} banner={data} adminRequests={adminRequests} />
            <ConfirmDeleteBannerModal isOpen={deleteModalOpen} toggleOpen={toggleDeleteModal} username={user?.username} banner={data} />
            <FullSizeImageModal isOpen={openImgModal} toggleOpen={toggleImgModal} imageURI={selectedImg} />
        </Box>
    ) : null;
};

export default BannerDetails;
