import { Trans, useTranslation } from 'react-i18next';
import { Button, Divider, Modal, ModalBody, ModalContent, ModalOverlay, Stack, Text, Code, ModalCloseButton } from '@chakra-ui/react';

import { IArticleCategory, IArticleCategoryField, IArticleCategoryFieldOption } from 'app/articles/types';
import { compareCategories, compareCategoriesObjects, compareFieldOptions, compareFields } from 'app/category/utils/helpers';
import { useEffect, useState } from 'react';

interface IProps {
    oldCategory: IArticleCategory;
    newCategory: IArticleCategory;
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    onSubmit: () => void;
}

const ChangesModal = ({ oldCategory, newCategory, openModal, setOpenModal, onSubmit }: IProps) => {
    const { t } = useTranslation();

    const [changes, setChanges] = useState<any>();

    const toggleOpenModal = () => {
        setOpenModal(!openModal);
    };

    const handleSubmit = () => {
        toggleOpenModal();
        onSubmit();
    };

    useEffect(() => {
        setChanges(compareCategories(oldCategory, newCategory));
    }, [openModal]);

    interface ChangesDisplayProps {
        fields: any[];
        type: 'added' | 'deleted' | 'edited';
        title: string;
        color: string;
    }

    interface ChangesOptionsDisplayProps {
        fieldOptions: any[];
        type: 'added' | 'deleted' | 'edited';
        title: string;
        color: string;
    }

    const displayChangesFields = ({ fields, type, title, color }: ChangesDisplayProps) => {
        return (
            <Stack mt="16px" gap="2px">
                <Text fontWeight={600} color={color}>
                    {title}
                </Text>
                {fields?.length > 0 ? (
                    fields.map((field: IArticleCategoryField) => (
                        <Stack key={field.id}>
                            <Text fontSize={'14px'} maxW="542px" color="black.60">
                                {field.translations.bs}
                            </Text>
                            {type === 'edited' && (
                                <Stack gap="1px" m={'-4px 0 8px 0'}>
                                    {compareFields(field, oldCategory?.productCategoryFields?.find((oldField) => oldField.id === field.id)).map((fieldChange) => (
                                        <Code key={fieldChange} fontSize={'12px'} children={fieldChange} />
                                    ))}
                                </Stack>
                            )}
                        </Stack>
                    ))
                ) : (
                    <Text fontSize={'14px'} color="black.40" fontStyle={'italic'}>
                        {t('CATEGORY.NO_CHANGES')}
                    </Text>
                )}
            </Stack>
        );
    };

    const displayChangesOptions = ({ fieldOptions, type, title, color }: ChangesOptionsDisplayProps) => {
        return (
            <Stack mt="16px" gap="2px">
                <Text fontWeight={600} color={color}>
                    {title}
                </Text>
                {fieldOptions?.length > 0 ? (
                    fieldOptions.map((fieldOption: IArticleCategoryFieldOption) => (
                        <Stack key={fieldOption.id}>
                            <Text fontSize={'14px'} maxW="542px" color="black.60">
                                {fieldOption.translations.bs}
                            </Text>
                            {type === 'edited' && (
                                <Stack gap="1px" m={'-4px 0 8px 0'}>
                                    {compareFieldOptions(
                                        fieldOption,
                                        oldCategory?.productCategoryFields?.flatMap((field) => field.productCategoryFieldOptions)?.find((oldOption) => oldOption.id === fieldOption.id),
                                    ).map((optionChange) => (
                                        <Code key={optionChange} fontSize={'12px'} children={optionChange} />
                                    ))}
                                </Stack>
                            )}
                        </Stack>
                    ))
                ) : (
                    <Text fontSize={'14px'} color="black.40" fontStyle={'italic'}>
                        {t('CATEGORY.NO_CHANGES')}
                    </Text>
                )}
            </Stack>
        );
    };

    return (
        <Modal isOpen={openModal} onClose={toggleOpenModal} isCentered closeOnEsc={true} closeOnOverlayClick={true} scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent bgColor={'white'}>
                <ModalCloseButton />

                <ModalBody>
                    <Stack mt="16px" spacing="4px">
                        <Text>
                            <Trans i18nKey={'CATEGORY.CHANGES_LIST'} />
                        </Text>
                    </Stack>

                    <Divider color="brand.menuBorder" m="16px 0px" />
                    {compareCategoriesObjects(newCategory, oldCategory)?.length > 0 ? (
                        <Stack>
                            {compareCategoriesObjects(newCategory, oldCategory).map((categoryChange) => (
                                <Code key={categoryChange} fontSize={'12px'} children={categoryChange} />
                            ))}
                        </Stack>
                    ) : (
                        <Text fontSize={'14px'} color="black.40" fontStyle={'italic'}>
                            {t('CATEGORY.NO_CHANGES')}
                        </Text>
                    )}
                    <Divider color="brand.menuBorder" m="16px 0px" />

                    {changes?.deletedFields && displayChangesFields({ fields: changes.deletedFields, type: 'deleted', title: t('CATEGORY.DELETED_FIELDS'), color: 'red.500' })}

                    {changes?.updatedFields && displayChangesFields({ fields: changes.updatedFields, type: 'edited', title: t('CATEGORY.UPDATED_FIELDS'), color: 'orange.300' })}

                    {changes?.addedFields && displayChangesFields({ fields: changes.addedFields, type: 'added', title: t('CATEGORY.NEW_FIELDS'), color: 'green.400' })}

                    <Divider color="brand.menuBorder" m="16px 0px" />

                    {changes?.deletedOptions && displayChangesOptions({ fieldOptions: changes.deletedOptions, type: 'deleted', title: t('CATEGORY.DELETED_OPTIONS'), color: 'red.500' })}

                    {changes?.updatedOptions && displayChangesOptions({ fieldOptions: changes.updatedOptions, type: 'edited', title: t('CATEGORY.UPDATED_OPTIONS'), color: 'orange.300' })}

                    {changes?.addedOptions && displayChangesOptions({ fieldOptions: changes.addedOptions, type: 'added', title: t('CATEGORY.NEW_OPTIONS'), color: 'green.400' })}

                    <Button backgroundColor={'black.100'} color={'white'} m="16px 0" w="100%" onClick={handleSubmit}>
                        <Trans i18nKey={'GENERAL.CONTINUE'} />
                    </Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ChangesModal;
