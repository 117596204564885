import { useState } from 'react';
import { Box, Heading, Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import { Trans } from 'react-i18next';
import WalletRequests from './tabs/requests';
import WalletHistory from './tabs/history';
import WalletQuotas from './tabs/quotas';

const Wallet = () => {
    const [updateTrigger, setUpdateTrigger] = useState<boolean>(false);

    return (
        <Box>
            <Heading as="h1">
                <Trans i18nKey="WALLET.TITLE" />
            </Heading>

            <Tabs color="black.100" mt="32px">
                <TabList>
                    <Tab _selected={{ fontWeight: 'bold', borderBottom: '2px solid black' }}>
                        <Trans i18nKey={'WALLET.REQUESTS'} />
                    </Tab>
                    <Tab _selected={{ fontWeight: 'bold', borderBottom: '2px solid black' }}>
                        <Trans i18nKey={'WALLET.HISTORY'} />
                    </Tab>
                    <Tab _selected={{ fontWeight: 'bold', borderBottom: '2px solid black' }}>
                        <Trans i18nKey={'WALLET.QUOTAS'} />
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <WalletRequests updateTrigger={updateTrigger} setUpdateTrigger={setUpdateTrigger} />
                    </TabPanel>
                    <TabPanel>
                        <WalletHistory updateTrigger={updateTrigger} setUpdateTrigger={setUpdateTrigger} />
                    </TabPanel>
                    <TabPanel>
                        <WalletQuotas />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default Wallet;
