import { UserVerificationStatus } from '../enums/users.enums';

export const userVerificationStatuses = [
    {
        status: UserVerificationStatus.NOT_INITIATED,
        label: 'USER.VERIFICATION_STATUS_1',
    },
    {
        status: UserVerificationStatus.PENDING,
        label: 'USER.VERIFICATION_STATUS_2',
    },
    {
        status: UserVerificationStatus.VERIFIED,
        label: 'USER.VERIFICATION_STATUS_3',
    },
    {
        status: UserVerificationStatus.DECLINED,
        label: 'USER.VERIFICATION_STATUS_4',
    },
];

export const userActiveStatus = [
    {
        status: 1,
        label: 'USER.STATUS_1',
    },
    {
        status: 2,
        label: 'USER.STATUS_2',
    },
];
