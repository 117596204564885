import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Select, Stack, Text } from '@chakra-ui/react';

import { DecimalInput, Input } from 'components';

import { IPromotionQuota } from 'app/marketing/types';
import { PromotionQuotaRequestDTO } from 'api/marketing/types/request';
import { createPromotionQuotaAPI, editPromotionQuotaAPI } from 'api/marketing';

import { arePropsUndefined } from 'common/helpers';
import { mapToPromotionQuotaRequestDTO, weeksAndDays } from 'app/marketing/helpers';
import { config } from 'core';

interface IProps {
    toggleCreateModal: () => void;
    modalOpen: boolean;
    entryCreated: boolean;
    setEntryCreated: (value: boolean) => void;
    selectedQuota?: IPromotionQuota;
    setSelectedQuota?: Dispatch<SetStateAction<IPromotionQuota | undefined>> | undefined;
}
const CreatePromotionQuotaModal = ({ toggleCreateModal, modalOpen, setEntryCreated, entryCreated, selectedQuota, setSelectedQuota }: IProps) => {
    const { t } = useTranslation();
    const [quotaData, setQuotaData] = useState<Partial<PromotionQuotaRequestDTO> | undefined>(selectedQuota ? mapToPromotionQuotaRequestDTO(selectedQuota) : undefined);
    const [price, setPrice] = useState<string>('');

    const isSaveButtonDisabled = arePropsUndefined({
        price: price,
        durationDays: quotaData?.durationDays,
        packageID: quotaData?.externalPackageID,
    });

    const onClose = () => {
        if (selectedQuota && setSelectedQuota) {
            setSelectedQuota(undefined);
        }
        setPrice('');
        setQuotaData(undefined);
        toggleCreateModal();
    };

    const closeOnSuccess = () => {
        setEntryCreated(!entryCreated);
        onClose();
    };

    const createNewQuota = async () => {
        try {
            const priceInCents = Math.round(parseFloat(price || '0') * 100);

            await createPromotionQuotaAPI({
                ...quotaData,
                currency: 'BAM',
                price: priceInCents,
                durationDays: weeksAndDays(quotaData?.durationDays ?? 1).remainingDays,
                durationWeeks: weeksAndDays(quotaData?.durationDays ?? 1).weeks,
            });
            closeOnSuccess();
        } catch (error) {
            console.log(error);
        }
        toggleCreateModal();
    };

    const editQuota = async () => {
        if (selectedQuota) {
            try {
                const priceInCents = Math.round(parseFloat(price || '0') * 100);

                await editPromotionQuotaAPI(
                    {
                        ...quotaData,
                        currency: 'BAM',
                        price: priceInCents,
                        durationDays: weeksAndDays(quotaData?.durationDays ?? 1).remainingDays,
                        durationWeeks: weeksAndDays(quotaData?.durationDays ?? 1).weeks,
                    },
                    selectedQuota?.id.toString() ?? '1',
                );
                closeOnSuccess();
            } catch (error) {
                console.log(error);
            }
            toggleCreateModal();
        }
    };

    const onSubmit = () => {
        if (selectedQuota) {
            editQuota();
        } else {
            createNewQuota();
        }
    };

    useEffect(() => {
        if (selectedQuota) {
            const formattedPrice = selectedQuota.price / 100;
            setPrice(formattedPrice.toString());
            setQuotaData({ ...selectedQuota, price: selectedQuota.price / 100 });
        }
    }, [modalOpen]);

    return (
        <Box>
            <Modal isOpen={modalOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bgColor={'white'}>
                    <ModalHeader>
                        <Trans i18nKey={selectedQuota ? 'QUOTAS.EDIT' : 'QUOTAS.ADD'} />
                    </ModalHeader>
                    <Text p="0 24px">
                        <Trans i18nKey={'QUOTA.DESCRIPTION'} />
                    </Text>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack gap="16px" mt="16px">
                            <Stack gap="4px">
                                <Text>
                                    <Trans i18nKey={'QUOTA.PRICE_LABEL'} />
                                </Text>
                                <DecimalInput placeholder={t('QUOTA.PRICE_PLACEHOLDER')} value={price} onChange={(newValue) => setPrice(newValue)} />
                            </Stack>

                            <Stack gap="4px">
                                <Text>
                                    <Trans i18nKey={'QUOTA.DAYS_LABEL'} />
                                </Text>
                                <Input
                                    placeholder={t('QUOTA.DAYS_PLACEHOLDER')}
                                    name="numbeerOfDays"
                                    type="number"
                                    value={quotaData?.durationDays}
                                    onChange={(e) => setQuotaData({ ...quotaData, durationDays: parseInt(e.currentTarget.value) })}
                                />
                            </Stack>

                            <Stack gap="4px">
                                <Text>
                                    <Trans i18nKey={'QUOTA.EXTERNAL_LABEL'} />
                                </Text>
                                <Text fontSize={'12px'} color="black.60">
                                    {t('QUOTA.EXTERNAL_GUIDE', { env: config.NAKLIK_ENV })}
                                </Text>
                                <Input
                                    placeholder={t('QUOTA.EXTERNAL_PLACEHOLDER')}
                                    name="externalID"
                                    value={quotaData?.externalPackageID}
                                    onChange={(e) => setQuotaData({ ...quotaData, externalPackageID: e.currentTarget.value })}
                                />
                            </Stack>

                            <Stack gap="4px">
                                <Text>
                                    <Trans i18nKey={'QUOTA.CURRENCY_LABEL'} />
                                </Text>
                                <Select placeholder={t('QUOTA.CURRENCY_PLACEHOLDER')} disabled></Select>
                            </Stack>
                        </Stack>
                    </ModalBody>

                    <ModalFooter justifyContent={'flex-start'}>
                        <Button variant="dark" mr={3} onClick={onSubmit} isDisabled={isSaveButtonDisabled}>
                            <Trans i18nKey={'GENERAL.SAVE'} />
                        </Button>
                        <Button variant="outline" onClick={onClose}>
                            <Trans i18nKey={'GENERAL.CANCEL'} />
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default CreatePromotionQuotaModal;
