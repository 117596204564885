import { Box, Stack, Text } from '@chakra-ui/react';
import { CSSProperties } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import colors from 'theme/foundations/colors';
import { formatDate, isNullOrUndefined } from 'common/helpers';
import { IOrder } from 'app/orders/types';
import { OrderStatus } from 'app/orders/enums/orders.enums';
import { sortByDate } from 'app/orders/helpers';

interface IProps {
    data: IOrder;
    isOrderInSelectedStatus: (value: number) => boolean;
}

const TrackStatus = ({ data, isOrderInSelectedStatus }: IProps) => {
    const { t } = useTranslation();

    const getStyleBasedOnStatus = (active: boolean): CSSProperties => {
        return active
            ? {
                  border: `2px solid ${colors.brand.success}`,
                  backgroundColor: colors.green[10],
                  color: colors.brand.success,
              }
            : { backgroundColor: colors.grey[10] };
    };

    return (
        <Box border="1px solid" borderColor={'grey.100'} borderRadius={'10px'} p="24px">
            <Stack gap="16px">
                <Text fontWeight={600} fontSize="20px">
                    <Trans i18nKey={'ORDER.STATUS'} />
                </Text>

                {isOrderInSelectedStatus(OrderStatus.COMPLETED) && (
                    <Box p="12px" style={getStyleBasedOnStatus(isOrderInSelectedStatus(OrderStatus.COMPLETED))} borderRadius={'9px'} fontWeight={500}>
                        <Text>
                            <Trans i18nKey={'ORDER.COMPLETED'} />
                        </Text>
                        <Text fontSize="14px">{formatDate(data?.updatedAt)}</Text>
                    </Box>
                )}

                {data.delivery &&
                    data.delivery.deliveryStatuses &&
                    sortByDate(data.delivery.deliveryStatuses).map((delivery) => (
                        <Box p="12px" style={getStyleBasedOnStatus(!isOrderInSelectedStatus(OrderStatus.COMPLETED) && data.delivery?.status === delivery.status)} borderRadius={'9px'} fontWeight={500}>
                            <Text>{t(`ORDER.DELIVERY_STATUS_${delivery.status}`)}</Text>
                            <Text fontSize="14px">{formatDate(delivery.statusChangeDate)}</Text>
                        </Box>
                    ))}

                {(isOrderInSelectedStatus(OrderStatus.COMPLETED) || isOrderInSelectedStatus(OrderStatus.STARTED)) && (
                    <Box p="12px" style={getStyleBasedOnStatus(isOrderInSelectedStatus(OrderStatus.STARTED) && isNullOrUndefined(data.delivery))} borderRadius={'9px'} fontWeight={500}>
                        <Text>
                            <Trans i18nKey={'ORDER.STARTED'} />
                        </Text>
                        <Text fontSize="14px">{formatDate(data?.updatedAt)}</Text>
                    </Box>
                )}

                {(isOrderInSelectedStatus(OrderStatus.COMPLETED) || isOrderInSelectedStatus(OrderStatus.ACCEPTED)) && (
                    <Box p="12px" style={getStyleBasedOnStatus(isOrderInSelectedStatus(OrderStatus.ACCEPTED))} borderRadius={'9px'} fontWeight={500}>
                        <Text>
                            <Trans i18nKey={'ORDER.ACCEPTED'} />
                        </Text>
                        <Text fontSize="14px">{formatDate(data?.updatedAt)}</Text>
                    </Box>
                )}

                {isOrderInSelectedStatus(OrderStatus.DECLINED) && (
                    <Box p="12px" style={getStyleBasedOnStatus(isOrderInSelectedStatus(OrderStatus.DECLINED))} borderRadius={'9px'} fontWeight={500}>
                        <Text>
                            <Trans i18nKey={'ORDER.DECLINED'} />
                        </Text>
                        <Text fontSize="14px">{formatDate(data?.updatedAt)}</Text>
                    </Box>
                )}

                <Box p="12px" style={getStyleBasedOnStatus(isOrderInSelectedStatus(OrderStatus.CREATED))} borderRadius={'9px'} fontWeight={500}>
                    <Text>
                        <Trans i18nKey={'ORDER.CREATED'} />
                    </Text>
                    <Text fontSize="14px">{formatDate(data?.createdAt)}</Text>
                </Box>
            </Stack>
        </Box>
    );
};

export default TrackStatus;
