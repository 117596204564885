import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Menu, MenuButton, MenuList, MenuItem, Button, HStack, Center, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { HiOutlineChevronDown } from 'react-icons/hi';

import { IPagination, IParams, IUser } from 'common/types';

import { usersAPI } from 'api/users';
import { buildQueryString, getParamsFromLocalStorage, removeBracketsAndParseNumber, saveParamsToLocalStorage } from 'common/helpers';

import { Table } from 'components';
import Search from 'components/search/search';

import { mapUsersTableData, tableConfig } from '../utils/helpers';
import { userActiveStatus, userVerificationStatuses } from '../contants';
import LoadingModal from 'components/loading';

const Articles = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [data, setData] = useState<IUser[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [search, setSearch] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [isErr, setIsErr] = useState<boolean>(false);
    const [apiParams, setApiParams] = useState<IParams>();

    const [page, setPage] = useState<number>(1);
    const [paginator, setPaginator] = useState<Partial<IPagination>>({ perPage: 10 });
    const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
    const [selectedActiveStatus, setSelectedActiveStatus] = useState<number | null>(null);

    const handleSearchFilter = (e: FormEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
    };

    const statusPlaceholder = () => {
        return selectedStatus ? `${t(userVerificationStatuses.find((status) => status.status === selectedStatus)?.label ?? 'GENERAL.NOT_FOUND')}` : `${t('USER.VERIFICATION_STATUS_PLACEHOLDER')}`;
    };

    const statusActivePlaceholder = () => {
        return selectedActiveStatus ? `${t(userActiveStatus.find((status) => status.status === selectedActiveStatus)?.label ?? 'GENERAL.NOT_FOUND')}` : `${t('USER.STATUS_PLACEHOLDER')}`;
    };

    const toggleSelectedStatus = (status: number | undefined) => {
        if (status) {
            if (status === selectedStatus) {
                setPage(1);
                setSelectedStatus(null);
            } else setSelectedStatus(status);
        }
    };

    const toggleSelectedActiveStatus = (status: number | undefined) => {
        if (status) {
            if (status === selectedActiveStatus) {
                setPage(1);
                setSelectedActiveStatus(null);
            } else setSelectedActiveStatus(status);
        }
    };

    const toggleSearchTrigger = () => {
        setSearch(!search);
    };

    const handleClickSearch = () => {
        setPage(1);
        toggleSearchTrigger();
    };

    const handleClearSearch = () => {
        setFilter('');
        toggleSearchTrigger();
    };

    const changePage = (newPage: number | undefined) => {
        if (newPage) {
            setPage(newPage);
        }
    };

    const redirectToDetails = (item: IUser) => {
        if (apiParams) saveParamsToLocalStorage(apiParams, 'userParams');

        navigate(`/users/${item.uuid}`);
    };

    const getUsers = async () => {
        try {
            setLoading(true);
            let params: IParams;

            if (getParamsFromLocalStorage('userParams')) {
                params = getParamsFromLocalStorage('userParams');
                setPage(params.page ?? 1);
                setFilter(params.filter ?? '');
                toggleSelectedActiveStatus(Number(params.active));
                toggleSelectedStatus(removeBracketsAndParseNumber(params.status ?? ''));

                localStorage.removeItem('userParams');
            } else {
                params = {
                    ...paginator,
                    page: page,
                    filter: filter,
                };
                if (selectedStatus) {
                    params.status = `[${selectedStatus}]`;
                } else {
                    delete params.status;
                }

                if (selectedActiveStatus) {
                    params.active = selectedActiveStatus === 1;
                } else {
                    delete params.active;
                }
            }
            setApiParams(params);

            const queryString = buildQueryString(params);

            const res = await usersAPI(queryString);
            setData(res.data.results);
            setPaginator({ ...paginator, totalPages: res.data.paginator.totalPages });
        } catch (error) {
            setIsErr(true);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const isDataEmpty = () => {
        return data.length === 0 && !loading && !isErr;
    };

    useEffect(() => {
        getUsers();
    }, [page, search, selectedActiveStatus, selectedStatus]);

    return (
        <Box>
            <Search filter={filter} searchTrigger={search} handleClearSearch={handleClearSearch} handleClickSearch={handleClickSearch} handleSearchFilter={handleSearchFilter} />

            <Heading m="40px 0 33px 0" as="h1">
                <Trans i18nKey="MENU.USERS" />
            </Heading>

            <HStack mb="8px">
                <Menu>
                    <MenuButton bg="white" fontSize="16px" fontWeight="normal" as={Button} rightIcon={<HiOutlineChevronDown />}>
                        {statusPlaceholder()}
                    </MenuButton>
                    <MenuList
                        minW={['100%', '500px', '500px']}
                        maxHeight={'300px'}
                        overflowX={'scroll'}
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {userVerificationStatuses.map((status) => (
                            <MenuItem onClick={() => toggleSelectedStatus(status.status)} key={status.status}>
                                {t(status.label)}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>

                <Menu>
                    <MenuButton bg="white" fontSize="16px" fontWeight="normal" as={Button} rightIcon={<HiOutlineChevronDown />}>
                        {statusActivePlaceholder()}
                    </MenuButton>
                    <MenuList
                        minW={['100%', '500px', '500px']}
                        maxHeight={'300px'}
                        overflowX={'scroll'}
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {userActiveStatus.map((status) => (
                            <MenuItem onClick={() => toggleSelectedActiveStatus(status.status)} key={status.label}>
                                {t(status.label)}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </HStack>
            {isErr && (
                <Center mt="24px">
                    <Text color="red">
                        <Trans i18nKey={'GENERAL.LOADING_ERROR'} />
                    </Text>
                </Center>
            )}
            {isDataEmpty() && (
                <Center mt="24px">
                    <Text color="black.60">
                        <Trans i18nKey={'GENERAL.EMPTY_STATE'} />
                    </Text>
                </Center>
            )}
            <Table data={mapUsersTableData(data, t)} config={tableConfig(t)} page={page} pagination={paginator} onPaging={changePage} onRowClick={redirectToDetails} />
            <LoadingModal isOpen={loading} setIsOpen={setLoading} />
        </Box>
    );
};

export default Articles;
