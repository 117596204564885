import { Box, Heading, Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import Search from 'components/search/search';
import { FormEvent, useState, useEffect } from 'react';

import { Trans } from 'react-i18next';
import Banners from './tabs/banners';
import Quotas from './tabs/quotas';
import { IArticleCategory } from 'app/articles/types';
import { allCategoriesAPI } from 'api/categories';
import { buildQueryString } from 'common/helpers';
import PromotionQuotas from './tabs/promotionQuotas';

const Marketing = () => {
    const [filter, setFilter] = useState<string>('');
    const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
    const [categories, setCategories] = useState<IArticleCategory[]>([]);
    const [page, setPage] = useState<number>(1);

    const toggleSearchTrigger = () => {
        setPage(1);
        setSearchTrigger(!searchTrigger);
    };

    const handleClickSearch = () => {
        toggleSearchTrigger();
    };

    const handleClearSearch = () => {
        setFilter('');
        toggleSearchTrigger();
    };

    const handleSearchFilter = (e: FormEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
    };

    const getCategories = async () => {
        try {
            const res = await allCategoriesAPI(buildQueryString({ perPage: Number.MAX_SAFE_INTEGER }));
            setCategories(res.data.results);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <Box>
            <Search filter={filter} searchTrigger={searchTrigger} handleClearSearch={handleClearSearch} handleClickSearch={handleClickSearch} handleSearchFilter={handleSearchFilter} />
            <Heading m="40px 0 33px 0" as="h1">
                <Trans i18nKey="BANNER.TITLE" />
            </Heading>
            <Tabs color="black.100">
                <TabList>
                    <Tab _selected={{ fontWeight: 'bold', borderBottom: '2px solid black' }}>
                        <Trans i18nKey={'BANNER.TAB_BANNER'} />
                    </Tab>
                    <Tab _selected={{ fontWeight: 'bold', borderBottom: '2px solid black' }}>
                        <Trans i18nKey={'BANNER.TAB_QUOTAS'} />
                    </Tab>
                    <Tab _selected={{ fontWeight: 'bold', borderBottom: '2px solid black' }}>
                        <Trans i18nKey={'BANNER.TAB_PROMOTION'} />
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Banners searchTrigger={searchTrigger} page={page} setPage={setPage} categories={categories} filter={filter} setFilter={setFilter} />
                    </TabPanel>
                    <TabPanel>
                        <Quotas categories={categories} />
                    </TabPanel>
                    <TabPanel>
                        <PromotionQuotas />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default Marketing;
