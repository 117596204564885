import { Heading, Center, Button, Link, Text } from '@chakra-ui/react';
import { routesConstants } from 'common';

import { Trans } from 'react-i18next';

const Forbidden = () => {
    return (
        <Center flexDir={'column'} height={'100%'} gap={'36px'}>
            <Heading as="h1">
                <Trans i18nKey="403.TITLE" />
            </Heading>
            <Text>
                <Trans i18nKey="403.HEADING" />
            </Text>
            <Button>
                <Link href={routesConstants.routes[routesConstants.prefixes.login].path}>
                    <Trans i18nKey="BACK_TO_LOGIN.BUTTON" />
                </Link>
            </Button>
        </Center>
    );
};

export default Forbidden;
