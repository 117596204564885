import { Box, Button, HStack, Heading, Menu, MenuButton, MenuItem, MenuList, Tag, Stack } from '@chakra-ui/react';
import { subscriptionsAPI } from 'api/subscriptions';
import { useEffect, useState } from 'react';
import { ISubscription, ISubscriptionTableSlot } from '../types';
import { IPagination, IParams, ITableSlots } from 'common/types';
import { mapSubsTableData, subsTableConfig } from '../utils/helpers';
import { Table } from 'components';
import { Trans, useTranslation } from 'react-i18next';
import { buildQueryString, getParamsFromLocalStorage, removeBracketsAndParseNumber, saveParamsToLocalStorage } from 'common/helpers';
import { differenceInDays } from 'date-fns';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { subscriptionStatuses } from '../constants';
import { useNavigate } from 'react-router-dom';
import { SubscriptionStatus } from '../enums/subscription.enums';

const Subscriptions = () => {
    const { t } = useTranslation();
    const [data, setData] = useState<ISubscription[]>([]);
    const [page, setPage] = useState<number>(1);
    const [paginator, setPaginator] = useState<Partial<IPagination>>({ perPage: 10, orderDir: 'ASC', orderBy: 'renews_at' });

    const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
    const [apiParams, setApiParams] = useState<IParams>();
    const navigate = useNavigate();

    const redirectToDetails = (item: ISubscriptionTableSlot) => {
        if (apiParams) saveParamsToLocalStorage(apiParams, 'subParams');

        navigate(`/subscriptions/${item.uuid}`);
    };

    const slots: ITableSlots = {
        status: (item: ISubscriptionTableSlot) => {
            const endDate = new Date();
            const startDate = new Date(item.renewalDate ?? '');

            const daysDifference = differenceInDays(startDate, endDate);

            return {
                content: (
                    <Stack>
                        {item.status === SubscriptionStatus.CANCELED || item.renewalDate === '' || !item.renewalDate ? (
                            <Tag w="100%" backgroundColor={'gray.200'}></Tag>
                        ) : (
                            <Tag w="100%" backgroundColor={daysDifference < 0 ? 'red.200' : daysDifference <= 5 ? 'orange.200' : 'green.200'}></Tag>
                        )}
                    </Stack>
                ),
                sortableValue: undefined,
            };
        },
    };

    const changePage = (newPage: number | undefined) => {
        if (newPage) {
            setPage(newPage);
        }
    };

    const statusPlaceholder = () => {
        return selectedStatus ? t(subscriptionStatuses.find((status) => status.id === selectedStatus)?.label ?? 'GENERAL.NOT_FOUND') : `${t('SUBS.CHOOSE_STATUS')}`;
    };

    const toggleSelectedStatys = (status: number | undefined) => {
        if (status) {
            if (status === selectedStatus) {
                setPage(1);
                setSelectedStatus(null);
            } else {
                setSelectedStatus(status);
                setPage(1);
            }
        }
    };

    const getSubscriptions = async () => {
        try {
            let params: IParams;

            if (getParamsFromLocalStorage('subParams')) {
                params = getParamsFromLocalStorage('subParams');
                setPage(params.page ?? 1);
                toggleSelectedStatys(removeBracketsAndParseNumber(params.status ?? ''));

                localStorage.removeItem('subParams');
            } else {
                params = {
                    ...paginator,
                    page: page,
                };

                if (selectedStatus) {
                    params.status = `[${selectedStatus}]`;
                } else {
                    params.status = `[${SubscriptionStatus.PAID}, ${SubscriptionStatus.PENDING_CANCEL}, ${SubscriptionStatus.CANCELED}]`;
                }
            }
            setApiParams(params);

            const queryString = buildQueryString(params);
            const response = await subscriptionsAPI(queryString);
            setData(response.data.results);
            setPaginator({ ...paginator, totalPages: response.data.paginator.totalPages });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSubscriptions();
    }, [page, selectedStatus]);

    return (
        <Box>
            <Heading as="h1" mb="32px">
                <Trans i18nKey={'MENU.SUBSCRIPTIONS'} />
            </Heading>

            <Box mb="8px">
                <HStack>
                    <Menu>
                        <MenuButton bg="white" fontSize="16px" fontWeight="normal" as={Button} rightIcon={<HiOutlineChevronDown />}>
                            {statusPlaceholder()}
                        </MenuButton>
                        <MenuList
                            minW={['100%', '500px', '500px']}
                            maxHeight={'300px'}
                            overflowX={'scroll'}
                            css={{
                                '&::-webkit-scrollbar': {
                                    width: '0.4em',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            {subscriptionStatuses.map((status) => (
                                <MenuItem onClick={() => toggleSelectedStatys(status.id)} key={status.id}>
                                    {t(status.label)}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </HStack>
            </Box>
            <Table data={mapSubsTableData(data, t)} config={subsTableConfig(t)} page={page} pagination={paginator} onPaging={changePage} slots={slots} onRowClick={redirectToDetails} />
        </Box>
    );
};

export default Subscriptions;
