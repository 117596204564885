import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Button, Divider, HStack, Modal, ModalBody, ModalContent, ModalOverlay, Stack, Text } from '@chakra-ui/react';

import Switch from 'components/switch';

import { isNullOrUndefined } from 'common/helpers';
import { IArticleCategory, IArticleCategoryField } from 'app/articles/types';

interface IProps {
    category: IArticleCategory;
    setCategory: (value: IArticleCategory) => void;
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
}

const FeaturedFieldsModal = ({ category, setCategory, openModal, setOpenModal }: IProps) => {
    const [featured, setFeatured] = useState<IArticleCategoryField[]>([]);
    const toggleOpenModal = () => {
        setOpenModal(!openModal);
    };

    const getFilteredFields = () => {
        return category.productCategoryFields?.filter((f) => f.iconURI && (isNullOrUndefined(f.parentID) || f.parentID === 0)) ?? [];
    };

    const onSubmit = () => {
        setOpenModal(!openModal);

        const updatedCategory = { ...category };

        updatedCategory.productCategoryFields = updatedCategory.productCategoryFields.map((field) => {
            const isFeatured = featured.some((featuredField) => featuredField.id === field.id);

            return {
                ...field,
                featured: isFeatured,
            };
        });

        setCategory(updatedCategory);
        setOpenModal(!openModal);
    };

    const handleSwitchChange = (field: IArticleCategoryField, value: boolean) => {
        const updatedFeaturedFields = [...featured];
        const index = updatedFeaturedFields.findIndex((f) => f.id === field.id);

        if (index !== -1) {
            if (value) {
                updatedFeaturedFields[index].featured = true;
            } else {
                updatedFeaturedFields.splice(index, 1);
            }
        } else {
            if (updatedFeaturedFields.length >= 4) {
                updatedFeaturedFields.shift();
            }
            if (value) {
                updatedFeaturedFields.push({ ...field, featured: true });
            }
        }

        setFeatured(updatedFeaturedFields);
    };

    const setFeaturedFieldsIfAny = () => {
        if (category.productCategoryFields?.find((f) => f.featured)) {
            const updatedFields = category.productCategoryFields.filter((field) => field.featured);
            setFeatured(updatedFields);
        }
    };

    const isChecked = (field: IArticleCategoryField) => {
        return featured.find((f) => f.id === field.id) ? true : false;
    };

    useEffect(() => {
        setFeaturedFieldsIfAny();
    }, []);

    return (
        <Modal isOpen={openModal} onClose={toggleOpenModal} isCentered closeOnEsc={false} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent bgColor={'white'}>
                <ModalBody>
                    <Stack mt="16px" spacing="4px">
                        <Text>
                            <Trans i18nKey={'CATEGORY.ADD_FIELDS_ORDER_CONFIRM'} />
                        </Text>
                        <Text fontSize="14px">
                            <Trans i18nKey={'CATEGORY.ADD_FIELDS_ORDER_CONFIRM_DESC'} />
                        </Text>
                    </Stack>

                    <Divider color="brand.menuBorder" mt="16px" />

                    <Stack mt="16px" spacing={'16px'}>
                        {getFilteredFields().map((field) => (
                            <HStack key={field.id}>
                                <Text wordBreak={'break-word'} align={'left'}>
                                    {field.translations.bs}
                                </Text>
                                <Switch isChecked={isChecked(field)} checkedColor="black.100" onChange={(e) => handleSwitchChange(field, e)} />
                            </HStack>
                        ))}
                    </Stack>

                    <Button backgroundColor={'black.100'} color={'white'} m="16px 0" w="100%" onClick={onSubmit}>
                        <Trans i18nKey={'CATEGORY.FIELDS_HIGHLIGHTED_BTN_CONFIRM'} />
                    </Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default FeaturedFieldsModal;
