export enum AdminRequestStatus {
    CREATED = 1,
    ACCEPTED = 2,
    DECLINED = 3,
}

export enum AdminRequestType {
    PRODUCT = 1,
    USER = 2,
    DELIVERY_ISSUE = 3,
    PRODUCT_REVIEW = 4,
    USER_REVIEW = 5,
    ADDRESS = 6,
    PRODUCT_COMMENT = 8,
    SELLER_VERIFY = 9,
    BUSINESS_VERIFY = 10,
    BANNER_REQUESTED = 11,
    ONLINE_PAYMENT = 12,
}

export enum AdminRequestSeverity {
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3,
    CRITICAL = 4,
}
