import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { Box, Flex, FormControl, FormLabel, Switch, Menu, MenuButton, MenuList, Button } from '@chakra-ui/react';

import RenderIf from 'components/render-if';
import TableView from 'components/table/components/table-view';
import GridView from 'components/table/components/grid-view';
import Paging from 'components/paging';
import { IConfig, IPagination, ITableSlots } from 'common/types';
import _ from 'common/lodash';

export interface IFooter {
    label: string;
}

interface IProps {
    config: IConfig[];
    data: any[];
    slots?: ITableSlots;
    footer?: IFooter[];
    showSwitch?: boolean;
    tableCaption?: string;
    gridTemplateColumns?: string;
    gridGap?: number;
    onRowClick?: (rawData: any) => void;
    pagination?: Partial<IPagination>;
    page?: number;
    onPaging?: (page?: number) => void;
    filtersComponent?: React.ComponentType<any>;
    filterComponentProps?: object;
    isLoading?: boolean;
    componentProps?: any;
    mb?: string;
    paginationPosition?: 'fixed' | 'relative';
    replacePageBreak?: boolean;
}

export const VIEWS = {
    table: 'table',
    grid: 'grid',
};

const Table = ({
    config,
    data,
    slots,
    footer,
    showSwitch = false,
    tableCaption,
    gridTemplateColumns = '1fr 1fr 1fr',
    gridGap = 5,
    onRowClick,
    pagination,
    page,
    onPaging,
    filtersComponent: FilterComponent,
    filterComponentProps,
    isLoading,
    componentProps,
    mb,
    paginationPosition,
    replacePageBreak,
}: IProps): JSX.Element => {
    const [view, setView] = useState<string>(VIEWS.table);

    return (
        <Box marginBottom={mb}>
            <RenderIf show={showSwitch}>
                <Flex width="100%" mb="30px">
                    {FilterComponent && (
                        <Menu>
                            {({ onClose }) => (
                                <>
                                    <MenuButton bg="white" fontSize="16px" fontWeight="normal" as={Button} rightIcon={<HiOutlineChevronDown />}>
                                        <Trans i18nKey="COMMON.FILTER" />
                                    </MenuButton>
                                    <MenuList minW={['100%', '500px', '500px']}>
                                        <FilterComponent {...Object.assign(filterComponentProps || {}, { onClose })} />
                                    </MenuList>
                                </>
                            )}
                        </Menu>
                    )}
                    <FormControl display="flex" alignItems="center" ml="auto" width="auto">
                        <Switch id="email-alerts" value={view} onChange={() => setView(view === VIEWS.table ? VIEWS.grid : VIEWS.table)} />
                        <FormLabel htmlFor="email-alerts" mb="0" ml="12px">
                            <Trans i18nKey="TABLE.GRID_VIEW" />
                        </FormLabel>
                    </FormControl>
                </Flex>
            </RenderIf>
            <RenderIf show={view === VIEWS.table}>
                <Box>
                    <TableView
                        config={config}
                        footer={footer}
                        data={data}
                        tableCaption={tableCaption}
                        onRowClick={onRowClick}
                        isLoading={isLoading}
                        componentProps={componentProps}
                        slots={slots}
                        replacePageBreak={replacePageBreak}
                    />
                </Box>
            </RenderIf>
            <RenderIf show={view === VIEWS.grid}>
                <Box overflowY="auto" height="60vh">
                    <GridView config={config} data={data} gridTemplateColumns={gridTemplateColumns} gridGap={gridGap} onRowClick={onRowClick} isLoading={isLoading} componentProps={componentProps} />
                </Box>
            </RenderIf>
            <RenderIf show={!_.isObjectEmpty(pagination as object) && data && data.length > 0}>
                {paginationPosition === undefined || paginationPosition === 'fixed' ? (
                    <Flex
                        mt="24px"
                        justifyContent={view === VIEWS.table ? 'flex-end' : 'center'}
                        position="fixed"
                        bottom="0"
                        left="55%"
                        transform="translateX(-55%)"
                        background="white"
                        padding="10px 20px"
                        zIndex={999}
                    >
                        <Paging page={page || 0} totalPages={pagination?.totalPages || 0} onChange={onPaging} />
                    </Flex>
                ) : (
                    <Flex w="100%" mt="24px" justifyContent={'center'}>
                        <Paging page={page || 0} totalPages={pagination?.totalPages || 0} onChange={onPaging} />
                    </Flex>
                )}
            </RenderIf>
        </Box>
    );
};

export default Table;
