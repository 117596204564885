import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    Stack,
    HStack,
    Button,
    Text,
    Accordion,
    AccordionItem,
    AccordionPanel,
    AccordionButton,
    AccordionIcon,
    Icon,
    Image,
    Divider,
} from '@chakra-ui/react';
import { IUser } from 'common/types';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AiOutlineCheck } from 'react-icons/ai';
import { FiExternalLink } from 'react-icons/fi';
import colors from 'theme/foundations/colors';
import ImageModal from './imageModal';
import ConfirmationModal from 'components/confirmationModal';

interface IProps {
    isOpen: boolean;
    toggleModal: () => void;
    user: IUser;

    onConfirm: () => void;
    onDecline: () => void;
}

const PaymentModal = ({ toggleModal, isOpen, user, onConfirm, onDecline }: IProps) => {
    const { t } = useTranslation();

    const [isImageModalOpen, setImageModalOpen] = useState<boolean>(false);
    const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const [actionType, setActionType] = useState<'accept' | 'decline'>('accept');
    const [selectedImage, setSelectedImage] = useState<string>('');

    const toggleImageModal = () => {
        setImageModalOpen(!isImageModalOpen);
    };

    const toggleConfirmationModal = () => {
        setConfirmModalOpen(!isConfirmModalOpen);
    };

    const previewImage = () => {
        setSelectedImage(user.onlinePaymentsVerifyImage ?? '');
        toggleImageModal();
    };

    const openConfirmModal = (type: 'accept' | 'decline') => {
        toggleConfirmationModal();
        setActionType(type);
    };

    const openVerifyImage = (imageUrl: string) => {
        window.open(imageUrl, '_blank');
    };

    const renderSellerOrBusinessVerificationData = () => {
        return (
            <Stack fontSize={'14px'}>
                {user.bankName !== '' && (
                    <HStack>
                        <Text color="black.60">
                            <Trans i18nKey={'USER.VERIFY_BANK'} />
                        </Text>
                        <Text>{user.bankName}</Text>
                    </HStack>
                )}
                {user.accountNumber !== '' && (
                    <HStack>
                        <Text color="black.60">
                            <Trans i18nKey={'USER.VERIFY_ACCOUNT'} />
                        </Text>
                        <Text>{user.accountNumber}</Text>
                    </HStack>
                )}

                <Divider m="8px 0 16px" />
            </Stack>
        );
    };

    return (
        <Stack>
            <Modal isOpen={isOpen} onClose={toggleModal} isCentered>
                <ModalOverlay />
                <ModalContent bgColor={'white'} maxW={'574px'} w="100%">
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack p="16px 0" spacing={'16px'}>
                            <Text fontSize={'20px'} fontWeight={600}>
                                <Trans i18nKey={'USER.PAYMENT_VERIFY_MODAL_HEADING'} />
                            </Text>
                            <Text color="black.60">
                                <Trans i18nKey={'USER.PAYMENT_VERIFY_MODAL_DESC'} />
                            </Text>
                        </Stack>

                        <Accordion allowToggle mb="8px">
                            <AccordionItem>
                                <HStack justifyContent={'space-between'} w="100%">
                                    <AccordionButton>
                                        <HStack ml="-16px" spacing="16px">
                                            <AccordionIcon />
                                            <Text>
                                                <Trans i18nKey={'USER.PAYMENT_VERIFY_STEP_1_NUMBER'} />
                                            </Text>
                                            <Text>
                                                <Trans i18nKey={'USER.PAYMENT_VERIFY_STEP_1_TEXT'} />
                                            </Text>
                                        </HStack>
                                    </AccordionButton>
                                    <Icon as={AiOutlineCheck} fill={colors.brand.success} />
                                </HStack>
                                <AccordionPanel>
                                    <Text fontSize={'14px'} color="black.60" mb="16px">
                                        <Trans i18nKey={'USER.PAYMENT_VERIFY_STEP_1_DESC'} />
                                    </Text>
                                    {user.onlinePaymentsVerifyImage?.endsWith('pdf') ? (
                                        <Button onClick={() => openVerifyImage(user.onlinePaymentsVerifyImage ?? '')} variant={'outline'} rightIcon={<FiExternalLink />}>
                                            <Trans i18nKey={'USER.PAYMENT_OPEN_PDF'} />
                                        </Button>
                                    ) : (
                                        <Image cursor={'zoom-in'} src={user.onlinePaymentsVerifyImage} alt="user" w="176px" height="123px" objectFit={'cover'} onClick={previewImage} />
                                    )}
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionButton>
                                    <HStack ml="-16px" spacing="16px">
                                        <AccordionIcon />
                                        <Text>
                                            <Trans i18nKey={'USER.PAYMENT_VERIFY_STEP_2_NUMBER'} />
                                        </Text>
                                        <Text>
                                            <Trans i18nKey={'USER.PAYMENT_VERIFY_STEP_2_TEXT'} />
                                        </Text>
                                    </HStack>
                                </AccordionButton>
                                <AccordionPanel>
                                    {renderSellerOrBusinessVerificationData()}
                                    <Stack spacing="24px">
                                        <Text fontSize={'14px'} color="black.60">
                                            <Trans i18nKey={'USER.PAYMENT_VERIFY_STEP_2_DESC_1'} />
                                        </Text>
                                        <Text fontSize={'14px'} color="black.60">
                                            <Trans i18nKey={'USER.PAYMENT_VERIFY_STEP_2_DESC_2'} />
                                        </Text>
                                    </Stack>
                                    <HStack mt="16px">
                                        <Button backgroundColor={'brand.success'}>
                                            <Text color="white" fontSize={'14px'} onClick={() => openConfirmModal('accept')}>
                                                <Trans i18nKey={'USER.PAYMENT_VERIFY_ACCEPT'} />
                                            </Text>
                                        </Button>
                                        <Button variant={'outline'}>
                                            <Text fontSize={'14px'} onClick={() => openConfirmModal('decline')}>
                                                <Trans i18nKey={'USER.PAYMENT_VERIFY_DECLINE'} />
                                            </Text>
                                        </Button>
                                    </HStack>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {selectedImage !== '' && <ImageModal isOpen={isImageModalOpen} onClose={toggleImageModal} imageUrl={selectedImage} />}
            <ConfirmationModal
                isOpen={isConfirmModalOpen}
                setIsOpen={setConfirmModalOpen}
                onConfirm={actionType === 'accept' ? onConfirm : onDecline}
                message={actionType === 'accept' ? t('USER.PAYMENT_VERIFY_CONFIRM_MESSAGE') : t('USER.PAYMENT_VERIFY_DECLINE_MESSAGE')}
            />
        </Stack>
    );
};

export default PaymentModal;
