import { Box, Text, Flex, Button, HStack } from '@chakra-ui/react';
import { deleteWalletQuotaAPI, walletQuotasAPI } from 'api/wallet';
import { IWalletQuota } from 'app/wallet/types';
import { buildQueryString, mapErrorCode } from 'common/helpers';
import { IPagination, ITableSlots } from 'common/types';
import { Table } from 'components';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LuPencilLine, LuTrash } from 'react-icons/lu';
import { mapQuotaTableData, quotaTableConfig } from 'app/wallet/utils';
import { FaPlus } from 'react-icons/fa';
import QuotaDetailsModal from '../modals/quotaDetails';
import ConfirmationModal from 'components/confirmationModal';
import { WalletQuotasResponse } from 'api/wallet/types/response';

const WalletQuotas = () => {
    const { t } = useTranslation();

    const [data, setData] = useState<IWalletQuota[]>([]);
    const [page, setPage] = useState<number>(1);
    const [paginator, setPaginator] = useState<Partial<IPagination>>({
        perPage: 5,
        orderBy: 'id',
    });

    const [selectedQuota, setSelectedQuota] = useState<IWalletQuota | undefined>();
    const [openDetailsModal, setOpenDetailsModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    const [update, setUpdate] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const toggleModalOpen = () => {
        setOpenDetailsModal(!openDetailsModal);
    };

    const openEditModal = (id: number) => {
        setSelectedQuota(data.find((quota) => quota.id === id));
        toggleModalOpen();
    };

    const openCreateModal = () => {
        setSelectedQuota(undefined);
        toggleModalOpen();
    };

    const openDeleteConfirmationModal = (id: number) => {
        setSelectedQuota(data.find((quota) => quota.id === id));
        setError(!error);
        setErrorMessage('');
        setOpenDeleteModal(!openDeleteModal);
    };

    const slots: ITableSlots = {
        actions: (item: IWalletQuota) => {
            return {
                content: (
                    <Flex>
                        <Button variant={'outline'} leftIcon={<LuPencilLine />} mr={3} onClick={() => openEditModal(item.id)}>
                            <Trans i18nKey={'GENERAL.EDIT'} />
                        </Button>
                        <Button variant={'outline'} leftIcon={<LuTrash />} color="red" borderColor="red" onClick={() => openDeleteConfirmationModal(item.id)}>
                            <Trans i18nKey={'GENERAL.REMOVE'} />
                        </Button>
                    </Flex>
                ),
                sortableValue: undefined,
            };
        },
    };

    const changePage = (newPage: number | undefined) => {
        if (newPage) {
            setPage(newPage);
        }
    };

    const isCurrentPageEmpty = (response: WalletQuotasResponse) => {
        return page > 1 && response.data.paginator.currentEntriesSize === 0 && response.data.paginator.totalEntriesSize > 0;
    };

    const getQuotas = async () => {
        try {
            const response = await walletQuotasAPI(buildQueryString({ ...paginator, page: page }));
            if (isCurrentPageEmpty(response)) {
                setPage(page - 1);
            }
            setData(response.data.results);
            setPaginator({ ...paginator, totalPages: response.data.paginator.totalPages });
        } catch (error) {
            console.log(error);
        }
    };

    const deleteQuota = async () => {
        try {
            await deleteWalletQuotaAPI(selectedQuota?.id.toString() ?? '1');
            setUpdate(!update);
            setOpenDeleteModal(!openDeleteModal);
        } catch (err) {
            console.log(err);
            setError(!error);
            setErrorMessage(mapErrorCode(err, t));
        }
    };

    useEffect(() => {
        getQuotas();
    }, [page, update]);

    return (
        <Box>
            <HStack justifyContent={'space-between'} m="8px 0 16px 0">
                <Text>
                    <Trans i18nKey="WALLET.QUOTAS_DESC" />
                </Text>
                <Button variant={'dark'} gap="8px" onClick={openCreateModal}>
                    <FaPlus />
                    <Trans i18nKey={'QUOTAS.ADD'} />
                </Button>
            </HStack>
            <Table data={mapQuotaTableData(data, t)} config={quotaTableConfig(t)} pagination={paginator} page={page} onPaging={changePage} slots={slots} />
            <QuotaDetailsModal
                toggleCreateModal={toggleModalOpen}
                modalOpen={openDetailsModal}
                setEntryCreated={setUpdate}
                entryCreated={update}
                selectedQuota={selectedQuota}
                setSelectedQuota={setSelectedQuota}
            />
            <ConfirmationModal
                isOpen={openDeleteModal}
                setIsOpen={setOpenDeleteModal}
                onConfirm={deleteQuota}
                message={t('WALLET.QUOTA_DELETE_CONFIRM')}
                error={error}
                errorMessage={t(errorMessage)}
            />
        </Box>
    );
};

export default WalletQuotas;
