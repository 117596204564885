import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Alert, AlertIcon } from '@chakra-ui/react';
import { demoteArticleAPI } from 'api/articles';
import { IArticle } from 'app/articles/types';

interface IProps {
    visible: boolean;
    setVisible: (value: boolean) => void;
    setData: (value: IArticle) => void;
    uuid: string;
}

const StopPromotingArticle = ({ visible, setVisible, uuid, setData }: IProps) => {
    const { t } = useTranslation();
    const [error, setError] = useState<boolean>(false);

    const onModalClose = () => {
        setError(false);
        setVisible(!visible);
    };

    const stopHighlight = async () => {
        try {
            const response = await demoteArticleAPI(uuid);
            setData(response.data);
            setVisible(!visible);
        } catch (err) {
            setError(!error);
        }
    };

    return (
        <Modal isOpen={visible} onClose={onModalClose} isCentered>
            <ModalOverlay />
            <ModalContent bgColor="white">
                <ModalHeader>{t('ARTICLE.HIGHLIGHT_STOP')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{t('ARTICLE.HIGHLIGHT_STOP_DESC')}</ModalBody>
                {error && (
                    <Alert status="error">
                        <AlertIcon />
                        {t('ERROR.GENERAL')}
                    </Alert>
                )}
                <ModalFooter justifyContent="flex-start">
                    <Button variant="danger" mr={3} onClick={stopHighlight}>
                        {t('GENERAL.CONFIRM')}
                    </Button>
                    <Button variant="outline" onClick={onModalClose}>
                        {t('GENERAL.CANCEL')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default StopPromotingArticle;
