import { extendTheme } from '@chakra-ui/react';
import colors from 'theme/foundations/colors';
import { breakpointsPixels } from 'theme/foundations/breakpoint-pixels';
import fonts from 'theme/foundations/fonts';

import { buttonTheme } from 'theme/components/button';
import { selectTheme } from 'theme/components/select';

const theme = extendTheme({
    colors,
    //Components
    components: {
        Button: buttonTheme,
        Select: selectTheme,
    },

    fonts,

    breakpointsPixels,
});

export default theme;
