import { Trans, useTranslation } from 'react-i18next';
import { Container, Flex, Heading, Center, Text, Button, Link, HStack, PinInput, PinInputField } from '@chakra-ui/react';
import jwt_decode from 'jwt-decode';

import { Logo } from 'components';
import { config } from 'core';
import { useState } from 'react';
import { loginAPI } from 'api/auth';
import { removeSession, setSession } from 'core/session';
import { verifyTpaApi } from 'api/users';
import { mapErrorCode } from 'common/helpers';
import { IDecodedToken } from 'common/types';

const VerifyMFA = () => {
    const { t } = useTranslation();

    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    const [error, setError] = useState<string>();
    const [otpCode, setOtpCode] = useState<string>();

    const backToLogin = () => {
        removeSession();
        window.location.href = '/login';
    };

    const redirectToForbidden = () => (window.location.href = '/403');

    const onSubmit = async () => {
        try {
            const res = await loginAPI({ password: password ?? '', username: username ?? '' });
            setSession(res?.data?.accessToken, res?.data?.refreshToken);

            if (otpCode) {
                const verifyTpaResponse = await verifyTpaApi({ otp: otpCode });

                const decoded: IDecodedToken = jwt_decode(verifyTpaResponse?.data?.accessToken);
                const scope = decoded.scope;

                if (scope.includes('Admin')) {
                    setSession(verifyTpaResponse.data.accessToken, verifyTpaResponse.data.refreshToken);

                    setError(undefined);
                    localStorage.removeItem('password');
                    localStorage.removeItem('username');
                    window.location.href = '/';
                } else {
                    removeSession();
                    redirectToForbidden();
                }
            }
        } catch (err) {
            setError(mapErrorCode(err, t));
            console.log(mapErrorCode(err, t));
        }
        setOtpCode(undefined);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit();
    };

    const isSubmitDisabled = () => {
        return otpCode?.length !== 6;
    };

    return (
        <Flex h="100%" flexDir="column" justifyContent="center">
            <Flex justifyContent={{ base: 'center', md: 'flex-start' }} p={{ base: '36px', md: 0 }}>
                <Logo wrapperProps={{ position: { base: 'static', md: 'absolute' }, top: 47, left: 150 }} />
            </Flex>

            <Container maxW="md">
                <Heading as="h2" className="text-center">
                    <Trans i18nKey={'LOGIN.MFA_CONFIRM'} />
                </Heading>
                <Text align={'center'} mt="12px" fontSize={'14px'}>
                    <Trans i18nKey={'LOGIN.MFA_CONFIRM_DESC'} />
                </Text>
                <form onSubmit={handleSubmit}>
                    <HStack p="24px 0" justifyContent={'center'}>
                        <PinInput autoFocus onChange={(e) => setOtpCode(e)} size={'lg'}>
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                        </PinInput>
                    </HStack>
                    <Center mb="16px" flexDir={'column'}>
                        <Button variant="dark" type="submit" isDisabled={isSubmitDisabled()}>
                            <Trans i18nKey={'GENERAL.CONFIRM'} />
                        </Button>
                        {error && (
                            <Text mt="8px" maxW="200px" align="center" fontSize={'12px'} color="red">
                                {error}
                            </Text>
                        )}
                    </Center>
                </form>
                <Center flexDir={'column'} gap={'8px'}>
                    <Text as={Link} fontSize="14px" fontWeight="bold" onClick={backToLogin}>
                        <Trans i18nKey={'LOGIN.BACK_TO_LOGIN'} />
                    </Text>
                    <Link fontSize="14px" isExternal href={`${config.WEB_URL}/ba/forgot-password`} fontWeight="bold">
                        <Trans i18nKey={'LOGIN.FORGOT_PASSWORD_LINK'} />
                    </Link>
                </Center>
            </Container>
        </Flex>
    );
};

export default VerifyMFA;
