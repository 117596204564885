import { CategoryRequestDTO } from 'api/categories/types/request';
import { InputTypes, Steps } from '../enums/categories.enums';
import { IFieldData, IInputType, IStep } from '../types';
import { IArticleCategoryField } from 'app/articles/types';

export const categoriesColors = ['#F1FDEF', '#DCF5F5', '#F1FBFB', '#FAF4E9', '#F2F6F6', '#F4F4F4', '#FCEEF1'];

export const stepsArr: IStep[] = [
    {
        value: Steps.BASIC_INFO,
        i18nKey: 'CATEGORY.FIELDS_STEP_1',
    },
    {
        value: Steps.ADDITIONAL_INFO,
        i18nKey: 'CATEGORY.FIELDS_STEP_2',
    },
];

export const defaultNewFieldData: IFieldData = {
    uuid: '',
    inputType: 1,
    name: '',
    placeholder: '',
    optional: false,
    searchable: false,
    options: [],
    step: stepsArr[0],
};

export const defaultFieldData: IArticleCategoryField = {
    id: 0,
    categoryId: 0,
    inputType: 1,
    optional: false,
    searchable: false,
    translations: {
        bs: '',
    },
    placeholderTranslations: {
        bs: '',
    },
    parentID: 1,
    createdAt: '',
    stepID: 1,
    updatedAt: '',
    productCategoryFieldOptions: [],
    iconURI: '',
    order: 0,
    featured: false,
};

export const defaultNewCategoryData: CategoryRequestDTO = {
    color: '',
    iconURI: '',
    imageURI: '',
    parentCategory: true,
    parentCategoryID: null,
    shippingAvailable: true,
    commonShipping: true,
    slug: '',
    translations: {
        bs: '',
    },
    order: 0,
    newAvailable: false,
    giftAvailable: false,
};

export const inputTypes: IInputType[] = [
    {
        i18nKey: 'CATEGORY.FIELDS_OPTIONS_TEXT',
        value: InputTypes.TEXT,
    },
    {
        i18nKey: 'CATEGORY.FIELDS_OPTIONS_NUMBER',
        value: InputTypes.NUMBER,
    },
    {
        i18nKey: 'CATEGORY.FIELDS_OPTIONS_RANGE',
        value: InputTypes.RANGE,
    },
    {
        i18nKey: 'CATEGORY.FIELDS_OPTIONS_TOGGLE',
        value: InputTypes.TOGGLE,
    },
    {
        i18nKey: 'CATEGORY.FIELDS_OPTIONS_SELECT',
        value: InputTypes.SELECT,
    },
    {
        i18nKey: 'CATEGORY.FIELDS_OPTIONS_CHECKBOX',
        value: InputTypes.CHECKBOX,
    },
];
