import { helpers } from 'common';
import { CategoriesResponseDTO, CategoryFieldOptionResponseDTO, CategoryFieldResponseDTO, CategoryResponseDTO, FieldsResponseDTO, OptionsResponseDTO } from './types/response';
import { CategoryRequestDTO, ChildFieldRequestDTO, FieldRequestDTO, OptionRequestDTO } from './types/request';

const { formAxiosObject } = helpers;

export const allCategoriesAPI = async (queryParams?: string): Promise<CategoriesResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/categories/${queryParams ? `?${queryParams}` : ''}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const categoryDetailsAPI = async (id: number): Promise<CategoryResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/categories/${id}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const fieldsListAPI = async (categoryID: number): Promise<FieldsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/categories/${categoryID}/fields/` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const optionsListAPI = async (categoryID: number, fieldID: number): Promise<OptionsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/categories/${categoryID}/fields/${fieldID}/options/` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const createCategoryAPI = async (data: CategoryRequestDTO): Promise<CategoryResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'POST', route: '/management/categories/' }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const createFieldAPI = async (data: FieldRequestDTO, categoryID: number): Promise<CategoryFieldResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'POST', route: `/management/categories/${categoryID}/fields/` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const createOptionAPI = async (data: OptionRequestDTO, categoryID: number, fieldID: number): Promise<CategoryFieldOptionResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'POST', route: `/management/categories/${categoryID}/fields/${fieldID}/options/` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const updateCategoryAPI = async (id: number, data: CategoryRequestDTO): Promise<any> => {
    try {
        const response = await formAxiosObject({ method: 'PUT', route: `/management/categories/${id}` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const updateFieldAPI = async (data: FieldRequestDTO, categoryID: number, fieldID: number): Promise<any> => {
    try {
        const response = await formAxiosObject({ method: 'PUT', route: `/management/categories/${categoryID}/fields/${fieldID}` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const updateChildFieldAPI = async (data: ChildFieldRequestDTO, categoryID: number, fieldID: number): Promise<any> => {
    try {
        const response = await formAxiosObject({ method: 'PUT', route: `/management/categories/${categoryID}/fields/${fieldID}` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const updateOptionAPI = async (data: OptionRequestDTO, categoryID: number, fieldID: number, optionID: number): Promise<any> => {
    try {
        const response = await formAxiosObject({ method: 'PUT', route: `/management/categories/${categoryID}/fields/${fieldID}/options/${optionID}` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const deleteCategoryAPI = async (categoryID: number): Promise<any> => {
    try {
        await formAxiosObject({ method: 'DELETE', route: `/management/categories/${categoryID}` });
    } catch (error) {
        throw error;
    }
};

export const deleteFieldAPI = async (categoryID: number, fieldID: number): Promise<any> => {
    try {
        await formAxiosObject({ method: 'DELETE', route: `/management/categories/${categoryID}/fields/${fieldID}` });
    } catch (error) {
        throw error;
    }
};

export const deleteOptionAPI = async (categoryID: number, fieldID: number, optionID: number): Promise<any> => {
    try {
        await formAxiosObject({ method: 'DELETE', route: `/management/categories/${categoryID}/fields/${fieldID}/options/${optionID}` });
    } catch (error) {
        throw error;
    }
};
