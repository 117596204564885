import React from 'react';
import { Trans } from 'react-i18next';
import { Input, Text, Box, InputGroup, InputLeftElement } from '@chakra-ui/react';

import Label from 'components/label';
import { SearchIcon } from 'common/images';

interface IProps {
    name: string;
    value?: string | number;
    onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    min?: number;
    max?: number;
    type?: string;
    error?: string;
    label?: string;
    placeholder?: string;
    touched?: boolean;
    disabled?: boolean;
    inputProps?: object;
    wrapperProps?: object;
    prefix?: string;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const DefaultInput = ({ name, value, onChange, onBlur, type = 'text', error, label, placeholder, touched, disabled, inputProps, wrapperProps, prefix, onKeyPress }: IProps) => (
    <Box {...(wrapperProps || {})}>
        {label && (
            <Label fontSize="14px" color="brand.inputLabelColor">
                {label}
            </Label>
        )}
        <Box position="relative">
            {prefix && (
                <Text position="absolute" left="10px" bottom="8px" color="black" opacity="0.5">
                    {prefix}
                </Text>
            )}
            <InputGroup>
                {type === 'search' && (
                    <InputLeftElement pointerEvents="none">
                        <img src={SearchIcon} alt="search" />
                    </InputLeftElement>
                )}
                <Input
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    type={type}
                    name={name}
                    disabled={disabled}
                    placeholder={placeholder}
                    borderRadius="5px"
                    bg="brand.inputBackground"
                    border="1px solid"
                    borderColor={touched && error ? 'red' : 'brand.inputBorder'}
                    paddingLeft={type === 'search' ? '40px' : prefix ? '24px' : '16px'}
                    height="40px"
                    onKeyDown={onKeyPress}
                    {...(inputProps || {})}
                />
            </InputGroup>
        </Box>
        {error && touched && (
            <Text fontSize="11px" mt="3px" color="red">
                <Trans i18nKey={error} />
            </Text>
        )}
    </Box>
);

export default DefaultInput;
