import { Modal, ModalOverlay, ModalContent, ModalBody, CircularProgress, Center } from '@chakra-ui/react';

interface IProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

const LoadingModal = ({ isOpen, setIsOpen }: IProps) => {
    const onClose = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnEsc={false} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent bgColor={'white'}>
                <ModalBody>
                    <Center>
                        <CircularProgress isIndeterminate color="black.100" />
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default LoadingModal;
