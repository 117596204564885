import { Box, Heading, Menu, MenuButton, MenuItem, MenuList, Button, Center, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { Table } from 'components';

import { IAdminRequest, IPagination, IParams } from 'common/types';
import { reportsAPI } from 'api/shared';
import { buildQueryString, getParamsFromLocalStorage, removeBracketsAndParseNumber, saveParamsToLocalStorage } from 'common/helpers';
import { mapTableData, tableConfig } from '../utils/helpers';
import { AdminRequestType } from '../enums/admin.requests.enums';
import { reportTypes } from '../constants';

import { HiOutlineChevronDown } from 'react-icons/hi';
import LoadingModal from 'components/loading';

const Reports = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [data, setData] = useState<IAdminRequest[]>([]);
    const [page, setPage] = useState<number>(1);
    const [paginator, setPaginator] = useState<Partial<IPagination>>({
        orderDir: 'DESC',
        orderBy: 'created_at',
        perPage: 10,
    });
    const [selectedType, setSelectedType] = useState<number | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [isErr, setIsErr] = useState<boolean>(false);
    const [apiParams, setApiParams] = useState<IParams>();

    const redirectToDetails = (item: IAdminRequest) => {
        if (apiParams) saveParamsToLocalStorage(apiParams, 'reportParams');

        navigate(`/reports/${item.id}`);
    };

    const typePlaceholder = () => {
        return selectedType ? t(reportTypes.find((report) => report.type === selectedType)?.label ?? 'GENERAL.NOT_FOUND') : `${t('ADMIN_REQUEST.CHOOSE_TYPE')}`;
    };

    const changePage = (newPage: number | undefined) => {
        if (newPage) {
            setPage(newPage);
        }
    };

    const toggleSelectedType = (type: number | undefined) => {
        if (type) {
            if (type === selectedType) {
                setPage(1);
                setSelectedType(null);
            } else setSelectedType(type);
        }
    };

    const getReports = async () => {
        try {
            setLoading(true);
            let params: IParams;

            if (getParamsFromLocalStorage('reportParams')) {
                params = getParamsFromLocalStorage('reportParams');
                setPage(params.page ?? 1);
                toggleSelectedType(removeBracketsAndParseNumber(params.type ?? ''));

                localStorage.removeItem('reportParams');
            } else {
                params = {
                    ...paginator,
                    page: page,
                };

                if (selectedType) {
                    params.type = `[${selectedType}]`;
                } else {
                    params.type = `[${AdminRequestType.PRODUCT}, ${AdminRequestType.USER}, ${AdminRequestType.DELIVERY_ISSUE}]`;
                }
            }
            setApiParams(params);

            const queryString = buildQueryString(params);
            const response = await reportsAPI(queryString);
            setData(response.data.results);
            setPaginator({ ...paginator, totalPages: response.data.paginator.totalPages });
        } catch (error) {
            console.log(error);
            setIsErr(true);
        } finally {
            setLoading(false);
        }
    };

    const isDataEmpty = () => {
        return data.length === 0 && !loading && !isErr;
    };

    useEffect(() => {
        getReports();
    }, [page, selectedType]);

    return (
        <Box>
            <Heading as="h1" mb="32px">
                <Trans i18nKey={'MENU.REPORTS'} />
            </Heading>

            <Box mb="8px">
                <Menu>
                    <MenuButton bg="white" fontSize="16px" fontWeight="normal" as={Button} rightIcon={<HiOutlineChevronDown />}>
                        {typePlaceholder()}
                    </MenuButton>
                    <MenuList
                        minW={['100%', '500px', '500px']}
                        maxHeight={'300px'}
                        overflowX={'scroll'}
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {reportTypes.map((report) => (
                            <MenuItem onClick={() => toggleSelectedType(report.type)} key={report.type}>
                                {t(report.label)}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>

            {isErr && (
                <Center mt="24px">
                    <Text color="red">
                        <Trans i18nKey={'GENERAL.LOADING_ERROR'} />
                    </Text>
                </Center>
            )}
            {isDataEmpty() && (
                <Center mt="24px">
                    <Text color="black.60">
                        <Trans i18nKey={'GENERAL.EMPTY_STATE'} />
                    </Text>
                </Center>
            )}
            <Table data={mapTableData(data, t)} config={tableConfig(t)} page={page} pagination={paginator} onPaging={changePage} onRowClick={redirectToDetails} />
            <LoadingModal isOpen={loading} setIsOpen={setLoading} />
        </Box>
    );
};

export default Reports;
