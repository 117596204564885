import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Text } from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { deleteBannerAPI } from 'api/marketing';
import { IBanner } from 'app/marketing/types';

interface IProps {
    isOpen: boolean;
    toggleOpen: () => void;
    username?: string;
    banner: IBanner;
}

const ConfirmDeleteBannerModal = ({ isOpen, toggleOpen, username, banner }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onSubmit = async () => {
        try {
            await deleteBannerAPI(banner.id);
            navigate('/marketing');
        } catch (error) {
            console.log(error);
        }
        toggleOpen();
    };

    return (
        <Modal isOpen={isOpen} onClose={toggleOpen} isCentered>
            <ModalOverlay />
            <ModalContent bg="white">
                <ModalHeader>{t('BANNER.REMOVE_TITLE')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>
                        <Trans i18nKey={'BANNER.REMOVE_DESC'} />
                        {`${username}?`}
                    </Text>
                </ModalBody>
                <ModalFooter justifyContent={'flex-start'}>
                    <Button variant={'dark'} mr={3} onClick={onSubmit}>
                        <Trans i18nKey={'GENERAL.SAVE'} />
                    </Button>
                    <Button variant="outline" onClick={toggleOpen}>
                        <Trans i18nKey={'GENERAL.CANCEL'} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmDeleteBannerModal;
