export enum WalletRequestStatus {
    PENDING = 1,
    ACCEPTED = 2,
    DECLINED = 3,
}

export enum WalletPaymentProvider {
    NONE = 1,
    MONRI = 2,
    BANK_TRANSFER = 3,
    IAP = 4,
    WALLET = 5,
    LEMON_SQUEEZY = 6,
    ADMIN = 7,
    SUBSCRIPTION = 8,
}
