export enum OrderStatus {
    CREATED = 1,
    STARTED = 2,
    ACCEPTED = 3,
    DECLINED = 4,
    PROCESSED = 5,
    COMPLETED = 6,
}

export enum DeliveryStatus {
    ANNOUNCED = 0,
    PICKED_UP_BY_COURIER = 2,
    IN_TRANSIT = 8,
    IN_DROP_POINT = 11,
    PICKED_UP_BY_DELIVERY_COURIER = 14,
    DELAYED = 17,
    RETURNED = 19,
    DELIVERED = 20,
    ATTEMPTED_DELIVERY = 29,
    LOST = 98,
}

export enum PaymentProvider {
    NONE = 1,
    MONRI = 2,
}
