import { SubscriptionStatus, SubscritpionQuota } from '../enums/subscription.enums';

export const subscriptionQuotas = [
    {
        id: SubscritpionQuota.PREMIUM,
        label: 'PREMIUM_MONTHLY',
    },
    {
        id: SubscritpionQuota.PREMIUM_SIX_MONTHS,
        label: 'PREMIUM_SIX_MONTHS',
    },
    {
        id: SubscritpionQuota.PREMIUM_YEAR,
        label: 'PREMIUM_YEARLY',
    },
];

export const subscriptionStatuses = [
    { id: SubscriptionStatus.PAID, label: 'SUB.PAID' },
    { id: SubscriptionStatus.PENDING_CANCEL, label: 'SUB.REQUESTED' },
    { id: SubscriptionStatus.CANCELED, label: 'SUB.CANCELED' },
];
