import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Text } from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { acceptBannerAPI } from 'api/marketing';
import { IBanner } from 'app/marketing/types';
import { IAdminRequest } from 'common/types';
import { updateReportAPI } from 'api/shared';
import { AdminRequestStatus } from 'app/reports/enums/admin.requests.enums';

interface IProps {
    isOpen: boolean;
    toggleOpen: () => void;
    username?: string;
    banner: IBanner;
    adminRequests: IAdminRequest[];
}

const ConfirmAcceptBannerModal = ({ isOpen, toggleOpen, username, banner, adminRequests }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onSubmit = async () => {
        try {
            await acceptBannerAPI(banner.id);

            const result = adminRequests.find((req) => req.referenceUUID === banner.id.toString());
            if (result) {
                await updateReportAPI(result.id, AdminRequestStatus.ACCEPTED);
            }
            navigate('/marketing');
        } catch (error) {
            console.log(error);
        }
        toggleOpen();
    };

    return (
        <Modal isOpen={isOpen} onClose={toggleOpen} isCentered>
            <ModalOverlay />
            <ModalContent bg="white">
                <ModalHeader>{t('BANNER.ACCEPT_TITLE')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>
                        <Trans i18nKey={'BANNER.ACCEPT_DESC'} />
                        {`${username}?`}
                    </Text>
                </ModalBody>
                <ModalFooter justifyContent={'flex-start'}>
                    <Button variant={'dark'} mr={3} onClick={onSubmit}>
                        <Trans i18nKey={'GENERAL.SAVE'} />
                    </Button>
                    <Button variant="outline" onClick={toggleOpen}>
                        <Trans i18nKey={'GENERAL.CANCEL'} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmAcceptBannerModal;
