import { TFunction } from 'i18next';
import { IOrder, IDeliveryStatus } from '../types';
import { formatDate } from 'common/helpers';

export const mapOrdersTableData = (orders: IOrder[], t: TFunction<'translation', undefined>) => {
    const tableData = orders.map((order) => ({
        uuid: order?.uuid,
        seller: order.seller?.username ?? '/',
        createdAt: formatDate(order?.createdAt),
        status: t(`ORDER.STATUS_${order.status}`),
    }));
    return tableData;
};

export const ordersTableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'uuid',
            label: t('ORDER.ID'),
            sortable: true,
        },
        {
            key: 'seller',
            label: t('ORDER.SELLER'),
            sortable: true,
        },
        {
            key: 'createdAt',
            label: t('ORDER.CREATED_AT'),
            sortable: true,
        },
        {
            key: 'status',
            label: t('ORDER.STATUS'),
            sortable: true,
        },
    ];
};

export const sortByDate = (data: IDeliveryStatus[]) => {
    const reversedArray = [...data];
    return reversedArray.reverse();
};
