import { formAxiosObject } from 'common/helpers';
import { IQuotaDetailsResponseDTO, ISubscriptionDetailsResponseDTO, ISubscriptionsResponseDTO } from './types';

export const subscriptionsAPI = async (queryParams?: string): Promise<ISubscriptionsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/subscriptions/${queryParams ? `?${queryParams}` : ''}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const subscriptionDetailsAPI = async (uuid: string): Promise<ISubscriptionDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/subscriptions/${uuid}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const subscriptionQuotasAPI = async () => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/me/subscriptionQuotas/` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const quotaDetailsAPI = async (quotaID: string): Promise<IQuotaDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/me/subscriptionQuotas/${quotaID}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};
