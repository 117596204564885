import { formatDate } from 'common/helpers';
import { IArticle, IArticleCategory } from '../types';
import { TFunction } from 'i18next';
import { differenceInDays } from 'date-fns';
import { IAddress } from 'common/types';

const getPromotionDaysRemaining = (promotedUntil: string) => {
    const startDate = new Date();
    const endDate = new Date(promotedUntil);

    const daysDifference = differenceInDays(endDate, startDate);

    if (daysDifference > 0) {
        return `Artikal istaknut još ${daysDifference} dan(a)`;
    } else if (daysDifference === 0) {
        return 'Zadnji dan promocije';
    } else {
        return 'Ne';
    }
};

export const addressTableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'address',
            label: t('ADDRESS.ADDRESS'),
            sortable: true,
        },
        {
            key: 'verified',
            label: t('ADDRESS.VERIFIED'),
            sortable: true,
        },
        {
            key: 'verifyImage',
            label: t('ADDRESS.IMAGE'),
            sortable: false,
        },
    ];
};

export const mapAddressesTableData = (addressList: IAddress[], t: TFunction<'translation', undefined>) => {
    const tableData = addressList.map((addressItem) => ({
        address: addressItem.address + ', ' + addressItem.postCode + ' ' + addressItem.city,
        postCode: addressItem.postCode,
        city: addressItem.city,
        verified: addressItem.verified ? t('GENERAL.YES') : t('GENERAL.NO'),
        verifyImage: addressItem.verifyImage,
    }));
    return tableData;
};

export const mapArticlesTableData = (articles: IArticle[], categories: IArticleCategory[]) => {
    const tableData = articles.map((article) => ({
        uuid: article?.uuid,
        name: article?.name,
        category: categories.find((c) => c?.id === article?.categoryID)?.translations.bs || '',
        seller: article?.seller?.username ?? '/',
        createdAt: formatDate(article?.createdAt),
        promotedUntil: getPromotionDaysRemaining(article?.promotedUntil),
        views: article?.views,
    }));
    return tableData;
};

export const articlesTableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'name',
            label: t('ARTICLE.NAME'),
            sortable: true,
        },
        {
            key: 'category',
            label: t('ARTICLE.CATEGORY'),
            sortable: true,
        },
        {
            key: 'seller',
            label: t('ARTICLE.SELLER'),
            sortable: true,
        },
        {
            key: 'createdAt',
            label: t('ARTICLE.CREATED_AT'),
            sortable: true,
        },
        {
            key: 'promotedUntil',
            label: t('ARTICLE.PROMOTED'),
            sortable: true,
        },
        {
            key: 'views',
            label: t('ARTICLE.VIEWS'),
            sortable: true,
        },
    ];
};

export const isCategoryEqual = (categoryOne: IArticleCategory, categoryTwo: IArticleCategory | null) => {
    return JSON.stringify(categoryOne) === JSON.stringify(categoryTwo);
};

export const calculateDaysDifference = (promotedUntil: string | null, t: TFunction<'translation', undefined>): string => {
    if (promotedUntil === null) {
        return t('GENERAL.NO');
    }

    const currentDate = new Date();
    const promotedUntilDate = new Date(promotedUntil);
    const diffInMilliseconds = promotedUntilDate.getTime() - currentDate.getTime();
    const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

    if (diffInDays <= 0) {
        return t('GENERAL.NO');
    } else {
        return `${diffInDays} ${t('ARTICLE.PROMOTED_DAYS')}`;
    }
};
