import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalHeader, Stack, Text, HStack, Button, Divider } from '@chakra-ui/react';
import { editUserAPI } from 'api/users';
import { subscriptionQuotas } from 'app/subscriptions/constants';
import ConfirmationModal from 'components/confirmationModal';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    uuid: string;
}

const PremiumModal = ({ isOpen, onClose, uuid }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [months, setMonths] = useState<number>(1);
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);

    const handleClose = () => {
        setMonths(1);
        onClose();
    };

    const getQuotaID = () => {
        switch (months) {
            case 1:
                return subscriptionQuotas[0].id;
            case 6:
                return subscriptionQuotas[1].id;
            case 12:
                return subscriptionQuotas[2].id;
            default:
                return subscriptionQuotas[0].id;
        }
    };

    const toggleConfirmationModal = () => {
        setConfirmationModal(!confirmationModal);
    };

    const confirmPremium = async () => {
        try {
            await editUserAPI(uuid, { subscriptionQuotaID: getQuotaID() });
            navigate('/users');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} isCentered>
            <ModalOverlay />
            <ModalContent bgColor={'white'}>
                <ModalHeader>
                    <Trans i18nKey={'USER.PREMIUM_CONFIRM'} />
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack gap="4px" w="fit-content">
                        <Text w="75%">
                            <Trans i18nKey={'USER.PREMIUM_CHOOSE'} />
                        </Text>
                        <HStack>
                            <Button variant={months === 1 ? 'dark' : 'outline'} onClick={() => setMonths(1)}>
                                <Trans i18nKey={'USER.PREMIUM_MONTH_1'} />
                            </Button>
                            <Button variant={months === 6 ? 'dark' : 'outline'} onClick={() => setMonths(6)}>
                                <Trans i18nKey={'USER.PREMIUM_MONTH_6'} />
                            </Button>
                            <Button variant={months === 12 ? 'dark' : 'outline'} onClick={() => setMonths(12)}>
                                <Trans i18nKey={'USER.PREMIUM_MONTH_12'} />
                            </Button>
                        </HStack>

                        <Divider color="brand.menuBorder" mt={'8px'} />
                    </Stack>

                    <HStack m="16px 0">
                        <Button variant="outline" onClick={onClose}>
                            <Trans i18nKey={'GENERAL.CANCEL'} />
                        </Button>
                        <Button backgroundColor={'brand.success'} color={'white'} fontSize={'14px'} onClick={toggleConfirmationModal}>
                            <Trans i18nKey={'USER.PREMIUM_ACCEPT'} />
                        </Button>
                    </HStack>
                </ModalBody>
                <ConfirmationModal
                    isOpen={confirmationModal}
                    setIsOpen={setConfirmationModal}
                    onConfirm={confirmPremium}
                    message={t('USER.PREMIUM_CONFIRM')}
                    description={t('USER.PREMIUM_DESCRIPTION', { months: months })}
                />
            </ModalContent>
        </Modal>
    );
};

export default PremiumModal;
