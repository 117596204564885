import React from 'react';
import { Switch as ChakraSwitch, Text, HStack } from '@chakra-ui/react';

interface SwitchProps {
    checkedColor: string;
    label?: string;
    isChecked?: boolean;
    onChange?: (isChecked: boolean) => void;
    isDisabled?: boolean;
}

const Switch: React.FC<SwitchProps> = ({ checkedColor, label, onChange, isChecked, isDisabled }) => {
    const handleSwitchChange = (isChecked: boolean) => {
        if (onChange) {
            onChange(isChecked);
        }
    };

    return (
        <HStack justifyContent={'space-between'}>
            {label && <Text fontSize={'14px'}>{label}</Text>}
            <ChakraSwitch
                onChange={(e) => handleSwitchChange(e.target.checked)}
                isChecked={isChecked}
                isDisabled={isDisabled}
                sx={{
                    '& input:checked + .chakra-switch__track': {
                        backgroundColor: checkedColor,
                    },
                }}
            />
        </HStack>
    );
};

export default Switch;
