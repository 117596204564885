import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, Button, HStack, Stack, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
    isOpen: boolean;
    username: string;
    onClose: () => void;
    onSubmit: () => void;
    type: 'promote' | 'demote';
}

const RoleChangeModal = ({ isOpen, onClose, onSubmit, type, username }: IProps) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent bgColor={'white'}>
                <ModalHeader>
                    <Trans i18nKey={type === 'promote' ? 'USER.PROMOTE' : 'USER.DEMOTE'} />
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack>
                        <Text color={'black.60'}>
                            {type === 'promote'
                                ? `${t('USER.PROMOTE_MESSAGE')} ${username} ${t('USER.ROLE_CHANGE_MESSAGE')}`
                                : `${t('USER.DEMOTE_MESSAGE')} ${username} ${t('USER.ROLE_CHANGE_MESSAGE')}`}
                        </Text>
                    </Stack>

                    <HStack mt="32px">
                        <Button backgroundColor={'black.100'} color="white" mb="16px" w="100%" onClick={onSubmit}>
                            <Trans i18nKey={'GENERAL.YES'} />
                        </Button>
                        <Button mb="16px" w="100%" onClick={onClose}>
                            <Trans i18nKey={'GENERAL.NO'} />
                        </Button>
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default RoleChangeModal;
