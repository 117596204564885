import { adminRequestStatuses, adminRequestTypes } from 'app/reports/constants';
import { formatDate, isStringEmpty } from 'common/helpers';
import { IAdminRequest } from 'common/types';
import { TFunction } from 'i18next';

export const tableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'type',
            label: t('ADMIN_REQUEST.REQUEST_TYPE'),
            sortable: true,
        },
        {
            key: 'user',
            label: t('ADMIN_REQUEST.TYPE_USER'),
            sortable: true,
        },
        {
            key: 'status',
            label: t('ADMIN_REQUEST.STATUS'),
            sortable: true,
        },
        {
            key: 'createdAt',
            label: t('ADMIN_REQUEST.CREATED_AT'),
            sortable: true,
        },
    ];
};

export const mapTableData = (data: IAdminRequest[], t: TFunction<'translation', undefined>) => {
    const tableData = data.map((req) => ({
        id: req.id,
        status: t(adminRequestStatuses.find((s) => s.status === req.status)?.label ?? 'GENERAL.NOT_FOUND'),
        user: req.user?.username ?? t('USER.NOT_FOUND'),
        type: t(adminRequestTypes.find((r) => r.type === req.type)?.label ?? 'GENERAL.NOT_FOUND'),
        reason: isStringEmpty(req.reason) ? '-' : req.reason,
        createdAt: formatDate(req.createdAt),
    }));
    return tableData;
};
