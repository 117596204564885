import { IArticleCategory, IArticleCategoryField, IArticleCategoryFieldOption } from 'app/articles/types';
import { createSlug, formatDate, isNullOrUndefined } from 'common/helpers';
import { TFunction } from 'i18next';
import { stepsArr } from '../constants';
import { CategoryRequestDTO, FieldRequestDTO, OptionRequestDTO } from 'api/categories/types/request';
import { FieldOption } from '../types';
import { InputTypes } from '../enums/categories.enums';

export const tableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'name',
            label: t('CATEGORY.NAME'),
            sortable: true,
        },
        {
            key: 'parent',
            label: t('CATEGORY.PARENT'),
            sortable: true,
        },
        {
            key: 'date',
            label: t('CATEGORY.DATE'),
            sortable: true,
        },
        {
            key: 'image',
            label: t('CATEGORY.IMAGE'),
            sortable: true,
        },
    ];
};

export const mapCategoryTableData = (data: IArticleCategory[], t: TFunction<'translation', undefined>, parentCategories: IArticleCategory[]) => {
    const tableData = data.map((category) => ({
        id: category.id,
        name: category?.translations.bs,
        parent: category?.parentID ? parentCategories.find((parentCategory) => parentCategory.id === category.parentID)?.translations.bs : t('GENERAL.NOT_FOUND'),
        date: formatDate(category.createdAt),
        image: category.imageURI ? t('GENERAL.YES') : t('GENERAL.NO'),
    }));
    return tableData;
};

export const findStepTranslationKey = (e: React.ChangeEvent<HTMLSelectElement>) => {
    return stepsArr.find((step) => step.value === parseInt(e.target.value))?.i18nKey || '';
};

export const filterFieldsByStepAndParent = (fields: IArticleCategoryField[], step: number) => {
    return fields?.filter((field) => field.stepID === step && (field.parentID === 0 || isNullOrUndefined(field.parentID))) ?? [];
};

export const mapToOptionRequest = (option: string, parentOptionId: number | null): OptionRequestDTO => {
    return {
        parentID: parentOptionId,
        translations: {
            bs: option,
        },
    };
};

export const generateCategoryUpdateObject = (category: IArticleCategory) => {
    return {
        translations: { bs: category.translations.bs },
        parentCategoryID: category.parentID,
        imageURI: category.imageURI,
        iconURI: category.iconURI,
        color: category.color,
        shippingAvailable: category.shippingAvailable,
        commonShipping: category.commonShipping,
        parentCategory: category.parentCategory,
        slug: createSlug(category.translations.bs),
        order: category.order ?? 0,
    };
};

export const generateFieldUpdateObject = (field: IArticleCategoryField) => {
    return {
        translations: field.translations,
        inputType: field.inputType,
        optional: field.optional,
        searchable: field.searchable,
        parentID: field.parentID,
        placeholderTranslations: field.placeholderTranslations,
        stepID: field.stepID,
    };
};

export const generateOptionUpdateObject = (option: IArticleCategoryFieldOption) => {
    return {
        translations: { bs: option.translations.bs },
        parentID: null,
    };
};

export const formatLocalOptions = (localOptions: FieldOption[], fieldID: number): Partial<IArticleCategoryFieldOption>[] => {
    return localOptions.map((option) => ({
        productCategoryFieldID: fieldID,
        translations: {
            bs: option.translations.bs,
        },

        id: 0,
        createdAt: '',
        updatedAt: '',
    }));
};

export const getOptionsLength = (category: IArticleCategory) => {
    return category.productCategoryFields?.reduce((total, field) => total + (field.productCategoryFieldOptions ? field.productCategoryFieldOptions.length : 0), 0);
};

export const findFieldIndexById = (fields: IArticleCategoryField[], id: number) => {
    return fields.findIndex((field) => field.id === id);
};

export const isTranslationEmpty = (translation: string | undefined) => {
    return !translation || translation.trim() === '';
};

export const defaultCategoryData: IArticleCategory = {
    id: 0,
    translations: {
        bs: '',
    },
    slug: '',
    parentID: 0,
    shippingAvailable: true,
    parentCategory: true,
    order: 0,
    createdAt: '',
    updatedAt: '',
    imageURI: '',
    iconURI: '',
    productCategoryFields: [],
    productCategories: [],
    products: [],
    color: '',
    commonShipping: true,
    giftAvailable: false,
    newAvailable: false,
};

export const defaultFieldData: IArticleCategoryField = {
    id: 0,
    categoryId: 0,
    inputType: 1,
    optional: false,
    searchable: false,
    translations: {
        bs: '',
    },
    placeholderTranslations: {
        bs: '',
    },
    parentID: 0,
    createdAt: '',
    stepID: 0,
    updatedAt: '',
    productCategoryFieldOptions: [],
    iconURI: '',
    order: 0,
    featured: false,
    unit: '',
    minRange: 0,
    maxRange: 0,
};

export const defaultOptionData: IArticleCategoryFieldOption = {
    parentID: null,
    id: 0,
    productCategoryFieldID: 0,
    translations: {
        bs: '',
    },
    createdAt: '',
    updatedAt: '',
};

export const createCategoryRequest = (category: IArticleCategory): CategoryRequestDTO => {
    return {
        color: category.color,
        iconURI: category.iconURI,
        imageURI: category.iconURI,
        parentCategory: category.parentCategory,
        parentCategoryID: category.parentID === 0 ? null : category.parentID,
        shippingAvailable: category.shippingAvailable,
        commonShipping: category.shippingAvailable ? category.commonShipping : false,
        slug: createSlug(category.translations.bs),
        translations: {
            bs: category.translations.bs,
        },
        order: category.order,
        newAvailable: category.newAvailable,
        giftAvailable: category.giftAvailable,
    };
};

export const createFieldRequest = (field: IArticleCategoryField): FieldRequestDTO => {
    return {
        inputType: field.inputType,
        iconURI: field.iconURI,
        order: field.order,
        featured: field.featured,
        unit: field.inputType === InputTypes.RANGE ? field.unit : '',
        maxRange: field.maxRange ?? null,
        minRange: field.minRange ?? null,
        optional: field.stepID === 1 ? false : true,
        parentID: field.parentID === 0 ? null : field.parentID,
        placeholderTranslations: {
            bs: field.placeholderTranslations.bs,
        },
        searchable: field.searchable,
        stepID: field.stepID,
        translations: {
            bs: field.translations.bs,
        },
    };
};

export const createOptionRequest = (option: IArticleCategoryFieldOption): OptionRequestDTO => {
    return {
        parentID: option.parentID,
        translations: {
            bs: option.translations.bs,
        },
    };
};

export function mergeFieldsWithSameParentId(fields: IArticleCategoryField[]): IArticleCategoryField[] {
    const mergedFields: IArticleCategoryField[] = [];
    const parentIdsProcessed = new Set<number>();

    for (const field of fields) {
        if (!parentIdsProcessed.has(field.parentID) && field.parentID !== 0) {
            const matchingFields = fields.filter((f) => f.parentID === field.parentID);

            const mergedField: IArticleCategoryField = {
                ...matchingFields[0],
                translations: matchingFields[0].translations,
                placeholderTranslations: matchingFields[0].placeholderTranslations,
                productCategoryFieldOptions: [],
            };

            matchingFields.forEach((f) => {
                if (f.productCategoryFieldOptions) {
                    mergedField.productCategoryFieldOptions.push(...f.productCategoryFieldOptions);
                }
            });

            mergedFields.push(mergedField);
            parentIdsProcessed.add(field.parentID);
        }
    }

    return mergedFields;
}

export function compareCategories(oldCategory: IArticleCategory, newCategory: IArticleCategory) {
    // Compare fields
    const addedFields: IArticleCategoryField[] = [];
    const deletedFields: IArticleCategoryField[] = [];
    const updatedFields: IArticleCategoryField[] = [];

    // Compare options within fields
    const addedOptions: IArticleCategoryFieldOption[] = [];
    const deletedOptions: IArticleCategoryFieldOption[] = [];
    const updatedOptions: IArticleCategoryFieldOption[] = [];

    for (const newField of newCategory.productCategoryFields) {
        const oldField = oldCategory.productCategoryFields.find((field) => field.id === newField.id);

        if (!oldField) {
            const existingChildField = oldCategory.productCategoryFields.find((field) => field.parentID === newField.parentID);
            if (existingChildField) {
                newField.productCategoryFieldOptions.forEach((opt) => addedOptions.push({ ...opt, productCategoryFieldID: existingChildField.id }));
            } else {
                addedFields.push(newField);
            }
        } else {
            // Check for changes in existing fields
            const fieldChanges: Partial<IArticleCategoryField> = {};

            if (oldField.translations.bs !== newField.translations.bs) {
                fieldChanges.translations = newField.translations;
            }

            if (oldField.placeholderTranslations.bs !== newField.placeholderTranslations.bs) {
                fieldChanges.placeholderTranslations = newField.placeholderTranslations;
            }

            if (oldField.stepID !== newField.stepID) {
                fieldChanges.stepID = newField.stepID;
            }

            if (oldField.searchable !== newField.searchable) {
                fieldChanges.searchable = newField.searchable;
            }

            if (oldField.iconURI !== newField.iconURI) {
                fieldChanges.iconURI = newField.iconURI;
            }

            if (oldField.parentID !== newField.parentID) {
                fieldChanges.parentID = newField.parentID;
            }

            if (oldField.featured !== newField.featured) {
                fieldChanges.featured = newField.featured;
            }

            if (oldField.inputType !== newField.inputType) {
                fieldChanges.inputType = newField.inputType;

                if ([InputTypes.NUMBER, InputTypes.TEXT, InputTypes.RANGE].includes(newField.inputType) && oldField.productCategoryFieldOptions && oldField.productCategoryFieldOptions.length > 0) {
                    oldField.productCategoryFieldOptions.forEach((option) => deletedOptions.push(option));
                }
            }

            if (oldField.unit !== newField.unit) {
                fieldChanges.unit = newField.unit;
            }

            if (oldField.minRange !== newField.minRange) {
                fieldChanges.minRange = newField.minRange;
            }

            if (oldField.maxRange !== newField.maxRange) {
                fieldChanges.maxRange = newField.maxRange;
            }

            if (Object.keys(fieldChanges).length > 0) {
                updatedFields.push({ ...oldField, ...fieldChanges });
            }

            // Check if newField.productCategoryFieldOptions is iterable
            if (Array.isArray(newField.productCategoryFieldOptions) && newField.productCategoryFieldOptions.length > 0) {
                for (const newOption of newField.productCategoryFieldOptions) {
                    const oldOption = oldField.productCategoryFieldOptions?.find((option) => option.id === newOption.id);

                    if (!oldOption) {
                        addedOptions.push(newOption);
                    } else {
                        // Check for changes in existing options
                        const optionChanges: Partial<IArticleCategoryFieldOption> = {};

                        if (oldOption.parentID !== newOption.parentID) {
                            addedOptions.push(newOption);
                            deletedOptions.push(oldOption);
                        }

                        if (oldOption.translations.bs !== newOption.translations.bs) {
                            optionChanges.translations = newOption.translations;
                        }

                        if (Object.keys(optionChanges).length > 0) {
                            updatedOptions.push({ ...oldOption, ...optionChanges });
                        }
                    }
                }

                if (Array.isArray(oldField.productCategoryFieldOptions) && oldField.productCategoryFieldOptions.length > 0)
                    for (const oldOption of oldField.productCategoryFieldOptions) {
                        const newOption = newField.productCategoryFieldOptions.find((option) => option.id === oldOption.id);

                        if (!newOption) {
                            deletedOptions.push(oldOption);
                        }
                    }
            } else {
                if (oldField.productCategoryFieldOptions?.length > 0 && newField.productCategoryFieldOptions?.length === 0 && oldField.inputType === newField.inputType) {
                    if (!deletedFields.includes(newField)) {
                        deletedFields.push(newField);
                    }
                    // Iterate over oldField.productCategoryFieldOptions to avoid duplicates
                    oldField.productCategoryFieldOptions.forEach((option) => {
                        // Check if option is already in deletedOptions
                        if (!deletedOptions.includes(option)) {
                            deletedOptions.push(option);
                        }
                    });
                }
            }
        }
    }

    for (const oldField of oldCategory.productCategoryFields) {
        const newField = newCategory.productCategoryFields?.find((field) => field.id === oldField.id);

        if (!newField) {
            deletedFields.push(oldField);
        }
    }

    return {
        addedFields,
        deletedFields,
        updatedFields,
        addedOptions,
        deletedOptions,
        updatedOptions,
    };
}

export function findDifference(array1: IArticleCategoryFieldOption[], array2: IArticleCategoryFieldOption[]) {
    return array1.filter((item1) => {
        return !array2.some((item2) => {
            if (item1 && item2 && item1.id === item2.id) {
                return true;
            }
            return false;
        });
    });
}

export function sortOptionsByTranslationsBs(fields: IArticleCategoryFieldOption[]): IArticleCategoryFieldOption[] {
    return fields.slice().sort((a, b) => {
        const translationA = a.translations.bs.toLowerCase();
        const translationB = b.translations.bs.toLowerCase();

        const isNumericA = !isNaN(Number(translationA));
        const isNumericB = !isNaN(Number(translationB));

        if (isNumericA && isNumericB) {
            const numericA = Number(translationA);
            const numericB = Number(translationB);
            return numericA - numericB;
        } else if (isNumericA) {
            return -1;
        } else if (isNumericB) {
            return 1;
        } else {
            if (translationA < translationB) {
                return -1;
            }
            if (translationA > translationB) {
                return 1;
            }
            return 0;
        }
    });
}

export function sortFieldsByTranslationsBs(fields: IArticleCategoryField[]): IArticleCategoryField[] {
    return fields.slice().sort((a, b) => {
        const translationA = a.translations.bs.toLowerCase();
        const translationB = b.translations.bs.toLowerCase();

        const isNumericA = !isNaN(Number(translationA));
        const isNumericB = !isNaN(Number(translationB));

        if (isNumericA && isNumericB) {
            const numericA = Number(translationA);
            const numericB = Number(translationB);
            return numericA - numericB;
        } else if (isNumericA) {
            return -1;
        } else if (isNumericB) {
            return 1;
        } else {
            if (translationA < translationB) {
                return -1;
            }
            if (translationA > translationB) {
                return 1;
            }
            return 0;
        }
    });
}

export const compareFields = (newField?: IArticleCategoryField, oldField?: IArticleCategoryField): string[] => {
    const changes: string[] = [];

    function compareObjects(newObj: any, oldObj: any, path: string = '') {
        for (const key in oldObj) {
            const newKey = path ? `${path}.${key}` : key;
            if (!(key in newObj)) {
                changes.push(`${newKey}: ${oldObj[key]} -> undefined`);
            } else if (typeof oldObj[key] === 'object' && oldObj[key] !== null && !Array.isArray(oldObj[key])) {
                compareObjects(newObj[key], oldObj[key], newKey);
            } else if (oldObj[key] !== newObj[key]) {
                changes.push(`${newKey}: ${oldObj[key]} -> ${newObj[key]}`);
            }
        }

        for (const key in newObj) {
            const newKey = path ? `${path}.${key}` : key;
            if (!(key in oldObj)) {
                changes.push(`${newKey}: undefined -> ${newObj[key]}`);
            }
        }
    }

    if (newField && oldField) compareObjects(newField, oldField);

    return changes;
};

export function compareFieldOptions(newOption?: IArticleCategoryFieldOption, oldOption?: IArticleCategoryFieldOption): string[] {
    const changes: string[] = [];

    function compareObjects(newObj: any, oldObj: any, path: string = '') {
        for (const key in oldObj) {
            const newKey = path ? `${path}.${key}` : key;
            if (!(key in newObj)) {
                changes.push(`${newKey}: ${oldObj[key]} -> undefined`);
            } else if (typeof oldObj[key] === 'object' && oldObj[key] !== null && !Array.isArray(oldObj[key])) {
                compareObjects(newObj[key], oldObj[key], newKey);
            } else if (oldObj[key] !== newObj[key]) {
                changes.push(`${newKey}: ${oldObj[key]} -> ${newObj[key]}`);
            }
        }

        for (const key in newObj) {
            const newKey = path ? `${path}.${key}` : key;
            if (!(key in oldObj)) {
                changes.push(`${newKey}: undefined -> ${newObj[key]}`);
            }
        }
    }

    if (newOption && oldOption) compareObjects(newOption, oldOption);

    return changes;
}

export function compareCategoriesObjects(newCategory: IArticleCategory, oldCategory: IArticleCategory): string[] {
    const changes: string[] = [];

    function compareObjects(newObj: any, oldObj: any, path: string = '') {
        for (const key in oldObj) {
            const newKey = path ? `${path}.${key}` : key;
            if (key === 'productCategoryFields' || key === 'productCategories' || key === 'products') {
                continue;
            }
            if (!(key in newObj)) {
                changes.push(`${newKey}: ${oldObj[key]} -> undefined`);
            } else if (typeof oldObj[key] === 'object' && oldObj[key] !== null && !Array.isArray(oldObj[key])) {
                compareObjects(newObj[key], oldObj[key], newKey);
            } else if (oldObj[key] !== newObj[key]) {
                changes.push(`${newKey}: ${oldObj[key]} -> ${newObj[key]}`);
            }
        }

        for (const key in newObj) {
            const newKey = path ? `${path}.${key}` : key;
            if (key === 'productCategoryFields' || key === 'productCategories' || key === 'products') {
                continue;
            }
            if (!(key in oldObj)) {
                changes.push(`${newKey}: undefined -> ${newObj[key]}`);
            }
        }
    }

    compareObjects(newCategory, oldCategory);

    return changes;
}
