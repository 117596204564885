import { truncateString } from 'common/helpers';
import { IUserReview } from '../types';
import { TFunction } from 'i18next';

export const mapReviewsTableData = (reviews: IUserReview[]) => {
    const tableData = reviews.map((review) => ({
        uuid: review?.uuid,
        reviewer: review.reviewer?.username ?? '/',
        reviewee: review.reviewee?.username ?? '/',
        rating: review.rating,
        comment: truncateString(review.comment, 30),
    }));
    return tableData;
};

export const reviewsTableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'reviewer',
            label: t('REVIEW.REVIEWER'),
            sortable: true,
        },
        {
            key: 'reviewee',
            label: t('REVIEW.REVIEWEE'),
            sortable: true,
        },
        {
            key: 'rating',
            label: t('REVIEW.RATING'),
            sortable: true,
        },
        {
            key: 'comment',
            label: t('REVIEW.COMMENT'),
            sortable: true,
        },
    ];
};
