import { useState, useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Button, Menu, MenuButton, MenuList, MenuItem, Text, Center } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { HiOutlineChevronDown } from 'react-icons/hi';

import { Table } from 'components';
import { IArticle, IArticleCategory } from '../types';
import { articlesAPI } from 'api/articles';
import { allCategoriesAPI } from 'api/categories';
import { articlesTableConfig, isCategoryEqual, mapArticlesTableData } from '../utils/helpers';
import { buildQueryString, getParamsFromLocalStorage, saveParamsToLocalStorage } from 'common/helpers';
import { IPagination, IParams } from 'common/types';
import Search from 'components/search/search';
import LoadingModal from 'components/loading';

const Articles = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [data, setData] = useState<IArticle[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isErr, setIsErr] = useState<boolean>(false);
    const [categories, setCategories] = useState<IArticleCategory[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<IArticleCategory | null>(null);

    const [apiParams, setApiParams] = useState<IParams>();

    const [page, setPage] = useState<number>(1);
    const [paginator, setPaginator] = useState<Partial<IPagination>>({
        perPage: 10,
        published: true,
    });

    const toggleSearchTrigger = () => {
        setPage(1);
        setSearchTrigger(!searchTrigger);
    };

    const handleClickSearch = () => {
        toggleSearchTrigger();
    };

    const handleClearSearch = () => {
        setFilter('');
        toggleSearchTrigger();
    };

    const handleSearchFilter = (e: FormEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
    };

    const redirectToDetails = (item: IArticle) => {
        if (apiParams) saveParamsToLocalStorage(apiParams, 'articlesParams');

        navigate(`/articles/${item.uuid}`);
    };

    const categoryPlaceholder = () => {
        return selectedCategory ? `${t('ARTICLE.CATEGORY')}: ${selectedCategory.translations?.bs}` : `${t('ARTICLE.CATEGORY')}`;
    };

    const toggleSelectedCategory = (category: IArticleCategory | undefined, pageNum?: number) => {
        if (category) {
            setPage(pageNum ?? 1);
            if (isCategoryEqual(category, selectedCategory)) {
                setSelectedCategory(null);
            } else setSelectedCategory(category);
        }
    };

    const changePage = (newPage: number | undefined) => {
        if (newPage) {
            setPage(newPage);
        }
    };

    const isDataEmpty = () => {
        return data.length === 0 && !loading && !isErr;
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setLoading(true);
                const res = await allCategoriesAPI(buildQueryString({ perPage: Number.MAX_SAFE_INTEGER }));
                setCategories(res.data.results);
                setLoading(false);
            } catch (error) {
                setIsErr(true);
                console.error(error);
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        if (categories.length > 0) {
            const fetchArticles = async () => {
                setLoading(true);
                try {
                    let params: IParams;

                    if (getParamsFromLocalStorage('articlesParams')) {
                        params = getParamsFromLocalStorage('articlesParams');
                        setPage(params.page ?? 1);
                        setFilter(params.filter ?? '');
                        toggleSelectedCategory(
                            categories.find((c) => c.id === params.categoryIDFilter),
                            params.page ?? 1,
                        );
                        localStorage.removeItem('articlesParams');
                    } else {
                        params = {
                            ...paginator,
                            page: page,
                            filter: filter,
                        };
                        if (selectedCategory) {
                            params.categoryIDFilter = selectedCategory.id;
                        } else {
                            delete params.categoryIDFilter;
                        }
                    }

                    setApiParams(params);

                    const queryString = buildQueryString(params);

                    const res = await articlesAPI(queryString);
                    setData(res.data.results);
                    setPaginator({ ...paginator, totalPages: res.data.paginator.totalPages });
                } catch (error) {
                    setIsErr(true);
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            };

            fetchArticles();
        }
    }, [page, selectedCategory, filter, categories]);

    return (
        <Box>
            <Search filter={filter} searchTrigger={searchTrigger} handleClearSearch={handleClearSearch} handleClickSearch={handleClickSearch} handleSearchFilter={handleSearchFilter} />

            <Heading m="40px 0 33px 0" as="h1">
                <Trans i18nKey="MENU.ARTICLES" />
            </Heading>

            <Box mb="8px">
                <Menu>
                    <MenuButton bg="white" fontSize="16px" fontWeight="normal" as={Button} rightIcon={<HiOutlineChevronDown />}>
                        {categoryPlaceholder()}
                    </MenuButton>
                    <MenuList
                        minW={['100%', '500px', '500px']}
                        maxHeight={'300px'}
                        overflowX={'scroll'}
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {categories.map((category) => (
                            <MenuItem onClick={() => toggleSelectedCategory(category)} key={category?.id}>
                                {category?.translations.bs}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>
            {isErr && (
                <Center mt="24px">
                    <Text color="red">
                        <Trans i18nKey={'GENERAL.LOADING_ERROR'} />
                    </Text>
                </Center>
            )}
            {isDataEmpty() && (
                <Center mt="24px">
                    <Text color="black.60">
                        <Trans i18nKey={'GENERAL.EMPTY_STATE'} />
                    </Text>
                </Center>
            )}
            <Table config={articlesTableConfig(t)} data={mapArticlesTableData(data, categories)} onRowClick={redirectToDetails} page={page} pagination={paginator} onPaging={changePage} />
            <LoadingModal isOpen={loading} setIsOpen={setLoading} />
        </Box>
    );
};

export default Articles;
