import { Box, Heading, Menu, MenuButton, MenuItem, MenuList, Button, HStack, Text, Center } from '@chakra-ui/react';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { Table } from 'components';

import { IAdminRequest, IPagination, IParams } from 'common/types';
import { reportsAPI } from 'api/shared';
import { buildQueryString, getParamsFromLocalStorage, removeBracketsAndParseNumber, saveParamsToLocalStorage } from 'common/helpers';

import { HiOutlineChevronDown } from 'react-icons/hi';
import { requestType } from 'app/reports/constants';
import { AdminRequestType } from 'app/reports/enums/admin.requests.enums';
import { mapTableData, tableConfig } from '../utils/helpers';
import LoadingModal from 'components/loading';
import Search from 'components/search/search';

const Requests = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [data, setData] = useState<IAdminRequest[]>([]);
    const [page, setPage] = useState<number>(1);
    const [paginator, setPaginator] = useState<Partial<IPagination>>({
        orderDir: 'DESC',
        orderBy: 'created_at',
        perPage: 10,
    });
    const [selectedType, setSelectedType] = useState<number | null>(null);
    const [filter, setFilter] = useState<string>('');
    const [searchTrigger, setSearchTrigger] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [isErr, setIsErr] = useState<boolean>(false);
    const [apiParams, setApiParams] = useState<IParams>();

    const redirectToDetails = (item: IAdminRequest) => {
        if (apiParams) saveParamsToLocalStorage(apiParams, 'requestParams');

        navigate(`/requests/${item.id}`);
    };

    const toggleSearchTrigger = () => {
        setPage(1);
        setSearchTrigger(!searchTrigger);
    };

    const handleClickSearch = () => {
        toggleSearchTrigger();
    };

    const handleClearSearch = () => {
        setFilter('');
        toggleSearchTrigger();
    };

    const handleSearchFilter = (e: FormEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
    };

    const typePlaceholder = () => {
        return selectedType ? t(requestType.find((req) => req.type === selectedType)?.label ?? '') : `${t('ADMIN_REQUEST.CHOOSE_TYPE')}`;
    };

    const changePage = (newPage: number | undefined) => {
        if (newPage) {
            setPage(newPage);
        }
    };

    const toggleSelectedType = (type: number | undefined) => {
        if (type) {
            if (type === selectedType) {
                setPage(1);
                setSelectedType(null);
            } else setSelectedType(type);
        }
    };

    const updateParams = (params: IParams) => {
        if (selectedType) {
            params.type = `[${selectedType}]`;
        } else {
            params.type = `[${AdminRequestType.ADDRESS}, ${AdminRequestType.BANNER_REQUESTED}, ${AdminRequestType.SELLER_VERIFY}, ${AdminRequestType.BUSINESS_VERIFY}, ${AdminRequestType.ONLINE_PAYMENT}]`;
        }
    };

    const getReports = async () => {
        let params: IParams;
        try {
            setLoading(true);
            if (getParamsFromLocalStorage('requestParams')) {
                params = getParamsFromLocalStorage('requestParams');
                setPage(params.page ?? 1);
                setFilter(params.filter ?? '');
                toggleSelectedType(removeBracketsAndParseNumber(params.type ?? ''));

                localStorage.removeItem('requestParams');
            } else {
                params = {
                    ...paginator,
                    page: page,
                    status: '1',
                    filter: filter,
                };

                updateParams(params);
            }
            setApiParams(params);

            const queryString = buildQueryString(params);
            const response = await reportsAPI(queryString);
            setData(response.data.results);
            setPaginator({ ...paginator, totalPages: response.data.paginator.totalPages });
        } catch (error) {
            setIsErr(true);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const isDataEmpty = () => {
        return data.length === 0 && !loading && !isErr;
    };

    useEffect(() => {
        getReports();
    }, [page, searchTrigger, selectedType]);

    return (
        <Box>
            <Search filter={filter} searchTrigger={searchTrigger} handleClearSearch={handleClearSearch} handleClickSearch={handleClickSearch} handleSearchFilter={handleSearchFilter} />

            <Heading as="h1" m="40px 0 33px 0">
                <Trans i18nKey={'MENU.REQUESTS'} />
            </Heading>
            <Text m="12px 0 32px 0" color="black.60">
                <Trans i18nKey={'ADMIN.REQUESTS.DESCRIPTION'} />
            </Text>

            <Box mb="8px">
                <HStack>
                    <Menu>
                        <MenuButton bg="white" fontSize="16px" fontWeight="normal" as={Button} rightIcon={<HiOutlineChevronDown />}>
                            {typePlaceholder()}
                        </MenuButton>
                        <MenuList
                            minW={['100%', '500px', '500px']}
                            maxHeight={'300px'}
                            overflowX={'scroll'}
                            css={{
                                '&::-webkit-scrollbar': {
                                    width: '0.4em',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            {requestType.map((request) => (
                                <MenuItem onClick={() => toggleSelectedType(request.type)} key={request.type}>
                                    {t(request.label)}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </HStack>
            </Box>

            {isErr && (
                <Center mt="24px">
                    <Text color="red">
                        <Trans i18nKey={'GENERAL.LOADING_ERROR'} />
                    </Text>
                </Center>
            )}
            {isDataEmpty() && (
                <Center mt="24px">
                    <Text color="black.60">
                        <Trans i18nKey={'GENERAL.EMPTY_STATE'} />
                    </Text>
                </Center>
            )}
            <Table data={mapTableData(data, t)} config={tableConfig(t)} page={page} pagination={paginator} onPaging={changePage} onRowClick={redirectToDetails} />
            <LoadingModal isOpen={loading} setIsOpen={setLoading} />
        </Box>
    );
};

export default Requests;
