import { useMemo } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Trans } from 'react-i18next';

interface IProps {
    page: number;
    totalPages: number;
    onChange?: (value: number) => void;
}

const Paging = ({ page, totalPages, onChange }: IProps) => {
    const paging = useMemo(() => {
        const boundary = 2;
        const endPage = Math.min(totalPages, page + boundary);
        const startPage = endPage === totalPages && endPage - boundary > 0 ? endPage - boundary : Math.max(page, page - boundary);

        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            if (i !== totalPages) {
                pageNumbers.push(i);
            }
        }

        return {
            pageNumbers,
            startPage,
            endPage,
        };
    }, [totalPages, page]);

    if (!onChange) {
        return null;
    }

    return (
        <Flex alignItems="center">
            <Button variant="outline" isDisabled={page === 1} onClick={() => onChange(page - 1)}>
                <BsChevronLeft strokeWidth="1" />
                <Text ml="10px" fontWeight="semibold">
                    <Trans i18nKey="PAGING.PREVIOUS_PAGE" />
                </Text>
            </Button>
            <Button ml="12px" variant={page === 1 ? 'solid' : 'outline'} onClick={() => onChange(1)}>
                <Text fontWeight={page === 1 ? 'bold' : 'semibold'}>{1}</Text>
            </Button>
            {paging.pageNumbers.slice(0, 2).map((value: number, index: number) =>
                value === 1 || value === totalPages ? null : (
                    <Flex key={`paging-number-${value}`}>
                        <Button ml="12px" variant={page === value ? 'solid' : 'outline'} onClick={() => onChange(value)}>
                            <Text fontWeight={page === value ? 'bold' : 'semibold'}>{value}</Text>
                        </Button>
                        {paging.pageNumbers.length > 2 && index === paging.pageNumbers.length - 2 && <Text ml="12px">...</Text>}
                    </Flex>
                ),
            )}
            {totalPages > 1 && (
                <Button ml="12px" variant={page === totalPages ? 'solid' : 'outline'} onClick={() => onChange(totalPages)}>
                    <Text fontWeight={page === totalPages ? 'bold' : 'semibold'}>{totalPages}</Text>
                </Button>
            )}
            <Button variant="outline" ml="12px" onClick={() => onChange(page + 1)} isDisabled={page === totalPages}>
                <Text mr="10px" fontWeight="semibold">
                    <Trans i18nKey="PAGING.NEXT_PAGE" />
                </Text>
                <BsChevronRight strokeWidth="1" />
            </Button>
        </Flex>
    );
};

export default Paging;
