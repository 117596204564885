import { helpers } from 'common';
import { OrderDetailsResponseDTO, OrdersResponseDTO } from './types/response';

const { formAxiosObject } = helpers;

export const ordersListAPI = async (queryParams?: string): Promise<OrdersResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/orders/${queryParams ? `?${queryParams}` : ''}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const orderDetailsAPI = async (uuid: string): Promise<OrderDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/orders/${uuid}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};
