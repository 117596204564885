import { useEffect, useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Heading, Menu, MenuButton, MenuList, MenuItem, Button, Center, Text } from '@chakra-ui/react';
import { HiOutlineChevronDown } from 'react-icons/hi';

import Search from 'components/search/search';
import { Table } from 'components';

import { userReviewsAPI } from 'api/users';
import { IPagination, IParams } from 'common/types';
import { IUserReview } from '../types';
import { mapReviewsTableData, reviewsTableConfig } from '../utils/helpers';
import { buildQueryString, getParamsFromLocalStorage, removeBracketsAndParseNumber, saveParamsToLocalStorage } from 'common/helpers';
import LoadingModal from 'components/loading';

const ReviewManagement = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const options: number[] = Array.from({ length: 5 }, (_, index) => index + 1);
    const [data, setData] = useState<IUserReview[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isErr, setIsErr] = useState<boolean>(false);

    const [filter, setFilter] = useState<string>('');
    const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [paginator, setPaginator] = useState<Partial<IPagination>>({
        perPage: 10,
        published: true,
        orderDir: 'DESC',
    });

    const [selectedRating, setSelectedRating] = useState<number | null>(null);
    const [apiParams, setApiParams] = useState<IParams>();

    const ratingPlaceholder = () => {
        return selectedRating ? selectedRating : `${t('REVIEW.RATING')}`;
    };

    const toggleSelectedRating = (rating: number | undefined) => {
        if (rating) {
            if (rating === selectedRating) {
                setPage(1);
                setSelectedRating(null);
            } else setSelectedRating(rating);
        }
    };

    const toggleSearchTrigger = () => {
        setPage(1);
        setSearchTrigger(!searchTrigger);
    };

    const handleClickSearch = () => {
        toggleSearchTrigger();
    };

    const handleClearSearch = () => {
        setFilter('');
        toggleSearchTrigger();
    };

    const redirectToDetails = (item: IUserReview) => {
        if (apiParams) saveParamsToLocalStorage(apiParams, 'reviewParams');

        navigate(`/reviews/${item.uuid}`);
    };

    const handleSearchFilter = (e: FormEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
    };

    const changePage = (newPage: number | undefined) => {
        if (newPage) {
            setPage(newPage);
        }
    };

    const getUserReviews = async () => {
        try {
            setLoading(true);
            let params: IParams;

            if (getParamsFromLocalStorage('reviewParams')) {
                params = getParamsFromLocalStorage('reviewParams');
                setPage(params.page ?? 1);
                setFilter(params.filter ?? '');
                toggleSelectedRating(removeBracketsAndParseNumber(params.type ?? ''));

                localStorage.removeItem('reviewParams');
            } else {
                params = {
                    ...paginator,
                    page: page,
                    filter: filter,
                };

                if (selectedRating) {
                    params.type = `[${selectedRating}]`;
                } else {
                    delete params.type;
                }
            }

            setApiParams(params);
            const queryString = buildQueryString(params);
            const response = await userReviewsAPI(queryString);
            setPaginator({ ...paginator, totalPages: response.data.paginator.totalPages });
            setData(response.data.results);
        } catch (error) {
            setIsErr(true);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const isDataEmpty = () => {
        return data.length === 0 && !loading && !isErr;
    };

    useEffect(() => {
        getUserReviews();
    }, [page, searchTrigger, selectedRating]);

    return (
        <Box>
            <Search filter={filter} searchTrigger={searchTrigger} handleClearSearch={handleClearSearch} handleClickSearch={handleClickSearch} handleSearchFilter={handleSearchFilter} />

            <Heading as="h1" m="40px 0 33px 0">
                <Trans i18nKey="MENU.REVIEW_MANAGEMENT" />
            </Heading>

            <Box mb="8px">
                <Menu>
                    <MenuButton bg="white" fontSize="16px" fontWeight="normal" as={Button} rightIcon={<HiOutlineChevronDown />}>
                        {ratingPlaceholder()}
                    </MenuButton>
                    <MenuList
                        minW={['100%', '500px', '500px']}
                        maxHeight={'300px'}
                        overflowX={'scroll'}
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {options.map((option) => (
                            <MenuItem onClick={() => toggleSelectedRating(option)} key={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>

            {isErr && (
                <Center mt="24px">
                    <Text color="red">
                        <Trans i18nKey={'GENERAL.LOADING_ERROR'} />
                    </Text>
                </Center>
            )}
            {isDataEmpty() && (
                <Center mt="24px">
                    <Text color="black.60">
                        <Trans i18nKey={'GENERAL.EMPTY_STATE'} />
                    </Text>
                </Center>
            )}

            <Table onPaging={changePage} page={page} pagination={paginator} data={mapReviewsTableData(data)} config={reviewsTableConfig(t)} onRowClick={redirectToDetails} />
            <LoadingModal isOpen={loading} setIsOpen={setLoading} />
        </Box>
    );
};

export default ReviewManagement;
