import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Image, Center } from '@chakra-ui/react';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    imageUrl: string;
}

const ImageModal = ({ isOpen, onClose, imageUrl }: IProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'} isCentered>
            <ModalOverlay />
            <ModalContent bgColor={'white'}>
                <ModalCloseButton />
                <ModalBody p="16px">
                    <Center>
                        <Image height="550px" src={imageUrl} objectFit={'contain'} m="32px 0" alt="Full Screen Image" />
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ImageModal;
