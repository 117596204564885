import { useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import theme from 'theme';
import { Hidden, RenderIf, Sidebar } from 'components';
import { IRoute } from 'common/types';
import { getSession } from 'core/session';

interface IProps {
    route: IRoute;
}

const RoutesWrapper = ({ route }: IProps) => {
    const session = getSession();
    const isUserLoggedIn = !!session?.accessToken;
    const Component = route.component;

    const sidebar = useMemo(() => {
        return <Sidebar {...route} />;
    }, [route.path]);

    return (
        <Flex flexDirection="column" width="100%" height="100%">
            <Flex height="100%" width="100%">
                <RenderIf show={!!(isUserLoggedIn && !route.hide_menu)}>
                    <Hidden sizeDown={theme.breakpointsPixels.lg}>{sidebar}</Hidden>
                </RenderIf>
                <Flex flexDirection="column" height="100%" width="100%" overflowY="scroll">
                    <Box p={route.is_protected ? ['30px 10px', '30px', '30px'] : '0px'} height="100%" overflow={route.is_protected ? 'initial' : 'auto'}>
                        <Component {...route} />
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default RoutesWrapper;
