import { TFunction } from 'i18next';
import { ISubscription } from '../types';
import { formatDate } from 'common/helpers';
import { subscriptionQuotas } from '../constants';

export const mapSubsTableData = (data: ISubscription[], t: TFunction<'translation', undefined>) => {
    const tableData = data.map((sub) => ({
        uuid: sub?.uuid,
        user: sub.user?.username ?? '/',
        package: t(subscriptionQuotas.find((quota) => quota.id === sub.subscriptionQuotaID)?.label ?? 'GENERAL.NOT_FOUND'),
        renewsAt: formatDate(sub.renewsAt) !== '' ? formatDate(sub.renewsAt) : t('GENERAL.NOT_FOUND'),
        renewalDate: sub.renewsAt,
        status: sub.status,
    }));
    return tableData;
};

export const subsTableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'status',
            label: t('SUB.STATUS'),
            sortable: false,
        },
        {
            key: 'renewsAt',
            label: t('SUB.DATE'),
            sortable: true,
        },
        {
            key: 'package',
            label: t('SUB.PACKAGE'),
            sortable: false,
        },
        {
            key: 'user',
            label: t('SUB.USER'),
            sortable: true,
        },
        {
            key: 'uuid',
            label: t('SUB.ID'),
            sortable: true,
        },
    ];
};
