import { OrderStatus, PaymentProvider } from '../enums/orders.enums';

export const orderStatuses = [
    {
        status: OrderStatus.CREATED,
        label: 'ORDER.STATUS_1',
    },
    {
        status: OrderStatus.STARTED,
        label: 'ORDER.STATUS_2',
    },
    {
        status: OrderStatus.ACCEPTED,
        label: 'ORDER.STATUS_3',
    },
    {
        status: OrderStatus.DECLINED,
        label: 'ORDER.STATUS_4',
    },
    {
        status: OrderStatus.PROCESSED,
        label: 'ORDER.STATUS_5',
    },
    {
        status: OrderStatus.COMPLETED,
        label: 'ORDER.STATUS_6',
    },
];

export const orderProviders = [
    {
        provider: PaymentProvider.MONRI,
        label: 'ORDER.CARD_PAYMENT',
    },
    {
        provider: PaymentProvider.NONE,
        label: 'ORDER.OTHER_PAYMENT',
    },
];
