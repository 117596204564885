import { useState, useRef, ChangeEvent } from 'react';

import {
    Text,
    Button,
    Box,
    RadioGroup,
    HStack,
    Stack,
    Select,
    Divider,
    Flex,
    Image,
    AlertIcon,
    Alert,
    NumberInput,
    NumberIncrementStepper,
    NumberDecrementStepper,
    NumberInputStepper,
    NumberInputField,
} from '@chakra-ui/react';

import { useTranslation, Trans } from 'react-i18next';

import { LuImagePlus } from 'react-icons/lu';

import { Input } from 'components';
import Switch from 'components/switch';
import Radio from 'components/radio';

import { categoriesColors } from 'app/category/constants';

import colors from 'theme/foundations/colors';

import { IArticleCategory } from 'app/articles/types';

import { CategoryType } from 'app/category/enums/categories.enums';
import { getUploadUrl, uploadToAws } from 'api/shared';
import { config } from 'core';
import { createSlug } from 'common/helpers';
import LoadingModal from 'components/loading';

interface IProps {
    editMode: boolean;
    hideIntro?: boolean;

    category: IArticleCategory;
    setCategory: (value: IArticleCategory) => void;
    parentCategories: IArticleCategory[];
}

const Shared = ({ category, setCategory, parentCategories, editMode, hideIntro }: IProps) => {
    const { t } = useTranslation();

    const hiddenFileInput = useRef<HTMLInputElement | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(category.iconURI ?? null);
    const [loading, setLoading] = useState<boolean>(false);
    const [categoryType, setCategoryType] = useState<string>(CategoryType.PARENT);

    const getColorBorder = (color: string) => {
        return category.color === color ? `2px solid ${colors.black[60]}` : `none`;
    };

    const isChild = () => {
        return categoryType === 'child' || category.parentID !== 0;
    };

    const handleFile = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            setImagePreview(e.target?.result as string);
        };
        reader.readAsDataURL(file);
    };

    const handleImageUpload = async (uploadedFile: File) => {
        if (uploadedFile) {
            try {
                const uploadUrlResponse = await getUploadUrl({
                    kind: config.IMAGES_BUCKET,
                    fileName: uploadedFile.name,
                    contentType: uploadedFile.type,
                });
                const url = uploadUrlResponse.accessURL;
                const uploadUrl = uploadUrlResponse.url;
                await uploadToAws(uploadedFile, uploadUrl, 0);
                setCategory({ ...category, iconURI: url, imageURI: url });
            } catch (error) {
                console.error('Error uploading image:', error);
            }
            setLoading(false);
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const fileUploaded = event.target.files?.[0];
        const fileInput = event.target;
        fileInput.value = '';

        if (fileUploaded) {
            handleFile(fileUploaded);
            setLoading(true);
            handleImageUpload(fileUploaded);
        }
    };

    const handleGiftChange = (isChecked: boolean) => {
        setCategory({
            ...category,
            giftAvailable: isChecked,
            commonShipping: isChecked ? false : category.commonShipping,
            shippingAvailable: isChecked ? false : category.shippingAvailable,
        });
    };

    const handleShippingChange = (isChecked: boolean) => {
        setCategory({
            ...category,
            shippingAvailable: isChecked,
            giftAvailable: isChecked ? false : category.giftAvailable,
        });
    };

    const handleClick = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const handleCategoryTypeChange = (value: string) => {
        if (value === CategoryType.PARENT) {
            setCategory({ ...category, parentCategory: true, newAvailable: false });
        } else {
            setCategory({ ...category, parentCategory: false, newAvailable: true });
        }
        setCategoryType(value);
    };

    return (
        <Flex gap="32px" direction="column" mb="32px" color={'black'}>
            {!hideIntro && (
                <Stack spacing={'32px'}>
                    <Text fontSize="24px" fontWeight={600} mb="24px">
                        <Trans i18nKey="CATEGORY.CREATION" />
                    </Text>

                    <RadioGroup onChange={(e: string) => handleCategoryTypeChange(e)} value={categoryType} isDisabled={!editMode}>
                        <HStack spacing={'24px'}>
                            <Radio value={CategoryType.PARENT} checkedColor="black.100" label={t('CATEGORY.CREATION_PARENT_OPTION')} />
                            <Radio value={CategoryType.CHILD} checkedColor="black.100" label={t('CATEGORY.CREATION_CHILD_OPTION')} />
                        </HStack>
                    </RadioGroup>

                    <Alert status="info" width={'fit-content'} maxW="542px">
                        <AlertIcon />
                        <Text fontSize={'14px'} color="black.60">
                            <Trans i18nKey={'CATEGORY.REQUIRED_FIELDS'} />
                        </Text>
                    </Alert>

                    <Text fontSize="20px" fontWeight={500}>
                        <Trans i18nKey={categoryType === CategoryType.PARENT ? 'CATEGORY.CREATION_PARENT_INFO' : 'CATEGORY.CREATION_CHILD_INFO'} />
                    </Text>
                </Stack>
            )}

            <Stack>
                <HStack gap={0}>
                    <Text fontSize={'14px'} fontWeight={500}>
                        <Trans i18nKey={'CATEGORY.NAME'} />
                    </Text>
                    <Text color="red">&#42;</Text>
                </HStack>

                <Input
                    disabled={!editMode}
                    type="text"
                    name="categoryName"
                    wrapperProps={{ maxW: '350px' }}
                    placeholder={t('CATEGORY.NAME_PLACEHOLDER')}
                    value={category.translations.bs}
                    onChange={(e) => setCategory({ ...category, translations: { bs: e.currentTarget.value }, slug: createSlug(e.currentTarget.value) })}
                />
                {category.translations.bs.length > 50 && (
                    <Text color="red" fontSize="14px">
                        <Trans i18nKey={'CATEGORY.NAME_MAX_ERR'} />
                    </Text>
                )}
            </Stack>

            {isChild() && (
                <Stack>
                    <HStack gap={0}>
                        <Text fontSize={'14px'} fontWeight={500}>
                            <Trans i18nKey={'CATEGORY.CHOOSE_PARENT_CATEGORY'} />
                        </Text>
                        {isChild() ? <Text color="red">&#42;</Text> : null}
                    </HStack>

                    <Select
                        borderColor={'brand.inputBorder'}
                        maxW={'350px'}
                        placeholder={t('CATEGORY.CHOOSE')}
                        isDisabled={!editMode}
                        value={category.parentID?.toString()}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setCategory({ ...category, parentCategory: false, parentID: parseInt(e.target.value) })}
                    >
                        {parentCategories.length > 0 &&
                            parentCategories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.translations.bs}
                                </option>
                            ))}
                    </Select>
                </Stack>
            )}

            <Stack>
                <Text fontSize={'14px'} fontWeight={500}>
                    <Trans i18nKey={'CATEGORY.ORDER'} />
                </Text>
                <NumberInput
                    isDisabled={!editMode}
                    step={1}
                    borderColor={'brand.inputBorder'}
                    defaultValue={category.order}
                    min={0}
                    max={Number.MAX_SAFE_INTEGER}
                    maxW="350px"
                    onChange={(e) => setCategory({ ...category, order: parseInt(e) })}
                >
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </Stack>
            <Divider color="brand.menuBorder" />

            <Stack>
                <HStack gap={0}>
                    <Text fontWeight={600}>{t('CATEGORY.PICTURE_UPLOAD_TITLE')}</Text>
                    <Text color="red">&#42;</Text>
                </HStack>
                <Text fontSize="14px" maxW={'392px'} color="black.60">
                    {t('CATEGORY.PICTURE_UPLOAD_DESC')}
                </Text>
            </Stack>

            <Button
                maxW="280px"
                w="100%"
                leftIcon={<LuImagePlus />}
                isDisabled={!editMode}
                onClick={() => {
                    if (!editMode) return;
                    handleClick();
                }}
            >
                <Text fontSize="14px" fontWeight={500}>
                    <Trans i18nKey={'CATEGORY.CHANGE_IMAGE'} />
                </Text>
            </Button>

            {imagePreview ? (
                <Box>
                    <Image cursor={!editMode ? 'not-allowed' : 'pointer'} objectFit="cover" src={imagePreview} alt="Selected Image" style={{ width: '280px', height: '128px' }} borderRadius={'9px'} />
                    {editMode && <input type="file" accept=".png" onChange={handleFileChange} ref={hiddenFileInput} style={{ display: 'none' }} />}
                </Box>
            ) : (
                <Box>
                    <Button isDisabled={!editMode} p="64px 128px" bgColor={'brand.imagePlaceholder'} leftIcon={<LuImagePlus style={{ height: '24px', width: '24px' }} />} iconSpacing={0}></Button>
                    {editMode && <input type="file" accept=".png" onChange={handleFileChange} ref={hiddenFileInput} style={{ display: 'none' }} />}
                </Box>
            )}

            <Divider color="brand.menuBorder" />

            <Stack>
                <HStack gap={0}>
                    <Text fontWeight={600}>
                        <Trans i18nKey={'CATEGORY.CHOOSE_COLOR'} />
                    </Text>
                    <Text color="red">&#42;</Text>
                </HStack>

                <HStack spacing="14px">
                    {categoriesColors.map((color: string) => (
                        <Box
                            border={getColorBorder(color)}
                            onClick={() => {
                                if (!editMode) return;
                                setCategory({ ...category, color: color });
                            }}
                            cursor={!editMode ? 'not-allowed' : 'pointer'}
                            key={color}
                            backgroundColor={color}
                            height="48px"
                            width="43px"
                            borderRadius="9px"
                        />
                    ))}
                </HStack>
            </Stack>

            <Divider color="brand.menuBorder" />

            <Text fontWeight={500}>
                <Trans i18nKey={'CATEGORY.SHARED_FIELDS'} />
            </Text>
            <Text fontSize={'14px'} maxW="542px" color="black.60">
                <Trans i18nKey={'CATEGORY.SHARED_FIELDS_DESC'} />
            </Text>

            <Flex maxW="538px" gap="24px" direction={'column'}>
                <Text fontSize={'14px'}>
                    <Trans i18nKey={'CATEGORY.FIELD_NAME'} />
                </Text>
                <Switch isDisabled={!editMode} checkedColor="black" isChecked={category.shippingAvailable} label={t('CATEGORY.FIELD_SHIPPING')} onChange={handleShippingChange} />

                {category.shippingAvailable && (
                    <Switch
                        isDisabled={!editMode}
                        checkedColor="black"
                        isChecked={category.commonShipping}
                        label={t('CATEGORY.COMMON_FIELD_SHIPPING')}
                        onChange={(isChecked) => setCategory({ ...category, commonShipping: isChecked })}
                    />
                )}
                <Text fontSize={'12px'} color="black.60" maxW="542px" mt="-16px">
                    <Trans i18nKey={'CATEGORY.SHIPPING_DESCRIPTION'} />
                </Text>
                {!category?.parentCategory && (
                    <>
                        <Switch
                            isDisabled={!editMode}
                            checkedColor="black"
                            isChecked={category.newAvailable}
                            label={t('CATEGORY.FIELD_STATE')}
                            onChange={(isChecked) => setCategory({ ...category, newAvailable: isChecked })}
                        />
                        <Text fontSize={'12px'} color="black.60" maxW="542px" mt="-16px">
                            <Trans i18nKey={'CATEGORY.STATUS_SELECTION'} />
                        </Text>
                    </>
                )}
                <Switch isDisabled={!editMode} checkedColor="black" isChecked={category.giftAvailable} label={t('CATEGORY.FIELD_GIFT')} onChange={handleGiftChange} />
                <Text fontSize={'12px'} color="black.60" maxW="542px" mt="-16px">
                    <Trans i18nKey={'CATEGORY.GIFT_DESCRIPTION'} />
                </Text>
                <Text fontSize={'14px'}>
                    <Trans i18nKey={'CATEGORY.FIELD_DESC'} />
                </Text>
                <Text fontSize={'14px'}>
                    <Trans i18nKey={'CATEGORY.FIELD_PRICE'} />
                </Text>

                <Text fontSize={'14px'}>
                    <Trans i18nKey={'CATEGORY.FIELD_LOCATION'} />
                </Text>
            </Flex>
            <LoadingModal isOpen={loading} setIsOpen={setLoading} />
        </Flex>
    );
};

export default Shared;
