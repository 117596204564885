import { Trans } from 'react-i18next';
import styled from '@emotion/styled';
import { typography, space, color, compose, layout, TypographyProps, SpaceProps, ColorProps, LayoutProps, OpacityProps } from 'styled-system';

interface IProps extends TypographyProps, SpaceProps, ColorProps, LayoutProps, OpacityProps {
    nonTranslatable?: boolean;
    children: string;
}

const Label = ({ nonTranslatable, children, ...rest }: IProps) => {
    const StyledLabel = styled('label')(
        {
            margin: '0',
        },
        compose(typography, space, color, layout),
    );

    return (
        <StyledLabel fontSize="12px" fontWeight="semibold" lineHeight="20px" mb="6px" {...rest}>
            {nonTranslatable ? children : <Trans i18nKey={children} />}
        </StyledLabel>
    );
};

export default Label;
