import { helpers } from 'common';
import { ArticleDetailsResponseDTO, ArticlesResponseDTO } from './types/response';
import { ArticlePromotionRequestDTO } from './types/request';

const { formAxiosObject } = helpers;

export const articlesAPI = async (queryParams?: string): Promise<ArticlesResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/products/${queryParams ? `?${queryParams}` : ''}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const articleDetailsAPI = async (uuid: string): Promise<ArticleDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/products/${uuid}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const deleteArticleAPI = async (uuid: string): Promise<any> => {
    try {
        await formAxiosObject({ method: 'DELETE', route: `/management/products/${uuid}` });
    } catch (error) {
        throw error;
    }
};

export const promoteArticleAPI = async (uuid: string, data: ArticlePromotionRequestDTO): Promise<ArticleDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'PUT', route: `/management/products/${uuid}/promote/` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const demoteArticleAPI = async (uuid: string): Promise<ArticleDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'PUT', route: `/management/products/${uuid}/demote/` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};
