import { TFunction } from 'i18next';
import { IBanner, IBannerQuota, IPromotionQuota, IPromotionQuotaTableEntry, IQuotaTableEntry } from '../types';
import { IArticleCategory } from 'app/articles/types';
import { bannerStatus, quotaPackageOptions } from '../constants';
import { BannerQuotaRequestDTO, PromotionQuotaRequestDTO } from 'api/marketing/types/request';
import { formatDate } from 'common/helpers';
import { IUser } from 'common/types';

export const calculateWeeks = (days: number) => {
    const numberOfDays = Math.max(0, Math.floor(days));

    const numberOfWeeks = Math.max(1, Math.ceil(numberOfDays / 7));

    return numberOfWeeks;
};

export const weeksAndDays = (numberOfDays: number) => {
    const weeks = Math.floor(numberOfDays / 7);
    let remainingDays = numberOfDays % 7;
    if (remainingDays === 0) {
        remainingDays = 0;
    }
    return { weeks, remainingDays };
};

export const daysFromWeeksAndDays = (weeks: number, days: number) => {
    return weeks * 7 + days;
};

export const tableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'id',
            label: t('GENERAL.ID'),
            sortable: true,
        },
        {
            key: 'category',
            label: t('QUOTA.CATEGORY_LABEL'),
            sortable: true,
        },
        {
            key: 'package',
            label: t('QUOTA.PACKAGE_LABEL'),
            sortable: true,
        },
        {
            key: 'durationDays',
            label: t('QUOTA.DAYS_LABEL'),
            sortable: true,
        },
        {
            key: 'price',
            label: t('QUOTA.PRICE_LABEL'),
            sortable: true,
        },
        {
            key: 'discount',
            label: t('QUOTA.DISCOUNT_LABEL'),
            sortable: true,
        },
        {
            key: 'currency',
            label: t('QUOTA.CURRENCY_LABEL'),
            sortable: true,
        },
        {
            key: 'actions',
            label: t('GENERAL.ACTIONS'),
            sortable: false,
        },
    ];
};

export const tablePromotionConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'id',
            label: t('GENERAL.ID'),
            sortable: true,
        },
        {
            key: 'durationDays',
            label: t('QUOTA.DAYS_LABEL'),
            sortable: true,
        },
        {
            key: 'price',
            label: t('QUOTA.PRICE_LABEL'),
            sortable: true,
        },
        {
            key: 'currency',
            label: t('QUOTA.CURRENCY_LABEL'),
            sortable: true,
        },
        {
            key: 'actions',
            label: t('GENERAL.ACTIONS'),
            sortable: false,
        },
    ];
};

export const mapPromotionQuotaTableData = (data: IPromotionQuota[], t: TFunction<'translation', undefined>): IPromotionQuotaTableEntry[] => {
    const tableData = data.map((quota) => ({
        id: quota.id,
        durationDays: `${daysFromWeeksAndDays(quota.durationWeeks, quota.durationDays)} ${t('QUOTA.DAYS')}`,
        price: quota.price / 100,
        currency: t('QUOTA.CURRENCY_PLACEHOLDER'),
        originalData: quota,
    }));
    return tableData;
};

export const mapQuotaTableData = (data: IBannerQuota[], t: TFunction<'translation', undefined>, categories?: IArticleCategory[]): IQuotaTableEntry[] => {
    const tableData = data.map((quota) => ({
        id: quota.id,
        category: categories?.find((category) => category.id === quota.categoryID)?.translations.bs ?? t('GENERAL.NOT_FOUND'),
        package: t(quotaPackageOptions.find((packageOption) => packageOption.id === quota.packageID)?.label ?? 'GENERAL.NOT_FOUND'),
        durationDays: `${daysFromWeeksAndDays(quota.durationWeeks, quota.durationDays)} ${t('QUOTA.DAYS')}`,
        price: quota.price / 100,
        discount: quota.discountedPrice / 100,
        currency: t('QUOTA.CURRENCY_PLACEHOLDER'),
        originalData: quota,
    }));
    return tableData;
};

export const mapToBannerQuotaRequestDTO = (data: IBannerQuota): BannerQuotaRequestDTO => {
    const { categoryID, currency, discountedPrice, durationDays, durationWeeks, packageID, price } = data;

    return {
        categoryID,
        currency,
        discountedPrice,
        durationDays,
        durationWeeks,
        packageID,
        price,
    };
};

export const mapToPromotionQuotaRequestDTO = (data: IPromotionQuota): PromotionQuotaRequestDTO => {
    const { currency, durationDays, durationWeeks, price, externalPackageID } = data;

    return {
        currency,
        durationDays,
        durationWeeks,
        price,
        externalPackageID,
    };
};

export const bannerTableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'user',
            label: t('BANNER.USER'),
            sortable: true,
        },
        {
            key: 'category',
            label: t('BANNER.CATEGORY'),
            sortable: true,
        },
        {
            key: 'createdAt',
            label: t('BANNER.DATE'),
            sortable: true,
        },
        {
            key: 'status',
            label: t('BANNER.STATUS'),
            sortable: true,
        },
        {
            key: 'durationDays',
            label: t('BANNER.DURATION'),
            sortable: true,
        },
    ];
};

export const mapBannerTableData = (data: IBanner[], users: IUser[], t: TFunction<'translation', undefined>, categories: IArticleCategory[]) => {
    const tableData = data.map((banner) => ({
        id: banner.id,
        user: users.find((user) => user.uuid === banner.userUUID)?.username ?? t('USER.NOT_FOUND'),
        category: categories.find((category) => category.id === banner.bannerQuota.categoryID)?.translations.bs ?? t('GENERAL.NOT_FOUND'),
        createdAt: formatDate(banner.createdAt),
        durationDays: `${daysFromWeeksAndDays(banner.bannerQuota.durationWeeks, banner.bannerQuota.durationDays)} ${t('QUOTA.DAYS')}`,
        status: t(bannerStatus.find((s) => s.status === banner.status)?.label ?? 'GENERAL.NOT_FOUND'),
    }));
    return tableData;
};
