import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Image, Center } from '@chakra-ui/react';

interface IProps {
    isOpen: boolean;
    toggleOpen: () => void;
    imageURI: string;
}

const FullSizeImageModal = ({ isOpen, toggleOpen, imageURI }: IProps) => {
    return (
        <Modal isOpen={isOpen} onClose={toggleOpen} size={'5xl'} isCentered>
            <ModalOverlay />
            <ModalContent bg="white">
                <ModalCloseButton />
                <ModalBody>
                    <Center>
                        <Image m="32px 0" cursor={'pointer'} src={imageURI} />
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default FullSizeImageModal;
