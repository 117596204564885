import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Button, HStack, Stack, Text, Alert, AlertIcon } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

interface IProps {
    isOpen: boolean;
    description: string;
    onClose: () => void;
    onSubmit: () => void;

    error?: boolean;
    errorMessage?: string;
}

const ToggleActivationModal = ({ isOpen, onClose, onSubmit, description, error, errorMessage }: IProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent bgColor={'white'}>
                <ModalCloseButton />
                <ModalBody>
                    <Stack p="16px 0" spacing={'16px'}>
                        <Text fontSize={'20px'} fontWeight={600}>
                            {description}
                        </Text>
                    </Stack>
                    {error && (
                        <Alert status="error">
                            <AlertIcon />
                            <Stack spacing={0}>
                                <Text fontSize={'14px'}>{errorMessage}</Text>
                            </Stack>
                        </Alert>
                    )}
                    <HStack mt="32px">
                        <Button backgroundColor={'black.100'} color="white" mb="16px" w="100%" onClick={onSubmit} isDisabled={error}>
                            <Trans i18nKey={'GENERAL.YES'} />
                        </Button>
                        <Button mb="16px" w="100%" onClick={onClose} isDisabled={error}>
                            <Trans i18nKey={'GENERAL.NO'} />
                        </Button>
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ToggleActivationModal;
