import { formatDate } from 'common/helpers';
import { IUser } from 'common/types';
import { TFunction } from 'i18next';
import { UserVerificationStatus } from '../enums/users.enums';

export const tableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'username',
            label: t('USER.NAME'),
            sortable: true,
        },
        {
            key: 'createdAt',
            label: t('USER.CREATED_AT'),
            sortable: true,
        },
        {
            key: 'verified',
            label: t('USER.VERIFY_STATUS'),
            sortable: true,
        },
        {
            key: 'active',
            label: t('USER.STATUS'),
            sortable: true,
        },
    ];
};

export const mapUsersTableData = (data: IUser[], t: TFunction<'translation', undefined>) => {
    const tableData = data.map((user) => ({
        uuid: user.uuid,
        username: user?.username,
        createdAt: formatDate(user.createdAt),
        verified: user.verificationStatus === UserVerificationStatus.VERIFIED ? t('GENERAL.YES') : t('GENERAL.NO'),
        active: user.active ? t('USER.ACTIVE') : t('USER.INACTIVE'),
    }));
    return tableData;
};

export const extractUsernameFromSocialLink = (link: string) => {
    const usernameRegex = /(?:https?:\/\/)?(?:www\.)?([^/]+)\/@?([^/]+)/;

    const match = link.match(usernameRegex);
    if (match) {
        return match[2];
    } else {
        return undefined;
    }
};

export const formatLink = (link: string) => {
    if (link.startsWith('http://') || link.startsWith('https://')) {
        return link;
    } else if (link.startsWith('www.')) {
        return 'https://' + link;
    } else {
        return 'https://' + link;
    }
};
