import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import RoutesWrapper from 'components/routes-wraper';
import { routesConstants, _ } from 'common';
import { IDecodedToken, IRoute } from 'common/types';
import { isStringEmpty } from 'common/helpers';
import { removeSession, getSession } from 'core/session';

const AppRoutes = () => {
    const session = getSession();
    const isUserLoggedIn = !!session?.accessToken;
    let scope: string;

    if (session.accessToken) {
        const decoded: IDecodedToken = jwt_decode(session.accessToken);
        scope = decoded.scope;
    }

    const getRenderComponent = (route: IRoute): JSX.Element => {
        if (!isUserLoggedIn && route.is_protected) {
            return <Navigate to={routesConstants.routes[routesConstants.prefixes.login].path} />;
        }

        if (isUserLoggedIn && isStringEmpty(scope)) {
            removeSession();
            return <Navigate to={routesConstants.routes[routesConstants.prefixes.login].path} />;
        }

        if (isUserLoggedIn && !route.is_protected) {
            return <Navigate to={routesConstants.routes[routesConstants.prefixes.articles].path} />;
        }

        return <RoutesWrapper route={route} />;
    };

    const renderRoutes = (routes: IRoute[]): JSX.Element[] | null => {
        if (routes && routes.length > 0) {
            return routes.map((route: IRoute) => <Route path={route.path} element={getRenderComponent(route)} key={route.prefix} />);
        }

        return null;
    };

    return (
        <BrowserRouter>
            <Routes>{renderRoutes(_.values(routesConstants.routes))}</Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
