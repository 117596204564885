import { Alert, AlertIcon, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Card, CardBody, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import UserContactCard from 'components/userCard';
import { ISubscription, ISubscriptionQuota } from '../types';
import { quotaDetailsAPI, subscriptionDetailsAPI } from 'api/subscriptions';
import { subscriptionQuotas } from '../constants';
import { formatDate } from 'common/helpers';

const SubscriptionDetails = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState<ISubscription>();
    const [quota, setQuota] = useState<ISubscriptionQuota>();

    const getUserName = () => {
        if (data && data.user) {
            const { businessName, firstName } = data.user;
            if (businessName) {
                return `${t('USER.VERIFY_BUSINESS_NAME')} ${businessName}`;
            } else if (firstName) {
                return `${t('USER.VERIFY_FIRST_NAME')} ${firstName}`;
            }
        }
        return null;
    };

    const getUserJIBOrLastName = () => {
        if (data && data.user) {
            const { businessJIBNumber, lastName } = data.user;
            if (businessJIBNumber) {
                return `${t('USER.VERIFY_JIB')} ${businessJIBNumber}`;
            } else if (lastName) {
                return `${t('USER.VERIFY_LAST_NAME')} ${lastName}`;
            }
        }
        return null;
    };

    const getUserPhoneOrBusinessPhone = () => {
        if (data && data.user) {
            const { businessPhone, phone } = data.user;
            if (businessPhone) {
                return `${t('USER.VERIFY_BUSINESS_PHONE')} ${businessPhone}`;
            } else if (phone) {
                return `${t('USER.PHONE_NUM')} ${phone}`;
            }
        }
        return null;
    };

    const getDetails = async () => {
        if (id) {
            try {
                const res = await subscriptionDetailsAPI(id);
                setData(res.data);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getQuota = async (id: number) => {
        try {
            const response = await quotaDetailsAPI(id.toString());
            setQuota(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getDetails();
    }, []);

    useEffect(() => {
        if (data?.subscriptionQuotaID) {
            getQuota(data.subscriptionQuotaID);
        }
    }, [data]);

    const userPhoneOrBusinessPhone = getUserPhoneOrBusinessPhone();
    const userName = getUserName();
    const userJIBOrLastName = getUserJIBOrLastName();

    return data ? (
        <Box>
            <Breadcrumb spacing="8px" separator={<HiOutlineChevronRight color="gray.500" />} m="24px 0">
                <BreadcrumbItem>
                    <BreadcrumbLink href="/subscriptions">
                        <Trans i18nKey={'MENU.SUBSCRIPTIONS'} />
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href="#">{id}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <Flex alignItems="flex-start" direction={{ base: 'column', md: 'row' }} gap="24px">
                <Card w="100%">
                    <CardBody>
                        <Text fontSize="20px">
                            <Trans i18nKey={'SUB.INFO'} />
                        </Text>
                        <Divider color="brand.menuBorder" m="16px 0" />

                        {data.user && (
                            <Stack>
                                {userName ? <Text>{userName}</Text> : null}
                                {userJIBOrLastName ? <Text>{userJIBOrLastName}</Text> : null}
                                {userPhoneOrBusinessPhone ? <Text>{userPhoneOrBusinessPhone}</Text> : null}

                                {data.user.email && (
                                    <Text>
                                        {t('USER.VERIFY_EMAIL')} {data.user.email}
                                    </Text>
                                )}
                                <Divider color="brand.menuBorder" m="16px 0" />
                            </Stack>
                        )}

                        <Alert status="info" width={'fit-content'} maxW="542px" mb="8px">
                            <AlertIcon />
                            <Text fontSize={'14px'} color="black.60">
                                {t('SUB.DATE')}: {formatDate(data.renewsAt) !== '' ? formatDate(data.renewsAt) : t('GENERAL.NOT_FOUND')}
                            </Text>
                        </Alert>
                        {quota && (
                            <Stack>
                                <Text fontWeight={700}>
                                    <Trans i18nKey={subscriptionQuotas.find((q) => q.id === quota.id)?.label ?? 'GENERAL.NOT_FOUND'} />
                                </Text>
                                {quota.walletCoinsAmount && (
                                    <Text>
                                        {t('SUB.WALLET_INFO')} {quota.walletCoinsAmount / 100} {t(quota.currency)}
                                    </Text>
                                )}
                                <Text>
                                    {t('SUB.PRICE')} {quota?.price / 100} {t(quota.currency)}
                                </Text>
                            </Stack>
                        )}
                    </CardBody>
                </Card>

                <Stack spacing="11px" maxW={{ base: '100%', md: '357px' }} w="100%">
                    <UserContactCard userUUID={data.userUUID} translation="" />
                </Stack>
            </Flex>
        </Box>
    ) : null;
};

export default SubscriptionDetails;
