import { Flex, Button } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

import { Input } from 'components';
import { FormEvent } from 'react';

export interface IProps {
    filter: string;
    searchTrigger: boolean;
    handleClearSearch: () => void;
    handleClickSearch: () => void;
    handleSearchFilter: (e: FormEvent<HTMLInputElement>) => void;
}

const Search = ({ filter, searchTrigger, handleClearSearch, handleClickSearch, handleSearchFilter }: IProps) => {
    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleClickSearch();
        }
    };

    return (
        <Flex gap="19px">
            <Input value={filter} wrapperProps={{ maxWidth: '452px', width: '100%' }} type="search" name="search" placeholder="Pretraga" onChange={handleSearchFilter} onKeyPress={onKeyPress} />

            <Button fontSize="14px" onClick={handleClickSearch}>
                <Trans i18nKey={'SEARCH.BUTTON'} />
            </Button>
            {filter !== '' && searchTrigger && (
                <Button variant={'outline'} onClick={handleClearSearch}>
                    <Trans i18nKey={'SEARCH.BUTTON_CLEAR'} />
                </Button>
            )}
        </Flex>
    );
};

export default Search;
