import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Stack, Text, HStack } from '@chakra-ui/react';

import { DecimalInput, Input } from 'components';

import { arePropsUndefined } from 'common/helpers';
import { IWalletQuota } from 'app/wallet/types';
import { createWalletQuotaAPI, editWalletQuotaAPI } from 'api/wallet';
import { isStringEmpty } from 'common/helpers';
import { config } from 'core';

interface IProps {
    toggleCreateModal: () => void;
    modalOpen: boolean;
    entryCreated: boolean;
    setEntryCreated: (value: boolean) => void;
    selectedQuota?: IWalletQuota;
    setSelectedQuota?: Dispatch<SetStateAction<IWalletQuota | undefined>> | undefined;
}

const QuotaDetailsModal = ({ toggleCreateModal, modalOpen, setEntryCreated, entryCreated, selectedQuota, setSelectedQuota }: IProps) => {
    const { t } = useTranslation();
    const [price, setPrice] = useState<string>('');
    const [externalID, setExternalID] = useState<string>('');

    const isSaveButtonDisabled = isStringEmpty(price) || isStringEmpty(externalID) || arePropsUndefined({ price, externalID });

    const onClose = () => {
        if (selectedQuota && setSelectedQuota) {
            setSelectedQuota(undefined);
        }
        setPrice('');
        setExternalID('');
        toggleCreateModal();
    };

    const closeOnSuccess = () => {
        setEntryCreated(!entryCreated);
        onClose();
    };

    const createNewQuota = async () => {
        try {
            const priceInCents = Math.round(parseFloat(price || '0') * 100);
            await createWalletQuotaAPI({
                currency: 'BAM',
                price: priceInCents,
                externalPackageID: externalID ?? '',
            });
            closeOnSuccess();
        } catch (error) {
            console.log(error);
        }
        toggleCreateModal();
    };

    const editQuota = async () => {
        if (selectedQuota) {
            try {
                const priceInCents = Math.round(parseFloat(price || '0') * 100);
                await editWalletQuotaAPI(selectedQuota?.id.toString() ?? '1', {
                    currency: 'BAM',
                    price: priceInCents,
                    externalPackageID: externalID ?? '',
                });
                closeOnSuccess();
            } catch (error) {
                console.log(error);
            }
            toggleCreateModal();
        }
    };

    const onSubmit = () => {
        if (selectedQuota) {
            editQuota();
        } else {
            createNewQuota();
        }
    };

    useEffect(() => {
        if (selectedQuota) {
            const formattedPrice = selectedQuota.price / 100;
            setPrice(formattedPrice.toString());
            setExternalID(selectedQuota.externalPackageID);
        }
    }, [modalOpen]);

    return (
        <Box>
            <Modal isOpen={modalOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent bgColor={'white'}>
                    <ModalHeader>
                        <Trans i18nKey={selectedQuota ? 'WALLET.QUOTA_EDIT' : 'WALLET.QUOTA_ADD'} />
                    </ModalHeader>

                    <ModalCloseButton />
                    <ModalBody>
                        <Stack gap="4px">
                            <Text>
                                <Trans i18nKey={'WALLET.PRICE_LABEL'} />
                            </Text>
                            <DecimalInput placeholder={t('WALLET.PRICE_PLACEHOLDER')} value={price} onChange={(newValue) => setPrice(newValue)} />
                        </Stack>
                        <Stack gap="4px">
                            <Text mt="8px">
                                <Trans i18nKey={'WALLET.EXTERNAL_LABEL'} />
                            </Text>
                            <Text fontSize="12px" color="black.60">
                                {t('WALLET.EXTERNAL_GUIDE', { env: config.NAKLIK_ENV })}
                            </Text>
                            <Input name="externalID" placeholder={t('WALLET.EXTERNAL_PLACEHOLDER')} value={externalID} onChange={(e) => setExternalID(e.currentTarget.value)} />
                        </Stack>
                    </ModalBody>

                    <ModalFooter justifyContent={'flex-start'} mt="8px">
                        <HStack>
                            <Button isDisabled={isSaveButtonDisabled} backgroundColor={'brand.success'} color={'white'} onClick={onSubmit} fontSize={'14px'}>
                                <Trans i18nKey={selectedQuota ? 'WALLET.QUOTA_EDIT' : 'WALLET.QUOTA_ADD'} />
                            </Button>
                            <Button variant="outline" mr={3} onClick={onClose}>
                                <Trans i18nKey={'GENERAL.CANCEL'} />
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default QuotaDetailsModal;
