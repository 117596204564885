import { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Menu, MenuButton, MenuItem, MenuList, Button, Center, Text } from '@chakra-ui/react';

import { Table } from 'components';
import { HiOutlineChevronDown } from 'react-icons/hi';

import { bannersAPI } from 'api/marketing';
import { userDetailsAPI } from 'api/users';
import { IArticleCategory } from 'app/articles/types';
import { bannerStatus } from 'app/marketing/constants';
import { IBanner } from 'app/marketing/types';
import { IPagination, IParams, IUser } from 'common/types';

import { bannerTableConfig, mapBannerTableData } from 'app/marketing/helpers';
import { buildQueryString, getParamsFromLocalStorage, removeBracketsAndParseNumber, saveParamsToLocalStorage } from 'common/helpers';
import { BannerStatus } from 'app/marketing/enums/banners.enums';
import LoadingModal from 'components/loading';
import { BannersResponseDTO } from 'api/marketing/types/response';

interface IProps {
    page: number;
    setPage: (value: number) => void;
    searchTrigger: boolean;
    filter: string;
    categories: IArticleCategory[];
    setFilter: (value: string) => void;
}
const Banners = ({ searchTrigger, page, setPage, categories, filter, setFilter }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [data, setData] = useState<IBanner[]>([]);
    const [paginator, setPaginator] = useState<Partial<IPagination>>({
        perPage: 10,
        orderDir: 'DESC',
    });
    const [userUUIDs, setUserUUIDs] = useState<string[]>([]);
    const [users, setUsers] = useState<IUser[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isErr, setIsErr] = useState<boolean>(false);
    const [apiParams, setApiParams] = useState<IParams>();

    const statusPlaceholder = () => {
        return selectedStatus ? `${t('BANNER.STATUS')}: ${t(bannerStatus.find((status) => status.status === selectedStatus)?.label ?? 'GENERAL.NOT_FOUND')}` : `${t('BANNER.STATUS_PLACEHOLDER')}`;
    };

    const redirectToDetails = (item: IBanner) => {
        if (apiParams) saveParamsToLocalStorage(apiParams, 'bannerParams');
        navigate(`/marketing/${item.id}`);
    };

    const toggleSelectedStatus = (status: number | undefined) => {
        if (status) {
            if (status === selectedStatus) {
                setPage(1);
                setSelectedStatus(null);
            } else setSelectedStatus(status);
        }
    };

    const changePage = (newPage: number | undefined) => {
        if (newPage) {
            setPage(newPage);
        }
    };

    const isCurrentPageEmpty = (response: BannersResponseDTO) => {
        return page > 1 && response.data.paginator.currentEntriesSize === 0 && response.data.paginator.totalEntriesSize > 0;
    };

    const getBanners = async () => {
        try {
            setLoading(true);
            let params: IParams;

            if (getParamsFromLocalStorage('bannerParams')) {
                params = getParamsFromLocalStorage('bannerParams');
                setPage(params.page ?? 1);
                toggleSelectedStatus(removeBracketsAndParseNumber(params.status ?? ''));
                setFilter(params.filter ?? '');

                localStorage.removeItem('bannerParams');
            } else {
                params = {
                    ...paginator,
                    page: page,
                    filter: filter,
                };

                if (selectedStatus) {
                    params.status = `[${selectedStatus}]`;
                } else {
                    params.status = `[${BannerStatus.REQUESTED}, ${BannerStatus.PAYMENT_ACCEPTED}, ${BannerStatus.PAYMENT_ACCEPTED}, ${BannerStatus.EXPIRED}]`;
                }
            }
            setApiParams(params);

            const queryString = buildQueryString(params);
            const res = await bannersAPI(queryString);
            if (isCurrentPageEmpty(res)) {
                setPage(page - 1);
            }

            const uniqueUUIDs = Array.from(new Set(res.data.results.map((banner) => banner.userUUID)));
            setUserUUIDs(uniqueUUIDs);

            setData(res.data.results);
            setPaginator({ ...paginator, totalPages: res.data.paginator.totalPages });
        } catch (error) {
            setIsErr(true);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const isDataEmpty = () => {
        return data.length === 0 && !loading && !isErr;
    };

    useEffect(() => {
        getBanners();
    }, [searchTrigger, page, selectedStatus, searchTrigger]);

    useEffect(() => {
        const fetchUsernames = async () => {
            try {
                const userPromises = userUUIDs.map(async (uuid) => {
                    try {
                        const userDetails = await userDetailsAPI(uuid);
                        return userDetails.data;
                    } catch (error) {
                        console.error(`Error fetching user with UUID ${uuid}:`, error);
                        return null;
                    }
                });

                const resolvedUsers = await Promise.all(userPromises);

                const validUsers = resolvedUsers.filter((user) => user !== null);

                setUsers(validUsers as IUser[]);
            } catch (error) {
                console.error('Error fetching usernames:', error);
            }
        };

        if (userUUIDs.length > 0) {
            fetchUsernames();
        }
    }, [userUUIDs]);

    return (
        <Box>
            <Box m="32px 0">
                <Menu>
                    <MenuButton bg="white" fontSize="16px" fontWeight="normal" as={Button} rightIcon={<HiOutlineChevronDown />}>
                        {statusPlaceholder()}
                    </MenuButton>
                    <MenuList
                        minW={['100%', '500px', '500px']}
                        maxHeight={'300px'}
                        overflowX={'scroll'}
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {bannerStatus.map((status) => (
                            <MenuItem onClick={() => toggleSelectedStatus(status.status)} key={status.status}>
                                {t(status.label)}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>

            {isErr && (
                <Center mt="32px">
                    <Text color="red">
                        <Trans i18nKey={'GENERAL.LOADING_ERROR'} />
                    </Text>
                </Center>
            )}
            {isDataEmpty() && (
                <Center mt="32px">
                    <Text color="black.60">
                        <Trans i18nKey={'GENERAL.EMPTY_STATE'} />
                    </Text>
                </Center>
            )}
            <Table
                data={mapBannerTableData(data, users, t, categories)}
                config={bannerTableConfig(t)}
                pagination={paginator}
                page={page}
                onPaging={changePage}
                paginationPosition="relative"
                onRowClick={redirectToDetails}
            />
            <LoadingModal isOpen={loading} setIsOpen={setLoading} />
        </Box>
    );
};

export default Banners;
