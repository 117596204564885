import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RxCross2 } from 'react-icons/rx';
import { HiOutlineChevronRight } from 'react-icons/hi';

import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Stack, Card, CardBody, Image, Flex, Text, Button, Divider, HStack } from '@chakra-ui/react';

import { Table } from 'components';
import ConfirmationModal from 'components/confirmationModal';
import UserContactCard from 'components/userCard';

import { isStringEmpty } from 'common/helpers';
import { mapTableData, singleEntryConfig } from '../utils/helpers';
import { deleteReportAPI, reportDetailsAPI } from 'api/shared';
import { AdminRequestType } from '../enums/admin.requests.enums';
import { IAdminRequest, IAdminRequestImage } from 'common/types';
import { IArticle } from 'app/articles/types';
import { articleDetailsAPI } from 'api/articles';

const ReportDetails = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [data, setData] = useState<IAdminRequest>();
    const [article, setArticle] = useState<IArticle>();
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    const redirectToReports = () => {
        navigate('/reports');
    };

    const openArticle = (uuid: string) => {
        navigate(`/articles/${uuid}`);
    };

    const openImage = (reportImage: IAdminRequestImage | undefined) => {
        if (reportImage) {
            window.open(reportImage.imageURI, '_blank');
        } else return;
    };

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const renderReporter = () => {
        return data && data.reporterUUID && <UserContactCard userUUID={data?.reporterUUID} translation="ADMIN_REQUEST.REPORTER" />;
    };

    const renderReported = () => {
        if (data && article && article.sellerUserUUID) {
            return <UserContactCard userUUID={article.sellerUserUUID} translation="ADMIN_REQUEST.REPORTED" />;
        } else if (data && data.type === AdminRequestType.USER && data.referenceUUID) {
            return <UserContactCard userUUID={data.referenceUUID} translation="ADMIN_REQUEST.REPORTED" />;
        }

        return null;
    };

    const deleteReport = async () => {
        if (id) {
            try {
                await deleteReportAPI(parseInt(id));
                toggleDeleteModal();
                redirectToReports();
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getReportDetails = async () => {
        try {
            if (id) {
                const response = await reportDetailsAPI(parseInt(id));
                setData(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getArticle = async (uuid: string) => {
        try {
            const response = await articleDetailsAPI(uuid);
            setArticle(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getReportDetails();
    }, []);

    useEffect(() => {
        if (data) {
            getArticle(data.referenceUUID);
        }
    }, [data, data?.type === AdminRequestType.PRODUCT]);

    return data ? (
        <Box>
            <Breadcrumb spacing="8px" separator={<HiOutlineChevronRight color="gray.500" />} m="24px 0">
                <BreadcrumbItem>
                    <BreadcrumbLink href="/reports">
                        <Trans i18nKey={'MENU.REPORTS'} />
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href="#">{id}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <Flex alignItems="flex-start" direction={{ base: 'column', md: 'row' }} gap="24px">
                <Card w="100%">
                    <CardBody>
                        <Text mb="20px" fontSize={'20px'}>
                            <Trans i18nKey={'ADMIN_REQUEST.INFO'} />
                        </Text>
                        <Table data={mapTableData([data], t)} config={singleEntryConfig(t)} />

                        <Divider color="brand.menuBorder" m="24px 0" />

                        <Text mb="20px" fontSize={'20px'}>
                            <Trans i18nKey={'ADMIN_REQUEST.DESC'} />
                        </Text>
                        <Text>{isStringEmpty(data?.description) ? '-' : data.description}</Text>

                        {data && data.images && data.images.length > 0 && (
                            <Stack m="20px 0">
                                <Divider color="brand.menuBorder" m="10px 0 24px 0" />

                                <Text fontSize={'20px'}>
                                    <Trans i18nKey={'ADMIN.REQUESTS.MEDIA'} />
                                </Text>
                                {data.images.map((reportImage) => (
                                    <Image cursor={'pointer'} onClick={() => openImage(reportImage)} objectFit={'contain'} src={reportImage.imageURI} alt="image" maxHeight={'200px'} />
                                ))}
                            </Stack>
                        )}

                        <HStack alignItems={'center'} mt="24px">
                            <Button variant="danger" leftIcon={<RxCross2 />} onClick={toggleDeleteModal}>
                                <Trans i18nKey={'GENERAL.DELETE'} />
                            </Button>

                            {data.type === AdminRequestType.PRODUCT && data.referenceUUID && (
                                <Button variant="outline" onClick={() => openArticle(data.referenceUUID)}>
                                    <Trans i18nKey={'ADMIN.REQUESTS.OPEN_ARTICLE'} />
                                </Button>
                            )}
                        </HStack>
                    </CardBody>
                </Card>

                <Stack spacing="11px" maxW={{ base: '100%', md: '357px' }} w="100%">
                    {renderReporter()}
                    {renderReported()}
                </Stack>
            </Flex>
            <ConfirmationModal isOpen={deleteModal} setIsOpen={setDeleteModal} onConfirm={deleteReport} message={t('ADMIN_REQUEST.DELETE_CONFIRM')} />
        </Box>
    ) : null;
};

export default ReportDetails;
