import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Grid, Card, CardBody, Text, Flex } from '@chakra-ui/react';

import { IConfig } from 'common/types';

interface IProps {
    config: IConfig[];
    data: any[];
    gridTemplateColumns: string;
    gridGap: number;
    onRowClick?: (rawData: any) => void;
    isLoading?: boolean;
    componentProps?: any;
}

const GridView = ({ config, data, gridTemplateColumns, gridGap, onRowClick, isLoading, componentProps }: IProps): JSX.Element => {
    if (!isLoading && (!data || !data.length)) {
        return <></>;
    }

    const cards = useMemo(() => {
        return data.map((item: any, index: number) => (
            <Card
                key={`table-grid-item-${index}`}
                cursor={onRowClick ? 'pointer' : 'initial'}
                onClick={() => {
                    if (onRowClick) {
                        onRowClick(item);
                    }
                }}
                borderRadius="12px"
                boxShadow="0px 4px 12px 0px #0000000D"
            >
                <CardBody>
                    {config.map((configItem: IConfig, configIndex: number) => (
                        <Flex mb="15px" key={`table-data-${index}-${configIndex}`}>
                            {configItem.label && (
                                <Text fontWeight="bold">
                                    <Trans i18nKey={configItem.label} />:
                                </Text>
                            )}
                            {configItem.component ? (
                                <configItem.component
                                    config={configItem}
                                    date={item[configItem.key]}
                                    componentProps={componentProps || {}}
                                    dataItem={item}
                                    {...item}
                                    ml="5px"
                                    wordBreak="break-all"
                                    fontSize="16px"
                                    width={configItem.label ? 'auto' : '100%'}
                                />
                            ) : (
                                <Text ml="5px" wordBreak="break-all">
                                    {item[configItem.key as keyof typeof data]}
                                </Text>
                            )}
                        </Flex>
                    ))}
                </CardBody>
            </Card>
        ));
    }, [data, config]);
    return (
        <Grid gridTemplateColumns={['1fr', gridTemplateColumns, gridTemplateColumns]} gridGap={gridGap}>
            {cards}
        </Grid>
    );
};

export default GridView;
