import theme from 'theme';

export const isHidden = (
    screenWidth: number,
    sizeUp?: number,
    sizeDown?: number,
    smDown?: boolean,
    smUp?: boolean,
    mdDown?: boolean,
    mdUp?: boolean,
    lgDown?: boolean,
    lgUp?: boolean,
    xlDown?: boolean,
    xlUp?: boolean,
) => {
    if (sizeUp || sizeDown) {
        if (sizeUp) {
            return screenWidth > sizeUp;
        }

        if (sizeDown) {
            return screenWidth < sizeDown;
        }
    }

    if (smDown) {
        return screenWidth < theme.breakpointsPixels.sm;
    }

    if (smUp) {
        return screenWidth > theme.breakpointsPixels.sm;
    }

    if (mdDown) {
        return screenWidth < theme.breakpointsPixels.md;
    }

    if (mdUp) {
        return screenWidth > theme.breakpointsPixels.md;
    }

    if (lgDown) {
        return screenWidth < theme.breakpointsPixels.lg;
    }

    if (lgUp) {
        return screenWidth > theme.breakpointsPixels.lg;
    }

    if (xlDown) {
        return screenWidth < theme.breakpointsPixels.xl;
    }

    if (xlUp) {
        return screenWidth > theme.breakpointsPixels.xl;
    }
};
