import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Button, Center, Text } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import { Trans, useTranslation } from 'react-i18next';

import { Table } from 'components';

import { allCategoriesAPI, categoryDetailsAPI } from 'api/categories';
import { IArticleCategory } from 'app/articles/types';

import { IPagination, IParams } from 'common/types';
import { buildQueryString, getParamsFromLocalStorage, saveParamsToLocalStorage } from 'common/helpers';
import { routesConstants } from 'common';
import Search from 'components/search/search';
import { mapCategoryTableData, tableConfig } from '../utils/helpers';
import LoadingModal from 'components/loading';

const Categories = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [data, setData] = useState<IArticleCategory[]>([]);
    const [parentCategories, setParentCategories] = useState<IArticleCategory[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [searchTrigger, setSearchTrigger] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [isErr, setIsErr] = useState<boolean>(false);
    const [apiParams, setApiParams] = useState<IParams>();

    const [page, setPage] = useState<number>(1);
    const [paginator, setPaginator] = useState<Partial<IPagination>>({
        perPage: 10,
    });

    const toggleSearchTrigger = () => {
        setPage(1);
        setSearchTrigger(!searchTrigger);
    };

    const handleClickSearch = () => {
        toggleSearchTrigger();
    };

    const handleClearSearch = () => {
        setFilter('');
        toggleSearchTrigger();
    };

    const handleSearchFilter = (e: FormEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
    };

    const redirectToDetails = (item: IArticleCategory) => {
        if (apiParams) saveParamsToLocalStorage(apiParams, 'categoriesParams');

        navigate(`/categories/${item.id}`);
    };

    const changePage = (newPage: number | undefined) => {
        if (newPage) {
            setPage(newPage);
        }
    };

    const redirectToCategoryCreation = () => {
        navigate(routesConstants.routes[routesConstants.prefixes.categoriesNew].path, {
            state: {
                categories: data,
            },
        });
    };

    const getParentCategory = async (parentId: number) => {
        try {
            const parentCategory = await categoryDetailsAPI(parentId);
            return parentCategory.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const getCategories = async () => {
        setLoading(true);
        try {
            let params: IParams;

            if (getParamsFromLocalStorage('categoriesParams')) {
                params = getParamsFromLocalStorage('categoriesParams');
                setPage(params.page ?? 1);
                setFilter(params.filter ?? '');

                localStorage.removeItem('categoriesParams');
            } else {
                params = {
                    ...paginator,
                    page: page,
                    filter: filter,
                };
            }
            setApiParams(params);

            const res = await allCategoriesAPI(buildQueryString(params));
            setData(res.data.results);
            setPaginator({ ...paginator, totalPages: res.data.paginator.totalPages });

            const parentCategoryIds = res.data.results.filter((category) => category.parentID).map((category) => category.parentID);

            for (const parentId of parentCategoryIds) {
                if (!parentCategories.some((category) => category.id === parentId)) {
                    const parentCategory = await getParentCategory(parentId);
                    if (parentCategory) {
                        setParentCategories((prevParentCategories) => [...prevParentCategories.filter((category) => category !== null), parentCategory]);
                    }
                }
            }
        } catch (error) {
            setIsErr(true);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const isDataEmpty = () => {
        return data.length === 0 && !loading && !isErr;
    };

    useEffect(() => {
        getCategories();
    }, [page, searchTrigger]);

    return (
        <Box>
            <Search filter={filter} searchTrigger={searchTrigger} handleClearSearch={handleClearSearch} handleClickSearch={handleClickSearch} handleSearchFilter={handleSearchFilter} />
            <Heading m="40px 0 33px 0" as="h1">
                <Trans i18nKey="MENU.CATEGORIES" />
            </Heading>

            <Table mb="64px" config={tableConfig(t)} data={mapCategoryTableData(data, t, parentCategories)} page={page} pagination={paginator} onPaging={changePage} onRowClick={redirectToDetails} />

            {isErr && (
                <Center m="24px 0">
                    <Text color="red">
                        <Trans i18nKey={'GENERAL.LOADING_ERROR'} />
                    </Text>
                </Center>
            )}
            {isDataEmpty() && (
                <Center m="24px 0">
                    <Text color="black.60">
                        <Trans i18nKey={'GENERAL.EMPTY_STATE'} />
                    </Text>
                </Center>
            )}

            <Button variant="dark" gap="8px" onClick={redirectToCategoryCreation} mb="64px">
                <FaPlus />
                <Trans i18nKey={'CATEGORY.NEW_BUTTON'} />
            </Button>
            <LoadingModal isOpen={loading} setIsOpen={setLoading} />
        </Box>
    );
};

export default Categories;
