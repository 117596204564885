import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    Text,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Alert,
    AlertIcon,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    HStack,
    Tag,
} from '@chakra-ui/react';
import { promoteArticleAPI } from 'api/articles';
import { IArticle } from 'app/articles/types';

interface IProps {
    visible: boolean;
    setVisible: (value: boolean) => void;
    setData: (value: IArticle) => void;
    uuid: string;
}

const PromoteArticle = ({ visible, setVisible, uuid, setData }: IProps) => {
    const { t } = useTranslation();
    const [error, setError] = useState<boolean>(false);
    const [days, setDays] = useState<number>(1);

    const numberOfDaysSuggestions = [2, 15, 30];

    const onModalClose = () => {
        setDays(1);
        setError(false);
        setVisible(!visible);
    };

    const startHighlight = async () => {
        try {
            const response = await promoteArticleAPI(uuid, { days: days });
            setData(response.data);
            setVisible(!visible);
        } catch (err) {
            setError(!error);
        }
    };

    const handleTagClick = (days: number) => {
        setDays(days);
    };

    return (
        <Modal isOpen={visible} onClose={onModalClose} isCentered>
            <ModalOverlay />
            <ModalContent bgColor="white">
                <ModalHeader pb={0}>{t('ARTICLE.HIGHLIGHT_START')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>{t('ARTICLE.HIGHLIGHT_INACTIVE')}</Text>

                    <Text mt="28px">{t('ARTICLE.HIGHLIGHT_START_ON_N_DAYS')}</Text>
                    <NumberInput
                        value={days}
                        min={1}
                        max={30}
                        onChange={(valueString) => {
                            const parsedValue = parseInt(valueString, 10);
                            if (!isNaN(parsedValue)) {
                                setDays(parsedValue);
                            }
                        }}
                        clampValueOnBlur
                    >
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>

                    <HStack spacing={4} mt="10px">
                        {numberOfDaysSuggestions.map((day) => (
                            <Tag size={'lg'} key={day} variant="solid" backgroundColor={'brand.tagBackground'} color={'black'} p="8px" cursor={'pointer'} onClick={() => handleTagClick(day)}>
                                <Text fontSize={'14px'} lineHeight={'24px'}>{`${day} ${t('GENERAL.DAYS')}`}</Text>
                            </Tag>
                        ))}
                    </HStack>
                    {error && (
                        <Alert status="error" mt="16px">
                            <AlertIcon />
                            {t('ERROR.GENERAL')}
                        </Alert>
                    )}
                </ModalBody>
                <ModalFooter justifyContent="flex-start">
                    <Button variant="dark" mr={3} onClick={startHighlight}>
                        {t('GENERAL.CONFIRM')}
                    </Button>
                    <Button variant="outline" onClick={onModalClose}>
                        {t('GENERAL.CANCEL')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default PromoteArticle;
