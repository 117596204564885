/* eslint-disable no-unused-vars */

const debounce = (func: (...args: any[]) => void, delay: number): ((...args: any[]) => void) => {
    let timerId: NodeJS.Timeout | null;

    return (...args: any[]) => {
        if (timerId) {
            clearTimeout(timerId);
        }

        timerId = setTimeout(() => {
            func(...args);
            timerId = null;
        }, delay);
    };
};

const lodash = {
    values: (obj: object) => Object.keys(obj).map((key: string | number) => obj[key as keyof typeof obj]),
    find: (array: Array<any>, callback: (item: object | string | number) => boolean) => array.find((item: object | string | number) => callback(item)),
    filter: (array: Array<any>, callback: (item: object | string | number) => boolean) => array.filter((item: object | string | number) => callback(item)),
    findIndex: (array: Array<any>, callback: (item: object | string | number) => boolean) => array.findIndex((item: object | string | number) => callback(item)),
    isObjectEmpty: (obj: object) => !obj || !!(Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype),
    map: (array: Array<any>, callback: (item: object | string | number) => object | string | number) => array.map((item: object | string | number) => callback(item)),
    debounce,
    convertPropertiesToString: (obj: { [key: string]: any }): { [key: string]: string } => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                obj[key] = obj[key].toString();
            }
        }

        return obj;
    },
};

export default lodash;
