import { BannerStatus, QuotaPackage } from '../enums/banners.enums';

export const bannerStatus = [
    {
        status: BannerStatus.REQUESTED,
        label: 'BANNER.STATUS_REQUESTED',
    },
    {
        status: BannerStatus.ACCEPTED,
        label: 'BANNER.STATUS_ACCEPTED',
    },
    {
        status: BannerStatus.PAYMENT_ACCEPTED,
        label: 'BANNER.STATUS_PAYMENT_ACCEPTED',
    },
    {
        status: BannerStatus.EXPIRED,
        label: 'BANNER.STATUS_EXPIRED',
    },
];

export const quotaPackageOptions = [
    {
        id: QuotaPackage.WEB,
        label: 'QUOTA.PACKAGE_WEB',
    },
    {
        id: QuotaPackage.MOBILE,
        label: 'QUOTA.PACKAGE_MOBILE',
    },
    {
        id: QuotaPackage.COMBO,
        label: 'QUOTA.PACKAGE_COMBO',
    },
];
