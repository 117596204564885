import { AdminRequestSeverity, AdminRequestStatus, AdminRequestType } from '../enums/admin.requests.enums';

export const adminRequestStatuses = [
    {
        status: AdminRequestStatus.CREATED,
        label: 'ADMIN_REQUEST.STATUS_CREATED',
    },
    {
        status: AdminRequestStatus.ACCEPTED,
        label: 'ADMIN_REQUEST.STATUS_ACCEPTED',
    },
    {
        status: AdminRequestStatus.DECLINED,
        label: 'ADMIN_REQUEST.STATUS_DECLINED',
    },
];

export const adminRequestTypes = [
    {
        type: AdminRequestType.PRODUCT,
        label: 'ADMIN_REQUEST.TYPE_PRODUCT',
    },
    {
        type: AdminRequestType.USER,
        label: 'ADMIN_REQUEST.TYPE_USER',
    },
    {
        type: AdminRequestType.DELIVERY_ISSUE,
        label: 'ADMIN_REQUEST.TYPE_DELIVERY_ISSUE',
    },
    {
        type: AdminRequestType.PRODUCT_REVIEW,
        label: 'ADMIN_REQUEST.TYPE_PRODUCT_REVIEW',
    },
    {
        type: AdminRequestType.USER_REVIEW,
        label: 'ADMIN_REQUEST.TYPE_USER_REVIEW',
    },
    {
        type: AdminRequestType.ADDRESS,
        label: 'ADMIN_REQUEST.TYPE_ADDRESS',
    },
    {
        type: AdminRequestType.PRODUCT_COMMENT,
        label: 'ADMIN_REQUEST.TYPE_PRODUCT_COMMENT',
    },
    {
        type: AdminRequestType.SELLER_VERIFY,
        label: 'ADMIN_REQUEST.TYPE_SELLER_VERIFY',
    },
    {
        type: AdminRequestType.BUSINESS_VERIFY,
        label: 'ADMIN_REQUEST.TYPE_BUSINESS_VERIFY',
    },
    {
        type: AdminRequestType.BANNER_REQUESTED,
        label: 'ADMIN_REQUEST.TYPE_BANNER_REQUESTED',
    },
    {
        type: AdminRequestType.ONLINE_PAYMENT,
        label: 'ADMIN_REQUEST.TYPE_ONLINE_PAYMENT',
    },
];

export const requestType = [
    {
        type: AdminRequestType.ADDRESS,
        label: 'ADMIN_REQUEST.TYPE_ADDRESS',
    },
    {
        type: AdminRequestType.BANNER_REQUESTED,
        label: 'ADMIN_REQUEST.TYPE_BANNER_REQUESTED',
    },
    {
        type: AdminRequestType.SELLER_VERIFY,
        label: 'ADMIN_REQUEST.TYPE_SELLER_VERIFY',
    },
    {
        type: AdminRequestType.BUSINESS_VERIFY,
        label: 'ADMIN_REQUEST.TYPE_BUSINESS_VERIFY',
    },
    {
        type: AdminRequestType.ONLINE_PAYMENT,
        label: 'ADMIN_REQUEST.TYPE_ONLINE_PAYMENT',
    },
];

export const adminRequestSeverities = [
    {
        severity: AdminRequestSeverity.LOW,
        label: 'ADMIN_REQUEST.SEVERITY_LOW',
    },
    {
        severity: AdminRequestSeverity.MEDIUM,
        label: 'ADMIN_REQUEST.SEVERITY_MEDIUM',
    },
    {
        severity: AdminRequestSeverity.HIGH,
        label: 'ADMIN_REQUEST.SEVERITY_HIGH',
    },
    {
        severity: AdminRequestSeverity.CRITICAL,
        label: 'ADMIN_REQUEST.SEVERITY_CRITICAL',
    },
];

export const reportTypes = [
    {
        type: AdminRequestType.PRODUCT,
        label: 'ADMIN_REQUEST.TYPE_PRODUCT',
    },
    {
        type: AdminRequestType.USER,
        label: 'ADMIN_REQUEST.TYPE_USER',
    },
    {
        type: AdminRequestType.DELIVERY_ISSUE,
        label: 'ADMIN_REQUEST.TYPE_DELIVERY_ISSUE',
    },
];
