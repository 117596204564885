export enum Steps {
    BASIC_INFO = 1,
    ADDITIONAL_INFO = 2,
}

export enum ChoiceVariant {
    TEXT = 'text',
    SELECT = 'select',
    CHECKBOX = 'checkbox',
}

export enum CategoryType {
    PARENT = 'parent',
    CHILD = 'child',
}

export enum InputTypes {
    TEXT = 1,
    SELECT = 2,
    CHECKBOX = 3,
    NUMBER = 4,
    RANGE = 5,
    TOGGLE = 6,
}
