import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Text, Textarea } from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IBanner } from 'app/marketing/types';
import { rejectBannerAPI } from 'api/marketing';
import { RejectBannerRequestDTO } from 'api/marketing/types/request';
import { isNullOrUndefined } from 'common/helpers';
import { IAdminRequest } from 'common/types';
import { updateReportAPI } from 'api/shared';
import { AdminRequestStatus } from 'app/reports/enums/admin.requests.enums';

interface IProps {
    isOpen: boolean;
    toggleOpen: () => void;
    banner: IBanner;
    adminRequests: IAdminRequest[];
}

const ConfirmRejectBannerModal = ({ isOpen, toggleOpen, banner, adminRequests }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [reason, setReason] = useState<RejectBannerRequestDTO>({ rejectReason: '' });

    const onSubmit = async () => {
        try {
            await rejectBannerAPI(banner.id, reason);

            const result = adminRequests.find((req) => req.referenceUUID === banner.id.toString());
            if (result) {
                await updateReportAPI(result.id, AdminRequestStatus.DECLINED);
            }
            navigate('/marketing');
        } catch (error) {
            console.log(error);
        }
        toggleOpen();
    };

    const isDisabled = () => {
        return reason.rejectReason === '' || isNullOrUndefined(reason.rejectReason);
    };

    return (
        <Modal isOpen={isOpen} onClose={toggleOpen} isCentered>
            <ModalOverlay />
            <ModalContent bg="white">
                <ModalHeader>{t('BANNER.REJECT_TITLE')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb="8px">
                        <Trans i18nKey={'BANNER.REJECT_DESC'} />
                    </Text>
                    <Textarea
                        name="reason"
                        placeholder={t('BANNER.REJECT_PLACEHOLDER')}
                        size="md"
                        resize={'none'}
                        value={reason.rejectReason}
                        onChange={(e) => setReason({ rejectReason: e.currentTarget.value })}
                    />
                </ModalBody>
                <ModalFooter justifyContent={'flex-start'}>
                    <Button variant={'dark'} mr={3} onClick={onSubmit} isDisabled={isDisabled()}>
                        <Trans i18nKey={'GENERAL.SAVE'} />
                    </Button>
                    <Button variant="outline" onClick={toggleOpen}>
                        <Trans i18nKey={'GENERAL.CANCEL'} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmRejectBannerModal;
