import { useState } from 'react';
import { Flex, Stack, HStack, Divider, Text, Button, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { FiEdit } from 'react-icons/fi';
import { LuTrash } from 'react-icons/lu';

import { IArticleCategory, IArticleCategoryField, IArticleCategoryFieldOption } from 'app/articles/types';
import FieldModal from '../modals/field';
import { defaultFieldData, filterFieldsByStepAndParent, findFieldIndexById } from 'app/category/utils/helpers';
import ConfirmationModal from 'components/confirmationModal';
import FeaturedFieldsModal from '../modals/featured';
import { generateCustomUUID } from 'common/helpers';

interface IProps {
    editMode: boolean;
    apiEdit: boolean;
    originalData?: IArticleCategory;
    category: IArticleCategory;
    setCategory: (value: IArticleCategory) => void;
}

const Fields = ({ category, setCategory, editMode, apiEdit, originalData }: IProps) => {
    const { t } = useTranslation();

    const [openFeaturedModal, setOpenFeaturedModal] = useState<boolean>(false);
    const [openFieldModal, setOpenFieldModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [selectedField, setSelectedField] = useState<IArticleCategoryField>(defaultFieldData);

    const toggleFieldModal = () => {
        setOpenFieldModal(!openFieldModal);
    };

    const toggleFeaturedModal = () => {
        setOpenFeaturedModal(!openFeaturedModal);
    };

    const toggleDeleteModal = () => {
        setOpenDeleteModal(!openDeleteModal);
    };

    const isEditingChild = (): boolean => {
        if (originalData) {
            const fieldIndex = findFieldIndexById(originalData.productCategoryFields ?? [], selectedField.id);
            return apiEdit && fieldIndex !== -1 && selectedField.parentID !== 0 && selectedField.parentID !== null;
        } else return false;
    };

    const filterChildOptions = (optionId: number) => {
        const filteredChildOptions: IArticleCategoryFieldOption[] = [];

        category.productCategoryFields?.forEach((field) => {
            field.productCategoryFieldOptions?.forEach((opt) => {
                if (opt.parentID === optionId && opt.translations.bs) {
                    filteredChildOptions.push(opt);
                }
            });
        });

        return filteredChildOptions;
    };

    const isExistingChildOption = (option: IArticleCategoryFieldOption) => {
        return filterChildOptions(option.id).some((opt) => opt.createdAt !== '');
    };

    const childField = (option: IArticleCategoryFieldOption) => {
        const result = category.productCategoryFields.find((field) => field.parentID === option.productCategoryFieldID);

        return result;
    };

    const newChildField = (option: IArticleCategoryFieldOption) => {
        const result = category.productCategoryFields.find((field) => field.productCategoryFieldOptions?.some((fieldOption) => fieldOption.parentID === option.id));

        return result;
    };

    const removeField = () => {
        if (isEditingChild()) {
            const updatedFields = [...category.productCategoryFields];
            if (selectedField.productCategoryFieldOptions?.length > 0) {
                updatedFields.forEach((field) => {
                    field.productCategoryFieldOptions = field.productCategoryFieldOptions?.filter((opt) => !selectedField.productCategoryFieldOptions?.some((option) => option.id === opt.id));
                });

                setCategory({
                    ...category,
                    productCategoryFields: updatedFields,
                });
            }
        } else {
            const updatedFields = category.productCategoryFields.filter((field) => field.id !== selectedField.id);
            setCategory({
                ...category,
                productCategoryFields: updatedFields,
            });
        }
        setSelectedField(defaultFieldData);
        toggleDeleteModal();
    };

    const addFieldButton = (stepID: number) => {
        return (
            <Button
                w="fit-content"
                leftIcon={<FaPlus />}
                p="0 128px"
                onClick={() => {
                    setSelectedField({ ...selectedField, stepID: stepID });
                    toggleFieldModal();
                }}
            >
                <Text fontSize={'14px'}>
                    <Trans i18nKey={'CATEGORY.FIELDS_ADD_BUTTON'} />
                </Text>
            </Button>
        );
    };

    const fieldTypesInformation = (titleKey: string, descriptionKey: string, stepID: number) => {
        const filteredFields = category.productCategoryFields ? filterFieldsByStepAndParent(category.productCategoryFields, stepID) : [];

        return (
            <Stack spacing={'16px'}>
                <Text fontWeight={500}>
                    <Trans i18nKey={titleKey} />
                </Text>

                <Text fontSize={'14px'} maxW="542px" color="black.60">
                    <Trans i18nKey={descriptionKey} />
                </Text>

                {filteredFields && filteredFields.length > 0 && (
                    <Accordion maxW={'549px'} allowToggle>
                        {filteredFields?.map((field) => (
                            <AccordionItem key={field.id + generateCustomUUID()} style={{ border: 'none' }}>
                                <HStack>
                                    <AccordionButton>
                                        <HStack w="100%" justifyContent={'space-between'}>
                                            <HStack>
                                                <AccordionIcon />
                                                <Text>{field.translations.bs}</Text>
                                            </HStack>

                                            {editMode && (
                                                <HStack>
                                                    <div>
                                                        <Button
                                                            variant="outline"
                                                            leftIcon={<FiEdit />}
                                                            iconSpacing={0}
                                                            onClick={() => {
                                                                setSelectedField(field);
                                                                toggleFieldModal();
                                                            }}
                                                        />
                                                    </div>

                                                    <div>
                                                        <Button
                                                            variant="outline"
                                                            leftIcon={<LuTrash />}
                                                            iconSpacing={0}
                                                            onClick={() => {
                                                                setSelectedField(field);
                                                                toggleDeleteModal();
                                                            }}
                                                        />
                                                    </div>
                                                </HStack>
                                            )}
                                        </HStack>
                                    </AccordionButton>
                                </HStack>

                                <AccordionPanel>
                                    <Stack spacing={'16px'}>
                                        {field.productCategoryFieldOptions &&
                                            field.productCategoryFieldOptions.length > 0 &&
                                            field.productCategoryFieldOptions?.map((option) => (
                                                <Stack key={option.id}>
                                                    <Text fontSize={'14px'} color={'black.60'}>
                                                        {option.translations.bs}
                                                    </Text>

                                                    {filterChildOptions(option.id).length > 0 && (
                                                        <Stack ml="24px">
                                                            <HStack justifyContent={'space-between'}>
                                                                <Text fontSize={'14px'} color={'black.60'}>
                                                                    {childField(option)?.translations.bs}
                                                                </Text>
                                                                {editMode && (
                                                                    <HStack>
                                                                        <Button
                                                                            variant="outline"
                                                                            leftIcon={<FiEdit />}
                                                                            iconSpacing={0}
                                                                            onClick={() => {
                                                                                if (apiEdit && isExistingChildOption(option)) {
                                                                                    setSelectedField({
                                                                                        ...childField(option),
                                                                                        productCategoryFieldOptions: filterChildOptions(option.id) ?? [],
                                                                                    } as IArticleCategoryField);
                                                                                    toggleFieldModal();
                                                                                } else {
                                                                                    setSelectedField(newChildField(option) as IArticleCategoryField);
                                                                                    toggleFieldModal();
                                                                                }
                                                                            }}
                                                                        />
                                                                        <Button
                                                                            variant="outline"
                                                                            leftIcon={<LuTrash />}
                                                                            iconSpacing={0}
                                                                            onClick={() => {
                                                                                if (apiEdit) {
                                                                                    setSelectedField({
                                                                                        ...childField(option),
                                                                                        productCategoryFieldOptions: filterChildOptions(option.id) ?? [],
                                                                                    } as IArticleCategoryField);
                                                                                    toggleDeleteModal();
                                                                                } else {
                                                                                    setSelectedField(childField(option) as IArticleCategoryField);
                                                                                    toggleDeleteModal();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </HStack>
                                                                )}
                                                            </HStack>

                                                            {filterChildOptions(option.id)?.map((childOption) => (
                                                                <Text fontSize={'14px'} color={'black.60'} ml="24px">
                                                                    {childOption?.translations.bs}
                                                                </Text>
                                                            ))}
                                                        </Stack>
                                                    )}
                                                </Stack>
                                            ))}
                                    </Stack>
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                )}

                {editMode && addFieldButton(stepID)}
            </Stack>
        );
    };

    return (
        <Flex direction="column" color="black" gap="28px">
            <Divider color="brand.menuBorder" />
            <Stack>
                <Text fontSize={'20px'} fontWeight={600}>
                    <Trans i18nKey={'CATEGORY.FIELDS_TITLE'} />
                </Text>
                <HStack gap={0}>
                    <Text fontSize={'14px'}>
                        <Trans i18nKey={'CATEGORY.FIELDS_INFO_REQUIRED'} />
                    </Text>
                    <Text color="red">&#42;</Text>
                </HStack>
            </Stack>

            {fieldTypesInformation('CATEGORY.FIELDS_BASIC_INFO', 'CATEGORY.FIELDS_BASIC_INFO_DESC', 1)}
            {fieldTypesInformation('CATEGORY.FIELDS_ADDITIONAL_INFO', 'CATEGORY.FIELDS_ADDITIONAL_INFO_DESC', 2)}

            <Divider color="brand.menuBorder" m="16px 0" />

            <Stack spacing={'16px'}>
                <Text fontWeight={500}>
                    <Trans i18nKey={'CATEGORY.FIELDS_HIGHLIGHTED'} />
                </Text>

                <Text fontSize={'14px'} maxW="542px" color="black.60">
                    <Trans i18nKey={'CATEGORY.FIELDS_HIGHLIGHTED_DESC'} />
                </Text>

                <Button w="fit-content" onClick={toggleFeaturedModal} isDisabled={!editMode}>
                    <Text fontSize={'14px'}>
                        <Trans i18nKey={'CATEGORY.FIELDS_HIGHLIGHTED_BTN'} />
                    </Text>
                </Button>

                <FieldModal
                    child={isEditingChild()}
                    open={openFieldModal}
                    setOpen={setOpenFieldModal}
                    category={category}
                    setCategory={setCategory}
                    selectedField={selectedField}
                    setSelectedField={setSelectedField}
                />

                <Stack>{category.productCategoryFields?.filter((field) => field.featured)?.map((f) => <Text>{f.translations.bs}</Text>)}</Stack>
            </Stack>
            <Divider color="brand.menuBorder" m="8px 0" />

            <FeaturedFieldsModal category={category} setCategory={setCategory} openModal={openFeaturedModal} setOpenModal={setOpenFeaturedModal} />
            <ConfirmationModal onConfirm={removeField} message={t('CATEGORY.FIELD_DELETE_CONFIRMATION')} isOpen={openDeleteModal} setIsOpen={setOpenDeleteModal} />
        </Flex>
    );
};

export default Fields;
