import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';

import { routesConstants, _ } from 'common';
import { IRoute } from 'common/types';
import { Logo } from 'components';
import { logoutAPI } from 'api/auth';
import { getSession, removeSession } from 'core/session';

const Sidebar = ({ prefix }: IRoute): JSX.Element => {
    const logout = async () => {
        const { refreshToken } = getSession();
        if (refreshToken) {
            try {
                await logoutAPI({ token: refreshToken });
                removeSession();
                window.location.href = '/login';
            } catch (error) {
                console.log(error);
            }
        }
    };

    const menuItems = useMemo(() => {
        const result = _.values(routesConstants.routes);

        return result.filter((route: IRoute) => route.is_in_sidebar);
    }, []);

    const links = useMemo(() => {
        return menuItems.map((route: IRoute) => (
            <Link to={route.path} key={`side-menu-link-${route.prefix}`}>
                <Box width="100%" p="5px" background={prefix === route.prefix ? 'brand.activeMenuItemBackground' : 'white'} mb="5px" borderRadius="5px">
                    <Text p="6px 8px" color="brand.text" fontSize="14px" fontWeight={prefix === route.prefix ? 'bold' : 'normal'} lineHeight="20px">
                        {route.menu_key ? <Trans i18nKey={route.menu_key} /> : route.prefix}
                    </Text>
                </Box>
            </Link>
        ));
    }, [prefix]);

    return (
        <Box bg="white" width="300px" height="100vh" p="24px" position="relative" borderRight="1px solid" borderRightColor="brand.menuBorder" overflowY="hidden">
            <Logo wrapperProps={{ mb: '32px' }} />
            {links}
            <Text as="b" p="5px" fontSize="14px" color={'red.500'} onClick={logout} cursor="pointer" position={'absolute'} bottom="32px">
                <Trans i18nKey={'LOGOUT.GENERAL'} />
            </Text>
        </Box>
    );
};

export default Sidebar;
