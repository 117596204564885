import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    Stack,
    HStack,
    Button,
    Text,
    Accordion,
    AccordionItem,
    AccordionPanel,
    AccordionButton,
    AccordionIcon,
    Icon,
    Image,
    Divider,
} from '@chakra-ui/react';
import { IAddress, IUser } from 'common/types';
import { useState } from 'react';
import { Trans } from 'react-i18next';

import { AiOutlineCheck } from 'react-icons/ai';
import { FiExternalLink } from 'react-icons/fi';
import colors from 'theme/foundations/colors';
import ImageModal from './imageModal';
import ConfirmationModal from './confirmationModal';
import { isNullOrUndefined } from 'common/helpers';

interface IProps {
    isOpen: boolean;
    toggleModal: () => void;
    user: IUser;
    addresses: IAddress[];

    onConfirm: () => void;
    onDecline: () => void;
}

const VerificationModal = ({ toggleModal, isOpen, user, onConfirm, onDecline, addresses }: IProps) => {
    const [isImageModalOpen, setImageModalOpen] = useState<boolean>(false);
    const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const [actionType, setActionType] = useState<'accept' | 'decline'>('accept');
    const [selectedImage, setSelectedImage] = useState<string>('');

    const latestAddress = addresses[addresses.length - 1];

    const toggleImageModal = () => {
        setImageModalOpen(!isImageModalOpen);
    };

    const toggleConfirmationModal = () => {
        setConfirmModalOpen(!isConfirmModalOpen);
    };

    const previewImage = () => {
        setSelectedImage(user.verifyImage ?? '');
        toggleImageModal();
    };

    const openConfirmModal = (type: 'accept' | 'decline') => {
        toggleConfirmationModal();
        setActionType(type);
    };

    const openVerifyImage = (imageUrl: string) => {
        window.open(imageUrl, '_blank');
    };

    const renderSellerOrBusinessVerificationData = () => {
        return (
            <Stack fontSize={'14px'}>
                {user.businessJIBNumber !== '' && (
                    <HStack>
                        <Text color="black.60">
                            <Trans i18nKey={'USER.VERIFY_JIB'} />
                        </Text>
                        <Text>{user.businessJIBNumber}</Text>
                    </HStack>
                )}
                {user.businessName !== '' && (
                    <HStack>
                        <Text color="black.60">
                            <Trans i18nKey={'USER.VERIFY_BUSINESS_NAME'} />
                        </Text>
                        <Text>{user.businessName}</Text>
                    </HStack>
                )}
                {user.businessPhone !== '' && (
                    <HStack>
                        <Text color="black.60">
                            <Trans i18nKey={'USER.VERIFY_BUSINESS_PHONE'} />
                        </Text>
                        <Text>{user.businessPhone}</Text>
                    </HStack>
                )}

                {user.firstName !== '' && (
                    <HStack>
                        <Text color="black.60">
                            <Trans i18nKey={'USER.VERIFY_FIRST_NAME'} />
                        </Text>
                        <Text>{user.firstName}</Text>
                    </HStack>
                )}
                {user.lastName !== '' && (
                    <HStack>
                        <Text color="black.60">
                            <Trans i18nKey={'USER.VERIFY_LAST_NAME'} />
                        </Text>
                        <Text>{user.lastName}</Text>
                    </HStack>
                )}
                {user.email !== '' && (
                    <HStack>
                        <Text color="black.60">
                            <Trans i18nKey={'USER.VERIFY_EMAIL'} />
                        </Text>
                        <Text>{user.email}</Text>
                    </HStack>
                )}
                {!isNullOrUndefined(latestAddress) && (
                    <HStack>
                        <Text color="black.60">
                            <Trans i18nKey={'USER.VERIFY_ADDRESS'} />
                        </Text>
                        <Text>{`${latestAddress.address}, ${latestAddress.postCode} ${latestAddress.city}`}</Text>
                    </HStack>
                )}
                <Divider m="8px 0 16px" />
            </Stack>
        );
    };

    return (
        <Stack>
            <Modal isOpen={isOpen} onClose={toggleModal} isCentered>
                <ModalOverlay />
                <ModalContent bgColor={'white'} maxW={'574px'} w="100%">
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack p="16px 0" spacing={'16px'}>
                            <Text fontSize={'20px'} fontWeight={600}>
                                <Trans i18nKey={'USER.VERIFY_MODAL_HEADING'} />
                            </Text>
                            <Text color="black.60">
                                <Trans i18nKey={'USER.VERIFY_MODAL_DESC'} />
                            </Text>
                        </Stack>

                        <Accordion allowToggle mb="8px">
                            <AccordionItem>
                                <HStack justifyContent={'space-between'} w="100%">
                                    <AccordionButton>
                                        <HStack ml="-16px" spacing="16px">
                                            <AccordionIcon />
                                            <Text>
                                                <Trans i18nKey={'USER.VERIFY_STEP_1_NUMBER'} />
                                            </Text>
                                            <Text>
                                                <Trans i18nKey={'USER.VERIFY_STEP_1_TEXT'} />
                                            </Text>
                                        </HStack>
                                    </AccordionButton>
                                    <Icon as={AiOutlineCheck} fill={colors.brand.success} />
                                </HStack>
                                <AccordionPanel>
                                    <Text fontSize={'14px'} color="black.60" mb="16px">
                                        <Trans i18nKey={'USER.VERIFY_STEP_1_DESC'} />
                                    </Text>
                                    {user.verifyImage?.endsWith('pdf') ? (
                                        <Button onClick={() => openVerifyImage(user.verifyImage ?? '')} variant={'outline'} rightIcon={<FiExternalLink />}>
                                            <Trans i18nKey={'USER.OPEN_PDF'} />
                                        </Button>
                                    ) : (
                                        <Image cursor={'zoom-in'} src={user.verifyImage} alt="user" w="176px" height="123px" objectFit={'cover'} onClick={previewImage} />
                                    )}
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionButton>
                                    <HStack ml="-16px" spacing="16px">
                                        <AccordionIcon />
                                        <Text>
                                            <Trans i18nKey={'USER.VERIFY_STEP_2_NUMBER'} />
                                        </Text>
                                        <Text>
                                            <Trans i18nKey={'USER.VERIFY_STEP_2_TEXT'} />
                                        </Text>
                                    </HStack>
                                </AccordionButton>
                                <AccordionPanel>
                                    {renderSellerOrBusinessVerificationData()}
                                    <Stack spacing="24px">
                                        <Text fontSize={'14px'} color="black.60">
                                            <Trans i18nKey={'USER.VERIFY_STEP_2_DESC_1'} />
                                        </Text>
                                        <Text fontSize={'14px'} color="black.60">
                                            <Trans i18nKey={'USER.VERIFY_STEP_2_DESC_2'} />
                                        </Text>
                                    </Stack>
                                    <HStack mt="16px">
                                        <Button backgroundColor={'brand.success'}>
                                            <Text color="white" fontSize={'14px'} onClick={() => openConfirmModal('accept')}>
                                                <Trans i18nKey={'USER.VERIFY_MODAL_HEADING'} />
                                            </Text>
                                        </Button>
                                        <Button variant={'outline'}>
                                            <Text fontSize={'14px'} onClick={() => openConfirmModal('decline')}>
                                                <Trans i18nKey={'USER.VERIFY_DECLINE'} />
                                            </Text>
                                        </Button>
                                    </HStack>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {selectedImage !== '' && <ImageModal isOpen={isImageModalOpen} onClose={toggleImageModal} imageUrl={selectedImage} />}
            <ConfirmationModal isOpen={isConfirmModalOpen} onClose={toggleConfirmationModal} type={actionType} onSubmit={actionType === 'accept' ? onConfirm : onDecline} />
        </Stack>
    );
};

export default VerificationModal;
