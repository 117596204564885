import { formAxiosObject } from 'common/helpers';
import { BannerDetailsResponseDTO, BannerQuotaDetailsResponseDTO, BannerQuotasResponseDTO, BannersResponseDTO, PromotionQuotasResponseDTO } from './types/response';
import { BannerQuotaRequestDTO, PromotionQuotaRequestDTO, RejectBannerRequestDTO } from './types/request';

export const bannersAPI = async (queryParams?: string): Promise<BannersResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/banners/${queryParams ? `?${queryParams}` : ''}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const bannerDetailsAPI = async (id: string): Promise<BannerDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/banners/${id}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const acceptBannerAPI = async (id: number): Promise<any> => {
    try {
        await formAxiosObject({ method: 'PATCH', route: `/management/banners/${id}/accept` });
    } catch (error) {
        throw error;
    }
};

export const rejectBannerAPI = async (id: number, data: RejectBannerRequestDTO): Promise<any> => {
    try {
        await formAxiosObject({ method: 'PATCH', route: `/management/banners/${id}/reject` }, data);
    } catch (error) {
        throw error;
    }
};

export const deleteBannerAPI = async (id: number): Promise<any> => {
    try {
        await formAxiosObject({ method: 'DELETE', route: `/management/banners/${id}` });
    } catch (error) {
        throw error;
    }
};

// bannerQuotas

export const bannerQuotasAPI = async (queryParams?: string): Promise<BannerQuotasResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/bannerQuotas/${queryParams ? `?${queryParams}` : ''}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const createBannerQuotaAPI = async (data: Partial<BannerQuotaRequestDTO>): Promise<any> => {
    try {
        const response = await formAxiosObject({ method: 'POST', route: `/management/bannerQuotas/` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const bannerQuotaDetailsAPI = async (id: string): Promise<BannerQuotaDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/bannerQuotas/${id}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const deleteBannerQuotaAPI = async (id: string): Promise<any> => {
    try {
        await formAxiosObject({ method: 'DELETE', route: `/management/bannerQuotas/${id}` });
    } catch (error) {
        throw error;
    }
};

export const editBannerQuotaAPI = async (data: Partial<BannerQuotaRequestDTO>, id: string): Promise<any> => {
    try {
        await formAxiosObject({ method: 'PUT', route: `/management/bannerQuotas/${id}` }, data);
    } catch (error) {
        throw error;
    }
};

// promotion quotas

export const promotionQuotasAPI = async (queryParams?: string): Promise<PromotionQuotasResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/promotionQuotas/${queryParams ? `?${queryParams}` : ''}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const createPromotionQuotaAPI = async (data: Partial<PromotionQuotaRequestDTO>): Promise<any> => {
    try {
        const response = await formAxiosObject({ method: 'POST', route: `/management/promotionQuotas/` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const deletePromotionQuotaAPI = async (id: string): Promise<any> => {
    try {
        await formAxiosObject({ method: 'DELETE', route: `/management/promotionQuotas/${id}` });
    } catch (error) {
        throw error;
    }
};

export const editPromotionQuotaAPI = async (data: Partial<PromotionQuotaRequestDTO>, id: string): Promise<any> => {
    try {
        await formAxiosObject({ method: 'PUT', route: `/management/promotionQuotas/${id}` }, data);
    } catch (error) {
        throw error;
    }
};
