import { Box } from '@chakra-ui/react';
import { NaKlikLogo } from 'common/images';

interface IProps {
    wrapperProps?: object;
}

const Logo = ({ wrapperProps }: IProps) => (
    <Box {...wrapperProps}>
        <img src={NaKlikLogo} alt="logo" />
    </Box>
);

export default Logo;
