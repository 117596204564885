import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Divider, Text } from '@chakra-ui/react';

import { Trans, useTranslation } from 'react-i18next';

import { allCategoriesAPI, createCategoryAPI, createFieldAPI, createOptionAPI } from 'api/categories';

import { IArticleCategory } from 'app/articles/types';
import { buildQueryString } from 'common/helpers';
import { createCategoryRequest, createFieldRequest, createOptionRequest, defaultCategoryData, mergeFieldsWithSameParentId } from '../utils/helpers';
import Shared from './details/shared';
import Fields from './details/fields';
import ConfirmationModal from 'components/confirmationModal';
import LoadingModal from 'components/loading';
import { IUUIDMapping } from '../types';

const CreateCategory = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [parentCategories, setParentCategories] = useState<IArticleCategory[]>([]);
    const [newCategoryData, setNewCategoryData] = useState<IArticleCategory>();
    const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

    const isParent = () => {
        return newCategoryData?.parentCategory === true;
    };

    const isCreateButtonDisabled = () => {
        if (
            newCategoryData?.translations.bs === '' ||
            newCategoryData?.color === '' ||
            newCategoryData?.iconURI === '' ||
            newCategoryData?.imageURI === '' ||
            (newCategoryData?.translations?.bs && newCategoryData.translations.bs.length > 50)
        ) {
            return true;
        }
        if (!isParent()) {
            return newCategoryData?.parentID === 0 || newCategoryData?.productCategoryFields.length === 0;
        }
        return false;
    };

    const createCategory = async () => {
        if (newCategoryData) {
            try {
                setIsLoading(true);

                const response = await createCategoryAPI(createCategoryRequest(newCategoryData));
                const categoryID: number = response.data.id;

                if (newCategoryData.productCategoryFields.length > 0) {
                    const parentFields = newCategoryData.productCategoryFields.filter((field) => field.parentID === 0);
                    const childFields = mergeFieldsWithSameParentId(newCategoryData.productCategoryFields);

                    const localFieldIDsToApiIDMap: IUUIDMapping = {};
                    const localOptionIDsToApiIDMap: IUUIDMapping = {};

                    // first create parent fields and options to populate local ids
                    for (const field of parentFields) {
                        const fieldResponse = await createFieldAPI(createFieldRequest(field), categoryID);
                        const fieldID = fieldResponse.data.id;

                        localFieldIDsToApiIDMap[field.id.toString()] = fieldID;

                        if (field.productCategoryFieldOptions?.length > 0) {
                            for (const option of field.productCategoryFieldOptions) {
                                const optionResponse = await createOptionAPI(createOptionRequest(option), categoryID, fieldID);
                                localOptionIDsToApiIDMap[option.id.toString()] = optionResponse.data.id;
                            }
                        }
                    }

                    // then create child fields and options with correct parentID values
                    for (const field of childFields) {
                        const fieldResponse = await createFieldAPI(createFieldRequest({ ...field, parentID: localFieldIDsToApiIDMap[field.parentID.toString()] }), categoryID);
                        const fieldID = fieldResponse.data.id;

                        if (field.productCategoryFieldOptions?.length > 0) {
                            for (const option of field.productCategoryFieldOptions) {
                                if (option.parentID) {
                                    await createOptionAPI(createOptionRequest({ ...option, parentID: localOptionIDsToApiIDMap[option.parentID.toString()] }), categoryID, fieldID);
                                }
                            }
                        }
                    }
                }

                setIsLoading(false);
                navigate('/categories');
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        }
    };

    const getParentCategories = async () => {
        try {
            const response = await allCategoriesAPI(buildQueryString({ perPage: Number.MAX_SAFE_INTEGER }));
            setParentCategories(response.data.results.filter((category) => category.parentCategory === true));
            setNewCategoryData({ ...defaultCategoryData, order: response.data.paginator.totalEntriesSize + 1 });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getParentCategories();
    }, []);

    return newCategoryData ? (
        <Box>
            <Shared editMode category={newCategoryData} setCategory={setNewCategoryData} parentCategories={parentCategories} />
            {!isParent() && <Fields apiEdit={false} editMode category={newCategoryData} setCategory={setNewCategoryData} />}

            {isCreateButtonDisabled() && (
                <Text m="32px 0 16px 0" fontSize={14} color="red">
                    <Trans i18nKey={'CATEGORY.REQUIRED_ERROR'} />
                </Text>
            )}
            <Button backgroundColor="brand.success" color="white" onClick={() => setOpenConfirmationModal(!openConfirmationModal)} isDisabled={isCreateButtonDisabled()}>
                <Text p={'0 16px'}>
                    <Trans i18nKey={'CATEGORY.CREATE_BUTTON_SUBMIT'} />
                </Text>
            </Button>

            <ConfirmationModal
                isOpen={openConfirmationModal}
                setIsOpen={setOpenConfirmationModal}
                onConfirm={createCategory}
                message={t('CATEGORY.CREATE_CONFIRMATION')}
                description={`${t('CATEGORY.CREATE_CONFIRMATION_MESSAGE_1')} ${newCategoryData?.translations.bs}${t('CATEGORY.CREATE_CONFIRMATION_MESSAGE_2')}`}
            />
            <LoadingModal isOpen={isLoading} setIsOpen={setIsLoading} />
            <Divider w="80%" m="32px" />
        </Box>
    ) : null;
};

export default CreateCategory;
