import { Box, Button, Card, CardBody, Text, Image, Stack, Flex, Wrap, Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Divider } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { HiOutlineChevronRight } from 'react-icons/hi';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DeleteArticleModal from './modals/deleteArticleModal';
import PromoteArticleModal from './modals/promoteArticleModal';
import StopPromotingArticleModal from './modals/stopPromotingArticle';

import colors from 'theme/foundations/colors';
import { articleDetailsAPI } from 'api/articles';
import { categoryDetailsAPI } from 'api/categories';
import { addressDetailsAPI, getRegion } from 'api/shared';
import { ICity } from 'api/shared/types';
import { IArticle, IArticleCategory } from '../types';
import { config } from 'core';
import UserContactCard from 'components/userCard';
import { IAddress } from 'common/types';

const ArticleDetails = () => {
    const { id } = useParams();
    const { t } = useTranslation();

    const [data, setData] = useState<IArticle>();
    const [address, setAddress] = useState<IAddress>();
    const [category, setCategory] = useState<IArticleCategory>();
    const [region, setRegion] = useState<string>();
    const [city, setCity] = useState<ICity>();

    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [openPromotedModal, setOpenPromotedModal] = useState<boolean>(false);
    const [openStopPromoteModal, setOpenStopPromoteModal] = useState<boolean>(false);

    const goToNaklikArticle = () => {
        window.open(`${config.WEB_URL}/ba/product-details/${data?.uuid}`, '_blank');
    };

    const getRegionDetails = async (id: number) => {
        if (id) {
            try {
                const res = await getRegion(id);
                setRegion(res.name);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getCategoryDetails = async (id: number) => {
        if (id) {
            try {
                const res = await categoryDetailsAPI(id);
                setCategory(res.data);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getAddressDetails = async (uuid: string) => {
        if (id) {
            try {
                const res = await addressDetailsAPI(uuid);
                setAddress(res.data);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getArticleDetails = async () => {
        if (id) {
            try {
                const res = await articleDetailsAPI(id);
                setData(res.data);
                setCity(res.data.city);
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        getArticleDetails();
    }, []);

    useEffect(() => {
        if (data) {
            if (data.categoryID) {
                getCategoryDetails(data.categoryID);
            }

            if (data.addressUUID) {
                getAddressDetails(data.addressUUID);
            }

            if (data.city) {
                getRegionDetails(data.city.regionID);
            }
        }
    }, [data]);

    return data ? (
        <Box>
            <Breadcrumb spacing="8px" separator={<HiOutlineChevronRight color="gray.500" />} m="24px 0">
                <BreadcrumbItem>
                    <BreadcrumbLink href="/articles">{t('MENU.ARTICLES')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href="#">{data?.name}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <Flex alignItems="flex-start" direction={{ base: 'column', md: 'row' }} gap="24px">
                <Card maxW="732px" w="100%" boxShadow={colors.brand.boxShadow}>
                    <CardBody p="24px">
                        <Wrap direction="row">
                            <Image
                                mr="20px"
                                maxH="190px"
                                maxW="193px"
                                src={data.primaryImageURI || (data?.images && data.images[0].imageURI) || 'https://ystyangin.com/wp-content/uploads/dummy-image-square.jpg'}
                                alt="product"
                                objectFit={'cover'}
                                borderRadius="lg"
                            />
                            <Stack>
                                <Text fontSize="16px" fontWeight={500}>
                                    <Trans i18nKey={'ARTICLE.DETAILS_TITLE'} />
                                </Text>
                                <Text fontSize="20px" fontWeight={700}>
                                    {data?.name || '-'}
                                </Text>
                                <Text fontSize="16px" fontWeight={600}>
                                    <Trans i18nKey={'ARTICLE.LOCATION_TITLE'} />
                                </Text>

                                <HStack>
                                    {city ? <Text>{city?.name}</Text> : <Text>/</Text>}
                                    {region ? <Text>- {region}</Text> : null}
                                </HStack>

                                <Stack direction="row" justifyContent={'space-between'} gap="24px" w="fit-content">
                                    <Stack>
                                        <Text fontSize="16px" fontWeight={600}>
                                            <Trans i18nKey={'ARTICLE.CATEGORY'} />
                                        </Text>
                                        <Text>{category?.translations.bs || '-'}</Text>
                                    </Stack>

                                    <Stack>
                                        <Text fontSize="16px" fontWeight={600}>
                                            <Trans i18nKey={'ARTICLE.PRICE'} />
                                        </Text>
                                        <Text>{`${data?.price / 100} ${t('ARTICLE.PRICE_CURRENCY')}`}</Text>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Wrap>

                        {address && (
                            <Stack>
                                <Divider m="32px 0 16px 0" />

                                <Text fontWeight={600}>
                                    <Trans i18nKey={'ARTICLE.PICKUP_ADDRESS'} />
                                </Text>
                                <Text color="black.60">
                                    {address?.address}, {address.postCode} {address.city}
                                </Text>
                            </Stack>
                        )}

                        <Stack direction="row" justifyContent="space-between" mt="24px">
                            <Button variant="outline" borderColor="brand.menuBorder" onClick={goToNaklikArticle}>
                                <Trans i18nKey={'ARTICLE.OPEN_IN_WEB'} />
                            </Button>
                            <Button variant="danger" onClick={() => setOpenDeleteModal(!openDeleteModal)}>
                                <Trans i18nKey={'ARTICLE.DELETE'} />
                            </Button>
                        </Stack>
                    </CardBody>
                </Card>

                <Stack spacing="11px" maxW={{ base: '100%', md: '357px' }} w="100%">
                    {data.sellerUserUUID && <UserContactCard userUUID={data.sellerUserUUID} translation="ARTICLE.SELLER" />}
                </Stack>
            </Flex>

            <DeleteArticleModal visible={openDeleteModal} setVisible={setOpenDeleteModal} uuid={data.uuid} />
            <PromoteArticleModal visible={openPromotedModal} setVisible={setOpenPromotedModal} uuid={data.uuid} setData={setData} />
            <StopPromotingArticleModal visible={openStopPromoteModal} setVisible={setOpenStopPromoteModal} uuid={data.uuid} setData={setData} />
        </Box>
    ) : (
        <></>
    );
};

export default ArticleDetails;
