import React from 'react';
import { Input } from '@chakra-ui/react';

interface IProps {
    value?: string;
    placeholder?: string;
    onChange?: (newValue: string) => void;
}

const DecimalInput = ({ value = '0', onChange, placeholder }: IProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (/^\d*\.?\d*$/.test(newValue) || newValue === '') {
            onChange?.(newValue);
        }
    };

    const handleBlur = () => {
        if (value.trim() === '') {
            onChange?.('0');
        }
    };

    return <Input bg="brand.inputBackground" border="1px solid" borderColor={'brand.inputBorder'} placeholder={placeholder} type="text" value={value} onChange={handleChange} onBlur={handleBlur} />;
};

export default DecimalInput;
