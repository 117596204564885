import { useState, useEffect } from 'react';
import { Box, Text, Center, Button } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { Table } from 'components';
import { IAddress, ITableSlots } from 'common/types';
import { FiExternalLink } from 'react-icons/fi';
import { addressTableConfig, mapAddressesTableData } from 'app/articles/utils/helpers';
import { userAddressesAPI } from 'api/users';

interface IProps {
    uuid: string;
}

const UserAddresses = ({ uuid }: IProps) => {
    const { t } = useTranslation();

    const openVerifyImage = (imageUrl: string) => {
        window.open(imageUrl, '_blank');
    };

    const slots: ITableSlots = {
        verifyImage: (item: IAddress) => {
            return {
                content: (
                    <Box>
                        {item.verifyImage ? (
                            <Button onClick={() => openVerifyImage(item.verifyImage)} variant={'outline'} rightIcon={<FiExternalLink />}>
                                <Trans i18nKey={'ADDRESS.OPEN_IMAGE'} />
                            </Button>
                        ) : (
                            <Text>
                                <Trans i18nKey={'ADDRESS.NO_IMAGE'} />
                            </Text>
                        )}
                    </Box>
                ),
                sortableValue: item.uuid,
            };
        },
    };

    const [data, setData] = useState<IAddress[]>([]);

    const getUserAddresses = async () => {
        try {
            const res = await userAddressesAPI(uuid);
            setData(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getUserAddresses();
    }, []);

    return (
        <Box>
            <Box mb="8px"></Box>
            {data.length > 0 ? (
                <Table paginationPosition="relative" config={addressTableConfig(t)} data={mapAddressesTableData(data, t)} slots={slots} />
            ) : (
                <Center>
                    <Text>
                        <Trans i18nKey={'ADDRESSES.EMPTY'} />
                    </Text>
                </Center>
            )}
        </Box>
    );
};

export default UserAddresses;
