export enum BannerStatus {
    CREATED = 1,
    PAYMENT_PENDING = 2,
    PAYMENT_ACCEPTED = 3,
    REQUESTED = 4,
    ACCEPTED = 5,
    EXPIRED = 6,
}

export enum QuotaPackage {
    WEB = 1,
    MOBILE = 2,
    COMBO = 3,
}
