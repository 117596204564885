import { helpers } from 'common';
import { WalletQuotaDetailsResponse, WalletQuotasResponse, WalletRequestDetailsResponse, WalletRequestsResponse } from './types/response';
import { WalletQuotaRequestDTO } from './types/request';

const { formAxiosObject } = helpers;

export const walletRequestsAPI = async (queryParams?: string): Promise<WalletRequestsResponse> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/walletOrders/${queryParams ? `?${queryParams}` : ''}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const walletRequestDetailsAPI = async (uuid: string): Promise<WalletRequestDetailsResponse> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/walletOrders/${uuid}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const walletQuotasAPI = async (queryParams?: string): Promise<WalletQuotasResponse> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/walletQuotas/${queryParams ? `?${queryParams}` : ''}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const editWalletQuotaAPI = async (id: string, data: WalletQuotaRequestDTO): Promise<any> => {
    try {
        const response = await formAxiosObject({ method: 'PUT', route: `/management/walletQuotas/${id}` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const createWalletQuotaAPI = async (data: WalletQuotaRequestDTO): Promise<any> => {
    try {
        const response = await formAxiosObject({ method: 'POST', route: `/management/walletQuotas/` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const deleteWalletQuotaAPI = async (id: string): Promise<any> => {
    try {
        await formAxiosObject({ method: 'DELETE', route: `/management/walletQuotas/${id}` });
    } catch (error) {
        throw error;
    }
};

export const walletQuotaDetailsAPI = async (id: number): Promise<WalletQuotaDetailsResponse> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/walletQuotas/${id}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const acceptWalletRequestAPI = async (uuid: string): Promise<any> => {
    try {
        await formAxiosObject({ method: 'PATCH', route: `/management/walletOrders/${uuid}/accept` });
    } catch (error) {
        throw error;
    }
};

export const rejectWalletRequestAPI = async (uuid: string): Promise<any> => {
    try {
        await formAxiosObject({ method: 'PATCH', route: `/management/walletOrders/${uuid}/reject` });
    } catch (error) {
        throw error;
    }
};
