import { formAxiosObject } from 'common/helpers';
import { IRegion, IUpload, AddressResponseDTO, ReportsResponseDTO, ReportDetailsResponseDTO } from './types';

export const getUploadUrl = async (data: IUpload) => {
    const response = await formAxiosObject({ method: 'POST', route: '/upload' }, data);

    return response?.data?.data;
};

export const uploadToAws = async (file: File, url: string, index: number, onUploadProgress?: (_percentage: number, _index: number, _name: string) => void) => {
    const config = {
        onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

            if (onUploadProgress) {
                onUploadProgress(percentCompleted, index, file.name);
            }
        },
        headers: {
            'Content-Type': file.type,
            'Access-Control-Allow-Origin': '*',
            'x-goog-acl': 'public-read',
        },
        isExternalRequest: true,
    };

    const response = await formAxiosObject(
        {
            route: url,
            method: 'PUT',
        },
        file,
        null,
        null,
        {
            'Content-Type': file.type,
            'Access-Control-Allow-Origin': '*',
            'x-amz-acl': 'public-read',
        },
        true,
        config,
    );

    return response?.data?.data;
};

export const getRegion = async (regionID: number): Promise<IRegion> => {
    const response = await formAxiosObject({ method: 'GET', route: `/regions/${regionID}` });

    return response?.data?.data;
};

export const addressDetailsAPI = async (addressUUID: string): Promise<AddressResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/addresses/${addressUUID}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const removeAddressAPI = async (userUUID: string, addressUUID: string, sendNotif: boolean) => {
    try {
        await formAxiosObject({ method: 'DELETE', route: `/management/users/${userUUID}/addresses/${addressUUID}?verification=${sendNotif}` });
    } catch (error) {
        throw error;
    }
};

export const reportsAPI = async (queryParams?: string): Promise<ReportsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/adminRequests/${queryParams ? `?${queryParams}` : ''}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const reportDetailsAPI = async (id: number): Promise<ReportDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/adminRequests/${id}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const deleteReportAPI = async (id: number): Promise<any> => {
    try {
        await formAxiosObject({ method: 'DELETE', route: `/management/adminRequests/${id}` });
    } catch (error) {
        throw error;
    }
};

export const updateReportAPI = async (id: number, status: number): Promise<ReportDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'PUT', route: `/management/adminRequests/${id}` }, { status });
        return response?.data;
    } catch (error) {
        throw error;
    }
};
