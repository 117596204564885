export enum SubscriptionStatus {
    CREATED = 1,
    PAID = 2,
    PENDING_CANCEL = 3,
    CANCELED = 4,
}

export enum SubscritpionQuota {
    PREMIUM = 1,
    PREMIUM_SIX_MONTHS = 2,
    PREMIUM_YEAR = 3,
}
