import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Card, CardBody, HStack, Heading, Stack, Text, Image, Divider, Button, Icon, Link, Flex } from '@chakra-ui/react';
import { orderDetailsAPI } from 'api/orders';
import { useEffect, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { FiChevronsRight } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { IOrder } from '../types';
import { config } from 'core';
import { OrderStatus, PaymentProvider } from '../enums/orders.enums';
import { IAddress } from 'common/types';
import { addressDetailsAPI } from 'api/shared';
import TrackStatus from './details/trackStatus';
import { isNullOrUndefined } from 'common/helpers';
import UserContactCard from 'components/userCard';

const OrderDetails = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState<IOrder>();
    const [selllerAddress, setSellerAddress] = useState<IAddress>();
    const [buyerAddress, setBuyerAddress] = useState<IAddress>();

    const isOrderInSelectedStatus = (status: number) => {
        return data?.status === status;
    };

    const openMonriLoginScreen = () => {
        window.open(config.MONRI_URI, '_blank');
    };

    const getAddresses = async () => {
        if (data?.delivery?.buyerAddressUUID) {
            try {
                const response = await addressDetailsAPI(data?.delivery?.buyerAddressUUID);
                setBuyerAddress(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        if (data?.delivery?.sellerAddressUUID) {
            try {
                const response = await addressDetailsAPI(data.delivery.sellerAddressUUID);
                setSellerAddress(response.data);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getOrderDetails = async () => {
        if (id) {
            try {
                const res = await orderDetailsAPI(id);
                setData(res.data);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const goToNaklikArticle = () => {
        window.open(`${config.WEB_URL}/ba/product-details/${data?.product?.uuid}`, '_blank');
    };

    useEffect(() => {
        getOrderDetails();
    }, []);

    useEffect(() => {
        getAddresses();
    }, [data]);

    return data ? (
        <Box>
            <Breadcrumb spacing="8px" separator={<HiOutlineChevronRight color="gray.500" />} m="24px 0">
                <BreadcrumbItem>
                    <BreadcrumbLink href="/">
                        <Trans i18nKey={'MENU.ORDERS'} />
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href="#">{id}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Heading m="40px 0 33px 0" as="h1">
                <Trans i18nKey="MENU.ORDERS" />
            </Heading>
            <Flex alignItems="flex-start" direction={{ base: 'column', md: 'row' }} gap="24px">
                <Card w="100%">
                    <CardBody>
                        <HStack wrap={'wrap'}>
                            <Stack>
                                <Image
                                    mr="20px"
                                    maxH="190px"
                                    maxW="193px"
                                    src={
                                        data?.product?.primaryImageURI ||
                                        (data?.product?.images && data?.product?.images[0].imageURI) ||
                                        'https://ystyangin.com/wp-content/uploads/dummy-image-square.jpg'
                                    }
                                    alt="product"
                                    objectFit={'cover'}
                                    borderRadius="lg"
                                />
                            </Stack>
                            <Stack>
                                <Text fontWeight={700}>
                                    {t('ORDER.INFO_ID')} {data?.uuid}
                                </Text>
                                <Text fontWeight={700}>{data?.product?.name}</Text>
                                <Text>
                                    {t('ORDER.INFO_QUANTITY')} {data?.quantity}
                                </Text>
                                {data.product?.price && (
                                    <Text>
                                        {t('ORDER.INFO_PRICE_PER_PIECE')} {data.product.price / 100} {data?.product?.currency}
                                    </Text>
                                )}
                                {data.product?.shipmentPrice && data.delivery && (
                                    <Text>
                                        {t('ORDER.INFO_PRICE_SHIPPING')} {data.product.shipmentPrice / 100} {data?.product?.currency}
                                    </Text>
                                )}

                                {data.delivery ? (
                                    <Text>
                                        {t('ORDER.INFO_PRICE_TOTAL')} {data?.price / 100} {data?.currency}
                                    </Text>
                                ) : (
                                    <Text>
                                        {t('ORDER.INFO_PRICE_TOTAL')} {(data?.product?.price ?? 0) / 100} {data?.currency}
                                    </Text>
                                )}
                            </Stack>
                        </HStack>
                        <HStack justifyContent={'space-between'} mt="24px" wrap={'wrap'}>
                            <Button variant="outline" borderColor="brand.menuBorder" onClick={goToNaklikArticle}>
                                <Trans i18nKey={'ARTICLE.OPEN_IN_WEB'} />
                            </Button>
                            {isOrderInSelectedStatus(OrderStatus.CREATED) && !isNullOrUndefined(data.delivery) && data.provider === PaymentProvider.MONRI && (
                                <HStack wrap="wrap">
                                    <Button variant="dark" onClick={openMonriLoginScreen}>
                                        <Trans i18nKey={'ORDER.CHANGE_STATUS'} />
                                    </Button>
                                </HStack>
                            )}
                        </HStack>

                        <Stack spacing={'32px'}>
                            <Divider color="brand.menuBorder" mt="32px" />

                            {data?.delivery && (
                                <HStack gap="24px">
                                    <Stack>
                                        <Text fontWeight={600}>
                                            <Trans i18nKey={'ORDER.LOCATION'} />
                                        </Text>
                                        {selllerAddress ? (
                                            <Text>
                                                {selllerAddress?.address}, {selllerAddress?.postCode} {selllerAddress?.city}
                                            </Text>
                                        ) : (
                                            <Text color="black.60" maxW="300px">
                                                <Trans i18nKey={'ORDER.ADDRESS_SELLER'} />
                                            </Text>
                                        )}
                                    </Stack>
                                    <Button variant={'outline'} borderRadius={'96px'} p="0" cursor={'default'}>
                                        <Icon as={FiChevronsRight} />
                                    </Button>
                                    <Stack>
                                        <Text fontWeight={600}>
                                            <Trans i18nKey={'ORDER.DELIVERY_ADDRESS'} />
                                        </Text>
                                        <Text>
                                            {buyerAddress?.address}, {buyerAddress?.postCode} {buyerAddress?.city}
                                        </Text>
                                    </Stack>
                                </HStack>
                            )}

                            <Stack>
                                <Text fontWeight={600}>
                                    <Trans i18nKey={'ORDER.PAYMENT'} />
                                </Text>
                                <Text>
                                    <Trans i18nKey={data.provider === PaymentProvider.MONRI ? 'ORDER.CARD_PAYMENT' : 'ORDER.OTHER_PAYMENT'} />
                                </Text>
                            </Stack>
                            {(data.externalID || data.additionalInfo) && <Divider color="brand.menuBorder" />}
                            <Stack spacing="32px">
                                {data.externalID && (
                                    <Stack>
                                        <Text fontWeight={600}>
                                            <Trans i18nKey={'ORDER.PAYMENT_ID'} />
                                        </Text>
                                        <Text as={Link} href={config.MONRI_URI} target="_blank" textDecor={'underline'}>
                                            {data.externalID}
                                        </Text>
                                    </Stack>
                                )}
                                {data.additionalInfo && (
                                    <Stack>
                                        <Text fontWeight={600}>
                                            <Trans i18nKey={'ORDER.ADDITIONAL_INFO'} />
                                        </Text>
                                        <Text>{data?.additionalInfo}</Text>
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>
                    </CardBody>
                </Card>
                <Stack spacing="11px" maxW={{ base: '100%', md: '357px' }} w="100%">
                    <TrackStatus data={data} isOrderInSelectedStatus={isOrderInSelectedStatus} />
                    {data.buyerUserUUID && data.sellerUserUUID && (
                        <Stack spacing="11px">
                            <UserContactCard userUUID={data.sellerUserUUID} translation="ORDER.SELLER" />
                            <UserContactCard userUUID={data.buyerUserUUID} translation="ORDER.BUYER" />
                        </Stack>
                    )}
                </Stack>
            </Flex>
        </Box>
    ) : null;
};

export default OrderDetails;
