import jwt_decode from 'jwt-decode';
import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Box, Button, Container, Flex, Heading, Text, Center, Link } from '@chakra-ui/react';

import { Logo } from 'components';
import DefaultInput from 'components/input';
import { IDecodedToken, ILoginFormData } from 'common/types';
import colors from 'theme/foundations/colors';
import { loginAPI } from 'api/auth';
import { mapErrorCode } from 'common/helpers';
import { config } from 'core';
import { setSession } from 'core/session';

const Login = () => {
    const { t } = useTranslation();
    const [loginAttempts, setLoginAttempts] = useState(0);
    const [isLockedOut, setIsLockedOut] = useState(false);
    const [isFormEnabled, setIsFormEnabled] = useState(true);
    const [countdown, setCountdown] = useState(0);

    const initialValues: ILoginFormData = {
        username: '',
        password: '',
    };

    const startCountdown = () => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 0) {
                    clearInterval(timer);
                    setIsLockedOut(false);
                    setIsFormEnabled(true);
                    localStorage.removeItem('lastLoginTime');
                    localStorage.removeItem('loginAttempts');
                }
                return Math.max(0, prevCountdown - 1);
            });
        }, 1000);
    };

    const handleLogin = async (values: ILoginFormData, actions: FormikHelpers<ILoginFormData>): Promise<void> => {
        try {
            const res = await loginAPI(values);
            setLoginAttempts(0);
            localStorage.removeItem('loginAttempts');
            localStorage.removeItem('lastLoginTime');

            const decoded: IDecodedToken = jwt_decode(res?.data?.accessToken);
            const scope = decoded.scope;

            const redirectToMFA = () => (window.location.href = '/login/mfa');
            const redirectToForbidden = () => (window.location.href = '/403');
            const setSessionAndRedirectHome = () => {
                setSession(res?.data?.accessToken, res?.data?.refreshToken);
                window.location.href = '/';
            };

            if (scope === '') {
                localStorage.setItem('username', values.username);
                localStorage.setItem('password', values.password);
                redirectToMFA();
            } else if (scope.includes('Admin')) {
                setSessionAndRedirectHome();
            } else {
                redirectToForbidden();
            }

            actions.resetForm();
            actions.setStatus(null);
        } catch (error) {
            const attempts = parseInt(localStorage.getItem('loginAttempts') ?? '0');
            localStorage.setItem('loginAttempts', String(attempts + 1));
            setLoginAttempts(attempts + 1);

            if (loginAttempts + 1 >= 3) {
                setIsLockedOut(true);
                localStorage.setItem('lastLoginTime', String(Date.now()));
                setIsFormEnabled(false);
                setCountdown(120);
                startCountdown();
            }

            actions.setStatus({ error: mapErrorCode(error, t) });
        }
    };

    useEffect(() => {
        const lastLoginTime = localStorage.getItem('lastLoginTime');
        if (lastLoginTime) {
            const timeSinceLastLogin = Date.now() - parseInt(lastLoginTime);
            if (timeSinceLastLogin < 120000) {
                setIsLockedOut(true);
                setIsFormEnabled(false);
            }
        }
    }, []);

    return (
        <Flex h="100%" flexDir="column" justifyContent="center">
            <Flex justifyContent={{ base: 'center', md: 'flex-start' }} p={{ base: '36px', md: 0 }}>
                <Logo wrapperProps={{ position: { base: 'static', md: 'absolute' }, top: 47, left: 150 }} />
            </Flex>

            <Container maxW="md">
                <Heading as="h2" className="text-center">
                    <Trans i18nKey={'LOGIN.HEADING'} />
                </Heading>
                <Box style={{ border: `1px solid ${colors.grey[100]}`, borderRadius: '6px', margin: '36px 0 14px 0', padding: '25px' }}>
                    <Formik initialValues={initialValues} onSubmit={handleLogin} disabled={!isFormEnabled}>
                        {({ touched, handleBlur, handleChange, status, setStatus }) => (
                            <Form>
                                <Field
                                    as={DefaultInput}
                                    onBlur={handleBlur}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handleChange(e);
                                        setStatus(null);
                                    }}
                                    name="username"
                                    label={t('LOGIN.USERNAME_FIELD')}
                                    touched={touched.username}
                                    wrapperProps={{ mb: '8px' }}
                                    disabled={!isFormEnabled}
                                />
                                <Field
                                    as={DefaultInput}
                                    onBlur={handleBlur}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handleChange(e);
                                        setStatus(null);
                                    }}
                                    name="password"
                                    label={t('LOGIN.PASSWORD_FIELD')}
                                    type="password"
                                    touched={touched.password}
                                    disabled={!isFormEnabled}
                                />

                                {status?.error && (
                                    <Box>
                                        <Text fontSize="11px" mt="3px" color="red">
                                            {status.error}
                                        </Text>
                                    </Box>
                                )}
                                {isLockedOut && (
                                    <Box>
                                        <Text fontSize="11px" mt="3px" color="red">
                                            {`${t('LOGIN.FORM_DISABLED_MESSAGE')} ${countdown} ${t('LOGIN.SECONDS')}`}
                                        </Text>
                                    </Box>
                                )}
                                <Button className="m-t-15" type="submit" variant="dark" isDisabled={!isFormEnabled}>
                                    <Trans i18nKey={'LOGIN.BUTTON'} />
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Box>
                <Center>
                    <Link fontSize="14px" isExternal href={`${config.WEB_URL}/ba/forgot-password`} fontWeight="bold">
                        <Trans i18nKey={'LOGIN.FORGOT_PASSWORD_LINK'} />
                    </Link>
                </Center>
            </Container>
        </Flex>
    );
};

export default Login;
