import { Box, Heading, Menu, MenuItem, MenuButton, MenuList, Button, Center, Text } from '@chakra-ui/react';
import { FormEvent, useEffect, useState } from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Search from 'components/search/search';
import { Table } from 'components';

import { ordersListAPI } from 'api/orders';
import { IOrder } from '../types';
import { IPagination, IParams } from 'common/types';

import { orderProviders, orderStatuses } from '../constants';
import { buildQueryString, getParamsFromLocalStorage, removeBracketsAndParseNumber, saveParamsToLocalStorage } from 'common/helpers';
import { mapOrdersTableData, ordersTableConfig } from '../helpers';
import LoadingModal from 'components/loading';

const Orders = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [data, setData] = useState<IOrder[]>([]);
    const [apiParams, setApiParams] = useState<IParams>();

    const [filter, setFilter] = useState<string>('');
    const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [paginator, setPaginator] = useState<Partial<IPagination>>({
        perPage: 10,
        published: true,
        orderDir: 'DESC',
    });
    const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
    const [selectedProvider, setSelectedProvider] = useState<number | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [isErr, setIsErr] = useState<boolean>(false);

    const statusPlaceholder = () => {
        return selectedStatus ? `${t('ORDER.STATUS')}: ${t(orderStatuses.find((status) => status.status === selectedStatus)?.label ?? 'GENERAL.NOT_FOUND')}` : `${t('ORDER.STATUS_PLACEHOLDER')}`;
    };

    const providerPlaceholder = () => {
        return selectedProvider
            ? `${t('ORDER.PROVIDER')}: ${t(orderProviders.find((payment) => payment.provider === selectedProvider)?.label ?? 'GENERAL.NOT_FOUND')}`
            : `${t('ORDER.PROVIDER_PLACEHOLDER')}`;
    };
    const toggleSearchTrigger = () => {
        setPage(1);
        setSearchTrigger(!searchTrigger);
    };

    const toggleSelectedStatus = (status: number | undefined) => {
        if (status) {
            if (status === selectedStatus) {
                setPage(1);
                setSelectedStatus(null);
            } else setSelectedStatus(status);
        }
    };

    const toggleSelectedProvider = (provider: number | undefined) => {
        if (provider) {
            if (provider === selectedProvider) {
                setPage(1);
                setSelectedProvider(null);
            } else setSelectedProvider(provider);
        }
    };

    const handleClickSearch = () => {
        toggleSearchTrigger();
    };

    const handleClearSearch = () => {
        setFilter('');
        toggleSearchTrigger();
    };

    const redirectToDetails = (item: IOrder) => {
        if (apiParams) saveParamsToLocalStorage(apiParams, 'orderParams');
        navigate(`/orders/${item.uuid}`);
    };

    const handleSearchFilter = (e: FormEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
    };

    const changePage = (newPage: number | undefined) => {
        if (newPage) {
            setPage(newPage);
        }
    };

    const getOrders = async () => {
        try {
            setLoading(true);
            let params: IParams;

            if (getParamsFromLocalStorage('orderParams')) {
                params = getParamsFromLocalStorage('orderParams');
                setPage(params.page ?? 1);
                toggleSelectedStatus(removeBracketsAndParseNumber(params.status ?? ''));
                toggleSelectedProvider(removeBracketsAndParseNumber(params.paymentProvider ?? ''));
                setFilter(params.filter ?? '');

                localStorage.removeItem('orderParams');
            } else {
                params = {
                    ...paginator,
                    page: page,
                    filter: filter,
                };

                if (selectedStatus) {
                    params.status = `[${selectedStatus}]`;
                } else {
                    delete params.status;
                }

                if (selectedProvider) {
                    params.paymentProvider = `[${selectedProvider}]`;
                } else {
                    delete params.paymentProvider;
                }
            }

            setApiParams(params);
            const queryString = buildQueryString(params);

            const res = await ordersListAPI(queryString);
            setData(res.data.results);
            setPaginator({ ...paginator, totalPages: res.data.paginator.totalPages });
        } catch (error) {
            console.log(error);
            setIsErr(true);
        } finally {
            setLoading(false);
        }
    };

    const isDataEmpty = () => {
        return data.length === 0 && !loading && !isErr;
    };

    useEffect(() => {
        getOrders();
    }, [page, searchTrigger, selectedStatus, selectedProvider]);

    return (
        <Box>
            <Search filter={filter} searchTrigger={searchTrigger} handleClearSearch={handleClearSearch} handleClickSearch={handleClickSearch} handleSearchFilter={handleSearchFilter} />

            <Heading m="40px 0 33px 0" as="h1">
                <Trans i18nKey="MENU.ORDERS" />
            </Heading>
            <Box mb="8px">
                <Menu>
                    <MenuButton bg="white" fontSize="16px" fontWeight="normal" as={Button} rightIcon={<HiOutlineChevronDown />}>
                        {statusPlaceholder()}
                    </MenuButton>
                    <MenuList
                        minW={['100%', '500px', '500px']}
                        maxHeight={'300px'}
                        overflowX={'scroll'}
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {orderStatuses.map((status) => (
                            <MenuItem onClick={() => toggleSelectedStatus(status.status)} key={status.status}>
                                {t(status.label)}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>

                <Menu>
                    <MenuButton bg="white" fontSize="16px" fontWeight="normal" as={Button} rightIcon={<HiOutlineChevronDown />}>
                        {providerPlaceholder()}
                    </MenuButton>
                    <MenuList
                        minW={['100%', '500px', '500px']}
                        maxHeight={'300px'}
                        overflowX={'scroll'}
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {orderProviders.map((payment) => (
                            <MenuItem onClick={() => toggleSelectedProvider(payment.provider)} key={payment.provider}>
                                {t(payment.label)}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>
            {isErr && (
                <Center mt="24px">
                    <Text color="red">
                        <Trans i18nKey={'GENERAL.LOADING_ERROR'} />
                    </Text>
                </Center>
            )}
            {isDataEmpty() && (
                <Center mt="24px">
                    <Text color="black.60">
                        <Trans i18nKey={'GENERAL.EMPTY_STATE'} />
                    </Text>
                </Center>
            )}
            <Table onPaging={changePage} page={page} pagination={paginator} data={mapOrdersTableData(data, t)} config={ordersTableConfig(t)} onRowClick={redirectToDetails} />
            <LoadingModal isOpen={loading} setIsOpen={setLoading} />
        </Box>
    );
};

export default Orders;
