import axios, { InternalAxiosRequestConfig, AxiosError } from 'axios';
import promise from 'promise';

import config from 'core/config';
import { getSession, setSession, removeSession } from 'core/session';

interface IConfig extends InternalAxiosRequestConfig {
    isExternalRequest?: boolean;
}

interface ISubscriber {
    fetchingToken?: boolean;
    callbacks: ((_token: string) => any)[];
}

const subscribers: { [key: string]: ISubscriber } = {};

const isServer = () => {
    return typeof window === 'undefined';
};

const onAccessTokenFetched = (token: string, uuid?: string) => {
    const subscriber = subscribers[uuid || ''] || {};
    subscriber?.callbacks?.forEach((callback) => callback(token));
    delete subscribers[uuid || ''];
};

const addSubscriber = (callback: (_token: string) => any, uuid?: string) => {
    if (subscribers[uuid || '']?.callbacks) {
        subscribers[uuid || ''].callbacks.push(callback);
    } else {
        subscribers[uuid || ''] = {
            callbacks: [callback],
        };
    }
};
const initilizeHttpClient = async (baseURL?: string) => {
    const instance = axios.create();
    instance.defaults.headers.common = {};
    axios.defaults.baseURL = baseURL || config.API_URL;

    const sessionData = getSession();
    const uuid = sessionData?.userDetails?.uuid;
    const subscriber = subscribers[uuid || ''] || {};

    const refreshToken = async (oError: AxiosError) => {
        try {
            const { response } = oError;

            // create new Promise to retry original request
            const retryOriginalRequest = new Promise((resolve) => {
                addSubscriber((token: string) => {
                    response!.config.headers['Authorization'] = `Bearer ${token}`;
                    resolve(axios(response!.config));
                }, uuid);
            });

            // check whether refreshing token or not
            if (!subscriber?.fetchingToken) {
                if (subscriber) {
                    subscribers[uuid || ''].fetchingToken = true;
                } else {
                    subscribers[uuid || ''] = {
                        fetchingToken: true,
                        callbacks: [],
                    };
                }

                // refresh token
                const response = await axios({
                    url: '/me/refresh-token',
                    method: 'POST',
                    data: {
                        token: sessionData?.refreshToken || '',
                    },
                    headers: {
                        Authorization: `Bearer ${sessionData.accessToken}`,
                    },
                });

                const tokenData = response?.data?.data;

                setSession(tokenData.accessToken, tokenData.refreshToken, sessionData?.userDetails);

                onAccessTokenFetched(tokenData.accessToken, uuid);
            }
            return retryOriginalRequest;
        } catch (error) {
            // on error go to login page
            if (!isServer()) {
                removeSession();
                window.location.href = '/login';
            }

            delete subscribers[uuid || ''];

            return Promise.reject(oError);
        } finally {
            delete subscribers[uuid || ''];
        }
    };

    axios.interceptors.request.use(
        (requestConfig: IConfig) => {
            // if request url is towards our API, send appropriate headers
            if (!requestConfig.isExternalRequest && requestConfig.method !== 'OPTIONS' && sessionData.accessToken && !requestConfig.headers.Authorization) {
                requestConfig.headers.Authorization = `Bearer ${sessionData.accessToken}`;
            }

            return requestConfig;
        },
        (error) => {
            return promise.reject(error);
        },
    );

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const status = error?.response?.status;

            if (status === 401) {
                return refreshToken(error);
            }

            return Promise.reject(error);
        },
    );

    return axios;
};

export { initilizeHttpClient };
