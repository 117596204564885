export enum UserVerificationStatus {
    NOT_INITIATED = 1,
    PENDING = 2,
    VERIFIED = 3,
    DECLINED = 4,
}

export enum VerificationAdminRequest {
    SELLER_VERIFY = 9,
    BUSINESS_VERIFY = 10,
}

export enum AdminRequestStatus {
    CREATED = 1,
    ACCEPTED = 2,
    DECLINED = 3,
}

export enum UserRoleID {
    USER = 1,
    ADMIN = 2,
}
