import { Modal, ModalOverlay, ModalContent, ModalBody, Text, Button, HStack, Stack, Alert, AlertIcon } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

interface IProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onConfirm: () => void;
    message: string;
    description?: string;
    error?: boolean;
    errorMessage?: string;
    setError?: (value: boolean) => void;
}
const ConfirmationModal = ({ isOpen, setIsOpen, onConfirm, message, description, error, errorMessage, setError }: IProps) => {
    const onClose = () => {
        setIsOpen(!isOpen);
        if (error && setError) {
            setError(!error);
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent bgColor="white" width={'fit-content'}>
                    <ModalBody>
                        <Stack p="16px 0" spacing={'16px'}>
                            <Text fontSize={'20px'} fontWeight={600}>
                                {message}
                            </Text>
                            {description && <Text>{description}</Text>}
                        </Stack>
                        {error && errorMessage && (
                            <Alert status="error" mb="8px">
                                <AlertIcon />
                                <Stack spacing={0}>
                                    <Text>
                                        <Trans i18nKey={'ERROR.GENERAL'} />
                                    </Text>
                                    <Text>
                                        <Trans i18nKey={errorMessage} />
                                    </Text>
                                </Stack>
                            </Alert>
                        )}

                        <HStack>
                            <Button backgroundColor={'black.100'} color="white" mb="16px" w="100%" onClick={onConfirm}>
                                <Trans i18nKey={'GENERAL.YES'} />
                            </Button>
                            <Button mb="16px" w="100%" onClick={onClose}>
                                <Trans i18nKey={'GENERAL.NO'} />
                            </Button>
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ConfirmationModal;
