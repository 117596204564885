import React from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from 'App';
import theme from 'theme';
import { initilizeHttpClient } from 'core';

import 'core/i18n';

initilizeHttpClient();

const AppWrapper = (): React.ReactElement<any> => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                retry: false,
            },
        },
    });

    return (
        <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
                <App />
            </ChakraProvider>
        </QueryClientProvider>
    );
};

const container: HTMLElement = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<AppWrapper />);
