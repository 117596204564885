/* eslint-disable prettier/prettier */
import { useState, useRef, useEffect, ChangeEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react';
import { Button, Text, Select, Stack, HStack, Divider, RadioGroup, Image, Box, Center } from '@chakra-ui/react';

import { Input } from 'components';
import Switch from 'components/switch';
import Radio from 'components/radio';

import { InputTypes } from 'app/category/enums/categories.enums';
import { IArticleCategory, IArticleCategoryField, IArticleCategoryFieldOption } from 'app/articles/types';
import { stepsArr } from 'app/category/constants';
import { inputTypes } from 'app/category/constants';
import { IInputType } from 'app/category/types';
import { defaultOptionData, defaultFieldData, getOptionsLength, findFieldIndexById, isTranslationEmpty, findDifference } from 'app/category/utils/helpers';
import colors from 'theme/foundations/colors';

import { LuImagePlus, LuTrash } from 'react-icons/lu';
import { getUploadUrl, uploadToAws } from 'api/shared';
import { config } from 'core';
import LoadingModal from 'components/loading';
import { generateCustomUUID, isNullOrUndefined } from 'common/helpers';

interface IProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    child?: boolean;

    selectedField: IArticleCategoryField;
    setSelectedField: (value: IArticleCategoryField) => void;

    category: IArticleCategory;
    setCategory: (value: IArticleCategory) => void;
}

const FieldModal = ({ open, setOpen, selectedField, setSelectedField, category, setCategory, child }: IProps) => {
    const { t } = useTranslation();

    const hiddenFileInput = useRef<HTMLInputElement | null>(null);
    const highestOptionIdRef = useRef(getOptionsLength(category));

    const [parentOption, setParentOption] = useState<number | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(selectedField.iconURI ?? null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleClose = () => {
        setSelectedField({ ...defaultFieldData });
        setImagePreview(null);
        setOpen(!open);
        setParentOption(null);
    };

    const filterFieldsByStepAndParent = (fields: IArticleCategoryField[], step: number) => {
        return fields?.filter((field) => field.stepID === step && (field.parentID === 0 || field.parentID === null)) ?? [];
    };

    const canAddOptions = () => {
        return selectedField.inputType === InputTypes.CHECKBOX || selectedField.inputType === InputTypes.SELECT;
    };

    const isPlaceholderVisible = () => {
        return ![InputTypes.TOGGLE].includes(selectedField.inputType);
    };

    const isRangeType = () => {
        return selectedField.inputType === InputTypes.RANGE;
    };

    const isAddingMoreOptionsDisabled = () => {
        return selectedField.inputType === InputTypes.CHECKBOX && selectedField.productCategoryFieldOptions?.length >= 3;
    };

    const isCheckbox = () => {
        return selectedField.inputType === InputTypes.CHECKBOX;
    };

    const isSubmitDisabled = () => {
        const { translations, placeholderTranslations, productCategoryFieldOptions, inputType } = selectedField;

        const hasEmptyTranslation = productCategoryFieldOptions?.some((option) => isTranslationEmpty(option.translations.bs));
        if (!canAddOptions() && isPlaceholderVisible()) {
            return translations.bs === '' || placeholderTranslations.bs === '';
        }

        if (inputType === InputTypes.SELECT) {
            return translations.bs === '' || placeholderTranslations.bs === '' || productCategoryFieldOptions?.length === 0 || hasEmptyTranslation;
        }

        if (inputType === InputTypes.CHECKBOX) {
            return translations.bs === '' || productCategoryFieldOptions?.length === 0 || productCategoryFieldOptions?.length > 3 || hasEmptyTranslation;
        }

        return false;
    };

    const getOptionsWithSameParentID = (optionParentID: number) => {
        const result = category.productCategoryFields?.flatMap((f) => f.productCategoryFieldOptions?.filter((o) => o.parentID === optionParentID));

        return result ?? [];
    };

    const handleFile = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            setImagePreview(e.target?.result as string);
        };
        reader.readAsDataURL(file);
    };

    const handleImageUpload = async (uploadedFile: File) => {
        if (uploadedFile) {
            try {
                const uploadUrlResponse = await getUploadUrl({
                    kind: config.IMAGES_BUCKET,
                    fileName: uploadedFile.name,
                    contentType: uploadedFile.type,
                });
                const url = uploadUrlResponse.accessURL;
                const uploadUrl = uploadUrlResponse.url;
                await uploadToAws(uploadedFile, uploadUrl, 0);
                setSelectedField({ ...selectedField, iconURI: url });
            } catch (error) {
                console.error('Error uploading image:', error);
            }
            setLoading(false);
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const fileUploaded = event.target.files?.[0];
        if (fileUploaded) {
            setLoading(true);
            handleFile(fileUploaded);
            handleImageUpload(fileUploaded);
        }
    };

    const handleClick = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const handleInputTypeChange = (type: string) => {
        if (parseInt(type) === InputTypes.TEXT || parseInt(type) === InputTypes.NUMBER || parseInt(type) === InputTypes.TOGGLE || parseInt(type) === InputTypes.RANGE) {
            setSelectedField({ ...selectedField, inputType: parseInt(type), productCategoryFieldOptions: [] });
        } else {
            setSelectedField({ ...selectedField, inputType: parseInt(type) });
        }
    };

    const handleAddOption = (quantity: number) => {
        const newOptions: IArticleCategoryFieldOption[] = [];

        for (let i = 0; i < quantity; i++) {
            highestOptionIdRef.current++;
            const fieldIndex = findFieldIndexById(category.productCategoryFields, selectedField.id);

            const newOption: IArticleCategoryFieldOption = {
                ...defaultOptionData,
                productCategoryFieldID: fieldIndex !== -1 ? selectedField.id : category.productCategoryFields?.length + 1,
                id: highestOptionIdRef.current,
                parentID: parentOption,
            };
            newOptions.push(newOption);
        }

        setSelectedField({
            ...selectedField,
            productCategoryFieldOptions: [...(selectedField.productCategoryFieldOptions ?? []), ...newOptions],
        });
    };

    const handleRemoveOption = (optionIdToRemove: number) => {
        const updatedOptions = selectedField.productCategoryFieldOptions?.filter((option) => option.id !== optionIdToRemove);

        setSelectedField({
            ...selectedField,
            productCategoryFieldOptions: updatedOptions,
        });
    };

    const handleParentFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        const [parentUUID, option] = selectedValue.split(':');
        setSelectedField({
            ...selectedField,
            parentID: Number(parentUUID),
        });

        if (selectedField.productCategoryFieldOptions.length > 0) {
            const originalOptions = getOptionsWithSameParentID(Number(option)).filter((opt) => !isNullOrUndefined(opt)) ?? [];

            const updatedOptions = [
                ...originalOptions,
                ...(selectedField.productCategoryFieldOptions?.map((fieldOption) => {
                    return {
                        ...fieldOption,
                        productCategoryFieldID: Number(originalOptions[0]?.productCategoryFieldID ?? selectedField.id),
                        parentID: Number(option),
                    };
                }) ?? []),
            ];

            setSelectedField({
                ...selectedField,
                parentID: Number(parentUUID),
                productCategoryFieldOptions: updatedOptions,
            });
        }

        setParentOption(Number(option));
    };

    const handleOptionTranslationChange = (optionId: number, newTranslation: string) => {
        const updatedSelectedField = {
            ...selectedField,
            productCategoryFieldOptions: selectedField.productCategoryFieldOptions?.map((option) => {
                if (option.id === optionId) {
                    return {
                        ...option,
                        translations: {
                            bs: newTranslation,
                        },
                    };
                }
                return option;
            }),
        };
        setSelectedField(updatedSelectedField);
    };

    const handleChildEdit = () => {
        const fieldIndex = findFieldIndexById(category.productCategoryFields, selectedField.id);

        if (fieldIndex !== -1) {
            const updatedFields = [...category.productCategoryFields];

            const parentID = selectedField.productCategoryFieldOptions[0]?.parentID; // Added a check here
            const originalOptions = category.productCategoryFields?.flatMap((field) => field.productCategoryFieldOptions?.filter((option) => option.parentID === parentID));

            const optionsToDelete = findDifference(originalOptions, selectedField.productCategoryFieldOptions);

            if (optionsToDelete.length > 0) {
                updatedFields.forEach((field) => {
                    if (field.productCategoryFieldOptions) {
                        field.productCategoryFieldOptions = field.productCategoryFieldOptions?.filter((opt) => !optionsToDelete?.some((option) => option && opt && option.id === opt.id));
                    }
                });
            }

            const editedOptions = selectedField.productCategoryFieldOptions.filter((opt) => {
                const existingOptionIndex = updatedFields[fieldIndex]?.productCategoryFieldOptions?.findIndex((existingOpt) => existingOpt && opt && existingOpt.id === opt.id);
                return existingOptionIndex !== -1;
            });

            if (editedOptions.length > 0) {
                editedOptions.forEach((editedOption) => {
                    const existingOptionIndex = updatedFields[fieldIndex]?.productCategoryFieldOptions?.findIndex((existingOpt) => existingOpt && editedOption && existingOpt.id === editedOption.id);
                    if (existingOptionIndex !== -1) {
                        updatedFields[fieldIndex]?.productCategoryFieldOptions?.splice(existingOptionIndex, 1, {
                            ...(updatedFields[fieldIndex]?.productCategoryFieldOptions[existingOptionIndex] || {}),
                            ...editedOption,
                        });
                    }
                });
            }

            if (selectedField.productCategoryFieldOptions.some((opt) => opt?.createdAt === defaultOptionData.createdAt)) {
                const newOptions = selectedField.productCategoryFieldOptions?.filter((opt) => opt?.createdAt === defaultOptionData.createdAt);
                if (newOptions.length > 0) {
                    for (const option of newOptions) {
                        updatedFields[fieldIndex] = {
                            ...selectedField,
                            productCategoryFieldOptions: [...(updatedFields[fieldIndex]?.productCategoryFieldOptions || []), option],
                        };
                    }
                }
            }

            setSelectedField({
                ...selectedField,
                productCategoryFieldOptions: updatedFields[fieldIndex]?.productCategoryFieldOptions,
            });

            updatedFields[fieldIndex] = {
                ...updatedFields[fieldIndex],
                translations: selectedField.translations,
                placeholderTranslations: selectedField.placeholderTranslations,
                parentID: selectedField.parentID,
                inputType: selectedField.inputType,
                iconURI: selectedField.iconURI,
                searchable: selectedField.searchable,
                stepID: selectedField.stepID,
            };

            setCategory({ ...category, productCategoryFields: updatedFields });
        }
    };

    const handleSubmit = () => {
        const fieldIndex = findFieldIndexById(category.productCategoryFields, selectedField.id);
        const selectedCategoryField = Object.assign({}, selectedField);

        if (selectedCategoryField.inputType === InputTypes.TOGGLE) {
            selectedCategoryField.productCategoryFieldOptions = [];
            setSelectedField(selectedCategoryField);
        }

        // special handling for child fields as they are merged by parentID
        if (child) {
            handleChildEdit();
        } else if (fieldIndex !== -1) {
            const updatedCategoryFields = [...category.productCategoryFields];
            updatedCategoryFields[fieldIndex] = {
                ...selectedCategoryField,
            };
            setCategory({ ...category, productCategoryFields: updatedCategoryFields });
        } else {
            setCategory({
                ...category,
                productCategoryFields: [
                    ...category.productCategoryFields,
                    {
                        ...selectedCategoryField,
                        id: category.productCategoryFields?.length + 1,
                        order: category.productCategoryFields?.length + 1,
                    },
                ],
            });
        }

        handleClose();
    };

    useEffect(() => {
        if (selectedField.productCategoryFieldOptions?.length > 0 && (selectedField.parentID !== 0 || selectedField.parentID !== null)) {
            setParentOption(selectedField.productCategoryFieldOptions[0].parentID);
        }
        if (selectedField.iconURI) {
            setImagePreview(selectedField.iconURI);
        }
    }, [open]);

    return (
        <Modal isOpen={open} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent bgColor="white" width="100%" maxW={'412px'}>
                <ModalHeader>
                    <Trans i18nKey={'CATEGORY.FIELDS_ADD'} />
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={'24px'}>
                        <Stack>
                            <Text fontSize="14px" fontWeight={500}>
                                <Trans i18nKey={'CATEGORY.FIELDS_ADD_PARENT'} />
                            </Text>
                            <Select
                                borderColor={'brand.inputBorder'}
                                value={`${selectedField.parentID}:${parentOption}`}
                                placeholder={t('CATEGORY.FIELDS_ADD_PARENT_PLACEHOLDER')}
                                onChange={handleParentFieldChange}
                            >
                                {filterFieldsByStepAndParent(category.productCategoryFields, selectedField.stepID).map(
                                    (field) =>
                                        field.productCategoryFieldOptions?.map((option) => (
                                            <option key={field.id + generateCustomUUID()} value={`${field.id}:${option.id}`}>
                                                {field.translations.bs}: {option.translations.bs}
                                            </option>
                                        )),
                                )}
                            </Select>
                            <Text fontSize="14px" color="black.60">
                                <Trans i18nKey={'CATEGORY.FIELDS_ADD_PARENT_DESC'} />
                            </Text>
                        </Stack>

                        <Stack>
                            <Text fontSize="14px" fontWeight={500}>
                                <Trans i18nKey={'CATEGORY.FIELDS_ADD_STEP'} />
                            </Text>
                            <Select
                                borderColor={'brand.inputBorder'}
                                isDisabled={!isNullOrUndefined(parentOption)}
                                value={selectedField.stepID}
                                onChange={(e) => setSelectedField({ ...selectedField, stepID: Number(e.target.value) })}
                            >
                                <option value={stepsArr[0].value}>{t(stepsArr[0].i18nKey)}</option>
                                <option value={stepsArr[1].value}>{t(stepsArr[1].i18nKey)}</option>
                            </Select>
                        </Stack>

                        <Stack>
                            <HStack gap={0}>
                                <Text fontSize="14px" fontWeight={500}>
                                    <Trans i18nKey={'CATEGORY.FIELDS_ADD_NAME'} />
                                </Text>
                                <Text color="red">&#42;</Text>
                            </HStack>

                            <Input
                                name="parentField"
                                placeholder={t('CATEGORY.FIELDS_ADD_NAME_PLACEHOLDER')}
                                value={selectedField.translations.bs}
                                onChange={(e) => setSelectedField({ ...selectedField, translations: { bs: e.currentTarget.value } })}
                            />
                        </Stack>

                        {isPlaceholderVisible() && (
                            <Stack>
                                <HStack gap={0}>
                                    <Text fontSize="14px" fontWeight={500}>
                                        <Trans i18nKey={'CATEGORY.FIELDS_ADD_PLACEHOLDER'} />
                                    </Text>
                                    {!isCheckbox() && <Text color="red">&#42;</Text>}
                                </HStack>

                                <Input
                                    name="placeholderField"
                                    placeholder={t('CATEGORY.FIELDS_ADD_PLACEHOLDER_PLACEHOLDER')}
                                    value={selectedField.placeholderTranslations.bs}
                                    onChange={(e) => setSelectedField({ ...selectedField, placeholderTranslations: { bs: e.currentTarget.value } })}
                                />
                            </Stack>
                        )}

                        <Switch
                            isChecked={selectedField.searchable}
                            onChange={(isChecked) => setSelectedField({ ...selectedField, searchable: isChecked })}
                            checkedColor="black.100"
                            label={t('CATEGORY.FIELD_SEARCHABLE')}
                        />
                        <Divider color="brand.menuBorder" />

                        <Stack>
                            <Stack>
                                <Text fontWeight={500}>
                                    <Trans i18nKey={'CATEGORY.FIELDS_ADD_ICON'} />
                                </Text>
                                <Text color="black.60" fontSize={'14px'}>
                                    <Trans i18nKey={'CATEGORY.FIELDS_ADD_ICON_DESC'} />
                                </Text>
                            </Stack>
                            <Box w="100%">
                                {imagePreview && (
                                    <Center w="100%" border={`1px solid ${colors.brand.menuBorder}`} p="8px" borderRadius="9px" mb="8px">
                                        <Image src={imagePreview} objectFit="cover" alt="Selected Image" style={{ height: '48px' }} />
                                    </Center>
                                )}
                                <Button leftIcon={<LuImagePlus />} onClick={handleClick} w="100%">
                                    <Text fontSize="14px" fontWeight={500}>
                                        <Trans i18nKey={'CATEGORY.FIELDS_ADD_ICON_BTN'} />
                                    </Text>
                                </Button>
                                <input type="file" accept=".png" onChange={handleFileChange} ref={hiddenFileInput} style={{ display: 'none' }} />
                            </Box>
                        </Stack>

                        <Divider color="brand.menuBorder" />

                        <HStack gap={0}>
                            <Text fontSize={'20px'} fontWeight={600}>
                                <Trans i18nKey={'CATEGORY.FIELDS_OPTIONS'} />
                            </Text>
                            <Text color="red">&#42;</Text>
                        </HStack>
                        <RadioGroup value={selectedField.inputType.toString()} onChange={handleInputTypeChange}>
                            <HStack spacing={'24px'} wrap={'wrap'}>
                                {inputTypes.map((type: IInputType) => (
                                    <Radio value={type.value.toString()} key={type.value + generateCustomUUID()} checkedColor="black.100" label={t(type.i18nKey)} />
                                ))}
                            </HStack>
                        </RadioGroup>

                        {canAddOptions() &&
                            selectedField.productCategoryFieldOptions?.map((option) => (
                                <HStack key={option.id}>
                                    <Input
                                        wrapperProps={{ width: '100%' }}
                                        name="option"
                                        value={option.translations.bs}
                                        onChange={(e) => handleOptionTranslationChange(option.id, e.currentTarget.value)}
                                    />
                                    <Button variant={'outline'} leftIcon={<LuTrash />} iconSpacing={0} onClick={() => handleRemoveOption(option.id)} />
                                </HStack>
                            ))}

                        {isRangeType() && (
                            <Stack>
                                <Input
                                    wrapperProps={{ width: '100%' }}
                                    name="option"
                                    placeholder={t('CATEGORY.FIELD_UNIT')}
                                    value={selectedField.unit}
                                    onChange={(e) => setSelectedField({ ...selectedField, unit: e.currentTarget.value })}
                                />

                                <HStack mt="8px">
                                    <Stack>
                                        <Text fontSize={'14px'}>
                                            <Trans i18nKey={'CATEGORY.FIELD_MAX'} />
                                        </Text>
                                        <NumberInput
                                            defaultValue={selectedField.minRange ?? 0}
                                            borderColor={'brand.inputBorder'}
                                            min={0}
                                            onChange={(valueString) => {
                                                const parsedValue = parseInt(valueString, 10);
                                                if (!isNaN(parsedValue)) {
                                                    setSelectedField({ ...selectedField, minRange: parsedValue });
                                                }
                                            }}
                                            clampValueOnBlur
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </Stack>

                                    <Stack>
                                        <Text fontSize={'14px'}>
                                            <Trans i18nKey={'CATEGORY.FIELD_MIN'} />
                                        </Text>
                                        <NumberInput
                                            defaultValue={selectedField.maxRange ?? 0}
                                            borderColor={'brand.inputBorder'}
                                            min={0}
                                            onChange={(valueString) => {
                                                const parsedValue = parseInt(valueString, 10);
                                                if (!isNaN(parsedValue)) {
                                                    setSelectedField({ ...selectedField, maxRange: parsedValue });
                                                }
                                            }}
                                            clampValueOnBlur
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </Stack>
                                </HStack>
                            </Stack>
                        )}

                        {canAddOptions() && (
                            <Stack spacing={'20px'}>
                                <Button fontSize={'14px'} onClick={() => handleAddOption(1)} isDisabled={isAddingMoreOptionsDisabled()}>
                                    <Trans i18nKey={'CATEGORY.FIELDS_OPTIONS_ADD_1'} />
                                </Button>
                                {!isCheckbox() && (
                                    <HStack spacing={'20px'}>
                                        <Button fontSize={'14px'} w="100%" onClick={() => handleAddOption(5)}>
                                            <Trans i18nKey={'CATEGORY.FIELDS_OPTIONS_ADD_5'} />
                                        </Button>
                                        <Button fontSize={'14px'} w="100%" onClick={() => handleAddOption(10)}>
                                            <Trans i18nKey={'CATEGORY.FIELDS_OPTIONS_ADD_10'} />
                                        </Button>
                                    </HStack>
                                )}
                            </Stack>
                        )}
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button backgroundColor="brand.success" color="white" onClick={handleSubmit} w={'100%'} isDisabled={isSubmitDisabled()}>
                        <Trans i18nKey={'CATEGORY.SAVE_FIELD_BUTTON'} />
                    </Button>
                </ModalFooter>
            </ModalContent>

            <LoadingModal isOpen={loading} setIsOpen={setLoading} />
        </Modal>
    );
};

export default FieldModal;
