import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalHeader, Stack, Text, HStack, Button } from '@chakra-ui/react';
import { userWalletTopup } from 'api/users';
import { isStringEmpty, mapErrorCode } from 'common/helpers';
import { DecimalInput } from 'components';
import ConfirmationModal from 'components/confirmationModal';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    uuid: string;
}

const TopupModal = ({ isOpen, onClose, uuid }: IProps) => {
    const { t } = useTranslation();

    const [price, setPrice] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);

    const handleClose = () => {
        setPrice('');
        setErrorMessage('');
        onClose();
    };

    const toggleConfirmationModal = () => {
        setConfirmationModal(!confirmationModal);
    };

    const confirmTransfer = async () => {
        try {
            const priceInCents = Math.round(parseFloat(price || '0') * 100);
            await userWalletTopup(uuid, { price: priceInCents });
            handleClose();
            toggleConfirmationModal();
        } catch (error) {
            setErrorMessage(mapErrorCode(error, t));
        }
    };

    const isSubmitDisabled = (): boolean => {
        return !isStringEmpty(errorMessage);
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} isCentered>
            <ModalOverlay />
            <ModalContent bgColor={'white'}>
                <ModalHeader>
                    <Trans i18nKey={'USER.TOPUP'} />
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack gap="4px" w="fit-content">
                        <Text w="75%">
                            <Trans i18nKey={'USER.TOPUP_LABEL'} />
                        </Text>
                        <DecimalInput placeholder={t('USER.TOPUP_PLACEHOLDER')} value={price} onChange={(newValue) => setPrice(newValue)} />
                    </Stack>

                    <HStack m="16px 0">
                        <Button variant="outline" onClick={onClose}>
                            <Trans i18nKey={'GENERAL.CANCEL'} />
                        </Button>
                        <Button isDisabled={isSubmitDisabled()} backgroundColor={'brand.success'} color={'white'} fontSize={'14px'} onClick={toggleConfirmationModal}>
                            <Trans i18nKey={'WALLET.ACCEPT_TOP_UP'} />
                        </Button>
                    </HStack>
                </ModalBody>
                <ConfirmationModal
                    isOpen={confirmationModal}
                    setIsOpen={setConfirmationModal}
                    onConfirm={confirmTransfer}
                    message={t('USER.TOPUP_CONFIRMATION')}
                    error={isSubmitDisabled()}
                    errorMessage={errorMessage}
                />
            </ModalContent>
        </Modal>
    );
};

export default TopupModal;
