import Cookies from 'js-cookie';

import { constants } from 'common';
import { IUser } from 'common/types';

interface ISessionData {
    accessToken?: string;
    refreshToken?: string;
    userDetails?: IUser;
}

export const setSession = (accessToken: string, refreshToken: string, userDetails?: IUser) => {
    const data: ISessionData = {
        accessToken,
        refreshToken,
        userDetails: userDetails || undefined,
    };

    Cookies.set(constants.SESSION_KEY, JSON.stringify(data));
};

export const getSession = (): ISessionData => {
    const data = Cookies.get(constants.SESSION_KEY);

    return data ? JSON.parse(data as string) : {};
};

export const removeSession = () => {
    Cookies.remove(constants.SESSION_KEY);
};

export const setUserDetails = (userDetails?: IUser) => {
    const session = getSession();
    session.userDetails = userDetails;

    Cookies.set(constants.SESSION_KEY, JSON.stringify(session));
};
