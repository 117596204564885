import { useEffect, useState } from 'react';
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Card,
    CardBody,
    HStack,
    Icon,
    Image,
    Link,
    Stack,
    Text,
    Tag,
    TagLabel,
    TagLeftIcon,
    Divider,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';

import { AiOutlineInstagram, AiOutlineYoutube, AiOutlineCheckCircle } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';
import { LuBuilding } from 'react-icons/lu';
import { LiaFacebook } from 'react-icons/lia';
import { BiWorld } from 'react-icons/bi';
import { GoShieldCheck } from 'react-icons/go';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { MdOutlineEmail } from 'react-icons/md';
import { LuUserCheck, LuUserX } from 'react-icons/lu';
import { IoWalletOutline } from 'react-icons/io5';
import { FiBriefcase } from 'react-icons/fi';
import { GoVerified } from 'react-icons/go';
import { AiOutlineBank } from 'react-icons/ai';
import { FaRegCreditCard } from 'react-icons/fa';
import { TbUserStar } from 'react-icons/tb';

import {
    activateUserAPI,
    addRoleAPI,
    deactivateUserAPI,
    declineVerifyUserAPI,
    editUserAPI,
    ratingAPI,
    removeRoleAPI,
    userAddressesAPI,
    userDetailsAPI,
    userRequestDetailsAPI,
    userRequestsAPI,
    userRolesAPI,
    verifyUserAPI,
    verifyUserAddressAPI,
} from 'api/users';

import { IAddress, IUser } from 'common/types';
import { Trans, useTranslation } from 'react-i18next';
import { extractUsernameFromSocialLink, formatLink } from '../utils/helpers';
import { RiStarSFill } from 'react-icons/ri';
import colors from 'theme/foundations/colors';
import { AdminRequestStatus, UserRoleID, UserVerificationStatus, VerificationAdminRequest } from '../enums/users.enums';

import { AvatarProfilePicture } from 'common/images';
import VerificationModal from './verify/verificationModal';
import { IUserAdminRequests, IUserRole } from '../types';
import UserArticles from './articles/articles';
import ToggleActivationModal from './delete/activationModal';
import { formatDate, isNullOrUndefined, mapErrorCode } from 'common/helpers';
import RoleChangeModal from './promote/roleChangeModal';
import UserAddresses from './addresses/addresses';
import { removeAddressAPI } from 'api/shared';
import TopupModal from './wallet/topupModal';
import ConfirmationModal from 'components/confirmationModal';
import heic2any from 'heic2any';
import { differenceInDays } from 'date-fns';
import { userVerificationStatuses } from '../contants';
import { AdminRequestType } from 'app/reports/enums/admin.requests.enums';
import PaymentModal from './verify/paymentModal';
import PremiumModal from './premium/upgradeToPremiumModal';

const UserDetails = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    const [user, setUser] = useState<IUser>();
    const [addresses, setAddress] = useState<IAddress[]>([]);
    const [request, setRequest] = useState<IUserAdminRequests>();
    const [paymentRequest, setPaymentRequest] = useState<IUserAdminRequests>();
    const [roles, setRoles] = useState<IUserRole[]>([]);
    const [rating, setRating] = useState<number>(0);

    const [deactivateModal, setDeactivateModal] = useState<boolean>(false);
    const [activateModal, setActivateModal] = useState<boolean>(false);
    const [previewDocuments, setPreviewDocuments] = useState<boolean>(false);
    const [roleChangeModal, setRoleChangeModal] = useState<boolean>(false);
    const [topupModal, setTopupModal] = useState<boolean>(false);
    const [verifyPhoneModal, setVerifyPhoneModal] = useState<boolean>(false);
    const [paymentModal, setPaymentModal] = useState<boolean>(false);
    const [promotePremiumModal, setPromotePremiumModal] = useState<boolean>(false);

    const isAdmin = () => {
        return roles.some((item) => item.name.toLowerCase().includes('admin'));
    };

    const isHeicFile = (url: string) => {
        return url.toLowerCase().endsWith('.heic');
    };

    const hasPremium = (userData: IUser) => {
        if (userData?.premium && userData.premiumUntil) {
            const startDate = new Date();
            const endDate = new Date(userData?.premiumUntil);

            const daysDifference = differenceInDays(endDate, startDate);

            return daysDifference >= 0;
        } else return false;
    };

    const canDisplayPaymentInfo = (userData: IUser) => {
        return userData.onlinePaymentsVerificationStatus === UserVerificationStatus.NOT_INITIATED || userData.onlinePaymentsVerificationStatus === UserVerificationStatus.DECLINED;
    };

    const convertHeicToJpeg = async (url: string) => {
        const response = await fetch(url);
        const blob = await response.blob();

        let convertedBlob;
        if (Array.isArray(blob)) {
            const promises = blob.map(async (b) => await heic2any({ blob: b, toType: 'image/jpeg' }));
            convertedBlob = await Promise.all(promises);
        } else {
            convertedBlob = await heic2any({ blob, toType: 'image/jpeg' });
        }

        const firstBlob = Array.isArray(convertedBlob) ? convertedBlob[0] : convertedBlob;
        return URL.createObjectURL(Array.isArray(firstBlob) ? firstBlob[0] : firstBlob);
    };

    const latestAddress = addresses[addresses.length - 1];

    const isBusiness = () => {
        return user?.businessJIBNumber || user?.businessName || user?.businessPhone;
    };

    const hasSocials = () => {
        return user?.instagram || user?.facebook || user?.youtube;
    };

    const hasBankData = () => {
        return user?.bankName || user?.accountNumber;
    };

    const togglePreviewDocuments = () => {
        setPreviewDocuments(!previewDocuments);
    };

    const togglePremiumModal = () => {
        setPromotePremiumModal(!promotePremiumModal);
    };

    const toggleDeactivateModal = () => {
        setDeactivateModal(!deactivateModal);
    };

    const toggleActivateModal = () => {
        setActivateModal(!activateModal);
    };

    const togglePaymentModal = () => {
        setPaymentModal(!paymentModal);
    };

    const toggleRoleChangeModal = () => {
        setRoleChangeModal(!roleChangeModal);
    };

    const toggleTopupModal = () => {
        setTopupModal(!topupModal);
    };

    const toggleVerifyPhoneModal = () => {
        setVerifyPhoneModal(!verifyPhoneModal);
    };

    const confirmDelete = async () => {
        if (user) {
            try {
                await deactivateUserAPI(user.uuid);
                navigate('/users');
            } catch (err) {
                setErrorMessage(mapErrorCode(err, t));
                setError(!error);
            }
        }
    };
    const confirmActivate = async () => {
        if (user) {
            try {
                await activateUserAPI(user.uuid);
                navigate('/users');
            } catch (error) {
                console.log(error);
            }
        }
    };

    const verifyPhoneNumber = async () => {
        if (user) {
            try {
                await editUserAPI(user.uuid, { phoneVerified: !user.phoneVerified });
                navigate('/users');
            } catch (error) {
                console.log(error);
            }
        }
    };

    const confirmPromote = async () => {
        if (user) {
            try {
                await addRoleAPI(user.uuid, UserRoleID.ADMIN);
                navigate('/users');
            } catch (error) {
                console.log(error);
            }
        }
    };

    const confirmDemote = async () => {
        if (user) {
            try {
                await removeRoleAPI(user.uuid, UserRoleID.ADMIN);
                navigate('/users');
            } catch (error) {
                console.log(error);
            }
        }
    };

    const submitRoleChange = () => {
        if (isAdmin()) {
            confirmDemote();
        } else confirmPromote();
    };

    const confirmVerify = async () => {
        if (user && request) {
            try {
                await verifyUserAPI(user.uuid, request.id);
                if (!isNullOrUndefined(latestAddress) && !latestAddress.verified) {
                    await verifyUserAddressAPI(user.uuid, latestAddress.uuid, false);
                }
                navigate('/users');
            } catch (error) {
                console.log(error);
            }
        }
    };

    const declineVerify = async () => {
        if (user && request) {
            try {
                await declineVerifyUserAPI(user.uuid, request.id, '?verification=false');
                await removeAddressAPI(user.uuid, latestAddress.uuid, false);
                navigate('/users');
            } catch (error) {
                console.log(error);
            }
        }
    };

    const confirmPaymentVerify = async () => {
        if (user && paymentRequest) {
            try {
                await verifyUserAPI(user.uuid, paymentRequest.id, '?onlinePayments=true');
                navigate('/users');
            } catch (error) {
                console.log(error);
            }
        }
    };

    const declinePaymentVerify = async () => {
        if (user && paymentRequest) {
            try {
                await declineVerifyUserAPI(user.uuid, paymentRequest.id, '?onlinePayments=true');
                navigate('/users');
            } catch (error) {
                console.log(error);
            }
        }
    };

    const onConfirm = () => {
        confirmVerify();
    };

    const onDecline = () => {
        declineVerify();
    };

    const onConfirmPayment = () => {
        confirmPaymentVerify();
    };

    const onDeclinePayment = () => {
        declinePaymentVerify();
    };

    const getRating = async (uuid: string) => {
        try {
            const response = await ratingAPI(uuid);
            setRating(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getUserRoles = async (uuid: string) => {
        try {
            const response = await userRolesAPI(uuid);
            setRoles(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getVerificationRequest = async (verificationRequest: IUserAdminRequests) => {
        if (user) {
            try {
                const response = await userRequestDetailsAPI(user?.uuid, verificationRequest.id);
                setRequest(response.data);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getPaymentRequest = async (verificationRequest: IUserAdminRequests) => {
        if (user) {
            try {
                const response = await userRequestDetailsAPI(user?.uuid, verificationRequest.id);
                setPaymentRequest(response.data);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getUserAddresses = async (uuid: string) => {
        try {
            const response = await userAddressesAPI(uuid);
            setAddress(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getUserAdminRequests = async (uuid: string) => {
        try {
            const requestsResponse = await userRequestsAPI(uuid);
            const verificationRequest = requestsResponse.data.find(
                (request) => (request.type === VerificationAdminRequest.BUSINESS_VERIFY || request.type === VerificationAdminRequest.SELLER_VERIFY) && request.status === AdminRequestStatus.CREATED,
            );
            const paymentRequest = requestsResponse.data.find((request) => request.type === AdminRequestType.ONLINE_PAYMENT && request.status === AdminRequestStatus.CREATED);

            if (paymentRequest) {
                getPaymentRequest(paymentRequest);
            }

            if (verificationRequest) {
                getVerificationRequest(verificationRequest);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getUserData = async () => {
        if (id) {
            try {
                const response = await userDetailsAPI(id);
                setUser(response.data);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const onScreenLoad = () => {
        getUserData();
    };

    useEffect(() => {
        onScreenLoad();
    }, []);

    useEffect(() => {
        const loadImage = async () => {
            if (user) {
                if (isHeicFile(user?.profileImage)) {
                    try {
                        const jpegUrl = await convertHeicToJpeg(user?.profileImage);
                        setImageSrc(jpegUrl);
                    } catch (error) {
                        console.error('Error converting HEIC to JPEG', error);
                        setImageSrc(user.profileImage);
                    }
                } else {
                    setImageSrc(user.profileImage);
                }
            }
        };

        loadImage();
    }, [user?.profileImage]);

    useEffect(() => {
        if ((user && user.verificationStatus === UserVerificationStatus.PENDING) || user?.onlinePaymentsVerificationStatus === UserVerificationStatus.PENDING) {
            getUserAdminRequests(user.uuid);
        }
        if (user) {
            getUserAddresses(user.uuid);
            getUserRoles(user.uuid);
            getRating(user.uuid);
        }
    }, [user]);

    return user ? (
        <Box>
            <Breadcrumb spacing="8px" separator={<HiOutlineChevronRight color="gray.500" />} m="24px 0">
                <BreadcrumbItem>
                    <BreadcrumbLink href="/users">{t('MENU.USERS')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href="#">{user.username}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Card>
                <CardBody>
                    <HStack spacing={4}>
                        <Text fontWeight={500} fontSize="20px">
                            <Trans i18nKey={'USER'} />
                        </Text>
                        {isAdmin() && (
                            <HStack spacing={4}>
                                <Tag size={'md'} borderRadius="full" variant="solid" colorScheme="blue" color={'white'}>
                                    <TagLeftIcon as={GoShieldCheck} />
                                    <TagLabel>
                                        <Trans i18nKey={'USER.ADMIN'} />
                                    </TagLabel>
                                </Tag>
                            </HStack>
                        )}
                    </HStack>

                    <HStack spacing={'10px'} mt="10px">
                        <Image style={{ width: '68px', height: '68px', borderRadius: '50%' }} objectFit={'cover'} alt="" src={imageSrc !== '' ? imageSrc : AvatarProfilePicture} />

                        <Stack spacing={'10x'}>
                            <Text>{user.username}</Text>
                            <Text>ID: {user.uuid}</Text>
                        </Stack>
                    </HStack>
                    <Stack mt="10px">
                        <HStack spacing={'16px'} fontSize={'14px'}>
                            {addresses.length > 0 && (
                                <HStack spacing={'4px'}>
                                    <Icon as={LuBuilding} />
                                    <Stack>
                                        <Text>{`${latestAddress.address}, ${latestAddress.city}`}</Text>
                                    </Stack>
                                </HStack>
                            )}
                            {user.phone && (
                                <HStack spacing={'4px'}>
                                    <Icon as={BsTelephone} />
                                    <Text>{user.phone}</Text>
                                </HStack>
                            )}
                            {user.email && (
                                <HStack spacing={'4px'}>
                                    <Icon as={MdOutlineEmail} />
                                    <Text>{user.email}</Text>
                                </HStack>
                            )}
                        </HStack>

                        {isBusiness() && (
                            <HStack spacing={'16px'} fontSize={'14px'}>
                                {addresses.length > 0 && (
                                    <HStack spacing={'4px'}>
                                        <Icon as={LuBuilding} />
                                        <Stack>
                                            <Text>{`${t('USER.VERIFY_JIB')} ${user.businessJIBNumber}`}</Text>
                                        </Stack>
                                    </HStack>
                                )}
                                {user.phone && (
                                    <HStack spacing={'4px'}>
                                        <Icon as={FiBriefcase} />
                                        <Text>{user.businessName}</Text>
                                    </HStack>
                                )}
                                {user.email && (
                                    <HStack spacing={'4px'}>
                                        <Icon as={BsTelephone} />
                                        <Text>{user.businessPhone}</Text>
                                    </HStack>
                                )}
                            </HStack>
                        )}
                        {hasSocials() && (
                            <HStack spacing={'16px'} fontSize={'12px'}>
                                {user.instagram && (
                                    <HStack spacing={'4px'}>
                                        <Icon as={AiOutlineInstagram} />
                                        <Text as={Link} href={user.instagram} target="_blank">{`@${extractUsernameFromSocialLink(user.instagram)}`}</Text>
                                    </HStack>
                                )}
                                {user.facebook && (
                                    <HStack spacing={'4px'}>
                                        <Icon as={LiaFacebook} />
                                        <Text as={Link} href={user.facebook} target="_blank">{`@${extractUsernameFromSocialLink(user.facebook)}`}</Text>
                                    </HStack>
                                )}
                                {user.youtube && (
                                    <HStack spacing={'4px'}>
                                        <Icon as={AiOutlineYoutube} />
                                        <Text as={Link} href={user.youtube} target="_blank">{`@${extractUsernameFromSocialLink(user.youtube)}`}</Text>
                                    </HStack>
                                )}
                            </HStack>
                        )}
                        <HStack spacing={'16px'} fontSize={'12px'}>
                            {rating && (
                                <HStack spacing={'4px'} alignItems="center">
                                    <Icon as={RiStarSFill} style={{ fill: colors.brand.starFill }} />
                                    <Text>{rating}</Text>
                                </HStack>
                            )}
                            {user.website && (
                                <HStack>
                                    <Icon as={BiWorld} />
                                    <Text as={Link} href={formatLink(user.website)} target="_blank">
                                        {user.website}
                                    </Text>
                                </HStack>
                            )}
                        </HStack>

                        <HStack justifyContent={'space-between'} alignItems={'flex-end'} mb="16px">
                            {user.active ? (
                                <Stack>
                                    {user.verificationStatus === UserVerificationStatus.VERIFIED && user.seller && user.premiumUntil === null && (
                                        <Button variant={'outline'} onClick={togglePremiumModal} w="fit-content" leftIcon={<TbUserStar />}>
                                            <Text fontSize={'14px'}>
                                                <Trans i18nKey={'USER.PROMOTE_PREMIUM'} />
                                            </Text>
                                        </Button>
                                    )}
                                    <HStack>
                                        <Button variant={'outline'} borderColor={'red'} onClick={toggleDeactivateModal}>
                                            <Text fontSize={'14px'} color={'red'}>
                                                <Trans i18nKey={'USER.DEACTIVATE'} />
                                            </Text>
                                        </Button>

                                        {isAdmin() ? (
                                            <Button variant={'outline'} leftIcon={<LuUserX />} onClick={toggleRoleChangeModal}>
                                                <Text fontSize={'14px'}>
                                                    <Trans i18nKey={'USER.DEMOTE'} />
                                                </Text>
                                            </Button>
                                        ) : (
                                            <Button variant={'outline'} leftIcon={<LuUserCheck />} onClick={toggleRoleChangeModal}>
                                                <Text fontSize={'14px'}>
                                                    <Trans i18nKey={'USER.PROMOTE'} />
                                                </Text>
                                            </Button>
                                        )}
                                    </HStack>
                                    <HStack>
                                        <Button variant={'outline'} onClick={toggleVerifyPhoneModal} w={'fit-content'} leftIcon={<GoVerified />}>
                                            <Text fontSize={'14px'}>
                                                <Trans i18nKey={user.phoneVerified ? 'USER.UNVERIFY_PHONE' : 'USER.VERIFY_PHONE'} />
                                            </Text>
                                        </Button>
                                        <Button variant={'outline'} leftIcon={<IoWalletOutline />} onClick={toggleTopupModal}>
                                            <Text fontSize={'14px'}>
                                                <Trans i18nKey={'USER.TOPUP'} />
                                            </Text>
                                        </Button>
                                    </HStack>
                                </Stack>
                            ) : (
                                <Button variant={'outline'} borderColor={'brand.success'} onClick={toggleActivateModal}>
                                    <Text fontSize={'14px'} color={'brand.success'}>
                                        <Trans i18nKey={'USER.ACTIVATE'} />
                                    </Text>
                                </Button>
                            )}
                            <Stack>
                                {user.verificationStatus === UserVerificationStatus.VERIFIED && (
                                    <HStack bgColor={colors.green[10]} p="12px" alignItems={'center'} borderRadius={'9px'}>
                                        <Icon as={AiOutlineCheckCircle} fill={colors.brand.success} />
                                        <Text fontSize={'14px'} color={colors.brand.success}>
                                            <Trans i18nKey={'USER.VERIFIED'} />
                                        </Text>
                                    </HStack>
                                )}
                                {user.verificationStatus === UserVerificationStatus.PENDING && (
                                    <Button variant="dark" onClick={togglePreviewDocuments}>
                                        <Trans i18nKey={'USER.CHECK_VERIFY'} />
                                    </Button>
                                )}
                                {(user.verificationStatus === UserVerificationStatus.NOT_INITIATED || user.verificationStatus === UserVerificationStatus.DECLINED) && (
                                    <Text fontSize={'14px'} p="12px">
                                        <Trans i18nKey={'USER.UNVERIFIED'} />
                                    </Text>
                                )}
                            </Stack>
                        </HStack>
                        <Stack>
                            <Divider m="12px 0 24px 0" />
                            {user.premium && user.premiumUntil && hasPremium(user) && (
                                <Alert status="info" width={'fit-content'} maxW="542px">
                                    <AlertIcon />
                                    <HStack gap={'2px'}>
                                        <Text fontSize={'14px'}>
                                            {t('USER.PREMIUM_LABEL')
                                                .split(/(<b>.*?<\/b>)/)
                                                .map((part, index) => (
                                                    <React.Fragment key={index}>
                                                        {part.startsWith('<b>') && part.endsWith('</b>') ? <b>{part.replace('<b>', '').replace('</b>', '')}</b> : part}
                                                    </React.Fragment>
                                                ))}
                                        </Text>
                                        <Text fontSize={'14px'}>{formatDate(user.premiumUntil)}</Text>
                                    </HStack>
                                </Alert>
                            )}

                            <Text fontWeight={600} mt="16px">
                                <Trans i18nKey={'USER.ONLINE_PAYMENT_LABELS'} />
                            </Text>
                            <HStack fontSize={'14px'} spacing={'2px'} mb="-8px">
                                <Text>
                                    <Trans i18nKey={'USER.ONLINE_PAYMENT_STATUS'} />
                                </Text>
                                <Text>{t(userVerificationStatuses.find((verifStatus) => verifStatus.status === user.onlinePaymentsVerificationStatus)?.label ?? 'GENERAL.NOT_FOUND')}</Text>
                            </HStack>
                            {canDisplayPaymentInfo(user) ? (
                                <Text fontSize={'14px'} mt="8px" color="black.60">
                                    <Trans i18nKey={'USER.ONLINE_PAYMENT_UNAVAILABLE'} />
                                </Text>
                            ) : (
                                <HStack spacing={'16px'} fontSize={'14px'} justifyContent={'space-between'}>
                                    {hasBankData() && (
                                        <HStack>
                                            {user.bankName && (
                                                <HStack spacing={'4px'}>
                                                    <Icon as={AiOutlineBank} />
                                                    <Text>{user.bankName}</Text>
                                                </HStack>
                                            )}
                                            {user.accountNumber && (
                                                <HStack spacing={'4px'}>
                                                    <Icon as={FaRegCreditCard} />
                                                    <Text>{user.accountNumber}</Text>
                                                </HStack>
                                            )}
                                        </HStack>
                                    )}

                                    {user.onlinePaymentsVerificationStatus === UserVerificationStatus.VERIFIED && (
                                        <HStack bgColor={colors.green[10]} p="12px" alignItems={'center'} borderRadius={'9px'}>
                                            <Icon as={AiOutlineCheckCircle} fill={colors.brand.success} />
                                            <Text fontSize={'14px'} color={colors.brand.success}>
                                                <Trans i18nKey={'USER.PAYMENT_VERIFIED'} />
                                            </Text>
                                        </HStack>
                                    )}
                                    {user.onlinePaymentsVerificationStatus === UserVerificationStatus.PENDING && (
                                        <Button variant="dark" onClick={togglePaymentModal}>
                                            <Trans i18nKey={'USER.PAYMENT_CHECK_VERIFY'} />
                                        </Button>
                                    )}
                                </HStack>
                            )}

                            <Divider m="24px 0" />
                        </Stack>

                        <UserAddresses uuid={user.uuid} />
                        <Divider m="24px 0" />
                        <UserArticles uuid={user.uuid} />
                    </Stack>
                </CardBody>
            </Card>

            <VerificationModal isOpen={previewDocuments} toggleModal={togglePreviewDocuments} user={user} onConfirm={onConfirm} onDecline={onDecline} addresses={addresses} />
            <PaymentModal isOpen={paymentModal} toggleModal={togglePaymentModal} user={user} onConfirm={onConfirmPayment} onDecline={onDeclinePayment} />
            <ToggleActivationModal
                onSubmit={confirmDelete}
                onClose={toggleDeactivateModal}
                isOpen={deactivateModal}
                description={t('USER.DEACTIVATE_CONFIRMATION')}
                error={error}
                errorMessage={errorMessage}
            />
            <ToggleActivationModal onSubmit={confirmActivate} onClose={toggleActivateModal} isOpen={activateModal} description={t('USER.ACTIVATE_CONFIRMATION')} />
            <RoleChangeModal isOpen={roleChangeModal} onSubmit={submitRoleChange} onClose={toggleRoleChangeModal} type={isAdmin() ? 'demote' : 'promote'} username={user.username} />
            <TopupModal isOpen={topupModal} onClose={toggleTopupModal} uuid={user.uuid} />
            <PremiumModal isOpen={promotePremiumModal} onClose={togglePremiumModal} uuid={user.uuid} />

            <ConfirmationModal
                isOpen={verifyPhoneModal}
                setIsOpen={setVerifyPhoneModal}
                onConfirm={verifyPhoneNumber}
                message={t(user.phoneVerified ? 'USER.PHONE_UNVERIFY_CONFIRMATION' : 'USER.PHONE_VERIFY_CONFIRMATION')}
            />
        </Box>
    ) : null;
};

export default UserDetails;
