import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Stack, Card, CardBody, Flex, Text, Button, HStack } from '@chakra-ui/react';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { Trans } from 'react-i18next';
import { RxCross2 } from 'react-icons/rx';

import { IUserReview } from '../types';
import { deleteUserReviewAPI, userDetailsAPI, userReviewDetailsAPI, userReviewReportsAPI } from 'api/users';
import colors from 'theme/foundations/colors';
import ConfirmDeleteModal from './modals/confirmationModal';
import { IAdminRequest, IUser } from 'common/types';
import UserContactCard from 'components/userCard';

const ReviewDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState<IUserReview>();
    const [reportToReview, setReportToReview] = useState<IAdminRequest[]>([]);
    const [reporterUUIDs, setReporterUUIDs] = useState<string[]>([]);
    const [reporters, setReporters] = useState<IUser[]>([]);

    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    const redirectToReviews = () => {
        navigate('/reviews');
    };

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const deleteReview = async () => {
        if (id) {
            try {
                await deleteUserReviewAPI(id);
                redirectToReviews();
            } catch (error) {
                console.log(error);
            }
            toggleDeleteModal();
        }
    };

    const getReportUsers = async () => {
        try {
            const promises = reporterUUIDs.map(async (uuid) => {
                const response = await userDetailsAPI(uuid);
                return response.data;
            });

            const reporterIUsers = await Promise.all(promises);
            setReporters(reporterIUsers);
        } catch (error) {
            console.log(error);
        }
    };

    const getReports = async () => {
        if (data) {
            try {
                const response = await userReviewReportsAPI(data.uuid);
                if (response.data.results.length > 0) {
                    setReportToReview(response.data.results);
                    const uniqueUUIDs = Array.from(new Set(response.data.results.map((report) => report.reporterUUID)));
                    setReporterUUIDs(uniqueUUIDs);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getReviewDetails = async () => {
        if (id) {
            try {
                const response = await userReviewDetailsAPI(id);
                setData(response.data);
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        getReviewDetails();
    }, []);

    useEffect(() => {
        getReports();
    }, [data]);

    useEffect(() => {
        if (reporterUUIDs.length > 0) {
            getReportUsers();
        }
    }, [reporterUUIDs]);

    return (
        <Box>
            <Breadcrumb spacing="8px" separator={<HiOutlineChevronRight color="gray.500" />} m="24px 0">
                <BreadcrumbItem>
                    <BreadcrumbLink href="/reviews">
                        <Trans i18nKey={'MENU.REVIEW_MANAGEMENT'} />
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href="#">{id}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <Flex alignItems="flex-start" direction={{ base: 'column', md: 'row' }} gap="24px">
                <Card w="100%">
                    <CardBody>
                        <HStack>
                            <Text mb="20px">
                                <Trans i18nKey={'REVIEW.RATING'} />
                                {`: ${data?.rating}`}
                            </Text>
                        </HStack>
                        <Text mb="20px">
                            <Trans i18nKey={'REVIEW.DESCRIPTION'} />
                        </Text>
                        <Stack border={`1px solid ${colors.brand.menuBorder}`} padding={'16px'} borderRadius={'9px'}>
                            <Text>{data?.comment}</Text>
                        </Stack>
                        <Button mt="24px" variant="danger" leftIcon={<RxCross2 />} onClick={toggleDeleteModal}>
                            <Trans i18nKey={'GENERAL.DELETE'} />
                        </Button>

                        {/* add redirect to report details once done */}
                        {reportToReview.length > 0 && (
                            <Stack mt="24px">
                                <Text fontSize={'20px'} fontWeight={600}>
                                    <Trans i18nKey={'REVIEW.REPORTS'} />
                                </Text>
                                {reportToReview.map((report) => (
                                    <HStack spacing={0} key={report.id}>
                                        <Box border={`1px solid ${colors.brand.menuBorder}`} padding={'8px 16px'} w="100%">
                                            {reporters.find((reporter) => reporter.uuid === report.reporterUUID)?.username}
                                        </Box>
                                        <Box border={`1px solid ${colors.brand.menuBorder}`} padding={'8px 16px'} w="100%">
                                            {`${report.reason} - ${report.description}`}
                                        </Box>
                                    </HStack>
                                ))}
                            </Stack>
                        )}
                    </CardBody>
                </Card>

                <Stack spacing="11px" maxW={{ base: '100%', md: '357px' }} w="100%">
                    {data && <UserContactCard userUUID={data?.reviewerUserUUID} translation="REVIEW.REVIEWER" />}
                    {data && <UserContactCard userUUID={data?.revieweeUserUUID} translation="REVIEW.REVIEWEE" />}
                </Stack>
            </Flex>
            <ConfirmDeleteModal isOpen={deleteModal} setIsOpen={setDeleteModal} onConfirm={deleteReview} translation="REVIEW.DELETE_CONFIRM" />
        </Box>
    );
};

export default ReviewDetails;
