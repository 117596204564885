import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Text, Tag, TagLabel } from '@chakra-ui/react';
import { walletRequestsAPI } from 'api/wallet';
import { IWalletRequest } from 'app/wallet/types';
import { IPagination, IParams, ITableSlots } from 'common/types';

import { WalletPaymentProvider, WalletRequestStatus } from '../../enums/wallet.enums';
import { buildQueryString } from 'common/helpers';
import { Table } from 'components';
import { historyTableConfig, mapWalletHistoryTableData } from 'app/wallet/utils';
import RequestDetailsModal from '../modals/requestDetails';

interface IProps {
    updateTrigger: boolean;
    setUpdateTrigger: (value: boolean) => void;
}

interface IWalletHistoryTableSlot {
    user: string;
    uuid: string;
    actions: any;
    status: number;
}

const WalletHistory = ({ updateTrigger, setUpdateTrigger }: IProps) => {
    const [data, setData] = useState<IWalletRequest[]>([]);
    const [page, setPage] = useState<number>(1);
    const [paginator, setPaginator] = useState<Partial<IPagination>>({ perPage: 5, orderDir: 'DESC', orderBy: 'created_at' });

    const [selectedRequestUUID, setSelectedRequestUUID] = useState<string | undefined>();
    const [openDetailsModal, setOpenDetailsModal] = useState<boolean>(false);
    const [user, setUser] = useState<string | undefined>();

    const { t } = useTranslation();
    const getWalletRequests = async () => {
        try {
            const params: IParams = {
                ...paginator,
                page: page,
                status: `[${WalletRequestStatus.ACCEPTED}, ${WalletRequestStatus.DECLINED}]`,
                paymentProvider: `[${WalletPaymentProvider.MONRI}, ${WalletPaymentProvider.BANK_TRANSFER}, ${WalletPaymentProvider.ADMIN}, ${WalletPaymentProvider.IAP}, ${WalletPaymentProvider.SUBSCRIPTION}]`,
            };

            const response = await walletRequestsAPI(buildQueryString(params));
            setData(response.data.results);
            setPaginator({ ...paginator, totalPages: response.data.paginator.totalPages });
        } catch (error) {
            console.log(error);
        }
    };

    const toggleOpenDetails = () => {
        setOpenDetailsModal(!openDetailsModal);
    };

    const openRequestDetailsModal = (requestUUID: string, username: string) => {
        setUser(username);
        setSelectedRequestUUID(requestUUID);
        toggleOpenDetails();
    };

    const changePage = (newPage: number | undefined) => {
        if (newPage) {
            setPage(newPage);
        }
    };

    const slots: ITableSlots = {
        status: (item: IWalletHistoryTableSlot) => {
            return {
                content:
                    item.status === WalletRequestStatus.ACCEPTED ? (
                        <Tag size={'lg'} borderRadius="full" variant="solid" backgroundColor={'green.100'} color={'green.500'}>
                            <TagLabel fontSize="14px" fontWeight={600}>
                                <Trans i18nKey={'WALLET.STATUS_ACCEPTED'} />
                            </TagLabel>
                        </Tag>
                    ) : (
                        <Tag size={'lg'} borderRadius="full" variant="solid" backgroundColor={'red.100'} color={'red.500'} fontWeight={600}>
                            <TagLabel fontSize="14px">
                                <Trans i18nKey={'WALLET.STATUS_DECLINED'} />
                            </TagLabel>
                        </Tag>
                    ),
                sortableValue: undefined,
            };
        },
        actions: (item: IWalletHistoryTableSlot) => {
            return {
                content: (
                    <Button variant={'outline'} onClick={() => openRequestDetailsModal(item.uuid, item.user)}>
                        <Trans i18nKey={'GENERAL.OPEN'} />
                    </Button>
                ),
                sortableValue: undefined,
            };
        },
    };

    useEffect(() => {
        getWalletRequests();
    }, [page, updateTrigger]);

    return (
        <Box>
            <Text m="16px 0 24px 0">
                <Trans i18nKey={'WALLET.HISTORY_DESC'} />
            </Text>
            <Table data={mapWalletHistoryTableData(data, t)} config={historyTableConfig(t)} pagination={paginator} page={page} onPaging={changePage} slots={slots} />
            <RequestDetailsModal
                isOpen={openDetailsModal}
                setIsOpen={setOpenDetailsModal}
                uuid={selectedRequestUUID}
                setUUID={setSelectedRequestUUID}
                setUpdateTrigger={setUpdateTrigger}
                updateTrigger={updateTrigger}
                user={user}
            />
        </Box>
    );
};

export default WalletHistory;
