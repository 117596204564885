import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Text, Button, HStack, Stack, Image } from '@chakra-ui/react';

import { rejectWalletRequestAPI, walletQuotaDetailsAPI, walletRequestDetailsAPI } from 'api/wallet';
import { IWalletQuota, IWalletRequest } from 'app/wallet/types';

import LoadingModal from 'components/loading';
import { WalletPaymentProvider, WalletRequestStatus } from 'app/wallet/enums/wallet.enums';
import ConfirmationModal from 'components/confirmationModal';
import { DecimalInput } from 'components';
import { userWalletTopup } from 'api/users';

interface IProps {
    isOpen: boolean;
    uuid?: string;
    updateTrigger?: boolean;
    user?: string;

    setIsOpen: (value: boolean) => void;
    setUUID?: (value: string | undefined) => void;
    setUpdateTrigger?: (value: boolean) => void;
}
const RequestDetailsModal = ({ isOpen, setIsOpen, uuid, setUUID, setUpdateTrigger, updateTrigger, user }: IProps) => {
    const { t } = useTranslation();

    const [data, setData] = useState<IWalletRequest | undefined>();
    const [quota, setQuota] = useState<IWalletQuota | undefined>();
    const [price, setPrice] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);

    const [openAcceptModal, setOpenAcceptModal] = useState<boolean>(false);
    const [openRejectModal, setOpenRejectModal] = useState<boolean>(false);

    const handleClose = () => {
        if (setUUID) {
            setUUID(undefined);
            setData(undefined);
            setQuota(undefined);
        }
        setPrice('');
        setOpenAcceptModal(false);
        setOpenRejectModal(false);
        setIsOpen(!isOpen);
    };

    const isBankTransfer = () => {
        return data?.paymentProvider === WalletPaymentProvider.BANK_TRANSFER;
    };

    const isPending = () => {
        return data?.status === WalletRequestStatus.PENDING;
    };

    const updateData = () => {
        if (setUpdateTrigger) {
            setUpdateTrigger(!updateTrigger);
        }
    };

    const acceptRequest = async () => {
        try {
            if (data?.userUUID && data.uuid) {
                const priceInCents = Math.round(parseFloat(price || '0') * 100);
                await userWalletTopup(data.userUUID, { price: priceInCents, walletOrderUUID: data.uuid });
            }
            updateData();
            handleClose();
        } catch (error) {
            console.log(error);
        }
    };

    const declineRequest = async () => {
        try {
            await rejectWalletRequestAPI(uuid ?? '');
            if (setUpdateTrigger) {
                setUpdateTrigger(!updateTrigger);
            }
            handleClose();
        } catch (error) {
            console.log(error);
        }
    };

    const getQuotaDetails = async (id: number) => {
        try {
            const response = await walletQuotaDetailsAPI(id);
            setQuota(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    const getRequestDetails = async (uuid: string) => {
        try {
            setLoading(true);
            const response = await walletRequestDetailsAPI(uuid);
            if (response.data.paymentProvider !== WalletPaymentProvider.BANK_TRANSFER && response.data.paymentProvider !== WalletPaymentProvider.ADMIN)
                await getQuotaDetails(response.data.walletQuotaID);
            setData(response.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (uuid && isOpen) {
            getRequestDetails(uuid);
        }
    }, [isOpen, uuid]);

    return (
        <>
            {loading ? (
                <LoadingModal isOpen={loading} setIsOpen={setLoading} />
            ) : (
                <Modal isOpen={isOpen} onClose={handleClose} isCentered size="lg">
                    <ModalOverlay />
                    <ModalContent bgColor="white">
                        <ModalCloseButton />
                        <ModalBody>
                            <Stack>
                                <Stack p="16px 0" spacing={'16px'}>
                                    <Text fontSize={'20px'} fontWeight={600}>
                                        <Trans i18nKey={'WALLET.OPEN'} />
                                    </Text>
                                    {quota && (
                                        <HStack>
                                            <Text>
                                                <Trans i18nKey={'WALLET.PURCHASED_QUOTA'} />
                                            </Text>
                                            <Text fontWeight={600}>{`${quota?.price / 100} ${t('QUOTA.CURRENCY_PLACEHOLDER')}`}</Text>
                                        </HStack>
                                    )}
                                    {data?.price && (
                                        <HStack>
                                            <Text>
                                                <Trans i18nKey={'WALLET.TOPUP_LABEL'} />
                                            </Text>
                                            <Text fontWeight={600}>{`${data.price / 100} ${t('QUOTA.CURRENCY_PLACEHOLDER')}`}</Text>
                                        </HStack>
                                    )}
                                    <Text>
                                        <Trans i18nKey={`WALLET.PAYMENT_PROVIDER_${data?.paymentProvider}`} />
                                    </Text>
                                    <HStack>
                                        <Text>
                                            <Trans i18nKey={'WALLET.USER_LABEL'} />
                                        </Text>
                                        <Text>{user}</Text>
                                    </HStack>

                                    {data?.image && <Image maxHeight={'300px'} objectFit={'contain'} src={data?.image} />}
                                </Stack>

                                {isBankTransfer() && isPending() && (
                                    <Stack gap="4px" w="fit-content">
                                        <Text w="75%">
                                            <Trans i18nKey={'USER.TOPUP_LABEL'} />
                                        </Text>
                                        <DecimalInput placeholder={t('USER.TOPUP_PLACEHOLDER')} value={price} onChange={(newValue) => setPrice(newValue)} />
                                    </Stack>
                                )}

                                {isPending() && (
                                    <HStack m="32px 0 16px 0">
                                        <Button variant="outline" onClick={() => setOpenRejectModal(!openRejectModal)}>
                                            <Trans i18nKey={'GENERAL.DECLINE'} />
                                        </Button>
                                        <Button onClick={() => setOpenAcceptModal(!openAcceptModal)} backgroundColor={'brand.success'} color={'white'} fontSize={'14px'}>
                                            <Trans i18nKey={'WALLET.ACCEPT_TOP_UP'} />
                                        </Button>
                                    </HStack>
                                )}
                            </Stack>
                        </ModalBody>
                    </ModalContent>
                    <ConfirmationModal isOpen={openAcceptModal} setIsOpen={setOpenAcceptModal} onConfirm={acceptRequest} message={t('WALLET.REQ_ACCEPT_CONFIRM')} />
                    <ConfirmationModal isOpen={openRejectModal} setIsOpen={setOpenRejectModal} onConfirm={declineRequest} message={t('WALLET.REQ_REJECT_CONFIRM')} />
                </Modal>
            )}
        </>
    );
};

export default RequestDetailsModal;
