import Articles from 'app/articles/components/articles';
import ArticleDetails from 'app/articles/components/[id]';
import Login from 'app/login/components/login';
import Users from 'app/users/components/users';
import Forbidden from 'app/forbidden/components/forbidden';

import { IRoute } from 'common/types';
import Orders from 'app/orders/components/orders';
import Marketing from 'app/marketing/components/marketing';
import ReviewManagement from 'app/reviewManagement/components/reviewManagement';
import Categories from 'app/category/components/categories';
import NotFound from 'app/notFound/components/notFound';
import UserDetails from 'app/users/components/[id]';
import OrderDetails from 'app/orders/components/[id]';
import BannerDetails from 'app/marketing/components/[id]';
import ReviewDetails from 'app/reviewManagement/components/[id]';
import CreateCategory from 'app/category/components/create';
import Category from 'app/category/components/[id]';
import Reports from 'app/reports/components/reports';
import ReportDetails from 'app/reports/components/[id]';
import Requests from 'app/requests/components/requests';
import RequestDetails from 'app/requests/components/[id]';
import VerifyMFA from 'app/login/components/verifyMFA';
import Wallet from 'app/wallet/components/wallet';
import Subscriptions from 'app/subscriptions/components/subscriptions';
import SubscriptionDetails from 'app/subscriptions/components/[id]';

export interface IRoutes {
    [key: string]: IRoute;
}

const prefixes = {
    articles: 'articles',
    articleDetails: 'articleDetails',
    users: 'users',
    userDetails: 'userDetails',
    orders: 'orders',
    marketing: 'marketing',
    reviewManagement: 'reviewManagement',
    reports: 'reports',
    login: 'login',
    verify: 'verify',
    forbidden: 'forbidden',
    notFound: 'notFound',
    categories: 'categories',
    categoriesNew: 'categoriesNew',
    categoryDetails: 'categoriesDetials',
    orderDetails: 'orderDetails',
    bannerDetails: 'bannerDetails',
    reviewDetails: 'reviewDetails',
    reportsDetail: 'reportsDetails',
    requests: 'requests',
    requestDetails: 'requestDetails',
    wallet: 'wallet',
    subscriptions: 'subscriptions',
    subscriptionDetails: 'subscriptionDetails',
};

// possible routes inside the app
const routes: IRoutes = {
    [prefixes.orders]: {
        path: '/',
        prefix: prefixes.orders,
        component: Orders,
        is_protected: true,
        is_in_sidebar: true,
        menu_key: 'MENU.ORDERS',
    },
    [prefixes.orderDetails]: {
        path: '/orders/:id',
        prefix: prefixes.orders,
        component: OrderDetails,
        is_protected: true,
        is_in_sidebar: false,
    },
    [prefixes.articles]: {
        path: '/articles',
        prefix: prefixes.articles,
        component: Articles,
        is_protected: true,
        is_in_sidebar: true,
        menu_key: 'MENU.ARTICLES',
    },
    [prefixes.articleDetails]: {
        path: '/articles/:id',
        prefix: prefixes.articles,
        component: ArticleDetails,
        is_protected: true,
        is_in_sidebar: false,
    },
    [prefixes.wallet]: {
        path: '/wallet',
        prefix: prefixes.wallet,
        component: Wallet,
        is_protected: true,
        is_in_sidebar: true,
        menu_key: 'MENU.WALLET',
    },
    [prefixes.users]: {
        path: '/users',
        prefix: prefixes.users,
        component: Users,
        is_protected: true,
        is_in_sidebar: true,
        menu_key: 'MENU.USERS',
    },
    [prefixes.userDetails]: {
        path: '/users/:id',
        prefix: prefixes.users,
        component: UserDetails,
        is_protected: true,
        is_in_sidebar: false,
    },
    [prefixes.marketing]: {
        path: '/marketing',
        prefix: prefixes.marketing,
        component: Marketing,
        is_protected: true,
        is_in_sidebar: true,
        menu_key: 'MENU.MARKETING',
    },
    [prefixes.bannerDetails]: {
        path: '/marketing/:id',
        prefix: prefixes.marketing,
        component: BannerDetails,
        is_protected: true,
        is_in_sidebar: false,
        menu_key: 'MENU.MARKETING',
    },
    [prefixes.reviewManagement]: {
        path: '/reviews',
        prefix: prefixes.reviewManagement,
        component: ReviewManagement,
        is_protected: true,
        is_in_sidebar: true,
        menu_key: 'MENU.REVIEW_MANAGEMENT',
    },
    [prefixes.reviewDetails]: {
        path: '/reviews/:id',
        prefix: prefixes.reviewManagement,
        component: ReviewDetails,
        is_protected: true,
        is_in_sidebar: false,
        menu_key: 'MENU.REVIEW_MANAGEMENT',
    },
    [prefixes.reports]: {
        path: '/reports',
        prefix: prefixes.reports,
        component: Reports,
        is_protected: true,
        is_in_sidebar: true,
        menu_key: 'MENU.REPORTS',
    },
    [prefixes.reportsDetail]: {
        path: '/reports/:id',
        prefix: prefixes.reports,
        component: ReportDetails,
        is_protected: true,
        menu_key: 'MENU.REPORTS',
    },
    [prefixes.requests]: {
        path: '/requests',
        prefix: prefixes.requests,
        component: Requests,
        is_protected: true,
        is_in_sidebar: true,
        menu_key: 'MENU.REQUESTS',
    },

    [prefixes.requestDetails]: {
        path: '/requests/:id',
        prefix: prefixes.requests,
        component: RequestDetails,
        is_protected: true,
        menu_key: 'MENU.REQUESTS',
    },
    [prefixes.subscriptions]: {
        path: '/subscriptions',
        prefix: prefixes.subscriptions,
        component: Subscriptions,
        is_protected: true,
        is_in_sidebar: true,
        menu_key: 'MENU.SUBSCRIPTIONS',
    },
    [prefixes.subscriptionDetails]: {
        path: '/subscriptions/:id',
        prefix: prefixes.subscriptions,
        component: SubscriptionDetails,
        is_protected: true,
        menu_key: 'MENU.SUBSCRIPTIONS',
    },
    [prefixes.categories]: {
        path: '/categories',
        prefix: prefixes.categories,
        component: Categories,
        is_protected: true,
        is_in_sidebar: true,
        menu_key: 'MENU.CATEGORIES',
    },
    [prefixes.categoriesNew]: {
        path: '/categories/new',
        prefix: prefixes.categories,
        component: CreateCategory,
        is_protected: true,
        is_in_sidebar: false,
        menu_key: 'MENU.CATEGORIES',
    },
    [prefixes.categoryDetails]: {
        path: '/categories/:id',
        prefix: prefixes.categories,
        component: Category,
        is_protected: true,
        is_in_sidebar: false,
    },
    [prefixes.login]: {
        path: '/login',
        prefix: prefixes.login,
        component: Login,
        is_protected: false,
        is_in_sidebar: false,
    },
    [prefixes.verify]: {
        path: '/login/mfa',
        prefix: prefixes.verify,
        component: VerifyMFA,
        is_protected: false,
        is_in_sidebar: false,
    },
    [prefixes.forbidden]: {
        path: '/403',
        prefix: prefixes.forbidden,
        component: Forbidden,
        is_protected: false,
        is_in_sidebar: false,
    },
    [prefixes.notFound]: {
        path: '*',
        prefix: prefixes.notFound,
        component: NotFound,
        is_protected: false,
        is_in_sidebar: false,
    },
};

export default {
    routes,
    prefixes,
};
