import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Menu, MenuButton, MenuList, MenuItem, Text, Center } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { HiOutlineChevronDown } from 'react-icons/hi';

import { Table } from 'components';
import { articlesAPI } from 'api/articles';
import { allCategoriesAPI } from 'api/categories';
import { buildQueryString } from 'common/helpers';
import { IPagination } from 'common/types';
import { IArticle, IArticleCategory, IArticlesParams } from 'app/articles/types';
import { articlesTableConfig, isCategoryEqual, mapArticlesTableData } from 'app/articles/utils/helpers';

interface IProps {
    uuid: string;
}

const UserArticles = ({ uuid }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [data, setData] = useState<IArticle[]>([]);
    const [categories, setCategories] = useState<IArticleCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<IArticleCategory | null>(null);

    const [page, setPage] = useState<number>(1);
    const [paginator, setPaginator] = useState<Partial<IPagination>>({
        perPage: 10,
        published: true,
    });

    const redirectToDetails = (item: IArticle) => {
        navigate(`/articles/${item.uuid}`);
    };

    const categoryPlaceholder = () => {
        return selectedCategory ? `${t('ARTICLE.CATEGORY')}: ${selectedCategory.translations?.bs}` : `${t('ARTICLE.CATEGORY')}`;
    };

    const toggleSelectedCategory = (category: IArticleCategory | undefined) => {
        if (category) {
            if (isCategoryEqual(category, selectedCategory)) {
                setPage(1);
                setSelectedCategory(null);
            } else setSelectedCategory(category);
        }
    };

    const changePage = (newPage: number | undefined) => {
        if (newPage) {
            setPage(newPage);
        }
    };

    const getArticles = async () => {
        try {
            const params: IArticlesParams = {
                ...paginator,
                page: page,
                userUUIDFilter: uuid,
            };

            if (selectedCategory) {
                params.categoryIDFilter = selectedCategory.id;
            } else {
                delete params.categoryIDFilter;
            }

            const queryString = buildQueryString(params);

            const res = await articlesAPI(queryString);
            setData(res.data.results);
            setPaginator({ ...paginator, totalPages: res.data.paginator.totalPages });
        } catch (error) {
            console.error(error);
        }
    };

    const getCategories = async () => {
        try {
            const res = await allCategoriesAPI(buildQueryString({ perPage: Number.MAX_SAFE_INTEGER }));
            setCategories(res.data.results);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getArticles();
    }, [page, selectedCategory]);

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <Box>
            <Box mb="8px">
                <Menu>
                    <MenuButton bg="white" fontSize="16px" fontWeight="normal" as={Button} rightIcon={<HiOutlineChevronDown />}>
                        {categoryPlaceholder()}
                    </MenuButton>
                    <MenuList
                        minW={['100%', '500px', '500px']}
                        maxHeight={'300px'}
                        overflowX={'scroll'}
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {categories.map((category) => (
                            <MenuItem onClick={() => toggleSelectedCategory(category)} key={category?.id}>
                                {category?.translations.bs}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>
            {data.length > 0 ? (
                <Table
                    paginationPosition="relative"
                    config={articlesTableConfig(t)}
                    data={mapArticlesTableData(data, categories)}
                    onRowClick={redirectToDetails}
                    page={page}
                    pagination={paginator}
                    onPaging={changePage}
                />
            ) : (
                <Center>
                    <Text>
                        <Trans i18nKey={'ARTICLES.EMPTY'} />
                    </Text>
                </Center>
            )}
        </Box>
    );
};

export default UserArticles;
