import { TFunction } from 'i18next';
import { IWalletQuota, IWalletRequest } from '../types';
import { WalletQuotaRequestDTO } from 'api/wallet/types/request';

export const tableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'user',
            label: t('WALLET.USER'),
            sortable: true,
        },
        {
            key: 'uuid',
            label: t('WALLET.ID'),
            sortable: true,
        },

        {
            key: 'actions',
            label: t('WALLET.ACTIONS'),
            sortable: false,
        },
    ];
};

export const mapWalletReqTableData = (data: IWalletRequest[], t: TFunction<'translation', undefined>) => {
    const tableData = data.map((req) => ({
        user: req.user?.username ?? t('USER.NOT_FOUND'),
        uuid: req.uuid,
    }));
    return tableData;
};

export const historyTableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'user',
            label: t('WALLET.USER'),
            sortable: true,
        },
        {
            key: 'uuid',
            label: t('WALLET.ID'),
            sortable: true,
        },
        {
            key: 'status',
            label: t('WALLET.STATUS'),
            sortable: false,
        },
        {
            key: 'actions',
            label: t('WALLET.ACTIONS'),
            sortable: false,
        },
    ];
};

export const mapWalletHistoryTableData = (data: IWalletRequest[], t: TFunction<'translation', undefined>) => {
    const tableData = data.map((req) => ({
        user: req.user?.username ?? t('USER.NOT_FOUND'),
        status: req.status,
        uuid: req.uuid,
    }));
    return tableData;
};

export const quotaTableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'id',
            label: t('WALLET.ID_NUM'),
            sortable: true,
        },
        {
            key: 'price',
            label: t('WALLET.PRICE'),
            sortable: true,
        },
        {
            key: 'actions',
            label: t('WALLET.ACTIONS'),
            sortable: false,
        },
    ];
};

export const mapQuotaTableData = (data: IWalletQuota[], t: TFunction<'translation', undefined>) => {
    const tableData = data.map((quota) => ({
        id: quota.id,
        price: `${quota.price / 100} ${t('QUOTA.CURRENCY_PLACEHOLDER')}`,
    }));
    return tableData;
};

export const mapToWalletQuotaRequest = (data: IWalletQuota): WalletQuotaRequestDTO => {
    const { currency, price, externalPackageID } = data;

    return {
        currency,
        price,
        externalPackageID,
    };
};
