import React from 'react';
import { Radio as ChakraRadio } from '@chakra-ui/react';

interface IProps {
    value: string;
    checkedColor: string;
    label?: string;
    onClick?: () => void;
}

const Radio: React.FC<IProps> = ({ value, checkedColor, label }) => {
    return (
        <ChakraRadio
            value={value}
            sx={{
                '&[aria-checked=true], &[data-checked]': {
                    background: 'white.100',
                    borderColor: 'brand.menuBorder',
                    color: checkedColor,
                },
                '&:not([aria-checked=true]):not([data-checked])': {
                    '*': {
                        borderColor: 'brand.menuBorder',
                    },
                },
            }}
        >
            {label}
        </ChakraRadio>
    );
};

export default Radio;
