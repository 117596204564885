import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle({
    borderRadius: '6px',
    color: 'brand.outlienButtonColor',
    borderColor: 'brand.outlineButtonBorder',
    backgroundColor: 'white',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '0px 16px',
});

const danger = defineStyle({
    borderRadius: '6px',
    color: 'white',
    borderColor: 'transaprent',
    backgroundColor: 'brand.danger',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '0px 16px',
});

const dark = defineStyle({
    borderRadius: '6px',
    color: 'white',
    borderColor: 'transaprent',
    backgroundColor: 'brand.darkButtonBackground',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '0px 16px',
});

const solid = defineStyle({
    borderRadius: '6px',
});

export const buttonTheme = defineStyleConfig({
    variants: { outline, solid, danger, dark },
});
