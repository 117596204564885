import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Select, Stack, Text } from '@chakra-ui/react';

import { IArticleCategory } from 'app/articles/types';
import { Input, DecimalInput } from 'components';

import { IBannerQuota } from 'app/marketing/types';
import { BannerQuotaRequestDTO } from 'api/marketing/types/request';
import { quotaPackageOptions } from 'app/marketing/constants';
import { createBannerQuotaAPI, editBannerQuotaAPI } from 'api/marketing';

import { arePropsUndefined } from 'common/helpers';
import { mapToBannerQuotaRequestDTO, weeksAndDays } from 'app/marketing/helpers';

interface IProps {
    categories: IArticleCategory[];
    toggleCreateModal: () => void;
    modalOpen: boolean;
    entryCreated: boolean;
    setEntryCreated: (value: boolean) => void;
    selectedQuota?: IBannerQuota;
    setSelectedQuota?: Dispatch<SetStateAction<IBannerQuota | undefined>> | undefined;
}
const CreateQuotaModal = ({ categories, toggleCreateModal, modalOpen, setEntryCreated, entryCreated, selectedQuota, setSelectedQuota }: IProps) => {
    const { t } = useTranslation();
    const [price, setPrice] = useState<string>('0');
    const [discountPrice, setDiscountPrice] = useState<string>('0');
    const [quotaData, setQuotaData] = useState<Partial<BannerQuotaRequestDTO> | undefined>(selectedQuota ? mapToBannerQuotaRequestDTO(selectedQuota) : undefined);

    const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue: number = Number(e.target.value);
        setQuotaData({ ...quotaData, categoryID: selectedValue });
    };

    const handlePackageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue: number = Number(e.target.value);
        setQuotaData({ ...quotaData, packageID: selectedValue });
    };

    const isSaveButtonDisabled = arePropsUndefined({
        categoryID: quotaData?.categoryID,
        packageID: quotaData?.packageID,
        price: price,
        discountPrice: discountPrice,
        durationDays: quotaData?.durationDays,
    });

    const onClose = () => {
        if (selectedQuota && setSelectedQuota) {
            setSelectedQuota(undefined);
        }
        setQuotaData(undefined);
        toggleCreateModal();
    };

    const closeOnSuccess = () => {
        setPrice('0');
        setDiscountPrice('0');
        setEntryCreated(!entryCreated);
        onClose();
    };

    const createNewQuota = async () => {
        try {
            const priceInCents = Math.round(parseFloat(price || '0') * 100);
            const discountedPriceInCents = Math.round(parseFloat(discountPrice || '0') * 100);

            await createBannerQuotaAPI({
                ...quotaData,
                currency: 'BAM',
                discountedPrice: discountedPriceInCents,
                price: priceInCents,
                durationDays: weeksAndDays(quotaData?.durationDays ?? 1).remainingDays,
                durationWeeks: weeksAndDays(quotaData?.durationDays ?? 1).weeks,
            });
            closeOnSuccess();
        } catch (error) {
            console.log(error);
        }
    };

    const editQuota = async () => {
        if (selectedQuota) {
            try {
                const priceInCents = Math.round(parseFloat(price || '0') * 100);
                const discountedPriceInCents = Math.round(parseFloat(discountPrice || '0') * 100);

                await editBannerQuotaAPI(
                    {
                        ...quotaData,
                        currency: 'BAM',
                        discountedPrice: discountedPriceInCents,
                        price: priceInCents,
                        durationDays: weeksAndDays(quotaData?.durationDays ?? 1).remainingDays,
                        durationWeeks: weeksAndDays(quotaData?.durationDays ?? 1).weeks,
                    },
                    selectedQuota?.id.toString() ?? '1',
                );
                closeOnSuccess();
            } catch (error) {
                console.log(error);
            }
        }
    };

    const onSubmit = () => {
        if (selectedQuota) {
            editQuota();
        } else {
            createNewQuota();
        }
    };

    useEffect(() => {
        if (selectedQuota) {
            setQuotaData({ ...selectedQuota, price: selectedQuota.price / 100, discountedPrice: selectedQuota.discountedPrice / 100 });

            const formattedPrice = selectedQuota.price / 100;
            const formattedDiscountPrice = selectedQuota.discountedPrice / 100;
            setPrice(formattedPrice.toString());
            setDiscountPrice(formattedDiscountPrice.toString());
        }
    }, [modalOpen]);

    return (
        <Box>
            <Modal isOpen={modalOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bgColor={'white'}>
                    <ModalHeader>
                        <Trans i18nKey={selectedQuota ? 'QUOTAS.EDIT' : 'QUOTAS.ADD'} />
                    </ModalHeader>
                    <Text p="0 24px">
                        <Trans i18nKey={'QUOTA.DESCRIPTION'} />
                    </Text>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack gap="16px" mt="16px">
                            <Stack gap="4px">
                                <Text>
                                    <Trans i18nKey={'QUOTA.CATEGORY_LABEL'} />
                                </Text>
                                <Select placeholder={t('CATEGORY.CHOOSE')} onChange={handleCategoryChange} value={quotaData?.categoryID}>
                                    {categories.map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.translations.bs}
                                        </option>
                                    ))}
                                </Select>
                            </Stack>

                            <Stack gap="4px">
                                <Text>
                                    <Trans i18nKey={'QUOTA.PACKAGE_LABEL'} />
                                </Text>
                                <Select placeholder={t('QUOTA.PACKAGE_CHOOSE')} onChange={handlePackageChange} value={quotaData?.packageID}>
                                    {quotaPackageOptions.map((packageOption) => (
                                        <option key={packageOption.id} value={packageOption.id}>
                                            {t(packageOption.label)}
                                        </option>
                                    ))}
                                </Select>
                            </Stack>

                            <Stack gap="4px">
                                <Text>
                                    <Trans i18nKey={'QUOTA.PRICE_LABEL'} />
                                </Text>
                                <DecimalInput placeholder={t('QUOTA.PRICE_PLACEHOLDER')} value={price} onChange={(newValue) => setPrice(newValue)} />
                            </Stack>

                            <Stack gap="4px">
                                <Text>
                                    <Trans i18nKey={'QUOTA.DISCOUNT_LABEL'} />
                                </Text>
                                <DecimalInput placeholder={t('QUOTA.PRICE_PLACEHOLDER')} value={discountPrice} onChange={(newValue) => setDiscountPrice(newValue)} />
                            </Stack>

                            <Stack gap="4px">
                                <Text>
                                    <Trans i18nKey={'QUOTA.DAYS_LABEL'} />
                                </Text>
                                <Input
                                    placeholder={t('QUOTA.DAYS_PLACEHOLDER')}
                                    name="numbeerOfDays"
                                    type="number"
                                    value={quotaData?.durationDays}
                                    onChange={(e) => setQuotaData({ ...quotaData, durationDays: parseInt(e.currentTarget.value) })}
                                />
                            </Stack>

                            <Stack gap="4px">
                                <Text>
                                    <Trans i18nKey={'QUOTA.CURRENCY_LABEL'} />
                                </Text>
                                <Select placeholder={t('QUOTA.CURRENCY_PLACEHOLDER')} disabled></Select>
                            </Stack>
                        </Stack>
                    </ModalBody>

                    <ModalFooter justifyContent={'flex-start'}>
                        <Button variant="dark" mr={3} onClick={onSubmit} isDisabled={isSaveButtonDisabled}>
                            <Trans i18nKey={'GENERAL.SAVE'} />
                        </Button>
                        <Button variant="outline" onClick={onClose}>
                            <Trans i18nKey={'GENERAL.CANCEL'} />
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default CreateQuotaModal;
