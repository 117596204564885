import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Button, HStack, Stack, Text } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
    type: 'accept' | 'decline';
}

const ConfirmationModal = ({ isOpen, onClose, onSubmit, type }: IProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent bgColor={'white'}>
                <ModalCloseButton />
                <ModalBody>
                    <Stack p="16px 0" spacing={'16px'}>
                        <Text fontSize={'20px'} fontWeight={600}>
                            <Trans i18nKey={type === 'accept' ? 'USER.VERIFY_CONFIRMATION' : 'USER.DECLINE_VERIFY_CONFIRMATION'} />
                        </Text>
                    </Stack>

                    <HStack mt="32px">
                        <Button backgroundColor={'black.100'} color="white" mb="16px" w="100%" onClick={onSubmit}>
                            <Trans i18nKey={'GENERAL.YES'} />
                        </Button>
                        <Button mb="16px" w="100%" onClick={onClose}>
                            <Trans i18nKey={'GENERAL.NO'} />
                        </Button>
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmationModal;
