import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { BsImages } from 'react-icons/bs';
import { FaMagnifyingGlass, FaRegTrashCan } from 'react-icons/fa6';

import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Stack, Card, CardBody, Flex, Text, Button, Divider, HStack, Tag, TagLabel, Image } from '@chakra-ui/react';

import ConfirmationModal from 'components/confirmationModal';
import UserContactCard from 'components/userCard';

import { addressDetailsAPI, deleteReportAPI, removeAddressAPI, reportDetailsAPI, updateReportAPI } from 'api/shared';
import { IAddress, IAdminRequest } from 'common/types';
import { AdminRequestType } from 'app/reports/enums/admin.requests.enums';
import { adminRequestStatuses, adminRequestTypes } from 'app/reports/constants';
import { AdminRequestStatus } from 'app/users/enums/users.enums';
import colors from 'theme/foundations/colors';
import ImageModal from 'app/users/components/verify/imageModal';
import { verifyUserAddressAPI } from 'api/users';

const RequestDetails = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [data, setData] = useState<IAdminRequest>();
    const [address, setAddress] = useState<IAddress>();
    const [selectedImage, setSelectedImage] = useState<string>('');

    const [openImage, setOpenImage] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [acceptModal, setAcceptModal] = useState<boolean>(false);
    const [declineModal, setDeclineModal] = useState<boolean>(false);

    const isVerifyingUser = () => {
        return data?.type === AdminRequestType.SELLER_VERIFY || data?.type === AdminRequestType.BUSINESS_VERIFY || data?.type === AdminRequestType.ONLINE_PAYMENT;
    };

    const isVerifyingBanner = () => {
        return data?.type === AdminRequestType.BANNER_REQUESTED;
    };

    const isVerifyingAddress = () => {
        return data?.status === AdminRequestStatus.CREATED && data?.type === AdminRequestType.ADDRESS;
    };

    const redirectToReports = () => {
        navigate('/requests');
    };

    const redirectToUser = (uuid: string) => {
        navigate(`/users/${uuid}`);
    };

    const redirectToBanner = (id: string) => {
        navigate(`/marketing/${id}`);
    };

    const redirectToReference = (uuid: string, type: number) => {
        if (type === AdminRequestType.BUSINESS_VERIFY || type === AdminRequestType.SELLER_VERIFY || type === AdminRequestType.ONLINE_PAYMENT) {
            redirectToUser(uuid);
        } else {
            redirectToBanner(uuid);
        }
    };

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const toggleAcceptModal = () => {
        setAcceptModal(!acceptModal);
    };

    const toggleDeclineModal = () => {
        setDeclineModal(!declineModal);
    };

    const toggleImageModal = () => {
        setOpenImage(!openImage);
    };

    const openImageInModal = (uri: string) => {
        setSelectedImage(uri);
        toggleImageModal();
    };

    const getColorBasedOnStatus = () => {
        if (data) {
            switch (data.status) {
                case AdminRequestStatus.ACCEPTED:
                    return colors.brand.success;
                case AdminRequestStatus.DECLINED:
                    return colors.brand.danger;
                case AdminRequestStatus.CREATED:
                    return colors.brand.darkButtonBackground;
                default:
                    return colors.brand.darkButtonBackground;
            }
        }
    };

    const acceptRequest = async () => {
        if (id) {
            try {
                await updateReportAPI(parseInt(id), AdminRequestStatus.ACCEPTED);
                if (address) {
                    await verifyUserAddressAPI(address.userUUID, address.uuid, true);
                }
                toggleAcceptModal();
                redirectToReports();
            } catch (error) {
                console.log(error);
            }
        }
    };

    const declineRequest = async () => {
        if (id) {
            try {
                await updateReportAPI(parseInt(id), AdminRequestStatus.DECLINED);

                if (isVerifyingAddress() && address?.userUUID && address.uuid) {
                    await removeAddressAPI(address?.userUUID, address?.uuid, true);
                }

                toggleDeclineModal();
                redirectToReports();
            } catch (error) {
                console.log(error);
            }
        }
    };

    const deleteRequest = async () => {
        if (id) {
            try {
                await deleteReportAPI(parseInt(id));
                toggleDeleteModal();
                redirectToReports();
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getRequestDetails = async () => {
        try {
            if (id) {
                const response = await reportDetailsAPI(parseInt(id));
                setData(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getAddress = async (uuid: string) => {
        try {
            const response = await addressDetailsAPI(uuid);
            setAddress(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getRequestDetails();
    }, []);

    useEffect(() => {
        if (data?.type === AdminRequestType.ADDRESS && data?.referenceUUID) {
            getAddress(data.referenceUUID);
        }
    }, [data]);

    return data ? (
        <Box>
            <Breadcrumb spacing="8px" separator={<HiOutlineChevronRight color="gray.500" />} m="24px 0">
                <BreadcrumbItem>
                    <BreadcrumbLink href="/requests">
                        <Trans i18nKey={'MENU.REQUESTS'} />
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href="#">{id}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <Flex alignItems="flex-start" direction={{ base: 'column', md: 'row' }} gap="24px">
                <Card w="100%">
                    <CardBody>
                        <Text mb="20px" fontSize={'20px'}>
                            <Trans i18nKey={'ADMIN_REQUEST.REQUEST_TYPE'} />
                        </Text>
                        <Text>{t(adminRequestTypes.find((r) => data.type === r.type)?.label ?? '')}</Text>

                        <Divider color="brand.menuBorder" m="24px 0" />

                        <Text mb="20px" fontSize={'20px'}>
                            <Trans i18nKey={'ADMIN_REQUEST.STATUS'} />
                        </Text>
                        <Tag size={'lg'} borderRadius="full" variant="solid" backgroundColor={getColorBasedOnStatus()} color={'white'}>
                            <TagLabel fontSize="14px">
                                <Trans i18nKey={adminRequestStatuses.find((s) => s.status === data.status)?.label ?? ''} />
                            </TagLabel>
                        </Tag>

                        {address && (
                            <Stack>
                                <Divider color="brand.menuBorder" m="24px 0" />
                                <Text mb="20px" fontSize={'20px'}>
                                    <Trans i18nKey={'ADMIN_REQUEST.REQUEST_INFO'} />
                                </Text>

                                <Stack color="black.60" fontSize="14px">
                                    {address.firstName && address.lastName && <Text>{address.firstName + ' ' + address.lastName}</Text>}
                                    <Text>{address.address}</Text>
                                    <Text>{address.city}</Text>
                                    <Text>{address.postCode}</Text>
                                </Stack>

                                <Text mt="16px">
                                    <Trans i18nKey={'ADMIN.REQUESTS.MEDIA'} />
                                </Text>
                                <Stack maxWidth={'550px'}>
                                    <Image
                                        onClick={() => openImageInModal(address.verifyImage)}
                                        borderRadius={'9px'}
                                        cursor={'zoom-in'}
                                        src={address.verifyImage}
                                        maxHeight={'260px'}
                                        objectFit={'cover'}
                                    />
                                </Stack>
                            </Stack>
                        )}

                        <HStack justifyContent={isVerifyingAddress() ? 'space-between' : 'flex-start'} mt="24px">
                            {isVerifyingAddress() && (
                                <HStack>
                                    <Button variant="danger" onClick={toggleDeclineModal}>
                                        <Trans i18nKey={'GENERAL.DECLINE'} />
                                    </Button>
                                    <Button variant="dark" onClick={toggleAcceptModal}>
                                        <Trans i18nKey={'GENERAL.ACCEPT'} />
                                    </Button>
                                </HStack>
                            )}

                            {isVerifyingUser() || isVerifyingBanner() ? (
                                data.status === AdminRequestStatus.CREATED ? (
                                    <Button variant="outline" leftIcon={<BsImages />} onClick={() => redirectToReference(data.referenceUUID, data.type)}>
                                        <Trans i18nKey={'ADMIN_REQUEST.PREVIEW'} />
                                    </Button>
                                ) : (
                                    <Button variant="outline" leftIcon={<FaMagnifyingGlass />} onClick={() => redirectToReference(data.referenceUUID, data.type)}>
                                        <Trans i18nKey={`ADMIN_REQUEST.PREVIEW_${data.type}`} />
                                    </Button>
                                )
                            ) : null}

                            <Button variant="outline" color="red" borderColor="red" leftIcon={<FaRegTrashCan />} onClick={toggleDeleteModal}>
                                <Trans i18nKey={'GENERAL.REMOVE'} />
                            </Button>
                        </HStack>
                    </CardBody>
                </Card>

                <Stack spacing="11px" maxW={{ base: '100%', md: '357px' }} w="100%">
                    {data && data.reporterUUID && <UserContactCard userUUID={data?.reporterUUID} translation="ADMIN_REQUEST.REQUESTED_BY" />}
                    {isVerifyingUser() && data.referenceUUID && <UserContactCard userUUID={data.referenceUUID} translation="ADMIN_REQUEST.REQUESTED_BY" />}
                    {address && <UserContactCard userUUID={address.userUUID} translation="ADMIN_REQUEST.REQUESTED_BY" />}
                </Stack>
            </Flex>

            <ImageModal imageUrl={selectedImage} isOpen={openImage} onClose={toggleImageModal} />
            <ConfirmationModal isOpen={deleteModal} setIsOpen={setDeleteModal} onConfirm={deleteRequest} message={t('ADMIN_REQUEST.REQUEST_DELETE_CONFIRM')} />
            <ConfirmationModal isOpen={acceptModal} setIsOpen={setAcceptModal} onConfirm={acceptRequest} message={t('ADMIN_REQUEST.REQUEST_ACCEPT_CONFIRM')} />
            <ConfirmationModal isOpen={declineModal} setIsOpen={setDeclineModal} onConfirm={declineRequest} message={t('ADMIN_REQUEST.REQUEST_DECLINE_CONFIRM')} />
        </Box>
    ) : null;
};

export default RequestDetails;
