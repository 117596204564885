import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Stack, Flex, Text, Heading, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Divider } from '@chakra-ui/react';

import { useTranslation, Trans } from 'react-i18next';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { LuPencilLine } from 'react-icons/lu';

import {
    allCategoriesAPI,
    categoryDetailsAPI,
    createFieldAPI,
    createOptionAPI,
    deleteCategoryAPI,
    deleteFieldAPI,
    deleteOptionAPI,
    updateCategoryAPI,
    updateFieldAPI,
    updateOptionAPI,
} from 'api/categories';
import { IArticleCategory } from 'app/articles/types';
import { buildQueryString, mapErrorCode } from 'common/helpers';
import Shared from './details/shared';
import Fields from './details/fields';
import { compareCategories, createCategoryRequest, createFieldRequest, createOptionRequest, mergeFieldsWithSameParentId } from '../utils/helpers';
import ConfirmationModal from 'components/confirmationModal';
import LoadingModal from 'components/loading';
import { IUUIDMapping } from '../types';
import ChangesModal from './modals/changesModal';

const CategoryDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const [category, setCategory] = useState<IArticleCategory>();
    const [editData, setEditData] = useState<IArticleCategory>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
    const [confirmEditModalOpen, setConfirmEditModalOpen] = useState<boolean>(false);
    const [confirmDeleteModalOpen, setConfrimDeleteModal] = useState<boolean>(false);
    const [confirmDiscardModalOpen, setConfirmDicardModalOpen] = useState<boolean>(false);

    const [parentCategories, setParentCategories] = useState<IArticleCategory[]>([]);
    const [deleteError, setDeleteError] = useState<boolean>(false);
    const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>();

    const toggleConfirmDiscardModal = () => {
        setConfirmDicardModalOpen(!confirmDiscardModalOpen);
    };

    const toggleConfirmationModal = () => {
        setConfirmationModalOpen(!confirmationModalOpen);
    };

    const toggleConfirmEditModal = () => {
        setConfirmEditModalOpen(!confirmEditModalOpen);
    };

    const toggleDeleteModal = () => {
        setConfrimDeleteModal(!confirmDeleteModalOpen);
    };

    const copyData = (data: IArticleCategory) => {
        const copyOriginalData = JSON.parse(JSON.stringify(data)) as IArticleCategory;
        if (copyOriginalData) setEditData(copyOriginalData);
    };

    const isSubmitDisabled = () => {
        return (editData && editData.productCategoryFields && editData.productCategoryFields.length === 0) || (editData?.translations?.bs || '').length > 50;
    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
        category && copyData(category);
        // deep copy to avoid duplicating
    };

    const confirmEdit = async () => {
        try {
            setIsLoading(true);
            if (category && editData) {
                if (category.parentID) {
                    const result = compareCategories(category, editData);
                    if (result.deletedOptions.length > 0) {
                        for (const deleteOpt of result.deletedOptions) {
                            await deleteOptionAPI(category.id, deleteOpt.productCategoryFieldID, deleteOpt.id);
                        }
                    }

                    if (result.updatedOptions.length > 0) {
                        for (const updateOpt of result.updatedOptions) {
                            await updateOptionAPI(createOptionRequest(updateOpt), category.id, updateOpt.productCategoryFieldID, updateOpt.id);
                        }
                    }

                    if (result.addedOptions.length > 0) {
                        for (const newOpt of result.addedOptions) {
                            await createOptionAPI(createOptionRequest(newOpt), category.id, newOpt.productCategoryFieldID);
                        }
                    }

                    if (result.deletedFields.length > 0) {
                        for (const deleteField of result.deletedFields) {
                            await deleteFieldAPI(category.id, deleteField.id);
                        }
                    }

                    if (result.updatedFields.length > 0) {
                        for (const updateField of result.updatedFields) {
                            await updateFieldAPI(createFieldRequest(updateField), category.id, updateField.id);
                        }
                    }

                    if (result.addedFields.length > 0) {
                        const parentFields = result.addedFields.filter((field) => field.parentID === 0);
                        const childFields = mergeFieldsWithSameParentId(result.addedFields);

                        const localFieldIDsToApiIDMap: IUUIDMapping = {};
                        const localOptionIDsToApiIDMap: IUUIDMapping = {};

                        for (const field of parentFields) {
                            const fieldResponse = await createFieldAPI(createFieldRequest(field), category.id);
                            const fieldID = fieldResponse.data.id;

                            localFieldIDsToApiIDMap[field.id.toString()] = fieldID;

                            if (field.productCategoryFieldOptions && field.productCategoryFieldOptions.length > 0) {
                                for (const option of field.productCategoryFieldOptions) {
                                    const optionResponse = await createOptionAPI(createOptionRequest(option), category.id, fieldID);
                                    localOptionIDsToApiIDMap[option.id.toString()] = optionResponse.data.id;
                                }
                            }
                        }

                        // then create child fields and options with correct parentID values
                        for (const field of childFields) {
                            const fieldIndex = category.productCategoryFields.findIndex((f) => f.id === field.parentID);

                            if (fieldIndex !== -1) {
                                const fieldResponse = await createFieldAPI(createFieldRequest(field), category.id);
                                const fieldID = fieldResponse.data.id;

                                if (field.productCategoryFieldOptions?.length > 0) {
                                    for (const option of field.productCategoryFieldOptions) {
                                        if (option.parentID) {
                                            await createOptionAPI(createOptionRequest(option), category.id, fieldID);
                                        }
                                    }
                                }
                            } else {
                                const fieldResponse = await createFieldAPI(createFieldRequest({ ...field, parentID: localFieldIDsToApiIDMap[field.parentID.toString()] }), category.id);
                                const fieldID = fieldResponse.data.id;

                                if (field.productCategoryFieldOptions?.length > 0) {
                                    for (const option of field.productCategoryFieldOptions) {
                                        if (option.parentID) {
                                            await createOptionAPI(createOptionRequest({ ...option, parentID: localOptionIDsToApiIDMap[option.parentID.toString()] }), category.id, fieldID);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                await updateCategoryAPI(category.id, createCategoryRequest(editData));
                navigate('/categories');
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            toggleConfirmationModal();
        }
    };

    const confirmDelete = async () => {
        if (category) {
            try {
                setIsLoading(true);

                await deleteCategoryAPI(category.id);

                setIsLoading(false);
                navigate('/categories');
            } catch (error) {
                console.log(error);
                setDeleteError(true);
                setDeleteErrorMsg(mapErrorCode(error, t));
                setIsLoading(false);
            }
        }
    };

    const confirmDiscard = () => {
        toggleEditMode();
        toggleConfirmDiscardModal();
    };

    const getCategoryDetails = async () => {
        if (id) {
            try {
                setIsLoading(true);
                const response = await categoryDetailsAPI(parseInt(id));

                setCategory(response.data);

                copyData(response.data);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        }
    };

    const getParentCategories = async () => {
        try {
            const response = await allCategoriesAPI(buildQueryString({ perPage: Number.MAX_SAFE_INTEGER }));
            setParentCategories(response.data.results.filter((category) => category.parentCategory === true));
        } catch (error) {
            console.log(error);
        }
    };

    const onScreenLoad = async () => {
        await getParentCategories();
        await getCategoryDetails();
    };

    useEffect(() => {
        onScreenLoad();
    }, []);

    return category && editData ? (
        <Stack spacing="32px">
            <Breadcrumb spacing="8px" separator={<HiOutlineChevronRight color="gray.500" />} m="24px 0">
                <BreadcrumbItem>
                    <BreadcrumbLink href="/categories">{t('MENU.CATEGORIES')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href="#">{category?.translations.bs}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Stack>
                <Text>{t('ARTICLE.CATEGORY')}</Text>
                <Heading as="h1">{category?.translations.bs}</Heading>
            </Stack>

            <Flex maxW={'549px'} w="100%" justifyContent={'space-between'}>
                <Text fontSize="20px" fontWeight={500}>
                    <Trans i18nKey="CATEGORY.CREATION_INFO" />
                </Text>
                {!editMode && (
                    <Button ml="8%" variant="outline" onClick={toggleEditMode} rightIcon={<LuPencilLine />}>
                        <Trans i18nKey={'CATEGORY.EDIT'} />
                    </Button>
                )}
            </Flex>

            <Shared category={editData} setCategory={setEditData} hideIntro editMode={editMode} parentCategories={parentCategories} />
            {category.parentID && <Fields apiEdit category={editData} setCategory={setEditData} editMode={editMode} originalData={category} />}

            {editMode ? (
                <Stack>
                    {isSubmitDisabled() && (
                        <Text color="red" fontSize={'14px'}>
                            <Trans i18nKey={'CATEGORY.REQUIRED_FIELDS_EDIT'} />
                        </Text>
                    )}
                    <Flex maxW={'549px'} mb="32px" gap="16px" w="100%" justifyContent={'flex-start'}>
                        <Button backgroundColor={'brand.success'} padding="0 24px" onClick={toggleConfirmationModal} isDisabled={isSubmitDisabled()}>
                            <Text color="white" fontSize={'14px'}>
                                <Trans i18nKey={'CATEGORY.SAVE_CHANGES'} />
                            </Text>
                        </Button>
                        <Button onClick={toggleConfirmDiscardModal} fontSize={'14px'} padding="0 24px">
                            <Text>
                                <Trans i18nKey={'CATEGORY.DISCARD_CHANGES'} />
                            </Text>
                        </Button>
                    </Flex>
                    <Divider color="brand.menuBorder" />
                    <Flex justifyContent={'flex-end'} onClick={toggleDeleteModal}>
                        <Text m="32px 0" color={'red'} fontWeight={600} fontSize={'14px'} cursor="pointer">
                            <Trans i18nKey={'CATEGORY.DELETE'} />
                        </Text>
                    </Flex>
                </Stack>
            ) : (
                <Flex maxW={'549px'} w="100%" justifyContent={'flex-end'}>
                    <Button w="fit-content" mb="32px" variant="outline" onClick={toggleEditMode} rightIcon={<LuPencilLine />}>
                        <Trans i18nKey={'CATEGORY.EDIT'} />
                    </Button>
                </Flex>
            )}

            <ConfirmationModal isOpen={confirmEditModalOpen} setIsOpen={setConfirmEditModalOpen} message={t('CATEGORY.EDIT_CONFIRM')} onConfirm={confirmEdit} />
            <ConfirmationModal
                isOpen={confirmDeleteModalOpen}
                setIsOpen={setConfrimDeleteModal}
                message={t('CATEGORY.DELETE_CONFIRM')}
                description={t('CATEGORY.DELETE_DESC')}
                onConfirm={confirmDelete}
                errorMessage={deleteErrorMsg}
                error={deleteError}
                setError={setDeleteError}
            />
            <ChangesModal openModal={confirmationModalOpen} setOpenModal={setConfirmationModalOpen} oldCategory={category} newCategory={editData} onSubmit={toggleConfirmEditModal} />

            <LoadingModal isOpen={isLoading} setIsOpen={setIsLoading} />
            <ConfirmationModal isOpen={confirmDiscardModalOpen} setIsOpen={setConfirmDicardModalOpen} message={t('CATEGORY.DISCARD_CONFIRM')} onConfirm={confirmDiscard} />
        </Stack>
    ) : (
        <LoadingModal isOpen={isLoading} setIsOpen={setIsLoading} />
    );
};

export default CategoryDetails;
