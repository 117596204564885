import { helpers } from 'common';
import {
    RatingResponseDTO,
    UserAddressesResponseDTO,
    UserAdminRequestDetailsResponseDTO,
    UserAdminRequestsResponseDTO,
    UserDetailsResponseDTO,
    UserReviewDetailsResponseDTO,
    UserReviewReportsResponseDTO,
    UserReviewsResponseDTO,
    UserRolesResponseDTO,
    UsersResponseDTO,
} from './types/response';
import { LoginResponseDTO } from 'api/auth/types/response';
import { VerifyRequestDTO } from 'api/auth/types/request';
import { UserDetailsEditRequestDTO, UserWalletTopupRequestDTO } from './types/request';

const { formAxiosObject } = helpers;

export const userDetailsAPI = async (uuid: string): Promise<UserDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/users/${uuid}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const editUserAPI = async (uuid: string, data: UserDetailsEditRequestDTO): Promise<UserDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'PUT', route: `/management/users/${uuid}` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const currentUserAPI = async (): Promise<UserDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/me/` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const verifyTpaApi = async (data: VerifyRequestDTO): Promise<LoginResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'POST', route: `/me/verify-tpa` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const usersAPI = async (queryParams?: string): Promise<UsersResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/users/${queryParams ? `?${queryParams}` : ''}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const userRequestsAPI = async (userUUID: string): Promise<UserAdminRequestsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/users/${userUUID}/adminRequests/` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const userRequestDetailsAPI = async (userUUID: string, requestID: number): Promise<UserAdminRequestDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/users/${userUUID}/adminRequests/${requestID}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const deactivateUserAPI = async (userUUID: string) => {
    try {
        await formAxiosObject({ method: 'PATCH', route: `/management/users/${userUUID}/deactivate` });
    } catch (error) {
        throw error;
    }
};

export const activateUserAPI = async (userUUID: string) => {
    try {
        await formAxiosObject({ method: 'PATCH', route: `/management/users/${userUUID}/activate` });
    } catch (error) {
        throw error;
    }
};

export const verifyUserAPI = async (userUUID: string, requestID: number, params?: string) => {
    try {
        await formAxiosObject({ method: 'PATCH', route: `/management/users/${userUUID}/adminRequests/${requestID}/accept/${params ? `${params}` : ''}` });
    } catch (error) {
        throw error;
    }
};

export const declineVerifyUserAPI = async (userUUID: string, requestID: number, params?: string) => {
    try {
        await formAxiosObject({ method: 'PATCH', route: `/management/users/${userUUID}/adminRequests/${requestID}/decline/${params ? `${params}` : ''}` });
    } catch (error) {
        throw error;
    }
};

export const userAddressesAPI = async (userUUID: string): Promise<UserAddressesResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/users/${userUUID}/addresses/` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const verifyUserAddressAPI = async (userUUID: string, addressUUID: string, sendNotif?: boolean): Promise<any> => {
    try {
        await formAxiosObject({ method: 'PATCH', route: `/management/users/${userUUID}/addresses/${addressUUID}?verification=${sendNotif}` });
    } catch (error) {}
};

export const userRolesAPI = async (userUUID: string): Promise<UserRolesResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/users/${userUUID}/roles/` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const userWalletTopup = async (userUUID: string, data: UserWalletTopupRequestDTO): Promise<UserRolesResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'PUT', route: `/management/users/${userUUID}/wallet` }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const addRoleAPI = async (userUUID: string, roleID: number): Promise<any> => {
    try {
        await formAxiosObject({ method: 'PUT', route: `/management/users/${userUUID}/roles/${roleID}` });
    } catch (error) {
        throw error;
    }
};

export const removeRoleAPI = async (userUUID: string, roleID: number): Promise<any> => {
    try {
        await formAxiosObject({ method: 'DELETE', route: `/management/users/${userUUID}/roles/${roleID}` });
    } catch (error) {
        throw error;
    }
};

export const userReviewsAPI = async (queryParams?: string): Promise<UserReviewsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/userReviews/${queryParams ? `?${queryParams}` : ''}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const userReviewDetailsAPI = async (uuid: string): Promise<UserReviewDetailsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/userReviews/${uuid}` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const deleteUserReviewAPI = async (uuid: string): Promise<any> => {
    try {
        await formAxiosObject({ method: 'DELETE', route: `/management/userReviews/${uuid}` });
    } catch (error) {
        throw error;
    }
};

export const userReviewReportsAPI = async (uuid: string): Promise<UserReviewReportsResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/management/userReviews/${uuid}/adminRequests/` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const ratingAPI = async (uuid: string): Promise<RatingResponseDTO> => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: `/users/all/${uuid}/rating/` });
        return response?.data;
    } catch (error) {
        throw error;
    }
};
