import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, HStack, Stack, Text, Button, Image, Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, useClipboard, Link } from '@chakra-ui/react';

import { Trans, useTranslation } from 'react-i18next';
import { FiPhone } from 'react-icons/fi';
import { FaViber } from 'react-icons/fa';
import { LuCopy } from 'react-icons/lu';

import colors from 'theme/foundations/colors';

import { AvatarProfilePicture } from 'common/images';
import { IAddress, IUser } from 'common/types';

import { userAddressesAPI, userDetailsAPI } from 'api/users';
import heic2any from 'heic2any';

interface IProps {
    userUUID: string;
    translation: string;
}

const UserContactCard = ({ userUUID, translation }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [imageSrc, setImageSrc] = useState<string | null>(null);

    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [user, setUser] = useState<IUser>();
    const [userAddresses, setUserAddresses] = useState<IAddress[]>([]);
    const { onCopy, hasCopied } = useClipboard(phoneNumber || '');

    const [displayPhoneNumberModal, setDisplayPhoneNumberModal] = useState(false);

    const redirectToUserDetails = () => {
        navigate(`/users/${userUUID}`);
    };

    const togglePhoneNumModal = () => {
        setDisplayPhoneNumberModal(!displayPhoneNumberModal);
    };

    const closePhoneNumModal = () => {
        togglePhoneNumModal();
        setPhoneNumber(null);
    };

    const isHeicFile = (url: string) => {
        return url.toLowerCase().endsWith('.heic');
    };

    const convertHeicToJpeg = async (url: string) => {
        const response = await fetch(url);
        const blob = await response.blob();

        let convertedBlob;
        if (Array.isArray(blob)) {
            const promises = blob.map(async (b) => await heic2any({ blob: b, toType: 'image/jpeg' }));
            convertedBlob = await Promise.all(promises);
        } else {
            convertedBlob = await heic2any({ blob, toType: 'image/jpeg' });
        }

        const firstBlob = Array.isArray(convertedBlob) ? convertedBlob[0] : convertedBlob;
        return URL.createObjectURL(Array.isArray(firstBlob) ? firstBlob[0] : firstBlob);
    };

    const displayPhoneNumber = (phoneNum: string) => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        if (isMobile && phoneNum) {
            // Enable calling for mobile devices
            const formattedPhoneNumber = phoneNum.replace(/\+/g, '%2B');
            const telLink = `tel:${formattedPhoneNumber}`;

            window.location.href = telLink;
        } else {
            setPhoneNumber(phoneNum);

            togglePhoneNumModal();
        }
    };

    const openViberChat = (phoneNum: string) => {
        const formattedPhoneNumber = phoneNum.replace(/\+/g, '%2B');
        const viberLink = `viber://chat?number=${formattedPhoneNumber}`;

        window.location.href = viberLink;
    };

    const getUserDetails = async () => {
        try {
            const response = await userDetailsAPI(userUUID);
            setUser(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getAddress = async () => {
        try {
            const response = await userAddressesAPI(userUUID);
            setUserAddresses(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getProfileImage = () => {
        return imageSrc !== '' ? imageSrc : AvatarProfilePicture;
    };

    const renderContactButtons = (user: IUser) => {
        const viberColor = colors.brand.viberLabelColor;

        return (
            <HStack mt="8px">
                <Button variant="outline" gap="6px" w="100%" borderColor={viberColor} backgroundColor={colors.brand.viberBackground} onClick={() => openViberChat(user.phone)}>
                    <FaViber fill={viberColor} />
                    <Text color={viberColor}>
                        <Trans i18nKey={'ARTICLE.CONTACT_VIBER'} />
                    </Text>
                </Button>

                <Button variant="outline" gap="6px" w="100%" onClick={() => displayPhoneNumber(user.phone)}>
                    <FiPhone style={{ height: '12px' }} />
                    <Text>
                        <Trans i18nKey={'ARTICLE.CONTACT_CALL'} />
                    </Text>
                </Button>
            </HStack>
        );
    };

    const renderAddresses = () => {
        return userAddresses && userAddresses.length > 0 ? (
            <Stack wordBreak={'break-word'}>
                <HStack key={userAddresses[0].uuid} spacing={1}>
                    <Stack>
                        <Text fontSize="14px" lineHeight="14px" color={'gray'}>
                            {userAddresses[0].address + ', ' + userAddresses[0].city}
                        </Text>
                    </Stack>
                </HStack>

                {userAddresses.length > 1 && (
                    <HStack key={userAddresses[1].uuid} spacing={1}>
                        <Text fontSize="14px" lineHeight="14px" color={'gray'}>
                            {userAddresses[0].address + ', ' + userAddresses[0].city}
                        </Text>
                    </HStack>
                )}
            </Stack>
        ) : null;
    };

    const renderUserCard = (user: IUser) => {
        return (
            <Box border="1px solid" borderColor={'grey.100'} borderRadius={'10px'} maxW={{ base: '100%', md: '357px' }} w="100%" p="24px">
                <Text fontSize={'20px'} fontWeight={500}>
                    <Trans i18nKey={translation} />
                </Text>
                <HStack>
                    <Image w="64px" h="64px" objectFit={'cover'} src={getProfileImage()} alt="placeholder" m="4px 0" borderRadius="50%" />
                    <Stack>
                        <Text as={Link} onClick={() => redirectToUserDetails()}>
                            {user.username}
                        </Text>
                        {renderAddresses()}
                    </Stack>
                </HStack>
                {renderContactButtons(user)}
            </Box>
        );
    };

    const phoneNumberModal = () => {
        return (
            <Modal isOpen={displayPhoneNumberModal} onClose={closePhoneNumModal} isCentered>
                <ModalOverlay />
                <ModalContent bgColor="white" width="100%" maxW={'412px'}>
                    <ModalBody>
                        <Text mt="8px">
                            <Trans i18nKey={'USER.PHONE_NUM'} />
                        </Text>
                        <Text mt="8px">{phoneNumber ? phoneNumber : t('GENERAL.NOT_FOUND')}</Text>
                    </ModalBody>
                    <ModalFooter gap="8px">
                        {phoneNumber && (
                            <Button variant={'dark'} leftIcon={hasCopied ? undefined : <LuCopy />} onClick={onCopy}>
                                {hasCopied ? t('GENERAL.COPIED') : t('GENERAL.COPY')}
                            </Button>
                        )}
                        <Button variant={'outline'} onClick={closePhoneNumModal}>
                            <Trans i18nKey={'GENERAL.CANCEL'} />
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    };

    useEffect(() => {
        getUserDetails();
        getAddress();
    }, []);

    useEffect(() => {
        const loadImage = async () => {
            if (user) {
                if (isHeicFile(user?.profileImage)) {
                    try {
                        const jpegUrl = await convertHeicToJpeg(user?.profileImage);
                        setImageSrc(jpegUrl);
                    } catch (error) {
                        console.error('Error converting HEIC to JPEG', error);
                        setImageSrc(user.profileImage);
                    }
                } else {
                    setImageSrc(user.profileImage);
                }
            }
        };

        loadImage();
    }, [user?.profileImage]);

    return user ? (
        <Stack spacing="11px">
            {renderUserCard(user)}

            {phoneNumberModal()}
        </Stack>
    ) : null;
};

export default UserContactCard;
