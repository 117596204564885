import { Box, Flex, Button, Center, Text } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { LuPencilLine, LuTrash } from 'react-icons/lu';
import { Trans, useTranslation } from 'react-i18next';

import CreatePromotionQuotaModal from '../modals/createPromotion';
import ConfirmDeleteQuotaModal from '../modals/confirmDeleteQuota';
import { Table } from 'components';

import { promotionQuotasAPI } from 'api/marketing';
import { IPromotionQuota, IPromotionQuotaTableEntry } from 'app/marketing/types';
import { IPagination, ITableSlots } from 'common/types';

import { daysFromWeeksAndDays, mapPromotionQuotaTableData, tablePromotionConfig } from 'app/marketing/helpers';
import { buildQueryString } from 'common/helpers';
import LoadingModal from 'components/loading';
import { PromotionQuotasResponseDTO } from 'api/marketing/types/response';

const PromotionQuotas = () => {
    const { t } = useTranslation();

    const [data, setData] = useState<IPromotionQuota[]>([]);
    const [page, setPage] = useState<number>(1);
    const [paginator, setPaginator] = useState<Partial<IPagination>>({
        perPage: 5,
        orderBy: 'id',
    });

    const [entryUpdate, setEntryUpdate] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [selectedQuota, setSelectedQuota] = useState<IPromotionQuota | undefined>();

    const [loading, setLoading] = useState<boolean>(false);
    const [isErr, setIsErr] = useState<boolean>(false);

    const toggleCreateModal = () => {
        setOpenModal(!openModal);
    };

    const toggleDeleteModal = () => {
        setOpenDeleteModal(!openDeleteModal);
    };

    const changePage = (newPage: number | undefined) => {
        if (newPage) {
            setPage(newPage);
        }
    };

    const openEditQuota = (item: IPromotionQuotaTableEntry) => {
        setSelectedQuota({ ...item.originalData, durationDays: daysFromWeeksAndDays(item.originalData.durationWeeks, item.originalData.durationDays) });
        toggleCreateModal();
    };

    const openDeleteQuota = (item: IPromotionQuotaTableEntry) => {
        setSelectedQuota(item.originalData);
        toggleDeleteModal();
    };

    const slots: ITableSlots = {
        actions: (item: IPromotionQuotaTableEntry) => {
            return {
                content: (
                    <Flex>
                        <Button variant={'outline'} leftIcon={<LuPencilLine />} mr={3} onClick={() => openEditQuota(item)}>
                            <Trans i18nKey={'GENERAL.EDIT'} />
                        </Button>
                        <Button variant={'outline'} leftIcon={<LuTrash />} color="red" borderColor="red" onClick={() => openDeleteQuota(item)}>
                            <Trans i18nKey={'GENERAL.REMOVE'} />
                        </Button>
                    </Flex>
                ),
                sortableValue: item.id.toString(),
            };
        },
    };

    const isCurrentPageEmpty = (response: PromotionQuotasResponseDTO) => {
        return page > 1 && response.data.paginator.currentEntriesSize === 0 && response.data.paginator.totalEntriesSize > 0;
    };

    const getBannerQuotas = async () => {
        try {
            setLoading(true);
            const res = await promotionQuotasAPI(buildQueryString({ ...paginator, page }));
            setData(res.data.results);
            if (isCurrentPageEmpty(res)) {
                setPage(page - 1);
            }
            setPaginator({ ...paginator, totalPages: res.data.paginator.totalPages });
            if (entryUpdate) {
                setEntryUpdate(false);
            }
        } catch (error) {
            setIsErr(true);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const isDataEmpty = () => {
        return data.length === 0 && !loading && !isErr;
    };

    useEffect(() => {
        getBannerQuotas();
    }, [entryUpdate, page]);

    return (
        <Box>
            <Flex alignItems={'center'} justifyContent={'space-between'} mb="16px">
                <Trans i18nKey={'QUOTAS.DESCRIPTION'} />
                <Button variant={'dark'} gap="8px" onClick={toggleCreateModal}>
                    <FaPlus />
                    <Trans i18nKey={'QUOTAS.ADD'} />
                </Button>
            </Flex>
            {isErr && (
                <Center mt="32px">
                    <Text color="red">
                        <Trans i18nKey={'GENERAL.LOADING_ERROR'} />
                    </Text>
                </Center>
            )}
            {isDataEmpty() && (
                <Center mt="32px">
                    <Text color="black.60">
                        <Trans i18nKey={'GENERAL.EMPTY_STATE'} />
                    </Text>
                </Center>
            )}
            <Table data={mapPromotionQuotaTableData(data, t)} config={tablePromotionConfig(t)} pagination={paginator} page={page} onPaging={changePage} slots={slots} />
            <CreatePromotionQuotaModal
                toggleCreateModal={toggleCreateModal}
                modalOpen={openModal}
                entryCreated={entryUpdate}
                setEntryCreated={setEntryUpdate}
                selectedQuota={selectedQuota}
                setSelectedQuota={setSelectedQuota}
            />
            {selectedQuota && (
                <ConfirmDeleteQuotaModal
                    visible={openDeleteModal}
                    setVisible={setOpenDeleteModal}
                    id={selectedQuota?.id.toString()}
                    entryUpdate={entryUpdate}
                    setEntryUpdate={setEntryUpdate}
                    type="article"
                />
            )}
            <LoadingModal isOpen={loading} setIsOpen={setLoading} />
        </Box>
    );
};

export default PromotionQuotas;
