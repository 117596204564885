import { formatDate, isStringEmpty, truncateString } from 'common/helpers';
import { IAdminRequest } from 'common/types';
import { TFunction } from 'i18next';
import { adminRequestTypes } from '../constants';

export const tableConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'description',
            label: t('ADMIN_REQUEST.DESCRIPTION'),
            sortable: true,
        },
        {
            key: 'type',
            label: t('ADMIN_REQUEST.TYPE'),
            sortable: true,
        },
        {
            key: 'reason',
            label: t('ADMIN_REQUEST.REASON'),
            sortable: true,
        },
        {
            key: 'createdAt',
            label: t('ADMIN_REQUEST.CREATED_AT'),
            sortable: true,
        },
    ];
};

export const singleEntryConfig = (t: TFunction<'translation', undefined>) => {
    return [
        {
            key: 'type',
            label: t('ADMIN_REQUEST.TYPE'),
            sortable: false,
        },
        {
            key: 'reason',
            label: t('ADMIN_REQUEST.REASON'),
            sortable: false,
        },
        {
            key: 'createdAt',
            label: t('ADMIN_REQUEST.CREATED_AT'),
            sortable: false,
        },
    ];
};

export const mapTableData = (data: IAdminRequest[], t: TFunction<'translation', undefined>) => {
    const tableData = data.map((req) => ({
        id: req.id,
        description: isStringEmpty(req.description) ? '-' : truncateString(req.description, 30),
        type: t(adminRequestTypes.find((r) => r.type === req.type)?.label ?? 'GENERAL.NOT_FOUND'),
        reason: isStringEmpty(req.reason) ? '-' : truncateString(req.reason, 30),
        createdAt: formatDate(req.createdAt),
    }));
    return tableData;
};
