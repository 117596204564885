import React from 'react';

import { useWindowSize } from 'hooks/use-windows-size';
import { isHidden } from 'components/hidden/helpers/hidden-helpers';

export interface IProps {
    sizeUp?: number;
    sizeDown?: number;
    smDown?: boolean;
    smUp?: boolean;
    mdDown?: boolean;
    mdUp?: boolean;
    lgDown?: boolean;
    lgUp?: boolean;
    xlDown?: boolean;
    xlUp?: boolean;
    children: JSX.Element | React.FC | string;
}

const Hidden = ({ sizeUp, sizeDown, smDown = false, smUp = false, mdDown = false, mdUp = false, lgDown = false, lgUp = false, xlDown = false, xlUp = false, children }: IProps) => {
    const currentSize = useWindowSize();
    const width = currentSize.width;

    return isHidden(width || 0, sizeUp, sizeDown, smDown, smUp, mdDown, mdUp, lgDown, lgUp, xlDown, xlUp) ? null : <>{children}</>;
};

export default Hidden;
