import { helpers } from 'common';
import { ILoginData, ILogoutData } from 'api/auth/types';

const { formAxiosObject } = helpers;

export const loginAPI = async (data: ILoginData): Promise<any> => {
    try {
        const response = await formAxiosObject({ method: 'POST', route: '/me/login' }, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const logoutAPI = async (data: ILogoutData): Promise<any> => {
    try {
        await formAxiosObject({ method: 'POST', route: '/me/logout' }, data);
    } catch (error) {
        throw error;
    }
};
