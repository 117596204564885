const requiredVar = (value: string | undefined, name: string): string => {
    if (value === undefined || value === '') {
        throw new Error('Missing required environment variable: ' + name);
    }
    return value;
};

const config = {
    NAKLIK_ENV: requiredVar(process.env.REACT_APP_NAKLIK_ENVIRONMENT, 'REACT_APP_NAKLIK_ENVIRONMENT'),
    API_URL: requiredVar(process.env.REACT_APP_API_URL, 'REACT_APP_API_URL'),
    WEB_URL: requiredVar(process.env.REACT_APP_WEB_URL, 'REACT_APP_WEB_URL'),
    IMAGES_BUCKET: requiredVar(process.env.REACT_APP_IMAGES_BUCKET, 'REACT_APP_IMAGES_BUCKET'),
    MONRI_URI: requiredVar(process.env.REACT_APP_MONRI_LOGIN, 'REACT_APP_MONRI_LOGIN'),
};

export default config;
