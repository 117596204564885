import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Alert, AlertIcon, Text, Stack } from '@chakra-ui/react';
import { deleteBannerQuotaAPI, deletePromotionQuotaAPI } from 'api/marketing';
import { mapErrorCode } from 'common/helpers';

interface IProps {
    visible: boolean;
    setVisible: (value: boolean) => void;
    entryUpdate: boolean;
    setEntryUpdate: (value: boolean) => void;
    id: string;
    type: 'article' | 'banner';
}

const ConfirmDeleteQuotaModal = ({ visible, setVisible, id, entryUpdate, setEntryUpdate, type }: IProps) => {
    const { t } = useTranslation();
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const onModalClose = () => {
        setError(false);
        setVisible(!visible);
    };

    const refreshData = () => {
        setEntryUpdate(!entryUpdate);
        onModalClose();
    };

    const deleteArticle = async () => {
        try {
            if (type === 'banner') {
                await deleteBannerQuotaAPI(id);
            } else {
                await deletePromotionQuotaAPI(id);
            }

            refreshData();
        } catch (err) {
            setErrorMessage(mapErrorCode(err, t));
            setError(!error);
        }
    };

    return (
        <Modal isOpen={visible} onClose={onModalClose} isCentered>
            <ModalOverlay />
            <ModalContent bgColor="white">
                <ModalHeader>{t('QUOTA.REMOVE')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{t('QUOTA.REMOVE_CONFIRM')}</ModalBody>
                {error && (
                    <Alert status="error">
                        <AlertIcon />
                        <Stack spacing={0}>
                            <Text>{t('ERROR.GENERAL')}</Text>
                            <Text>{errorMessage}</Text>
                        </Stack>
                    </Alert>
                )}
                <ModalFooter justifyContent="flex-start">
                    <Button variant="danger" mr={3} onClick={deleteArticle}>
                        {t('GENERAL.CONFIRM')}
                    </Button>
                    <Button variant="outline" onClick={onModalClose}>
                        {t('GENERAL.CANCEL')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmDeleteQuotaModal;
