const colors = {
    brand: {
        text: '#0F172A',
        menuBorder: '#E2E8F0',
        activeMenuItemBackground: '#F1F5F9',
        outlineButtonBorder: '#CACACA',
        outlienButtonColor: '##23262A',
        danger: '#C43434',
        success: '#64B054',
        darkButtonBackground: '#0F172A',
        inputBorder: '#C2C2C2',
        inputLabelColor: '#334155',
        boxShadow: 'rgb(226, 232, 240) 0px 1px 3px 0px, rgb(226, 232, 240) 0px 1px 2px 0px',
        viberBackground: '#E1DDFF',
        viberLabelColor: '#7061EA',
        starFill: '#FB6728',
        tagBackground: '#DDE9EC',
        imagePlaceholder: '#DDE9EC',
    },
    white: {
        100: '#FFFFF',
    },
    green: {
        10: '#E8F5E6',
    },
    grey: {
        10: '#F8F8F8',
        100: '#E2E8F0',
    },
    black: {
        40: '#929ea6',
        60: '#4F5A61',
        100: '#0F172A',
    },
};

export default colors;
