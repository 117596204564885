import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Alert, AlertIcon } from '@chakra-ui/react';
import { deleteArticleAPI } from 'api/articles';

interface IProps {
    visible: boolean;
    setVisible: (value: boolean) => void;
    uuid: string;
}

const DeleteArticleModal = ({ visible, setVisible, uuid }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [error, setError] = useState<boolean>(false);

    const onModalClose = () => {
        setError(false);
        setVisible(!visible);
    };

    const redirectToArticles = () => {
        navigate('/articles/');
    };

    const deleteArticle = async () => {
        try {
            await deleteArticleAPI(uuid);
            redirectToArticles();
        } catch (err) {
            setError(!error);
        }
    };

    return (
        <Modal isOpen={visible} onClose={onModalClose} isCentered>
            <ModalOverlay />
            <ModalContent bgColor="white">
                <ModalHeader>{t('ARTICLE.DELETE')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{t('ARTICLE.DELETE_DESC')}</ModalBody>
                {error && (
                    <Alert status="error">
                        <AlertIcon />
                        {t('ERROR.GENERAL')}
                    </Alert>
                )}
                <ModalFooter justifyContent="flex-start">
                    <Button variant="danger" mr={3} onClick={deleteArticle}>
                        {t('GENERAL.CONFIRM')}
                    </Button>
                    <Button variant="outline" onClick={onModalClose}>
                        {t('GENERAL.CANCEL')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DeleteArticleModal;
