import { Box } from '@chakra-ui/react';

import { Routes } from 'components';

import './common/styles/app.scss';

const App = () => (
    <Box className="main-container">
        <Box className="main-container__content">
            <Routes />
        </Box>
    </Box>
);

export default App;
