import { useMemo, useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { TableContainer, Table, TableCaption, Thead, Tr, Th, Td, Tbody, Tfoot, Flex } from '@chakra-ui/react';

import { IFooter } from 'components/table';
import { IConfig, IPaging, ITableSlots } from 'common/types';
import { SortIcon } from 'common/images';

interface IProps {
    config: IConfig[];
    data: any[];
    slots?: ITableSlots;
    footer?: IFooter[];
    tableCaption?: string;
    onRowClick?: (rawData: any) => void;
    isLoading?: boolean;
    componentProps?: any;
    replacePageBreak?: boolean;
}

const TableView = ({ config, data, footer, tableCaption, onRowClick, isLoading, componentProps, slots, replacePageBreak }: IProps) => {
    const [tableData, setTableData] = useState<any[]>(data);
    const [paging, setPaging] = useState<IPaging>({ key: null, direction: 'asc' });

    const sortTable = (key: string, sortable?: boolean) => {
        if (!sortable) {
            return;
        }

        let direction: 'asc' | 'desc' = 'asc';

        if (paging.key === key && paging.direction === 'asc') {
            direction = 'desc';
        }

        setPaging({ key, direction });
    };

    useEffect(() => {
        const sortableData = [...tableData];

        if (paging.key) {
            sortableData.sort((a, b) => {
                if (a[paging.key as keyof typeof a] < b[paging.key as keyof typeof b]) {
                    return paging.direction === 'asc' ? -1 : 1;
                }
                if (a[paging.key as keyof typeof a] > b[paging.key as keyof typeof b]) {
                    return paging.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }

        setTableData(sortableData);
    }, [paging]);

    useEffect(() => {
        setTableData(data);
    }, [data]);

    const tableHeader = useMemo(() => {
        return (
            <Tr bg="white">
                {config.map((item: IConfig, index: number) => (
                    <Th
                        key={`table-header-${item.key}-${index}`}
                        border="1px solid"
                        borderColor="grey.100"
                        color="black.100"
                        fontSize="16px"
                        fontWeight="bold"
                        textTransform="none"
                        onClick={() => sortTable(item.key, item.sortable)}
                        padding="8px 16px"
                        textAlign={item.align || 'left'}
                    >
                        {item.label ? (
                            <Flex alignItems="center" justifyContent="space-between">
                                <Trans i18nKey={item.label} />
                                <Flex cursor="pointer">{item.sortable ? <img src={SortIcon} alt="sort" /> : null}</Flex>
                            </Flex>
                        ) : (
                            <></>
                        )}
                    </Th>
                ))}
            </Tr>
        );
    }, [config, paging]);

    const tableFooter = useMemo(() => {
        if (footer && footer.length > 0) {
            return (
                <Tfoot>
                    <Tr>
                        {footer.map((item: IFooter, index: number) => (
                            <Th key={`table-footer-${item.label}-${index}`} color="black.100">
                                <Trans i18nKey={item.label} />
                            </Th>
                        ))}
                    </Tr>
                </Tfoot>
            );
        }

        return null;
    }, [footer]);

    const tableBody = useMemo(() => {
        return tableData.map((item: any, index: number) => (
            <Tr key={`table-data-item-${index}`}>
                {config.map((configItem: IConfig, configIndex: number) => {
                    const tdStyle = {
                        padding: '8px 16px',
                        color: 'black.100',
                        textAlign: configItem.align || 'left',
                    };

                    if (slots && slots[configItem.key]) {
                        return (
                            <Td
                                border="1px solid"
                                borderColor="grey.100"
                                key={`table-data-${index}-${configIndex}`}
                                cursor={onRowClick ? 'pointer' : 'initial'}
                                onClick={() => {
                                    if (onRowClick) {
                                        onRowClick(item);
                                    }
                                }}
                                style={tdStyle}
                            >
                                {slots[configItem.key](item).content}
                            </Td>
                        );
                    }

                    if (configItem.component) {
                        return (
                            <Td
                                border="1px solid"
                                borderColor="grey.100"
                                key={`table-data-${index}-${configIndex}`}
                                cursor={onRowClick ? 'pointer' : 'initial'}
                                onClick={() => {
                                    if (onRowClick) {
                                        onRowClick(item);
                                    }
                                }}
                                style={tdStyle}
                            >
                                <configItem.component config={configItem} date={item[configItem.key]} componentProps={componentProps || {}} dataItem={item} {...item} />
                            </Td>
                        );
                    }

                    return replacePageBreak ? (
                        <Td
                            border="1px solid"
                            borderColor="grey.100"
                            key={`table-data-${index}-${configIndex}`}
                            cursor={onRowClick ? 'pointer' : 'initial'}
                            onClick={() => {
                                if (onRowClick) {
                                    onRowClick(item);
                                }
                            }}
                            style={tdStyle}
                            dangerouslySetInnerHTML={{ __html: item[configItem.key].replace(/\n/g, '<br>') }}
                        />
                    ) : (
                        <Td
                            border="1px solid"
                            borderColor="grey.100"
                            key={`table-data-${index}-${configIndex}`}
                            cursor={onRowClick ? 'pointer' : 'initial'}
                            onClick={() => {
                                if (onRowClick) {
                                    onRowClick(item);
                                }
                            }}
                            style={tdStyle}
                        >
                            {item[configItem.key as keyof typeof data]}
                        </Td>
                    );
                })}
            </Tr>
        ));
    }, [config, tableData]);

    if (!isLoading && (!data || !data.length)) {
        return <></>;
    }

    return (
        <TableContainer position="relative" maxHeight="60vh" overflowY="auto">
            <Table variant="simple" border="1px solid" borderColor="grey.100">
                {tableCaption && (
                    <TableCaption>
                        <Trans i18nKey={tableCaption} />
                    </TableCaption>
                )}
                <Thead position="sticky" top="0">
                    {tableHeader}
                </Thead>
                <Tbody>{tableBody}</Tbody>
                {tableFooter}
            </Table>
        </TableContainer>
    );
};

export default TableView;
