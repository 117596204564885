const constants = {
    // eslint-disable-next-line
    MAX_UPLOAD_SIZE: 2097152,
    EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    USERNAME_REGEX: /^[a-zA-Z0-9._-]{4,20}$/,
    PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w\W]{6,25}$/,
    LANGUAGES: [
        {
            code: 'ba',
            name: 'Bosanski',
        },
    ],
    DEFAULT_LANGUEG: 'ba',
    SESSION_KEY: 'admin-session',
    MAX_CHECKBOX_OPTIONS: 3,
};

export default constants;
