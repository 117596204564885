import { AxiosRequestConfig, RawAxiosRequestHeaders, AxiosResponse } from 'axios';
import _ from 'common/lodash';
import { IParams, IRequestObject } from 'common/types';
import environment from 'core/config';
import { initilizeHttpClient } from 'core';

export type { AxiosResponse, AxiosRequestConfig, RawAxiosRequestHeaders };

export interface IAxiosRequestConfig extends AxiosRequestConfig {
    isExternalRequest?: boolean;
}
export const blockPageScroll = (): void => {
    if (document.body.className.indexOf('loader-block-scroll') === -1) {
        document.body.className += ' loader-block-scroll';
    }
};

export const unblockPageScroll = (): void => {
    document.body.className = document.body.className.split('loader-block-scroll').join('');
};

export const formAxiosObject = async (
    requestObject: IRequestObject,
    data?: object | null,
    params?: object | null,
    urlParams?: object | null,
    headers?: RawAxiosRequestHeaders | null,
    isExternalRequest?: boolean,
    config?: object | null,
) => {
    if (!requestObject || !requestObject.route || !requestObject.method) {
        return null;
    }

    let { route } = requestObject;

    if (urlParams && !_.isObjectEmpty(urlParams)) {
        Object.keys(urlParams).forEach((key) => {
            route += `/${urlParams[key as keyof typeof urlParams]}`;
        });
    }

    const axiosObject: IAxiosRequestConfig = {
        url: route,
        method: requestObject.method,
        isExternalRequest,
    };

    const { method } = axiosObject;

    if (data && (method === 'POST' || method === 'PUT' || method === 'DELETE' || method === 'PATCH')) {
        axiosObject.data = data;
    }

    if (params) {
        axiosObject.params = params;
    }

    if (headers) {
        axiosObject.headers = headers;
    }

    const API = await initilizeHttpClient(environment.API_URL);
    const response = await API({ ...axiosObject, ...(config || {}) });
    return response;
};

export const isObjectEmpty = (obj: any) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const mapErrorCode = (error: any, t: (key: string) => string): string => {
    const errorData = error?.response?.data?.error;

    if (errorData && errorData.code) {
        const errorMessage = t(errorData.code);
        return errorMessage;
    }

    return t('ERROR.GENERAL');
};

export const formatDate = (dateString: string | undefined) => {
    if (dateString) {
        const date = new Date(dateString);

        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();

        return `${dd}/${mm}/${yyyy}`;
    } else return '';
};

export const buildQueryString = (params: any) => {
    const queryString = Object.keys(params)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
    return queryString;
};

export const removeDiacritics = (text: string) => {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const createSlug = (text: string) => {
    const lowercaseText = removeDiacritics(text.toLowerCase());

    const slug = lowercaseText.replace(/[^\w\s-]/g, '').replace(/[\s]+/g, '-');

    return slug;
};

export const generateCustomUUID = () => {
    const timestamp = new Date().getTime().toString(16);
    const randomPart = Math.random().toString(16).substring(2, 6);
    const uuid = timestamp + randomPart;

    return uuid;
};

export const isNullOrUndefined = (value: any) => {
    return value === null || value === undefined;
};

export const arePropsUndefined = (props: any) => {
    return Object.values(props).some((prop) => prop === undefined || Number.isNaN(prop));
};

export const truncateString = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
        return str.slice(0, maxLength) + '...';
    }
    return str;
};

export const isStringEmpty = (str: string | undefined) => {
    return !str || str.trim() === '';
};

export const saveParamsToLocalStorage = (apiParams: IParams, name: string) => {
    const paramsJSON = JSON.stringify(apiParams);
    localStorage.setItem(name, paramsJSON);
};

export const getParamsFromLocalStorage = (name: string) => {
    const localParams = localStorage.getItem(name) as string;
    return JSON.parse(localParams);
};

export const removeBracketsAndParseNumber = (str: string) => {
    if (str === '') {
        return undefined;
    }
    const regex = /\[(\d+)\]/;
    const match = str.match(regex);

    if (match && match[1]) {
        const number = parseInt(match[1]);
        return number;
    } else {
        return undefined;
    }
};
